import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import type { Storage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	ERROR_LOCATION,
	CAPACITY_EXCEEDED,
	CLEAR,
	DELETE,
	DELETE_STALE_ENTRY,
	GET,
	GET_ENTRIES,
	HYDRATE,
	SET,
	MEMORY,
	LOCAL,
	UPDATE,
} from '../../constants.tsx';
import type { CacheAPI, Props, Entry, Store } from '../../types.tsx';
import defaults, { scopeGuard, CACHE_PREFIX } from '../scope/index.tsx';

const calculateEntryAge = (timestamp: number): number => Date.now() - timestamp;

export function Cache<K, V>(props: Props): CacheAPI<K, V> {
	const cache: Map<K, Entry<V>> = new Map();
	const { size } = props;
	let storage: Storage;
	let hasSyncedFromStorage = false;
	const devTools =
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window && window.__REDUX_DEVTOOLS_EXTENSION__
			? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.__REDUX_DEVTOOLS_EXTENSION__.connect({
					name: `Cache ${props.cacheKey}`,
					serialize: true,
				})
			: null;

	const syncWithDevTools = (
		type: string,
		payload: [K, Entry<V>][] | Entry<V> | Store<K, V> | K,
	) => {
		if (defaults.devTools) {
			devTools && devTools.send({ type, payload }, cache, {}, props.cacheKey);
		}
	};

	const removeExpiredKeysFromStorage = async (store: Store<K, V>): Promise<Store<K, V>> => {
		const updatedStore = { ...store };

		if (props.storageType === MEMORY) {
			return {};
		}

		const { cacheKey, maxAge } = props;
		if (maxAge !== undefined) {
			Object.keys(store).forEach((key) => {
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, Entry<V>>>'.
				const keyAge = calculateEntryAge(store[key].timestamp);
				if (keyAge > maxAge) {
					// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: any; }' is not assignable to parameter of type 'K | Entry<V> | Partial<Record<K, Entry<V>>> | [K, Entry<V>][]'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, Entry<V>>>'.
					syncWithDevTools(DELETE_STALE_ENTRY, { [String(key)]: updatedStore[key] });
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, Entry<V>>>'.
					delete updatedStore[key];
					// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'K'.
					cache.delete(key);
				}
			});
			storage.set(cacheKey, updatedStore);
		}

		return updatedStore;
	};

	const removeExpiredEntry = (key: K, val: Entry<V>) => {
		if (props.storageType !== MEMORY) {
			const { maxAge } = props;
			if (maxAge !== undefined && calculateEntryAge(val.timestamp) > maxAge) {
				// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: Entry<V>; }' is not assignable to parameter of type 'K | Entry<V> | Partial<Record<K, Entry<V>>> | [K, Entry<V>][]'.
				syncWithDevTools(DELETE_STALE_ENTRY, { [String(key)]: val });
				cache.delete(key);
				return true;
			}
		}
		return false;
	};

	const createStore = () => {
		if (props.storageType === LOCAL) {
			storage = createLocalStorageProvider(CACHE_PREFIX);
		} else {
			storage = createSessionStorageProvider(CACHE_PREFIX);
		}
		return storage;
	};

	const set = async (key: string | K, value: V) => {
		await load();

		// @ts-expect-error - TS2345 - Argument of type 'string | K' is not assignable to parameter of type 'K'.
		cache.delete(key);

		const entry: Entry<V> = {
			value,
			timestamp: Date.now(),
		};

		let storedContent: Store<K, V> = {};

		if (props.storageType !== MEMORY) {
			storedContent = storage.get(props.cacheKey) || {};
			// @ts-expect-error - TS2536 - Type 'string | K' cannot be used to index type 'Partial<Record<K, Entry<V>>>'.
			storedContent[key] = entry;
		}

		// @ts-expect-error - TS2345 - Argument of type 'string | K' is not assignable to parameter of type 'K'.
		cache.set(key, entry);
		// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: Entry<V>; }' is not assignable to parameter of type 'K | Entry<V> | Partial<Record<K, Entry<V>>> | [K, Entry<V>][]'.
		syncWithDevTools(SET, { [String(key)]: entry });

		if (cache.size > size) {
			const keys = cache.keys();
			const firstEntry = keys.next().value;

			if (firstEntry !== undefined) {
				cache.delete(firstEntry);
				syncWithDevTools(CAPACITY_EXCEEDED, firstEntry);

				if (props.storageType !== MEMORY) {
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<K, Entry<V>>>'.
					delete storedContent[firstEntry];
				}
			}
		}

		props.storageType !== MEMORY && storage.set(props.cacheKey, storedContent);
	};

	const load = async () => {
		if (__SERVER__ || hasSyncedFromStorage) {
			return;
		}

		hasSyncedFromStorage = true;
		if (props.storageType === MEMORY) {
			return;
		}

		const { cacheKey } = props;
		storage = createStore();

		try {
			let data: Store<K, V> = storage.get(cacheKey) || {};

			data = await removeExpiredKeysFromStorage(data);
			syncWithDevTools(HYDRATE, data);

			const orderedKeys = Object.keys(data).sort(
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, Entry<V>>>'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, Entry<V>>>'.
				(key1, key2) => data[key1].timestamp - data[key2].timestamp,
			);

			await Promise.all(
				orderedKeys.map(async (key) => {
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<K, Entry<V>>>'.
					await set(key, data[key].value);
				}),
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			log.safeWarnWithoutCustomerData(
				ERROR_LOCATION,
				`There was an error syncing with storage - ${cacheKey}`,
				err,
			);
			throw err;
		}
	};

	const clear = async () => {
		await load();
		cache.clear();

		if (props.storageType !== MEMORY) {
			const { cacheKey } = props;
			storage.remove(cacheKey);
		}
		syncWithDevTools(CLEAR, {});
	};

	const get = async (key: K) => {
		await load();

		let val = cache.get(key);
		if (val) {
			if (removeExpiredEntry(key, val)) {
				val = undefined;
			}
		}

		// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: Entry<V> | undefined; }' is not assignable to parameter of type 'K | Entry<V> | Partial<Record<K, Entry<V>>> | [K, Entry<V>][]'.
		syncWithDevTools(GET, { [String(key)]: val });
		return val && val.value;
	};

	const update = async (key: K, value: V) => {
		await load();

		const previousValue = cache.get(key);
		if (!previousValue) {
			return;
		}

		const entry: Entry<V> = {
			value,
			timestamp: previousValue.timestamp,
		};

		let storedContent: Store<K, V> = {};

		if (props.storageType !== MEMORY) {
			storedContent = storage.get(props.cacheKey) || {};
			storedContent[key] = entry;
		}

		cache.set(key, entry);
		// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: Entry<V>; }' is not assignable to parameter of type 'K | Entry<V> | Partial<Record<K, Entry<V>>> | [K, Entry<V>][]'.
		syncWithDevTools(UPDATE, { [String(key)]: entry });

		props.storageType !== MEMORY && storage.set(props.cacheKey, storedContent);
	};

	const getEntries = async () => {
		await load();

		const entries = Array.from(cache.entries()).filter(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			([key, value]: [any, any]) => !removeExpiredEntry(key, value),
		);

		syncWithDevTools(GET_ENTRIES, entries);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return entries.map(([key, { value }]: [any, any]) => [key, value]);
	};

	const deleteEntry = async (key: K) => {
		await load();

		if (props.storageType !== MEMORY) {
			const storedContent = storage.get(props.cacheKey) || {};
			delete storedContent[key];
			storage.set(props.cacheKey, storedContent);
		}

		cache.delete(key);
		// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: Entry<V> | undefined; }' is not assignable to parameter of type 'K | Entry<V> | Partial<Record<K, Entry<V>>> | [K, Entry<V>][]'.
		syncWithDevTools(DELETE, { [String(key)]: cache.get(key) });
	};

	const refresh = async () => {
		if (props.storageType === MEMORY) {
			return;
		}

		cache.clear();
		hasSyncedFromStorage = false;
		await load();
	};

	if (defaults.scopes.current !== defaults.scopes.prev) {
		clear();
	}

	return {
		cache,
		get,
		set,
		delete: deleteEntry,
		clear,
		load,
		update,
		// @ts-expect-error - TS2322 - Type '() => Promise<any[][]>' is not assignable to type '() => Promise<[K, V][]>'.
		getEntries,
		refresh,
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cacheMap: Record<string, any> = {};

const getCache: <K, V>(props: Props) => Promise<CacheAPI<K, V>> = async (props) => {
	await scopeGuard().catch((error) => {
		log.safeErrorWithoutCustomerData(
			'common.cache.scope',
			'There was a failure within cache scopeGuard',
			error,
		);
	});

	const { cacheKey } = props;
	// @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
	cacheMap[cacheKey] = cacheMap[cacheKey] || new Cache(props);
	return cacheMap[cacheKey];
};

/**
 * @deprecated intended to be used only by test code
 */
export const clearJiraFrontendCache = () => {
	Object.values(cacheMap).forEach((cache) => cache.clear());
};

export default getCache;

export type Config = Props;
