import type {
	ComponentType,
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
} from 'react';
import { type WithContextProps, withAnalyticsContext } from '@atlaskit/analytics-next';
import mapPayload from '../utils/map-data-payload-to-context-payload.tsx';
import type { AnalyticsDataPayload } from '../utils/types.tsx';

type ContextResult<C, P> = ForwardRefExoticComponent<
	PropsWithoutRef<JSX.LibraryManagedAttributes<C, P & WithContextProps>> & RefAttributes<C>
>;

/**
 * @deprecated - Just provide the data when firing analytics events.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (payload: AnalyticsDataPayload) =>
	<C extends ComponentType<P>, P = React.ComponentProps<C>>(
		WrappedComponent: C,
	): ContextResult<C, P> =>
		withAnalyticsContext(mapPayload(payload))(WrappedComponent);
