import { NO_NAVIGATION } from '../../model/navigation/index.tsx';
import { SET_CONSUMER_STATE, type SetConsumerStateAction } from './actions.tsx';
import type { ConsumerState } from './types.tsx';

type Action = SetConsumerStateAction;

const initialState = {
	maxDepth: 0,
	addLinkCaption: undefined,
	DetailsPanelComponent: undefined,
	NonTemporaryCellWrapper: undefined,
	autoHeight: true,
	showShadowAtBottom: false,
	navigationMode: NO_NAVIGATION,

	rowTree: {
		rows: {},
		rootIds: [],
	},
	rowsConfiguration: {},
	addedRows: undefined,
	expandedRowIds: [],

	columnTree: {
		columns: {},
		rootCoreIds: [],
		rootAdditionalIds: [],
	},
	expandedColumnIds: [],
	columnWidths: {},
	sortedColumnIds: undefined,
	draggableColumns: false,
	LoadingRowComponent: undefined,
	callbacks: {},
} as const;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state: ConsumerState = initialState as any, action: Action): ConsumerState => {
	switch (action.type) {
		case SET_CONSUMER_STATE: {
			return action.payload;
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
