// @ts-expect-error - TS2305 - Module '"reselect"' has no exported member 'SelectorCreator'.
import { createSelectorCreator, defaultMemoize, type SelectorCreator } from 'reselect';
import { createMemorizer, toReturnValue } from './weakmap-memoize/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const weakMapMemoize = (func: any) => {
	const memorizer = createMemorizer();

	// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
	return (...args) => {
		const cached = memorizer.read(args);
		if (cached !== undefined) {
			return toReturnValue(cached);
		}

		const result = func.apply(func, args);

		memorizer.store(args, result);

		return result;
	};
};

// @ts-expect-error - TS2345 - Argument of type '(func: any) => (...args: any[]) => any' is not assignable to parameter of type '<F extends Function>(func: F) => F'.
export const createWeakMapSelector: SelectorCreator = createSelectorCreator(weakMapMemoize);

export const createSelector: SelectorCreator = createSelectorCreator(defaultMemoize);
