import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	anonymous: {
		id: 'issue-table.common.fields.reporter.anonymous',
		defaultMessage: 'Anonymous',
		description: 'Text to show for an anonymous user',
	},
	inactive: {
		id: 'issue-table.common.fields.reporter.inactive',
		defaultMessage: '{name} (Inactive)',
		description: 'Text to show to indicate an inactive user',
	},
});
