import type { Selector } from 'reselect';
/**
 * This allows switching redux selectors based on a condition.
 * @param condition condition A predicate that returns true or false, used to decide which selector to return. This is typically a feature flag function.
 * @param selectorTrue This is the selector which is returned when the condition is true
 * @param selectorFalse This is the selector which is returned when the condition is false
 * @returns A selector that matches the condition
 */

export const selectorWithCondition = <S, R>(
	condition: () => boolean,
	selectorTrue: Selector<S, R>,
	selectorFalse: Selector<S, R>,
): Selector<S, R> => {
	const selector: Selector<S, R> = (...args) => {
		if (condition()) {
			return selectorTrue(...args);
		}
		return selectorFalse(...args);
	};
	return selector;
};
