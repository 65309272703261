export const PERSISTENT_UPGRADE_PRE_TRIAL_FREE_EDITION = 'pre-trial';
export const PERSISTENT_UPGRADE_ANALYTICS_SOURCE = 'persistentUpgrade';
export const PERSISTENT_UPGRADE_ANALYTICS_UPGRADE_FLOW = 'upgradeFlow';
export const PERSISTENT_UPGRADE_UPFLOW_MODAL_FLOW = 'persistent-upgrade';
export const PERSISTENT_UPGRADE_TOUCHPOINT_BANNER = 'persistentUpgradeBanner';
export const PERSISTENT_UPGRADE_TOUCHPOINT_BANNER_VIEWED = 'persistentUpgradeBanner viewed';
export const MAX_CADENCE_INDEX = 3;
export const KEY_USER_PROPERTIES_JSW = 'growth-puet-banner-cadence';
export const KEY_USER_PROPERTIES_JSM = 'growth-puet-banner-cadence-jsm';
export const BANNER_CADENCE_INFO = 'Banner Cadence Info';
export const SEND_OPERATIONAL_EVENT = 'sendOperationalEvent';
export const API_SUCCESS = 'success';
export const API_FAIL = 'failed';
export const GET_USER_PROPERTY_API_CALL = 'getUserPropertyApiCall';
export const GET_USER_PROPERTY_API_CALL_SUCCESS = 'getUserPropertyApiCall success';
export const GET_USER_PROPERTY_API_CALL_FAIL = 'getUserPropertyApiCall failed';
export const SET_USER_PROPERTY_API_CALL = 'setUserPropertyApiCall';
export const SET_USER_PROPERTY_API_CALL_SUCCESS = 'setUserPropertyApiCall success';
export const SET_USER_PROPERTY_API_CALL_FAIL = 'setUserPropertyApiCall failed';

export const PERSISTENT_PRETRIAL_BANNER_CADENCES_MS = [
	0,
	7 * 86400000,
	21 * 86400000,
	42 * 86400000,
] as const;
