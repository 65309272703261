import { useMemo, useRef } from 'react';
import lodashIsEqual from 'lodash/isEqual';

export const useMemoizedStateObject = <T,>(
	data: T,
	equalityCheck: (a: unknown, b: unknown) => boolean = lodashIsEqual,
): T => {
	const memoizedData = useRef(data);
	return useMemo(() => {
		if (!equalityCheck(data, memoizedData.current)) {
			memoizedData.current = data;
			return data;
		}
		return memoizedData.current;
	}, [data, equalityCheck]);
};
