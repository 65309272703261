import type { ComponentType } from 'react';
import type { State } from '../../state/reducers/types.tsx';
import { mergeIssueKeysWithLockedIssues } from '../../state/selectors/issues/index.tsx';
import { transformProps } from '../../state/selectors/table-props/index.tsx';
import { getRefreshSidebar, getRenderSidebarIcon } from '../../state/selectors/ui/index.tsx';
import { connect } from '../../state/store/index.tsx';
import Table, { type Props } from './view.tsx';

const mapStateToProps = (state: State) => ({
	issueKeys: mergeIssueKeysWithLockedIssues(state),
	transformProps,
	renderSidebarIcon: getRenderSidebarIcon(state),
	refreshSidebar: getRefreshSidebar(state),
});

// @ts-expect-error - TS2322 - Type 'ComponentClass<Omit<DispatchProp<any>, "dispatch">> & { WrappedComponent: Component<DispatchProp<any>>; }' is not assignable to type 'ComponentType<Props>'. | TS2554 - Expected 3-4 arguments, but got 1. | TS2345 - Argument of type 'typeof Table' is not assignable to parameter of type 'Component<DispatchProp<any>>'.
const ConnectedIssueTable: ComponentType<Props> = connect(mapStateToProps)(Table);

export default ConnectedIssueTable;
