import type { RowId } from '../../../../model/rows/index.tsx';

export const SET_MULTI_ADD_ANCHOR = 'ops.rows.add-anchor.set.SET_MULTI_ADD_ANCHOR' as const;

export type SetMultiAddAnchorAction = {
	type: typeof SET_MULTI_ADD_ANCHOR;
	payload: RowId;
};

export const set = (rowId: RowId): SetMultiAddAnchorAction => ({
	type: SET_MULTI_ADD_ANCHOR,
	payload: rowId,
});
