import React, { type ComponentType } from 'react';
import type { ColumnId } from '../../../../model/columns/index.tsx';

type ComponentProps = {
	id: ColumnId;
};

type Props = {
	id: ColumnId;
	DynamicColumnComponent: ComponentType<ComponentProps>;
};

export default function Column(props: Props) {
	const { id, DynamicColumnComponent } = props;
	return <DynamicColumnComponent id={id} />;
}
