import {
	type ErrorPayload,
	trackFetchErrors,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { JsdFetchError } from '../fetch/requests.tsx';

export const ANALYTIC_CONTEXTS = {
	AUTOMATION_SETTINGS: 'AutomationSettings',
	KNOWLEDGE: 'Knowledge',
	QUEUES: 'Queues',
	CSAT: 'Csat',
} as const;

export const ttrRecommendedSlo = 1000;
export const ttiRecommendedSlo = 4000;

export const trackJsdFetchErrors = async (payload: ErrorPayload) => {
	const { error, attributes } = payload;
	if (error instanceof JsdFetchError) {
		const { reasonKey } = error;
		trackFetchErrors({
			...payload,
			attributes: { ...attributes, reasonKey },
		});
		return;
	}
	trackFetchErrors(payload);
};
