import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type JSM_MAJOR_INCIDENT_FIELD,
	MAJOR_INCIDENT_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggJSMMajorIncidentToLegacyField: AggTransformerFunction<
	typeof JSM_MAJOR_INCIDENT_FIELD
> = (node) => ({
	...transformAggNodeToLegacyField(node),
	value: node.majorIncident,
	renderedValue: null,
	configuration: {},
	schema: {
		configuration: null,
		custom: MAJOR_INCIDENT_CF_TYPE,
		items: null,
		renderer: PLAIN_TEXT,
		system: null,
		type: ISSUE_FIELD_STRING_CF_TYPE,
	},
});
