import type { Actions } from './types.tsx';

const actions: Actions = {
	setIssueKeyContext:
		(issueKey) =>
		({ setState }) =>
			setState({ issueKeyContext: issueKey }),
};

export default actions;
