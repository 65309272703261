import React, { Component } from 'react';
import AddCircleIcon from '@atlaskit/icon/core/migration/add--add-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type IntlShape, defineMessages, injectIntl } from '@atlassian/jira-intl';
import type { RowAddCallback } from '../../../../../../../model/callbacks/index.tsx';
import type { Optional } from '../../../../../../../model/optional/index.tsx';
import type { RowId, RelativePosition } from '../../../../../../../model/rows/index.tsx';
import { Wrapper, IconWrapper } from './styles.tsx';

const messages = defineMessages({
	addIcon: {
		id: 'virtual-table.table.content.rows.common.default-row.add-bar.add-icon',
		defaultMessage: 'Click to add row',
		description: '',
	},
});

type Props = {
	rowId: RowId;
	addPosition: RelativePosition;
	isRowExpanded: boolean;
	canAddChildren: boolean;
	canAddSibling: boolean;
	onRowAddRequested: Optional<RowAddCallback>;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class AddBar extends Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		const { rowId, isRowExpanded, canAddChildren, canAddSibling } = this.props;

		return (
			rowId !== nextProps.rowId ||
			canAddChildren !== nextProps.canAddChildren ||
			canAddSibling !== nextProps.canAddSibling ||
			isRowExpanded !== nextProps.isRowExpanded
		);
	}

	onRowAddRequested = () => {
		const { rowId, addPosition, onRowAddRequested } = this.props;

		onRowAddRequested &&
			onRowAddRequested({
				anchorId: rowId,
				position: addPosition,
			});
	};

	renderAddBar() {
		const {
			intl: { formatMessage },
			isRowExpanded,
			rowId,
		} = this.props;

		return (
			<Wrapper
				isIndented={isRowExpanded}
				onClick={this.onRowAddRequested}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.default-row.add-bar.wrapper-${rowId}`}
			>
				<IconWrapper>
					<AddCircleIcon
						color={token('color.icon.accent.green', colors.G200)}
						label={formatMessage(messages.addIcon)}
					/>
				</IconWrapper>
			</Wrapper>
		);
	}

	render() {
		const { canAddChildren, canAddSibling, addPosition } = this.props;

		const canAddAtPosition =
			(addPosition === 'INSIDE' && canAddChildren) || (addPosition === 'AFTER' && canAddSibling);

		return canAddAtPosition ? this.renderAddBar() : null;
	}
}

export default injectIntl(AddBar);
