import type { Action } from '@atlassian/react-sweet-state';
import { BULK_SELECTION_LIMIT } from '../../constants.tsx';
import type { State } from '../../types.tsx';

/**
 * Used by smart-suggestions/related-issues-modal which lets users select issues.
 * The limit for the modal's selected issues must be refreshed so that the total
 * queue view selected issues + modal selected issues does not exceed
 * BULK_SELECTION_LIMIT.
 */
export const refreshSelectionLimit =
	(numSelected: number): Action<State> =>
	({ setState }) => {
		if (numSelected > 0) {
			setState({
				limit: Math.max(BULK_SELECTION_LIMIT - numSelected, 0),
			});
		}
	};
