import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getProjectKey } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import type { State } from '../../../../../state/reducers/types.tsx';
import { getCompleteJql } from '../../../../../state/selectors/queue/index.tsx';
import { OpenInIssueNavButton } from './view.tsx';

const mapStateToProps = (state: State) => ({
	projectKey: getProjectKey(state),
	queueJql: getCompleteJql(state),
});

export default connect(mapStateToProps, {})(OpenInIssueNavButton);
