import React, { Component } from 'react';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { end } from '@atlassian/servicedesk-frontend-apdex';

type Props = {
	context?: string | undefined;
};

/**
 * @deprecated use UFO instead
 *
 * This component should be rendered once a page is deemed ready for user interaction. The APDEX
 * event to end will be determined from the current route.
 *
 * This will end the currently running performance metrics (i.e., for APDEX).
 * Each page (route) should mount this component once.
 *
 * If a page will need to fire multiple APDEX events over its lifetime, the
 * "context" prop should be passed and should change with each event which is
 * relevant for APDEX.
 *
 * This component should be rendered into the DOM only once, as the first child and as high-up in
 * the tree as possible, AFTER any required rendering has completed. It should be placed so that
 * #componentDidMount of this component (which captures the timing) is called as early as possible
 * after the DOM is ready-for-user (before all other #componentDidMount calls of child components).
 *
 * Side note: interestingly, if you have 2 nested components like <A><B/></A>, React will call
 * #componentDidMount of <B> first (from testing on React 15/16), however this should not be a
 * problem, provided that this is rendered as one of the first children and since this component
 * will be a leaf.
 *
 * From measurements on a local dev machine, the time between the DOM being rendered and the
 * ready-for-user checkpoint is only a few milliseconds at most.
 */
export const ReadyForUser = (props: Props) => <ReadyForUserInner {...props} />;

type PropsInner = Props & {
	onLoadEnd: (arg1: string) => void;
};

/**
 * Use this component for storybooks or other test components that don't want to be connected to the
 * "real" servicedesk-frontend-apdex library.
 */
// eslint-disable-next-line jira/react/no-class-components
export class ReadyForUserInner extends Component<PropsInner> {
	static defaultProps = {
		context: undefined,
		onLoadEnd: end,
	};

	componentDidMount() {
		this.recordEndMetric();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.context !== this.props.context) {
			this.recordEndMetric();
		}
	}

	recordEndMetric() {
		const { onLoadEnd } = this.props;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const location = window.location.pathname;
		setMark('jsd.performance.profile.record.metric.end');
		onLoadEnd(location);

		// Unfortunately the apdex key for this legacy apdex mark is calculated outside of the jira-frontend
		// in cypress 'location' ends up being 'iframe.html' so we can't actually check if the correct metric key is being triggered
		// we can at least check if it has been triggered at all
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line jira/import/webpack-magic-comment-entries
			import('@atlassian/jira-common-integration-tests/src/signal/emit').then(({ emitSignal }) =>
				emitSignal('apdex.submited'),
			);
		}
	}

	render() {
		return null;
	}
}
