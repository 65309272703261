import type { RefObject } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import debounce from 'lodash/debounce';

/**
 * A `ResizeSubscriber` is a single element being observed and its callback
 */
export interface ResizeSubscriber {
	element: Element;
	callback: () => void;
}

/**
 * This class implements "de-multiplexing" ResizeObserver resize events onto
 * multiple subscriber callbacks.
 *
 * Each "group" will create a single shared ResizeObserver. When an element is
 * 'observed' it is both added to this observer and added to a subscribers Map.
 * When a resize event happens, only the elements that were resized are added to
 * a pending updates map.
 *
 * After a configurable debounce period, the elements that were resized have
 * their callbacks called. Elements that are unmounted before the flush aren't
 * looped through. All callbacks run under a single `unstable_batchedUpdates`
 * block, which should result in only one React update. For most cases, only one
 * group should be created for the entire application and should deliver best
 * performance.
 *
 * Multiple groups can be created if groups of elements are known to never
 * resize together. As long as elements resize together in the same frame, a
 * single group will deliver better performance. The profile/need for this
 * should be looser with React 18, since automatic batching would eclipse the
 * need for some of the concerns. However, debouncing and 'demuxing' events
 * would still be present with React 18.
 *
 * Map is used for lookup/update/delete complexity, however since both
 * pending/subscriber lists should be very small an Array could have better
 * performance.
 */
export class ResizeObserverGroup {
	private observer: ResizeObserver | null;

	private subscribers: Map<Element, ResizeSubscriber> = new Map();

	private pendingUpdates: Map<Element, ResizeSubscriber> = new Map();

	private scheduleFlush: () => void;

	constructor(debouncePeriod = 16) {
		this.observer =
			typeof ResizeObserver !== 'undefined' ? new ResizeObserver(this.onResize) : null;
		this.scheduleFlush =
			debouncePeriod > 0
				? debounce(
						() => {
							this.flush();
						},
						debouncePeriod,
						{
							trailing: true,
							maxWait: 32, // targeting ~30fps
						},
					)
				: () => {
						this.flush();
					};
	}

	observe(ref: RefObject<HTMLElement>, callback: () => void): () => void {
		if (!ref.current) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		this.subscribers.set(ref.current, {
			element: ref.current,
			callback,
		});
		this.observer?.observe(ref.current);

		return () => {
			if (!ref.current) {
				return;
			}

			this.subscribers.delete(ref.current);
			this.pendingUpdates.delete(ref.current);
			this.observer?.unobserve(ref.current);
		};
	}

	private onResize = (entries: ResizeObserverEntry[]) => {
		entries.forEach((entry) => {
			const subscriber = this.subscribers.get(entry.target);
			if (subscriber) {
				this.pendingUpdates.set(subscriber.element, subscriber);
			}
		});

		this.scheduleFlush();
	};

	flush() {
		const entries = Array.from(this.pendingUpdates.values()).filter((entry) =>
			this.subscribers.has(entry.element),
		);

		this.pendingUpdates.clear();
		unstable_batchedUpdates(() => {
			entries.forEach(({ callback }) => {
				callback();
			});
		});
	}
}
