import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import {
	getExperienceTrackingInitialFetchStatus,
	getInitialFetchErrorEventAttributes,
} from '../../../../state/selectors/experience-tracking/index.tsx';
import Tracker from './view.tsx';

const mapStateToProps = (state: State) => ({
	initialFetchStatus: getExperienceTrackingInitialFetchStatus(state),
	initialFetchFailureEventAttributes: getInitialFetchErrorEventAttributes(state),
});

export default connect(mapStateToProps, {})(Tracker);
