import type { UserProps } from '../../../../../model/fields/json-fields/common/types.tsx';

export type HighlightRange = {
	start: number;
	end: number;
};

// Follows the user type in @atlaskit/user-picker
export type UserPickerUser = {
	id: string;
	avatarUrl?: string;
	name: string;
	publicName?: string;
	highlight?: {
		name?: HighlightRange[];
		publicName?: HighlightRange[];
	};
	fixed?: boolean;
	byline?: string;
};

export type UserForEditing = UserPickerUser & {
	value?: UserProps;
};

export const ASSIGNEE_CONTEXT = 'ASSIGNEE' as const;
export const DEFAULT_CONTEXT = 'DEFAULT' as const;
export type FieldContext = typeof ASSIGNEE_CONTEXT | typeof DEFAULT_CONTEXT;
