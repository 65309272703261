import type { SortOrderConfiguration } from '../../../../model/columns/index.tsx';

export const SET_COLUMN_SORT_ORDER = 'ops.columns.sorting.set.SET_COLUMN_SORT_ORDER' as const;

export type SetColumnSortOrderAction = {
	type: typeof SET_COLUMN_SORT_ORDER;
	payload: SortOrderConfiguration;
};

export const set = (payload: SortOrderConfiguration): SetColumnSortOrderAction => ({
	type: SET_COLUMN_SORT_ORDER,
	payload,
});
