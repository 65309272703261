import React from 'react';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';

export default function FieldRenderedValue(props: JsonComponentProps<'creator'>) {
	const {
		dataSelectorProps: {
			value: { displayName },
		},
	} = props;
	return <div>{displayName}</div>;
}
