// use USER_JSM_PROJECT_ROLE instead
const AGENT_ROLE = 'agent' as const;
const AGENT_PROJECT_ADMIN_ROLE = 'agent-project-admin' as const;
const JIRA_ROLE = 'jira-user' as const;

export const USER_JSM_PROJECT_ROLE = {
	AGENT_ROLE,
	AGENT_PROJECT_ADMIN_ROLE,
	JIRA_ROLE,
} as const;
