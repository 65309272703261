import type {
	VerticalScrollOffsetChangeCallback,
	VerticalPosition,
} from '../../../model/index.tsx';

// UPDATE_VERTICAL_OFFSET

export const UPDATE_VERTICAL_OFFSET = 'state.actions.offsets.UPDATE_VERTICAL_OFFSET' as const;

export type UpdateVerticalOffsetAction = {
	type: typeof UPDATE_VERTICAL_OFFSET;
	payload: {
		totalPages: number;
		verticalPosition: VerticalPosition;
		onVerticalScrollOffsetChanged: VerticalScrollOffsetChangeCallback | undefined;
	};
};

export const updateVerticalOffsetAction = (
	totalPages: number,
	verticalPosition: VerticalPosition,
	onVerticalScrollOffsetChanged?: VerticalScrollOffsetChangeCallback,
): UpdateVerticalOffsetAction => ({
	type: UPDATE_VERTICAL_OFFSET,
	payload: {
		totalPages,
		verticalPosition,
		onVerticalScrollOffsetChanged,
	},
});

// SET_VERTICAL_OFFSET

export const SET_VERTICAL_OFFSET = 'state.actions.offsets.SET_VERTICAL_OFFSET' as const;

export type SetVerticalOffsetAction = {
	type: typeof SET_VERTICAL_OFFSET;
	payload: {
		verticalOffset: number;
	};
};

export const setVerticalOffsetAction = (verticalOffset: number): SetVerticalOffsetAction => ({
	type: SET_VERTICAL_OFFSET,
	payload: {
		verticalOffset,
	},
});

// SET_HORIZONTAL_OFFSET

export const SET_HORIZONTAL_OFFSET = 'state.actions.offsets.SET_HORIZONTAL_OFFSET' as const;

export type SetHorizontalOffsetAction = {
	type: typeof SET_HORIZONTAL_OFFSET;
	payload: {
		horizontalOffset: number;
	};
};

export const setHorizontalOffsetAction = (horizontalOffset: number): SetHorizontalOffsetAction => ({
	type: SET_HORIZONTAL_OFFSET,
	payload: {
		horizontalOffset,
	},
});
