import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	previousButton: {
		id: 'issue-table.pagination.previous-button',
		defaultMessage: 'Prev',
		description: 'Previous page button of pagination at bottom of issue table',
	},
	nextButton: {
		id: 'issue-table.pagination.next-button',
		defaultMessage: 'Next',
		description: 'Next page button of pagination at bottom of issue table',
	},
});
