import type { AppPropsState } from '@atlassian/jira-servicedesk-common/src/model/types.tsx';
import { type Action, RESET_PROJECT_CONTEXT } from '../../actions/app-props/index.tsx';

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const reducer = (state?: AppPropsState, action: Action) => {
	switch (action.type) {
		case RESET_PROJECT_CONTEXT: {
			return {
				...state,
				...action.payload.projectContext,
			};
		}

		default: {
			// @ts-expect-error - TS2322 - Type 'ResetProjectContextAction' is not assignable to type 'never'.
			const _exhaustiveCheck: never = action;
			return state || null;
		}
	}
};

export default reducer;
