import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/map';
import { LOAD_ISSUES_ACTION_SOURCE_SORT } from '../../../model/index.tsx';
import { loadIssuesAction } from '../../../state/actions/issue/index.tsx';
import { RESET_SORT_ORDER } from '../../../state/actions/table/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>) =>
	action$.ofType(RESET_SORT_ORDER).map(() => loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_SORT));
