// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UNDEFINED: any = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NULL: any = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toStoreKey = (key: any): any => {
	if (key === undefined) {
		return UNDEFINED;
	}
	if (key === null) {
		return NULL;
	}
	return key;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toReturnValue = (x: any): any => {
	if (x === UNDEFINED) {
		return undefined;
	}
	if (x === NULL) {
		return null;
	}
	return x;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFromWeakMapChain = (args: any[], index: number, map: WeakMap<any, any>): any => {
	const element = map.get(toStoreKey(args[index]));
	if (index < args.length - 1) {
		if (element) {
			return getFromWeakMapChain(args, index + 1, element);
		}
		return undefined;
	}
	return element;
};

const storeToEmptyWeakMapChain = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	args: any[],
	index: number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	map: WeakMap<any, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	result: any,
): void => {
	if (index < args.length - 1) {
		const subMap = new WeakMap();
		map.set(toStoreKey(args[index]), subMap);
		storeToEmptyWeakMapChain(args, index + 1, subMap, result);
	} else {
		map.set(toStoreKey(args[index]), result);
	}
};

const storeToWeakMapChain = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	args: any[],
	index: number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	map: WeakMap<any, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	result: any,
): void => {
	if (index < args.length - 1) {
		const element = map.get(toStoreKey(args[index]));
		if (element) {
			storeToWeakMapChain(args, index + 1, element, result);
		} else {
			const subMap = new WeakMap();
			map.set(toStoreKey(args[index]), subMap);
			// we know that from now on, all chain elements need to be new maps,
			// so we move to a slightly more performent recursion
			storeToEmptyWeakMapChain(args, index + 1, subMap, result);
		}
	} else {
		map.set(toStoreKey(args[index]), result);
	}
};

export type WeakMapMemorizer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: (args: any[], result?: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	read: (args: any[]) => any;
};

export const createMemorizer = (): WeakMapMemorizer => {
	const weakMap = new WeakMap();

	return {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: (args: any[], result: any) =>
			result !== undefined
				? storeToWeakMapChain(args, 0, weakMap, result)
				: storeToWeakMapChain(args, 0, weakMap, UNDEFINED),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		read: (args: any[]): any => getFromWeakMapChain(args, 0, weakMap),
	};
};
