import React, { Component, type ReactNode, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	isDisabled: boolean;
	children: ReactNode;
	activeIssueKey: string | undefined;
	onOpenIssue: () => void;
};

const AnalyticsWrapper = AnalyticsEventToProps('issue', {
	onKeyDown: 'pressed',
})(({ children, onKeyDown }) => (
	<Wrapper role="presentation" onKeyDown={onKeyDown}>
		{children}
	</Wrapper>
));

export const privacySafeRowId = (
	arg: string,
): {
	numericalValue: number | null;
	containsHyphen: boolean;
	length: number;
} => {
	const argSplitByHyphen: string[] = arg.split('-');
	return {
		containsHyphen: argSplitByHyphen.length > 1,
		numericalValue: parseInt(argSplitByHyphen[argSplitByHyphen.length - 1], 10),
		length: arg.length,
	};
};

// eslint-disable-next-line jira/react/no-class-components
export default class KeyboardShortcutListener extends Component<Props> {
	static defaultProps = {
		isDisabled: false,
	};

	onKeyDown = (event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => {
		const { onOpenIssue, activeIssueKey, isDisabled } = this.props;
		if (!isDisabled && activeIssueKey != null) {
			switch (event.key) {
				case 'o':
					fireUIAnalytics(analyticsEvent, 'openIssue', {
						keyPressed: 'o',
						issueKey: privacySafeRowId(activeIssueKey),
					});
					onOpenIssue();
					event.preventDefault();
					break;
				case 'Enter':
					fireUIAnalytics(analyticsEvent, 'openIssue', {
						keyPressed: 'enter',
						issueKey: privacySafeRowId(activeIssueKey),
					});
					onOpenIssue();
					event.preventDefault();
					break;
				default:
					break;
			}
		}
	};

	render() {
		return (
			<AnalyticsWrapper onKeyDown={this.onKeyDown} role="presentation">
				{this.props.children}
			</AnalyticsWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	height: '100%',
});
