import flow from 'lodash/flow';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getIsCurrentDataFiltered } from '../../../state/selectors/filter/index.tsx';
import { getIssueCount } from '../../../state/selectors/issues/index.tsx';
import { getProjectStateHash } from '../../../state/selectors/update-metadata/index.tsx';
import NavUpdater from './view.tsx';

const mapStateToProps = (state: State) => ({
	issueCount: getIssueCount(state),
	projectStateHash: getProjectStateHash(state),
	isCurrentDataFiltered: getIsCurrentDataFiltered(state),
});

export default flow(
	connect(mapStateToProps, {}),
	AnalyticsEventToProps('navUpdate', {
		onProjectStateHashUpdate: 'queuesCountsRefreshed',
	}),
)(NavUpdater);
