export const BOARD_SIDEBAR = 'BOARD_SIDEBAR' as const;

export const EDIT_FIELD = 'EDIT_FIELD' as const;

export const GIVE_FEEDBACK = 'GIVE_FEEDBACK' as const;

export const SHOW_MORE = 'SHOW_MORE' as const;

export const STATUS_AND_APPROVAL_SPOTLIGHT = 'status-and-approval-spotlight' as const;
export const STATUS_SPOTLIGHT = 'status-spotlight' as const;
export const REPORTER_SPOTLIGHT = 'reporter-spotlight' as const;
export const ISSUE_FIELD_SPOTLIGHT_PREFIX = 'global-spotlight-target-' as const;

export const FIELDS_PERFORMANCE_KEYS = {
	ASSIGNEE: {
		TIME_TO_RENDER_INITIAL_SUGGESTIONS:
			'jira.fe.issue.fields.assignee.time-to.render-initial-suggestions',
		TIME_TO_REFLECT_INPUT_RESULT: 'jira.fe.issue.fields.assignee.time-to.reflect-input-results',
		TIME_TO_REFLECT_SELECTED: 'jira.fe.issue.fields.assignee.time-to.reflect-selected',
		TIME_TO_ASSIGN_TO_ME_VIA_SHORTCUT:
			'jira.fe.issue.fields.assignee.time-to.assign-to-me-via-shortcut',
	},
	SUMMARY: {
		TIME_TO_RENDER_EDIT_VIEW: 'jira.fe.issue.field.summary.time-to.render-edit-view',
		TIME_TO_RENDER_READ_VIEW_ON_CONFIRM:
			'jira.fe.issue.field.summary.time-to.render-read-view-on-confirm',
		TIME_TO_RENDER_READ_VIEW_ON_CANCEL:
			'jira.fe.issue.field.summary.time-to.render-read-view-on-cancel',
	},
	LABELS: {
		TIME_TO_RENDER_INITIAL_SUGGESTIONS:
			'jira.fe.issue.fields.labels.time-to.render-initial-suggestions',
		TIME_TO_REFLECT_INPUT_RESULT: 'jira.fe.issue.fields.labels.time-to.reflect-input-results',
		TIME_TO_RENDER_READ_VIEW_ON_CONFIRM:
			'jira.fe.issue.field.labels.time-to.render-read-view-on-confirm',
		TIME_TO_RENDER_READ_VIEW_ON_CANCEL:
			'jira.fe.issue.field.labels.time-to.render-read-view-on-cancel',
		TIME_TO_REFLECT_SELECTED_EXISTING:
			'jira.fe.issue.fields.labels.time-to.reflect-selected-existing',
		TIME_TO_REFLECT_SELECTED_NEW: 'jira.fe.issue.fields.labels.time-to.reflect-selected-new',
		TIME_TO_REFLECT_REMOVED_EXISTING:
			'jira.fe.issue.fields.labels.time-to.reflect-removed-existing',
	},
	STORY_POINTS: {
		TIME_TO_RENDER_EDIT_VIEW: 'jira.fe.issue.field.story-points.time-to.render-edit-view',
		TIME_TO_RENDER_READ_VIEW_ON_CONFIRM:
			'jira.fe.issue.field.story-points.time-to.render-read-view-on-confirm',
		TIME_TO_RENDER_READ_VIEW_ON_CANCEL:
			'jira.fe.issue.field.story-points.time-to.render-read-view-on-cancel',
	},
	ISSUE_LINKS: {
		TIME_TO_REFLECT_SELECTED_ISSUE:
			'jira.fe.issue.fields.issue-links.time-to.reflect-selected-issue',
		TIME_TO_REFLECT_REMOVED_ISSUE: 'jira.fe.issue.fields.issue-links.time-to.reflect-removed-issue',
		TIME_TO_REFLECT_INPUT_RESULT: 'jira.fe.issue.fields.issue-links.time-to.reflect-input-results',
		TIME_TO_RENDER_LINKED_ISSUE: 'jira.fe.issue.fields.issue-links.time-to.render-linked-issue',
		TIME_TO_RENDER_NEW_LINKED_ISSUE_INPUT:
			'jira.fe.issue.fields.issue-links.time-to.render-new-linked-issue-input',
	},
	SPRINT: {
		TIME_TO_REFLECT_SELECTED_SPRINT: 'jira.fe.issue.fields.sprint.time-to.reflect-selected-sprint',
		TIME_TO_REFLECT_REMOVED_SPRINT: 'jira.fe.issue.fields.sprint.time-to.reflect-removed-sprint',
		TIME_TO_REFLECT_INPUT_RESULT: 'jira.fe.issue.fields.sprint.time-to.reflect-input-results',
		TIME_TO_RENDER_READ_VIEW: 'jira.fe.issue.fields.sprint.time-to.render-read-view',
		TIME_TO_RENDER_INITIAL_SUGGESTIONS:
			'jira.fe.issue.fields.sprint.time-to.render-initial-suggestions',
	},
	PRIORITY: {
		TIME_TO_RENDER_READ_VIEW: 'jira.fe.issue.fields.priority.time-to.render-read-view',
		TIME_TO_RENDER_SUGGESTIONS: 'jira.fe.issue.fields.priority.time-to.render-suggestions',
		TIME_TO_REFLECT_PRIORITY_UPDATE:
			'jira.fe.issue.fields.priority.time-to.reflect-priority-update',
	},
	COMMENTS: {
		TIME_TO_RENDER_NEW_COMMENT_EDITOR:
			'jira.fe.issue.fields.comments.time-to.render-new-comment-editor',
		TIME_TO_RENDER_SAVED_COMMENT: 'jira.fe.issue.fields.comments.time-to.render-saved-comment',
	},
	DESCRIPTION: {
		TIME_TO_RENDER_EDIT_VIEW: 'jira.fe.issue.field.description.time-to.render-edit-view',
		TIME_TO_SAVE_UPDATED_DESCRIPTION:
			'jira.fe.issue.field.description.time-to.save-updated-description',
		TIME_TO_SAVE_UNCHANGED_DESCRIPTION:
			'jira.fe.issue.field.description.time-to.save-unchanged-description',
	},
	EPIC_LINKS: {
		TIME_TO_FETCH_INPUT_RESULTS_EPIC_PICKER_ENABLED:
			'jira.fe.issue.fields.epic-link.time-to.fetch-input-results.epic-picker-enabled',
		TIME_TO_FETCH_INPUT_RESULTS_EPIC_PICKER_DISABLED:
			'jira.fe.issue.fields.epic-link.time-to.fetch-input-results.epic-picker-disabled',
	},
} as const;
