import React, { type ReactNode } from 'react';
import { useSpotlight } from '@atlaskit/onboarding';
import { HOME_ITEMS_IDS } from '@atlassian/jira-servicedesk-common/src/constants.tsx';

const targetName = HOME_ITEMS_IDS.ALL_ITSM_QUEUES_SPOTLIGHT;

export const IfNavTargetsAreShowing = ({ children }: { children: ReactNode }) => {
	const { isTargetRendered } = useSpotlight();
	const isNavTargetPresent = isTargetRendered(targetName);

	return isNavTargetPresent ? <>{children}</> : null;
};
