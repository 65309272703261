export const SET_SCROLLBAR_SIZE = 'ops.scrolling.size.SET_SCROLLBAR_SIZE' as const;

export type SetScrollbarSizeAction = {
	type: typeof SET_SCROLLBAR_SIZE;
	payload: number;
};

export const set = (payload: number): SetScrollbarSizeAction => ({
	type: SET_SCROLLBAR_SIZE,
	payload,
});
