import { createSelector } from 'reselect';
import { MAX_ISSUES_PER_PAGE } from '@atlassian/jira-issue-table/src/common/table/constants.tsx';
import type { CollectionItem } from '../../../model/index.tsx';
import type { Pagination } from '../../reducers/persisted/queue/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getCollection, getIssueCount } from '../issues/index.tsx';
import { getQueuePageData } from '../queue/index.tsx';
import { isLoadingIssues } from '../ui/index.tsx';

const LOADING_ROW_COUNT = 10;

export const getPagination = (state: State): Pagination => getQueuePageData(state).pagination;

export const getCurrentPage = (state: State): number => getPagination(state).currentPage;

export const getCurrentPageStartIndex = (state: State): number =>
	(getCurrentPage(state) - 1) * MAX_ISSUES_PER_PAGE;

export const getTotalPages = (state: State): number =>
	Math.max(1, Math.ceil(getIssueCount(state) / MAX_ISSUES_PER_PAGE));

export const getCurrentPageEndIndex = (state: State): number =>
	getCurrentPage(state) * MAX_ISSUES_PER_PAGE - 1;

export const getIndexOfLastIssueOnPage = (state: State): number => {
	const maxPageEndIndex = getCurrentPageEndIndex(state);
	const minPageEndIndex = getCurrentPageStartIndex(state) - 1;
	return Math.min(Math.max(getIssueCount(state) - 1, minPageEndIndex), maxPageEndIndex);
};

export const getIssueKeysForPage = createSelector(
	getCollection,
	getCurrentPageStartIndex,
	getIndexOfLastIssueOnPage,
	isLoadingIssues,
	(issues: CollectionItem[], startIndex: number, endIndex: number, isLoading: boolean) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const issueKeys: Array<any | string> = [];
		const isNewDataSet = isLoading && issues.length === 0;

		// If we are expecting data from a new source like a queue or a page.
		// If the collection has not been cleared we want to show
		// existing row until loading is complete
		if (isNewDataSet) {
			issueKeys.length = LOADING_ROW_COUNT;

			if (endIndex - startIndex + 1 === MAX_ISSUES_PER_PAGE) {
				issueKeys.length = MAX_ISSUES_PER_PAGE;
			}
			return issueKeys;
		}

		issueKeys.length = endIndex - startIndex + 1;
		for (let i = startIndex; i <= endIndex; i += 1) {
			const issue = issues[i];
			if (issue) {
				issueKeys[i - startIndex] = issue.key;
			}
		}
		return issueKeys;
	},
);
