export const ERROR_LOCATION = 'common-cache';

export const CAPACITY_EXCEEDED = 'capacity exceeded';
export const CLEAR = 'clear';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const DELETE_STALE_ENTRY = 'delete stale entry';
export const GET = 'get';
export const HYDRATE = 'hydrate';
export const SET = 'set';
export const GET_ENTRIES = 'get entries';

export const MEMORY = 'memory' as const;
export const LOCAL = 'local' as const;
export const SESSION = 'session' as const;
