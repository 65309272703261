import React from 'react';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './issue-search/index.tsx';
import { SkeletonWrapper } from './issue-search/view.tsx';

const LazyComponent = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-queues-agent-view-issue-search" */ './issue-search'),
);

const Component = (props: Props) => (
	<Placeholder
		name="queues-agent-view-issue-search"
		fallback={
			<SkeletonWrapper>
				<Skeleton height="32px" width="240px" />
			</SkeletonWrapper>
		}
	>
		{/* @ts-expect-error - TS2559 - Type '{ onChange: (text: string | null) => void; onChangedIsPendingSubmission: (loading: boolean) => void; onFocusChange: (isFocused: boolean) => void; queueId: number; filterQuery?: string | null | undefined; }' has no properties in common with type 'IntrinsicAttributes & { context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: Store<...> | undefined; }'. */}
		<LazyComponent {...props} />
	</Placeholder>
);

export default Component;
