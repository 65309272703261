export const CLEAR_RESIZING_COLUMN = 'ops.columns.resizing.clear.CLEAR_RESIZING_COLUMN' as const;

export type ClearResizingColumnAction = {
	type: typeof CLEAR_RESIZING_COLUMN;
	payload: undefined;
};

export const clear = (): ClearResizingColumnAction => ({
	type: CLEAR_RESIZING_COLUMN,
	payload: undefined,
});
