import { connect } from '../../../../../../../../common/table-redux.tsx';
import type { RowId } from '../../../../../../../../model/rows/index.tsx';
import {
	getRowExpansionCallback,
	getRowAddCallback,
} from '../../../../../../../../state/consumer/selectors/index.tsx';
import {
	getMaxDepth,
	isRowExpanded,
	getRowCanAddChildren,
	getRowHasChildren,
} from '../../../../../../../../state/consumer/selectors/rows/index.tsx';
import { getRowDepth, isGlobalAddEnabled } from '../../../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../../../state/types.tsx';
import RowChildOperationIndicator from './index-dumb.tsx';

type Props = {
	rowId: RowId;
	isRowHovered: boolean;
	isRowKeyboardActive: boolean;
};

export default connect(
	(state: State, { rowId }: Props) => ({
		maxDepth: getMaxDepth(state),
		rowDepth: getRowDepth(state, rowId),
		isRowExpanded: isRowExpanded(state, rowId),
		rowHasChildren: getRowHasChildren(state, rowId),
		isAddEnabled: isGlobalAddEnabled(state) && getRowCanAddChildren(state, rowId),
		onRowExpansionChangeRequested: getRowExpansionCallback(state),
		onRowAddRequested: getRowAddCallback(state),
	}),
	{},
)(RowChildOperationIndicator);
