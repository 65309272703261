import type { ErrorDescription } from './types.tsx';

export const ERROR = 'ops.common.errors.ERROR' as const;

export type ErrorAction = {
	type: typeof ERROR;
	payload: ErrorDescription;
};

export const error = (payload: ErrorDescription): ErrorAction => ({
	type: ERROR,
	payload,
});
