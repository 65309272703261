import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { State } from '../navigation-category/index.tsx';

export const LOCAL_STORAGE_ID = 'jsm-queue-groups-state';
export const LOCAL_STORAGE_KEY = 'selected-group-id';

export const getQueueGroupsStateFromLocalStorage = (projectKey: string): State | undefined => {
	const localStorageProvider = createLocalStorageProvider(LOCAL_STORAGE_ID);
	try {
		const state = localStorageProvider.get(`${LOCAL_STORAGE_KEY}-${projectKey}`);
		if (state) {
			return JSON.parse(state);
		}
		return undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return undefined;
	}
};

export const setQueueGroupsStateInLocalStorage = (projectKey: string, value: State) => {
	const localStorageProvider = createLocalStorageProvider(LOCAL_STORAGE_ID);
	try {
		localStorageProvider.set(`${LOCAL_STORAGE_KEY}-${projectKey}`, JSON.stringify(value));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		/* empty */
	}
};
