export const gqlTagRest = <T extends string | number>(
	literals: string[] | TemplateStringsArray,
	...substitutions: T[]
) => {
	let result = '';

	// run the loop only for the substitution count
	substitutions.forEach((key, i) => {
		result += literals[i];
		result += key.toString();
	});

	// add the last literal
	result += literals[literals.length - 1];

	return result;
};

const getIsHybridAgilityProjectQuery = () =>
	`
    isHybridAgilityProject
    `;

const getEnvironmentQuery = () =>
	`environmentAdf {
        value
    }`;

const getAgileQuery = () =>
	`
    agile {
        epic {
          key
        },
    }`;

const getCustomFieldSchemaConfiguration = () =>
	`configuration {
        key
        value
    }
    `;

const getProjectQuery = (projectKey: string) => `
        project(projectIdOrKey: "${projectKey}") {
            id
            name
            key
            projectTypeKey
            simplified
            avatarUrls {
                key
                value
            }
            archived
            deleted
        }`;

// Disable this eslint rule temporarily so we can query for configuration
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issueKey: string, projectKey: string) =>
	gqlTagRest`query issueDataQuery {
        issue(issueIdOrKey: "${issueKey}", latestVersion: true, screen: "view") {
            id
            viewScreenId
            fields {
                key
                title
                editable
                required
                autoCompleteUrl
                allowedValues
                content
                renderedContent
                schema {
                    custom
                    system
                    ${getCustomFieldSchemaConfiguration()}
                    items
                    type
                    renderer
                }
                configuration
            }
            expandAssigneeInSubtasks
            expandAssigneeInIssuelinks
            expandTimeTrackingInSubtasks
            systemFields {
                descriptionAdf {
                    value
                }
                ${getEnvironmentQuery()}
            }
            customFields {
                textareaAdf {
                    key
                    value
                }
            }
            ${getIsHybridAgilityProjectQuery()}
            ${getAgileQuery()}
        }
        ${getProjectQuery(projectKey)}
    }`;
