import { useRef, useCallback, useLayoutEffect } from 'react';

/**
 * Given a callback, returns a wrapping callback which will never change identity but always call the latest
 * version of the input argument.
 *
 * Calling this during render will cause issues, however event listeners will benefit from stable identity callbacks
 * returned from this helper.
 *
 * This is https://github.com/reactjs/rfcs/pull/220
 */
export const useEvent = <Args extends Array<unknown>, R>(
	callback: (...args: Args) => R,
): ((...args: Args) => R) => {
	const callbackRef = useRef(callback);

	useLayoutEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	return useCallback((...args) => callbackRef.current(...args), []);
};
