import isEqual from 'lodash/isEqual';
import type {
	FlagsMapperFactoryArguments,
	FlagsMapper,
	FlagId,
} from '@atlassian/jira-flags/src/async';
import {
	DISPLAY_UNSUCCESSFUL_FETCH_WARNING,
	DISPLAY_UNSUCCESSFUL_FETCH_ERROR,
	type DisplayUnsuccessfulFetchWarningAction,
	type DisplayUnsuccessfulFetchErrorOnScrollAction,
} from '../state/actions/table/index.tsx';
import { DELETE_QUEUE_FAILURE, type DeleteQueueFailureAction } from '../state/actions/ui/index.tsx';
import {
	DISPLAY_UNSUCCESSFUL_POLL_WARNING,
	DISMISS_ALL_FLAGS,
	DISMISS_WARNING_FLAG,
	type DisplayUnsuccessfulPollWarningAction,
	type DismissAllFlagsAction,
	type DismissWarningFlagAction,
} from '../state/actions/update-metadata/index.tsx';
import type { State } from '../state/reducers/types.tsx';
import {
	unsuccessfulPollFlagConfig,
	unsuccessfulFetchFlagConfig,
	unsuccessfulFetchErrorFlagConfig,
	deleteQueueErrorConfig,
} from './configurations.tsx';

export type Action =
	| DisplayUnsuccessfulPollWarningAction
	| DisplayUnsuccessfulFetchWarningAction
	| DisplayUnsuccessfulFetchErrorOnScrollAction
	| DismissAllFlagsAction
	| DismissWarningFlagAction
	| DeleteQueueFailureAction;

const WARNING_FLAG_ID = 'WARNING_FLAG_ID' as const;
const FETCH_WARNING_FLAG_ID = 'FETCH_WARNING_FLAG_ID' as const;
const ERROR_FLAG_ID = 'ERROR_FLAG_ID' as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ dismissFlag, toFlagId }: FlagsMapperFactoryArguments) => {
	let unsuccessfulFlagId: FlagId | undefined;

	const isErrorFlagDisplayed = () => isEqual(unsuccessfulFlagId, toFlagId(ERROR_FLAG_ID));

	const flagsMapper: FlagsMapper<Action, State> = (action) => {
		switch (action.type) {
			case DELETE_QUEUE_FAILURE: {
				return deleteQueueErrorConfig(action.payload.queueName);
			}
			case DISPLAY_UNSUCCESSFUL_POLL_WARNING: {
				if (unsuccessfulFlagId) {
					return undefined;
				}
				unsuccessfulFlagId = toFlagId(WARNING_FLAG_ID);
				return unsuccessfulPollFlagConfig(unsuccessfulFlagId);
			}
			case DISPLAY_UNSUCCESSFUL_FETCH_WARNING: {
				if (unsuccessfulFlagId) {
					return undefined;
				}
				unsuccessfulFlagId = toFlagId(FETCH_WARNING_FLAG_ID);
				return unsuccessfulFetchFlagConfig(unsuccessfulFlagId);
			}
			case DISPLAY_UNSUCCESSFUL_FETCH_ERROR: {
				if (unsuccessfulFlagId) {
					return undefined;
				}
				unsuccessfulFlagId = toFlagId(ERROR_FLAG_ID);
				return unsuccessfulFetchErrorFlagConfig(unsuccessfulFlagId);
			}
			case DISMISS_ALL_FLAGS: {
				if (!unsuccessfulFlagId) {
					return undefined;
				}
				const dismissAllFlagsConfig = dismissFlag(unsuccessfulFlagId);
				unsuccessfulFlagId = undefined;
				return dismissAllFlagsConfig;
			}
			case DISMISS_WARNING_FLAG: {
				if (!unsuccessfulFlagId || isErrorFlagDisplayed()) {
					return undefined;
				}
				const dismissWarningFlagConfig = dismissFlag(unsuccessfulFlagId);
				unsuccessfulFlagId = undefined;
				return dismissWarningFlagConfig;
			}
			default: {
				const _exhaustiveCheck: never = action;
				return undefined;
			}
		}
	};
	return flagsMapper;
};
