import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import { removeCollectionFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';

const emptyAdfObject = getEmptyADF();

type AggJiraRichText = {
	adfValue: { json: unknown; convertedPlainText?: { plainText?: string | null } };
	plainText?: string;
};

export const transformAggRichTextFieldValueToLegacyFieldValue = (
	richText: AggJiraRichText,
	renderer?: string | null | undefined,
) => {
	if (!renderer) {
		throw new Error('aggFieldChangeProps did not have renderer');
	}
	if (renderer === PLAIN_TEXT) {
		if (richText.plainText !== undefined) {
			return richText.plainText;
		}
		return richText?.adfValue?.convertedPlainText?.plainText;
	}

	return richText?.adfValue?.json
		? removeCollectionFromAdf(richText?.adfValue?.json)
		: emptyAdfObject;
};
