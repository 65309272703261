import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { setIssueKeyContext } from '../../../state/actions/ui/index.tsx';
import { IssueContextListener } from './view.tsx';

export type Props = {
	onSetIssueKeyContext: (arg1: string | undefined) => void;
};

const mapDispatchToProps = {
	onSetIssueKeyContext: setIssueKeyContext,
} as const;

export default connect(null, mapDispatchToProps)(IssueContextListener);
