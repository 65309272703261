export type ObservationDataEntry = {
	timestamp: DOMHighResTimeStamp;
	duration: number;
};

type EmptyObservationData = {
	type: string;
	events?: never;
	dragStartEndTimestamp?: DOMHighResTimeStamp;
};

type ExpandedObservationData = {
	type: string;
	appName: string;
	startTimestamp: DOMHighResTimeStamp;
	eventStartPerformanceMark?: string | null;
	events: ObservationDataEntry[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extra: Record<PropertyKey, any>;
	dragStartEndTimestamp: DOMHighResTimeStamp;
};

export type ObservationData = EmptyObservationData | ExpandedObservationData;

export const isExpandedObservationData = (
	observationData?: ObservationData,
): observationData is ExpandedObservationData =>
	observationData !== undefined &&
	'events' in observationData &&
	observationData.events !== undefined;

export type LongTasksSloSettings = {
	framerateThreshold: number;
	blocktimeThreshold: number;
};
