import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	openIssueInSidebar: {
		id: 'servicedesk.queues.agent-view.layout.open-issue-in-sidebar',
		defaultMessage: 'Open issue in sidebar',
		description: 'View the highlighted issue in the sidebar by clicking this button',
	},
	openIssueInDialog: {
		id: 'servicedesk.queues.agent-view.layout.open-issue-in-dialog',
		defaultMessage: 'Open issue in dialog',
		description: 'View the highlighted issue in a dialog by clicking this button',
	},
});
