import { connect } from '../../../../../common/table-redux.tsx';
import { sortedRowIdsHashRootId } from '../../../../../model/rows/index.tsx';
import { getRowAddCallback } from '../../../../../state/consumer/selectors/index.tsx';
import {
	getAddLinkCaption,
	getAddBarOverflow,
} from '../../../../../state/consumer/selectors/rows/index.tsx';
import { getSortedRowIdsHash } from '../../../../../state/internal/selectors.tsx';
import { isAddLinkShown } from '../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import AddLink from './index-dumb.tsx';

export default connect((state: State) => {
	const sortedRowIds = getSortedRowIdsHash(state)[sortedRowIdsHashRootId];
	return {
		addLinkCaption: getAddLinkCaption(state),
		lastRowId: sortedRowIds && sortedRowIds[sortedRowIds.length - 1],
		isVisible: isAddLinkShown(state),
		onRowAddRequested: getRowAddCallback(state),
		addBarOverflow: getAddBarOverflow(state),
	};
}, {})(AddLink);
