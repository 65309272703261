import React from 'react';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import {
	EnvironmentLozenge,
	nameWithCustomEnvironment,
} from '@atlassian/jira-forge-ui-extension-title/src/ui/environment-lozenge/index.tsx';
import MenuLinkItem from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/menu-link-item-with-analytics/index.tsx';
import { useGetLinkProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-link-props/index.tsx';
import type { ForgeItemPropsWithAnalyticsItemId } from '../../../types.tsx';
import { ForgeIcon } from '../forge-icon/index.tsx';

export const ForgeItemSimple = (props: ForgeItemPropsWithAnalyticsItemId) => {
	const { getLinkProps } = useGetLinkProps();
	const {
		showIcon,
		id: itemId,
		iconUrl,
		environmentType,
		environmentKey,
		url,
		name,
		overrides,
		analytics,
	} = props;

	if (overrides?.showSelectedAppMenuOnly) {
		return null;
	}

	return (
		<MenuLinkItem
			analytics={analytics}
			elemBefore={showIcon ? <ForgeIcon url={iconUrl} /> : undefined}
			elemAfter={<EnvironmentLozenge environmentType={environmentType} />}
			{...getLinkProps(url, { shouldMatchExactPath: false })}
			onClick={() => {
				forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
			}}
		>
			{nameWithCustomEnvironment(name, environmentType, environmentKey)}
		</MenuLinkItem>
	);
};
