import type { IssueKeysPositionIndex } from '../../../model/index.tsx';

export const getIndexesOfStringArray = (issueKeys: string[]): IssueKeysPositionIndex => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const positionIndexByIssueKey: Record<string, any> = {};
	issueKeys.forEach((value, index) => {
		positionIndexByIssueKey[value] = index;
	});
	return positionIndexByIssueKey;
};

type IteratorResult<T> =
	| {
			done: false;
			readonly value: T;
	  }
	| {
			done: true;
			readonly value: undefined;
	  };
export class IteratorWrapper<T> {
	iterator: Iterator<T>;

	current: IteratorResult<T>;

	lastNonDoneValue: T | undefined;

	constructor(sourceArray: T[]) {
		this.iterator = sourceArray.values();
		// @ts-expect-error - TS2322 - Type 'IteratorResult<T, any>' is not assignable to type 'IteratorResult<T>'.
		this.current = this.iterator.next();
		this.lastNonDoneValue = this.current.done ? undefined : this.current.value;
	}

	next(): IteratorResult<T> {
		// @ts-expect-error - TS2322 - Type 'IteratorResult<T, any>' is not assignable to type 'IteratorResult<T>'.
		this.current = this.iterator.next();
		return this.current;
	}
}
