export const getSourceByInviteFlow = (inviteFlow: string): string => {
	let source = inviteFlow;

	if (inviteFlow === 'mention') {
		source = 'mentionInEditor';
	} else if (inviteFlow === 'assignee') {
		source = 'assigneeField';
	}

	return source;
};
