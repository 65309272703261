import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { AnError } from '@atlassian/jira-servicedesk-common/src/model/error/anerror/index.tsx';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';

// ONBOARDING_RESOLVED

export const ONBOARDING_RESOLVED = 'state.actions.ui.ONBOARDING_RESOLVED' as const;

export type OnboardingResolvedAction = {
	type: typeof ONBOARDING_RESOLVED;
	payload: {
		onboardingResolved: boolean;
	};
};

export const onboardingResolvedAction = (
	onboardingResolved: boolean,
): OnboardingResolvedAction => ({
	type: ONBOARDING_RESOLVED,
	payload: {
		onboardingResolved,
	},
});

// SET_DELETE_MODAL_VISIBILITY

export const SET_DELETE_MODAL_VISIBILITY = 'state.actions.ui.SET_DELETE_MODAL_VISIBILITY' as const;

export type SetDeleteModalVisibilityAction = {
	type: typeof SET_DELETE_MODAL_VISIBILITY;
	payload: {
		isVisible: boolean;
	};
};

export const setDeleteModalVisibilityAction = (
	isVisible: boolean,
): SetDeleteModalVisibilityAction => ({
	type: SET_DELETE_MODAL_VISIBILITY,
	payload: {
		isVisible,
	},
});

// SET_MODAL_IS_DELETING

export const SET_MODAL_IS_DELETING = 'state.actions.ui.SET_MODAL_IS_DELETING' as const;

export type SetModalIsDeletingAction = {
	type: typeof SET_MODAL_IS_DELETING;
	payload: {
		isDeleting: boolean;
	};
};

export const setModalIsDeletingAction = (isDeleting: boolean): SetModalIsDeletingAction => ({
	type: SET_MODAL_IS_DELETING,
	payload: {
		isDeleting,
	},
});

// DELETE_QUEUE

export const DELETE_QUEUE = 'state.actions.ui.DELETE_QUEUE' as const;

export type DeleteQueueAction = {
	type: typeof DELETE_QUEUE;
	payload: {
		queueId: QueueId;
		onDeleteComplete: (arg1: number) => void;
	};
	meta: {
		analyticsEvent?: UIAnalyticsEvent;
	};
};

export const deleteQueueAction = (
	queueId: QueueId,
	onDeleteComplete: (arg1: number) => void,
	analyticsEvent?: UIAnalyticsEvent,
): DeleteQueueAction => ({
	type: DELETE_QUEUE,
	payload: {
		queueId,
		onDeleteComplete,
	},
	meta: {
		analyticsEvent,
	},
});

// DELETE_QUEUE_FAILURE

export const DELETE_QUEUE_FAILURE = 'state.actions.ui.DELETE_QUEUE_FAILURE' as const;

export type DeleteQueueFailureAction = {
	type: typeof DELETE_QUEUE_FAILURE;
	payload: {
		queueName: string;
		error: AnError;
	};
};

export const deleteQueueFailureAction = (
	queueName: string,
	error: AnError,
): DeleteQueueFailureAction => ({
	type: DELETE_QUEUE_FAILURE,
	payload: {
		error,
		queueName,
	},
});

// SET_NAV_COLLAPSED

export const SET_NAV_COLLAPSED = 'state.actions.ui.SET_NAV_COLLAPSED' as const;

export type SetNavCollapsedAction = {
	type: typeof SET_NAV_COLLAPSED;
	payload: {
		isNavCollapsed: boolean;
	};
};

export const setNavCollapsedAction = (isNavCollapsed: boolean): SetNavCollapsedAction => ({
	type: SET_NAV_COLLAPSED,
	payload: {
		isNavCollapsed,
	},
});

// SET_ISSUE_KEY_CONTEXT

export const SET_ISSUE_KEY_CONTEXT = 'state.actions.ui.SET_ISSUE_KEY_CONTEXT' as const;

export type SetIssueKeyContextAction = {
	type: typeof SET_ISSUE_KEY_CONTEXT;
	payload: {
		issueKey: string | undefined;
	};
};

export const setIssueKeyContext = (issueKey?: string): SetIssueKeyContextAction => ({
	type: SET_ISSUE_KEY_CONTEXT,
	payload: {
		issueKey,
	},
});

// SET_SIDEBAR_ISSUE_KEY

export const SET_SIDEBAR_ISSUE_KEY = 'state.actions.ui.SET_SIDEBAR_ISSUE_KEY' as const;

export type SetSidebarIssueKeyAction = {
	type: typeof SET_SIDEBAR_ISSUE_KEY;
	payload: {
		sidebarIssueKey: string | null;
	};
};

export const setSidebarIssueKey = (sidebarIssueKey: string | null): SetSidebarIssueKeyAction => ({
	type: SET_SIDEBAR_ISSUE_KEY,
	payload: {
		sidebarIssueKey,
	},
});

// SET_SIDEBAR_REACT_KEY

export const SET_SIDEBAR_REACT_KEY = 'state.actions.ui.SET_SIDEBAR_REACT_KEY' as const;

export type SetSidebarReactKeyAction = {
	type: typeof SET_SIDEBAR_REACT_KEY;
	payload: {
		sidebarReactKey: string | null;
	};
};

export const setSidebarReactKey = (sidebarReactKey: string | null): SetSidebarReactKeyAction => ({
	type: SET_SIDEBAR_REACT_KEY,
	payload: {
		sidebarReactKey,
	},
});

// SET_SELECTED_ISSUE_KEYS

export const SET_SELECTED_ISSUE_KEYS = 'state.actions.ui.SET_SELECTED_ISSUE_KEYS' as const;

export type SetSelectedIssueKeysAction = {
	type: typeof SET_SELECTED_ISSUE_KEYS;
	payload: {
		selectedIssueKeys: string[] | undefined;
	};
};

export const setSelectedIssueKeys = (
	selectedIssueKeys: string[] | undefined,
): SetSelectedIssueKeysAction => ({
	type: SET_SELECTED_ISSUE_KEYS,
	payload: {
		selectedIssueKeys,
	},
});

// REFRESH_SIDEBAR_ISSUE_VIEW

export const REFRESH_SIDEBAR_ISSUE_VIEW = 'state.actions.ui.REFRESH_SIDEBAR_ISSUE_VIEW' as const;

export type RefreshSidebarIssueViewAction = {
	type: typeof REFRESH_SIDEBAR_ISSUE_VIEW;
	payload: {
		issueKey?: string;
	};
};

export const refreshSidebarIssueView = ({
	issueKey,
}: { issueKey?: string } = {}): RefreshSidebarIssueViewAction => ({
	type: REFRESH_SIDEBAR_ISSUE_VIEW,
	payload: { issueKey },
});

export type Action =
	| OnboardingResolvedAction
	| SetDeleteModalVisibilityAction
	| SetModalIsDeletingAction
	| DeleteQueueAction
	| DeleteQueueFailureAction
	| SetNavCollapsedAction
	| SetIssueKeyContextAction
	| SetSidebarIssueKeyAction
	| SetSidebarReactKeyAction
	| SetSelectedIssueKeysAction
	| RefreshSidebarIssueViewAction;
