import React, { Component, createContext, type Context, type ReactElement } from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import type { Subscription } from 'rxjs/Subscription';
import { getQueueFragment } from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import {
	type QueueId,
	type QueueChangedNotifier,
	fromQueueIdString,
	toQueueIdString,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types.tsx';
import {
	getFromLocalStorage,
	setInLocalStorage,
} from '@atlassian/jira-servicedesk-queues-common/src/services/local-storage/local-storage.tsx';
import type { QueuesNavActions } from '@atlassian/jira-servicedesk-queues-nav-api/src/types.tsx';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import FavoriteSubscriberDI from '../favorite-subscriber/main.tsx';
import type { FavoriteSubscriberType } from '../favorite-subscriber/types.tsx';
import type { QueuesDataSource } from './types.tsx';
import { pageToQueues } from './utils/transform.tsx';

type QueuesContextType = {
	setFavorite: (queueId: QueueId, value: boolean) => void;
	deleteNavItem: (queueId: QueueId, push: QueuesPush) => void;
	updateIssueCount: (queueId: QueueId, newCount: number) => void;
	markNavItemsAsStale: () => void;
	getQueueCount: () => number;
	setLastVisitedQueueId: (queueId: QueueId, projectKey: string) => void;
	getLastVisitedQueueId: (projectKey: string) => string | undefined | null;
};

type Props = {
	children: (arg1: QueuesDataSource) => ReactElement;
} & QueueChangedNotifier & {
		FavoriteSubscriber: FavoriteSubscriberType;
		queuesPrefetchData: Page[];
		push: QueuesPush;
		category?: QueueCategory;
		onDeleteNavItem: ((arg1: string) => void) | QueuesNavActions['deleteItem'] | undefined;
	};

const LAST_VISITED_QUEUE_KEY_PREFIX = 'last_visited_queue';

// @ts-expect-error - TS2322 - Type 'Context<{ setFavorite: (...args: any[]) => void; deleteNavItem: (...args: any[]) => void; updateIssueCount: (...args: any[]) => void; markNavItemsAsStale: (...args: any[]) => void; getQueueCount: (...args: any[]) => void; setLastVisitedQueueId: (...args: any[]) => void; getLastVisitedQueueId: (...args: any[]) => voi...' is not assignable to type 'Context<QueuesContextType>'.
const AvailableQueuesContext: Context<QueuesContextType> = createContext({
	setFavorite: noop,
	deleteNavItem: noop,
	updateIssueCount: noop,
	markNavItemsAsStale: noop,
	getQueueCount: noop,
	setLastVisitedQueueId: noop,
	getLastVisitedQueueId: noop,
});

const { Provider, Consumer: AvailableQueuesConsumer } = AvailableQueuesContext;

// eslint-disable-next-line jira/react/no-class-components
export default class QueuesApiDataProvider extends Component<Props, QueuesDataSource> {
	static defaultProps = {
		FavoriteSubscriber: FavoriteSubscriberDI,
		queuesPrefetchData: [],
		push: undefined,
		onDeleteNavItem: undefined,
	};

	constructor(props: Props) {
		const { onNavUpdated, onIssueCountUpdated, queuesPrefetchData, push } = props;
		super(props);

		this.queuesContext = {
			deleteNavItem: this.deleteNavItem(push),
			updateIssueCount: onIssueCountUpdated,
			markNavItemsAsStale: () => onNavUpdated(false),
			setFavorite: this.setFavorite,
			getQueueCount: this.getQueueCount,
			setLastVisitedQueueId: this.setLastVisitedQueueId,
			getLastVisitedQueueId: this.getLastVisitedQueueId,
		};

		this.deletedQueueIds = new Set();
		this.state = {
			loading: false,
			error: undefined,
			data: queuesPrefetchData.length ? pageToQueues(queuesPrefetchData) : undefined,
		};
	}

	static getDerivedStateFromProps(props: Props, state: QueuesDataSource) {
		const { queuesPrefetchData } = props;
		if (!isEqual(pageToQueues(queuesPrefetchData), state.data) && queuesPrefetchData.length) {
			return { data: pageToQueues(queuesPrefetchData) };
		}

		return null;
	}

	componentWillUnmount() {
		const { fetchSubscription, deleteItemSubscription } = this;
		fetchSubscription && fetchSubscription.unsubscribe();
		deleteItemSubscription && deleteItemSubscription.unsubscribe();
	}

	getQueueCount = (): number => {
		const { data } = this.state;
		return data
			? Object.keys(data).filter((queueId) => !this.deletedQueueIds.has(toQueueIdString(queueId)))
					.length
			: 0;
	};

	getLastVisitedQueueId = (projectKey?: string | null): string | undefined | null => {
		const { category } = this.props;

		if (!projectKey) {
			return null;
		}

		try {
			if (category) {
				return getFromLocalStorage(`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}_${category}`);
			}
			return getFromLocalStorage(`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}`);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			return null;
		}
	};

	setFavorite = (queueId: QueueId, newValue: boolean) => {
		const { FavoriteSubscriber } = this.props;
		FavoriteSubscriber.setFavorite(queueId, newValue);
	};

	setLastVisitedQueueId = (queueId: QueueId, projectKey: string) => {
		const lastVisitedQueueId = this.getLastVisitedQueueId(projectKey);

		const { category } = this.props;

		// @ts-expect-error - TS2367 - This condition will always return 'true' since the types 'string | null | undefined' and 'number' have no overlap.
		if (projectKey && lastVisitedQueueId !== queueId) {
			try {
				if (category) {
					setInLocalStorage(
						`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}_${category}`,
						fromQueueIdString(queueId),
					);
				} else {
					setInLocalStorage(
						`${LAST_VISITED_QUEUE_KEY_PREFIX}_${projectKey}`,
						fromQueueIdString(queueId),
					);
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				// Don't care if it fails. Default behavior in QueuesRedirect will take users to first available queue.
			}
		}
	};

	queuesContext: QueuesContextType;

	// @ts-expect-error - TS2564 - Property 'fetchSubscription' has no initializer and is not definitely assigned in the constructor.
	fetchSubscription: Subscription;

	// @ts-expect-error - TS2564 - Property 'deleteItemSubscription' has no initializer and is not definitely assigned in the constructor.
	deleteItemSubscription: Subscription;

	deletedQueueIds: Set<QueueId>;

	deleteNavItem(push: QueuesPush) {
		return (queueId: QueueId) => {
			const { onDeleteNavItem } = this.props;
			onDeleteNavItem && onDeleteNavItem(fromQueueIdString(queueId));
			// update queue counts to reflect the deletion
			// note that deleteQueueFromSidebar is called after the delete endpoint returns success

			const updatedQueues = { ...this.state.data };
			delete updatedQueues[fromQueueIdString(queueId)];

			const deletedLastQueue = Object.keys(updatedQueues).length === 0;
			if (deletedLastQueue) {
				push && push(`${getQueueFragment()}/custom/new`);
			} else {
				push && push(getQueueFragment());
			}
		};
	}

	render() {
		return <Provider value={this.queuesContext}>{this.props.children(this.state)}</Provider>;
	}
}

export { AvailableQueuesConsumer };
