import type { RenderSidebarIcon } from '../../model/cell-component/index.tsx';
import type { ColumnId, SortOrderConfiguration } from '../../model/columns/index.tsx';
import type { TableSize } from '../../model/index.tsx';
import {
	type ActiveItem,
	type ActiveCell,
	type ActiveRow,
	ACTIVE_CELL_TYPE,
	ACTIVE_ROW_TYPE,
} from '../../model/navigation/index.tsx';
import type { Optional } from '../../model/optional/index.tsx';
import type { RowId, SortedRowIdsHash } from '../../model/rows/index.tsx';
import type { State } from '../types.tsx';

export const getActiveItem = (state: State): Optional<ActiveItem> => state.internal.activeItem;
export const getActiveRow = (state: State): Optional<ActiveRow> => {
	const activeItem = getActiveItem(state);
	return activeItem && activeItem.type === ACTIVE_ROW_TYPE ? activeItem : undefined;
};
export const getActiveCell = (state: State): Optional<ActiveCell> => {
	const activeItem = getActiveItem(state);
	return activeItem && activeItem.type === ACTIVE_CELL_TYPE ? activeItem : undefined;
};

export const getResizingColumnId = (state: State): Optional<ColumnId> =>
	state.internal.resizingColumnId;
export const getVerticalScrollOffset = (state: State): number =>
	state.internal.verticalScrollOffset;
export const hasVerticallyScrolled = (state: State): boolean =>
	state.internal.verticalScrollOffset > 0;
export const getVerticalScrollDelta = (state: State): number => state.internal.verticalScrollDelta;
export const getHorizontalScrollOffset = (state: State): number =>
	state.internal.horizontalScrollOffset;
export const getIsSortOverrideEnabled = (state: State): boolean =>
	state.internal.isSortOverrideEnabled;

export const getTableSize = (state: State): TableSize => state.internal.tableSize;
export const getTableHeight = (state: State): number => getTableSize(state).height;
export const getTableWidth = (state: State): number => getTableSize(state).width;
export const getTableWidthWithoutScrollbarOffset = (state: State): number =>
	getTableSize(state).widthWithoutScrollbarOffset;
export const getTableRowMargin = (state: State): number => getTableSize(state).rowMargin;
// FSN-4037 Remove draggingRowId related logic during FF cleanup
export const getDraggingRowId = (state: State): Optional<RowId> => state.internal.draggingRowId;
export const getMultiAddAnchorId = (state: State): Optional<RowId> =>
	state.internal.multiAddAnchorId;
export const getActiveSortedColumnConfiguration = (
	state: State,
): Optional<SortOrderConfiguration> => state.internal.activeSortedColumnConfiguration;

export const getSortedRowIdsHash: (arg1: State) => SortedRowIdsHash = (state: State) =>
	state.internal.sortedRowIdsHash;

export const getScrollbarSize = (state: State): number => state.internal.scrollbarSize;

export const getHeaderHeight = (state: State) => state.internal.headerHeight;

export const getRenderSidebarIcon = (state: State): Optional<RenderSidebarIcon> =>
	state.internal.renderSidebarIcon;
