import {
	type ReactNode,
	type RefObject,
	useCallback,
	useLayoutEffect,
	useRef,
	useEffect,
	useState,
} from 'react';
import { ResizeObserverGroup } from './utils.tsx';

export interface ResizeObserverContainerProps {
	children: ReactNode;
}
export interface UseResizeObserverParams {
	/**
	 * The element to observe. May not change.
	 */
	ref: RefObject<HTMLElement>;
	/**
	 * On resize callback for this element.
	 */
	onResize: () => void;
}

export interface CreateResizeObserverHookParams {
	debouncePeriod: number;
}

export interface CreateResizeObserverHookResult {
	/**
	 * Uses a shared ResizeObserver instance to dispatch events to react components.
	 * Batches calls together for better performance on React v16.
	 */
	useResizeObserver: (params: UseResizeObserverParams) => void;
	/**
	 * Synchronously flush the observers. This is useful in cases where the developer
	 * knows layout will shift, and wants to avoid the asynchronous behaviour of
	 * ResizeObserver, which leads to jank.
	 *
	 * Even with synchronous flushes this will still be batched.
	 */
	flushResizeObservers: () => void;
}

/**
 * Create a new "ResizeObserverGroup" context. Each group created like this will
 * share a single resize observer. This resize observer will 'demux' its events
 * onto each resized element.
 *
 * All callbacks will be called under a single react batch, so this should result
 * in a single react update regardless of the nº of subscribers and updated
 * elements.
 */
export function createResizeObserverHook(
	params?: CreateResizeObserverHookParams,
): CreateResizeObserverHookResult {
	const group = new ResizeObserverGroup(params?.debouncePeriod);

	/**
	 * This hook uses a shared ResizeObserver instance.
	 *
	 * When a resize notification comes in, a single batched React update will be
	 * dispatched after a debounce period only for the elements that have been
	 * resized.
	 *
	 * This does NOT react to updates to REF
	 */
	function useResizeObserver({ ref, onResize }: UseResizeObserverParams) {
		const onResizeRef = useRef(onResize);
		useEffect(() => {
			onResizeRef.current = onResize;
		});

		const stableOnResize = useCallback(() => onResizeRef.current(), []);

		useLayoutEffect(() => group.observe(ref, stableOnResize), [ref, stableOnResize]);
	}

	return {
		useResizeObserver,
		flushResizeObservers: () => group.flush(),
	};
}

export const { useResizeObserver, flushResizeObservers } = createResizeObserverHook();

/**
 * Observe the rectangle of a reference using a shared global resize observer and dispatch
 * queue. This should be an optimal way to observe the rectangles minimising the number of
 * re-renders.
 *
 * It's important that the ref is attached unconditionally to the element. That is, that the
 * elements are not conditionally rendered, or that the ref is not conditionally attached.
 *
 * @example Working example
 * ```tsx
 * function MyComponent() {
 *     const ref = useRef(null);
 *     const rect = useRectObserver({ ref });
 *
 *     return <div ref={ref}>The rect is {rect?.width}x{rect?.height}</div>;
 * }
 * ```
 *
 * @example Broken example
 * ```tsx
 * function DO_NOT_DO_THIS({ isLoading }) {
 *     const ref = useRef(null);
 *     const rect = useRectObserver({ ref });
 *
 *     if (isLoading) return null;
 *
 *     return <div ref={ref}>The rect is {rect?.width}x{rect?.height}</div>;
 * }
 * ```
 */
export function useRectObserver({
	ref,
	useResizeObserverDI = useResizeObserver,
}: {
	ref: UseResizeObserverParams['ref'];
	useResizeObserverDI?: typeof useResizeObserver;
}): DOMRect | null {
	const [rect, setRect] = useState<DOMRect | null>(null);
	const onResize = () => {
		if (ref.current) {
			setRect(ref.current.getBoundingClientRect());
		}
	};
	useLayoutEffect(() => {
		if (!ref.current) {
			throw new Error(
				[
					'useRectObserver: ref is not available on mount.',
					'This means you are attaching refs conditionally onto your elements, for example in with an early return.',
					'This will not work properly. Please update your code so that refs are always attached to components on mount.',
				].join('\n'),
			);
		}
		setRect(ref.current.getBoundingClientRect());
	}, [ref]);

	useResizeObserverDI({ ref, onResize });
	return rect;
}
