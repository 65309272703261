import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	allQueues: {
		id: 'servicedesk-queues-nav4.common.practice-queues.all-queues-section.all-queues',
		defaultMessage: 'All queues',
		description: 'Header for the All queues section',
	},
	viewAllQueues: {
		id: 'servicedesk-queues-nav4.common.practice-queues.all-queues-section.view-all-queues',
		defaultMessage: 'View all queues',
		description: 'Text displayed on the view all queues menu item button opening a fly-out view',
	},
	searchYourQueue: {
		id: 'servicedesk-queues-nav4.common.practice-queues.all-queues-section.search-your-queue',
		defaultMessage: 'Search your queue',
		description:
			'Placeholder text displayed on the search your queue text field in the fly-out view',
	},
	noSearchResultsFound: {
		id: 'servicedesk-queues-nav4.common.practice-queues.all-queues-section.no-search-results-found',
		defaultMessage: 'No search results found',
		description: 'Text displayed when search result returns no matches',
	},
});
