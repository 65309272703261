import {
	type FORGE_STRINGS_GQL_FIELD,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';
import isFieldMissingRenderer from './helpers/is-field-missing-renderer.tsx';

export const transformAggForgeStringsToLegacyField: AggTransformerFunction<
	typeof FORGE_STRINGS_GQL_FIELD
> = (node) => {
	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	const hasValue = Boolean(node.selectedLabelsConnection?.edges?.length);

	const value = hasValue
		? transformAggConnection(node.selectedLabelsConnection, ({ name }) => name)
		: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		autoCompleteUrl: node.searchUrl,
		schema: {
			configuration: null,
			items: ISSUE_FIELD_STRING_CF_TYPE,
			system: null,
			custom: node.type,
			renderer: node.renderer,
			type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
		},
	};
};
