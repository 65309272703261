import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { AssigneeInitialization } from '../../../model/fields/json-fields/system-fields/types.tsx';
import { getCurrentUser$ } from '../../../rest/current-user/get/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): Observable<AssigneeInitialization> =>
	getCurrentUser$().mergeMap((response) =>
		Observable.of({
			avatarUrl: response.avatarUrls['48x48'],
			displayName: response.displayName,
			emailAddress: response.emailAddress,
		}),
	);
