import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { withShouldRenderWithinItsmSampleProject } from '@atlassian/jira-servicedesk-itsm-sample-project/src/ui/with-should-render-within-itsm-sample-project/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { IncidentManagementTourIssueProps } from './ui/incident-management-tour-issue/types.tsx';
import type { IncidentManagementTourQueuesProps } from './ui/incident-management-tour-queues/types.tsx';

const IncidentManagementTourIssueComponent = () =>
	import(
		/* webpackChunkName: "incident-management-tour-issue" */ './ui/incident-management-tour-issue'
	);

const IncidentManagementTourQueuesComponent = () =>
	import(
		/* webpackChunkName: "incident-management-tour-queues" */ './ui/incident-management-tour-queues'
	);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IncidentManagementTourIssueNew = lazy(() => IncidentManagementTourIssueComponent());

const AsyncIncidentManagementTourIssueNew = (props: IncidentManagementTourIssueProps) => (
	<Placeholder name="incident-management-tour-issue" fallback={null}>
		<IncidentManagementTourIssueNew {...props} />
	</Placeholder>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IncidentManagementTourQueuesNew = lazy(() => IncidentManagementTourQueuesComponent());

const AsyncIncidentManagementTourQueuesNew = (props: IncidentManagementTourQueuesProps) => (
	<Placeholder name="incident-management-tour-queues" fallback={null}>
		<IncidentManagementTourQueuesNew {...props} />
	</Placeholder>
);

export const AsyncIncidentManagementTourIssue: ComponentType<IncidentManagementTourIssueProps> =
	withShouldRenderWithinItsmSampleProject(AsyncIncidentManagementTourIssueNew, false);

export const AsyncIncidentManagementTourQueues: ComponentType<IncidentManagementTourQueuesProps> =
	withShouldRenderWithinItsmSampleProject(AsyncIncidentManagementTourQueuesNew, false);
