import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { CompiledTheme } from '../../../../model/themes/index.tsx';

const softShadow = {
	'@media screen and (-webkit-min-device-pixel-ratio: 0)': {
		boxShadow: token('elevation.shadow.overflow', '0 0 12px 0 rgba(0, 0, 0, 0.1)'),
	},
	'@media screen and (-webkit-transition)': {
		boxShadow: token('elevation.shadow.overflow', '0 0 6px 0 rgba(0, 0, 0, 0.1)'),
	},
	'@-moz-document url-prefix()': {
		boxShadow: token('elevation.shadow.overflow', '0 0 6px 0 rgba(0, 0, 0, 0.1)'),
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LeftWrapper = styled.div<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	offset: any;
	height: string | number;
	theme: CompiledTheme;
}>({
	position: 'absolute',

	top: token('space.0', '0px'),
	bottom: token('space.0', '0px'),
	left: token('space.0', '0px'),
	overflow: 'hidden',
	width: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderLeft: ({ theme }) => `${theme.shadow.left.borderWidth}px solid ${theme.header.borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ theme, height }) => (theme.shadow.left.computeHeight ? `${height}px` : 'inherit'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LeftShadow = styled.div<{ theme: CompiledTheme }>({
	position: 'absolute',
	top: token('space.negative.100', '-8px'),
	bottom: token('space.negative.100', '-8px'),
	left: token('space.negative.100', '-8px'),
	width: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...softShadow,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RightWrapper = styled.div<{ height: string | number; theme: CompiledTheme }>({
	position: 'absolute',
	top: token('space.0', '0px'),
	right: token('space.400', '32px'),
	bottom: token('space.0', '0px'),
	overflow: 'hidden',
	width: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ theme }) => theme.shadow.right.display,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RightShadow = styled.div({
	position: 'absolute',
	top: token('space.negative.100', '-8px'),
	bottom: token('space.negative.100', '-8px'),
	left: token('space.250', '20px'),
	width: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...softShadow,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BottomWrapper = styled.div<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	topPositionForBottomShadow: any;
	tableWidth: string | number;
	height: string | number;
	theme: CompiledTheme;
}>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ topPositionForBottomShadow }) => `${topPositionForBottomShadow}px`,
	left: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ tableWidth }) => `width: ${tableWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ theme }) => theme.shadow.bottom.display,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BottomShadow = styled.div({
	position: 'absolute',
	left: token('space.0', '0px'),
	width: '100%',
	height: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...softShadow,
});
