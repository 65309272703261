import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

// ANALYTIC

export const SEND_ANALYTIC_CREATOR = 'state.actions.analytic.SEND_ANALYTIC_CREATOR' as const;

export type SendAnalyticCreatorAction = {
	type: typeof SEND_ANALYTIC_CREATOR;
	payload: {
		createAnalyticsEvent: CreateUIAnalyticsEvent;
	};
};

export const sendAnalyticCreatorAction = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
): SendAnalyticCreatorAction => ({
	type: SEND_ANALYTIC_CREATOR,
	payload: { createAnalyticsEvent },
});

export type Action = SendAnalyticCreatorAction;
