import { connect } from '../../../../common/table-redux.tsx';
import { getResizingColumnId } from '../../../../state/internal/selectors.tsx';
import { getTableContentWidth } from '../../../../state/selectors/index.tsx';
import type { State } from '../../../../state/types.tsx';
import ResizeColumnOverlay from './index-dumb.tsx';

export default connect(
	(state: State) => ({
		width: getTableContentWidth(state),
		isVisible: !!getResizingColumnId(state),
	}),
	{},
)(ResizeColumnOverlay);
