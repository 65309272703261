export const MutationSource = {
	CREATE: 0,
	UPDATE: 1,
	DELETE: 2,
} as const;

export const CacheType = {
	LOCAL: 'local',
	SESSION: 'session',
} as const;
