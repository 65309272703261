import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyQueueHeaderOne: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-header-one',
		defaultMessage: 'Your queue is empty but what is empty?',
		description:
			'Header of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly below it is emptyQueueDescriptionOne.',
	},
	emptyQueueDescriptionOne: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-description-one',
		defaultMessage: 'Time to ponder the mysteries of the Universe.',
		description:
			'Secondary text of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly above it is emptyQueueHeaderOne.',
	},
	emptyQueueHeaderTwo: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-header-two',
		defaultMessage: 'You’ve cleaned up here perfectly!',
		description:
			'Header of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly below it is emptyQueueDescriptionTwo.',
	},
	emptyQueueDescriptionTwo: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-description-two',
		defaultMessage: 'The only thing left to clean is your computer screen.',
		description:
			'Secondary text of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly above it is emptyQueueHeaderTwo.',
	},
	emptyQueueHeaderThree: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-header-three',
		defaultMessage: 'Queue’s empty! Dance party!',
		description:
			'Header of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly below it is emptyQueueDescriptionThree.',
	},
	emptyQueueDescriptionThree: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-description-three',
		defaultMessage: 'Congratulate yourself on a job well done!',
		description:
			'Secondary text of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly above it is emptyQueueHeaderThree.',
	},
	emptyQueueHeaderFour: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-header-four',
		defaultMessage: 'Your queue is empty.',
		description:
			'Header of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly below it is emptyQueueDescriptionFour.',
	},
	emptyQueueDescriptionFour: {
		id: 'servicedesk.queues.agent-view.layout.empty-queue.empty-queue-description-four',
		defaultMessage: 'Cupcakes for all!',
		description:
			'Secondary text of a tongue-in-cheek message/joke displayed to user when their queue is empty. Directly above it is emptyQueueHeaderFour.',
	},
});
