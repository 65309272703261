import type { BatchAction } from 'redux-batched-actions';
import { combineEpics, type Epic } from 'redux-observable';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import type { Action } from '../../state/actions/types.tsx';
import type { State } from '../../state/reducers/types.tsx';
import getEpic from './get/index.tsx';
import internalEpic from './internal/index.tsx';
import openIssue from './open/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush): Epic<Action | BatchAction, State> =>
	combineEpics(internalEpic, getEpic, openIssue(push));
