import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	none: {
		id: 'servicedesk-customer-service-custom-fields.common.ui.organization-read-view.none-non-final',
		defaultMessage: 'None',
		description:
			'Text shown as the organization field value when no organization has been selected.',
	},
});
