import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type CONNECT_RICH_TEXT_GQL_FIELD,
	ISSUE_FIELD_STRING_CF_TYPE,
	TEXT_AREA_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggJiraConnectRichTextField } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';
import isFieldMissingRenderer from './helpers/is-field-missing-renderer.tsx';

const emptyAdfObject = getEmptyADF();

const getPlainTextValue = (node: AggJiraConnectRichTextField) => {
	if (!node.richText) {
		return null;
	}

	if (node.richText.plainText == null) {
		return '';
	}
	return node.richText.plainText;
};

export const transformAggConnectRichTextToLegacyField: AggTransformerFunction<
	typeof CONNECT_RICH_TEXT_GQL_FIELD
> = (node) => {
	const { renderer } = node;

	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	const isPlainTextRenderer = renderer === PLAIN_TEXT;

	const values = isPlainTextRenderer
		? { value: getPlainTextValue(node) }
		: { adfValue: node.richText?.adfValue?.json || emptyAdfObject };

	return {
		...transformAggNodeToLegacyField(node),
		...values,
		schema: {
			custom: TEXT_AREA_CF_TYPE,
			renderer,
			type: ISSUE_FIELD_STRING_CF_TYPE,
			system: null,
			configuration: null,
			items: null,
		},
	};
};
