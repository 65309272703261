import React from 'react';
import { ProjectContextProvider } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsItsmTemplateProject } from '@atlassian/jira-router-resources-service-desk-is-itsm-project/src/controllers/index.tsx';
import { AsyncIncidentManagementTourQueues } from '@atlassian/jira-servicedesk-incident-management-tour/src/async.tsx';
import { SHORT_FORM_INCIDENTS } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { usePathParam } from '@atlassian/react-resource-router';

export type Props = {
	projectId: ProjectId;
};

export const IncidentManagementTour = (props: Props) => {
	const [practiceType] = usePathParam('practiceType');
	const { data: isItsmProject } = useIsItsmTemplateProject();
	if (isItsmProject !== true) {
		return null;
	}
	return practiceType === SHORT_FORM_INCIDENTS ? (
		<ProjectContextProvider>
			{({ data }) =>
				data && data.isProjectAdmin === true ? (
					<AsyncIncidentManagementTourQueues {...props} />
				) : null
			}
		</ProjectContextProvider>
	) : null;
};
