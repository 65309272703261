import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	serviceRequestsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.service-requests-header',
		defaultMessage: 'Welcome to your new service requests queue',
		description:
			'Service requests queues empty state header when no request types are configured, agent view',
	},
	serviceRequestsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.service-requests-description',
		defaultMessage:
			'Your admin is setting up a service request management workflow to help you deliver great service experiences. To start using your new queue, your admin will need to add request types to the service requests category. Contact your admin for more information.',
		description:
			'Service request queues empty state description when no request types are configured',
	},
	incidentsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.incidents-header',
		defaultMessage: 'Welcome to your new incidents queue',
		description:
			'Incidents queues empty state header when no request types are configured, agent view',
	},
	incidentsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.incidents-description',
		defaultMessage:
			'Your admin is setting up an incident management workflow to help you deliver great service experiences. To start using your new queue, your admin will need to add request types to the incidents category. Contact your admin for more information.',
		description:
			'Incidents queues empty state description when no request types are configured, agent view',
	},
	problemsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.problems-header',
		defaultMessage: 'Welcome to your new problems queue',
		description:
			'Problems queues empty state header when no request types are configured, agent view',
	},
	problemsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.problems-description',
		defaultMessage:
			'Your admin is setting up a problem management workflow to help you deliver great service experiences. To start using your new queue, your admin will need to add request types to the problems category. Contact your admin for more information.',
		description:
			'Problems queues empty state description when no request types are configured, agent view',
	},
	changesHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.changes-header',
		defaultMessage: 'Welcome to your new changes queue',
		description:
			'Changes queues empty state header when no request types are configured, agent view',
	},
	changesDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.changes-description',
		defaultMessage:
			'Your admin is setting up a change management workflow to help you deliver great service experiences. To start using your new queue, your admin will need to add request types to the changes category. Contact your admin for more information.',
		description:
			'Changes queues empty state description when no request types are configured, agent view',
	},
	postIncidentReviewsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.post-incident-reviews-header',
		defaultMessage: 'Get started with post-incident reviews',
		description:
			'Post-incident reviews queues empty state header when no request types are configured, agent view',
	},
	postIncidentReviewsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.post-incident-reviews-description',
		defaultMessage:
			'Your admin is setting up a post-incident review queue to help you deliver great service experiences. To start using your new queue, your admin will need to add request types to the post-incident review category. Contact your admin for more information.',
		description:
			'Post-incident reviews queues empty state description when no request types are configured, agent view',
	},
});
