import { batchUpdateRowHeightMapping } from '../../../state/internal/actions.tsx';
import { defineTransaction } from '../../common/transactions/index.tsx';
import {
	BATCH_UPDATE_ROW_HEIGHT_MAPPING,
	type BatchUpdateRowHeightMappingAction,
} from './action.tsx';

const batchUpdateRowHeightMappingEpic = defineTransaction(
	BATCH_UPDATE_ROW_HEIGHT_MAPPING,
	(action: BatchUpdateRowHeightMappingAction) =>
		batchUpdateRowHeightMapping(action.payload.rowIds, action.payload.rowHeightMapping),
);

export default batchUpdateRowHeightMappingEpic;
