import { combineEpics, type Epic } from 'redux-observable';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import type { Action } from '../../state/actions/types.tsx';
import type { State } from '../../state/reducers/types.tsx';
import changedEpic from './changed/index.tsx';
import cloneEpic from './clone/index.tsx';
import deleteEpic from './delete/index.tsx';
import editEpic from './edit/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush): Epic<Action, State> =>
	// @ts-expect-error - TS2322: Type 'unknown' is not assignable to type 'Action'.
	combineEpics(deleteEpic(push), editEpic(push), cloneEpic(push), changedEpic);
