import type { ColumnId, ColumnTree } from '../../../../model/columns/index.tsx';
import type { Optional } from '../../../../model/optional/index.tsx';
import getSortedLevelIds from './get-sorted-level-ids.tsx';

const traverseColumns = (
	columnTree: ColumnTree,
	columnIds: ColumnId[],
	expandedColumnIdsHash: Record<ColumnId, boolean>,
	hiddenColumnIdsHash: Record<ColumnId, boolean>,
	sortedColumnIds: Optional<ColumnId[]>,
	result: ColumnId[],
) => {
	getSortedLevelIds(sortedColumnIds, columnIds).forEach((columnId) => {
		const childrenIds =
			(expandedColumnIdsHash[columnId] ? columnTree.columns[columnId].childrenIds : []) || [];

		if (!hiddenColumnIdsHash[columnId]) {
			result.push(columnId);
			traverseColumns(
				columnTree,
				childrenIds,
				expandedColumnIdsHash,
				hiddenColumnIdsHash,
				sortedColumnIds,
				result,
			);
		}
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	columnTree: ColumnTree,
	rootColumnIds: ColumnId[],
	expandedColumnIdsHash: Record<ColumnId, boolean>,
	hiddenColumnIdsHash: Record<ColumnId, boolean>,
	sortedColumnIds: Optional<ColumnId[]>,
): ColumnId[] => {
	const result: Array<ColumnId> = [];
	traverseColumns(
		columnTree,
		rootColumnIds,
		expandedColumnIdsHash,
		hiddenColumnIdsHash,
		sortedColumnIds,
		result,
	);
	return result;
};
