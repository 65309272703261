import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { getQueueId } from '../../../../state/selectors/queue/index.tsx';
import { isQueueVisible } from '../../../../state/selectors/ui/index.tsx';
import Provider from './view.tsx';

const mapStateToProps = (state: State) => ({
	queueId: getQueueId(state),
	isQueueVisible: isQueueVisible(state),
});

export default connect(mapStateToProps, {})(Provider);
