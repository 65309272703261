import React from 'react';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';

type Props =
	JsonComponentProps<'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype'>;

const WorkCategory = (props: Props) => {
	const {
		dataSelectorProps: { value },
	} = props;

	return <span>{value}</span>;
};

export default WorkCategory;
