import React, { useMemo } from 'react';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context/index.tsx';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category/index.tsx';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { usePopoutConfig } from '../../../../utils/popout-view/index.tsx';
import { useIsQueueSelected } from '../common/index.tsx';
import { QueueItem } from '../queue-list/queue-item/main.tsx';
import { AllQueuesFlyout } from './all-queues-fly-out/index.tsx';
import { AllQueuesPopOut } from './all-queues-pop-out/index.tsx';

type AllQueuesSectionProps = {
	projectKey: string;
	practice: ItsmPractice;
};

export const AllQueuesSection = ({ projectKey, practice }: AllQueuesSectionProps) => {
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [queueIdFromPath] = usePathParam('queueId');
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	const popoutConfig = usePopoutConfig(projectKey, practice);
	const selectedQueue = useMemo(
		() => allQueues?.find((queue) => queue.id === queueIdFromPath),
		[allQueues, queueIdFromPath],
	);
	const isQueueSelected = useIsQueueSelected(selectedQueue, QueueListSection.ALL_QUEUES);
	const hasSelectedQueue = isQueueSelected && selectedQueue !== undefined;

	if (!allQueues || allQueues.length === 0) {
		return null;
	}

	if (popoutConfig.isPoppedOut) {
		return <AllQueuesPopOut projectKey={projectKey} practice={practice} />;
	}

	return (
		<>
			{hasSelectedQueue && (
				<QueueItem queue={selectedQueue} section={QueueListSection.ALL_QUEUES} />
			)}
			<AllQueuesFlyout projectKey={projectKey} practice={practice} />
		</>
	);
};
