import {
	type DATA_CLASSIFICATION_FIELD,
	DATA_CLASSIFICATION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggDataClassificationToLegacyField: AggTransformerFunction<
	typeof DATA_CLASSIFICATION_FIELD
> = (node) => ({
	...transformAggNodeToLegacyField(node),
	key: DATA_CLASSIFICATION_TYPE,
	value: node,
	schema: {
		configuration: null,
		items: null,
		renderer: null,
		custom: null,
		system: null,
		type: DATA_CLASSIFICATION_TYPE,
	},
});
