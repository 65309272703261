import React, { useState, useCallback, useMemo } from 'react';
import Button from '@atlaskit/button/standard-button';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { SimpleTag as Tag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import { Container, Content, PopUpContent } from './styled.tsx';

type Props =
	| JsonComponentProps<'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes'>
	| JsonComponentProps<'com.atlassian.jira.plugin.system.customfieldtypes:multiselect'>;

const MultiValueField = (props: Props) => {
	const {
		dataSelectorProps: { value: items },
		tableInjectedProps: { fieldName, fieldId },
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const expandedView = useMemo(
		() =>
			items.map(({ value }, index) => <Tag key={`multi-value-${fieldId}-${index}`} text={value} />),
		[items, fieldId],
	);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [setIsOpen, isOpen]);

	const getContent = () => (
		<PopUpContent>
			<TagGroup>{expandedView}</TagGroup>
		</PopUpContent>
	);

	const getTrigger = (triggerProps: TriggerProps) => (
		<Button {...triggerProps} appearance="link" isSelected={isOpen} onClick={onToggle}>
			{`${items.length} ${fieldName}`}
		</Button>
	);

	if (!items || items.length === 0) {
		return null;
	}

	if (items.length <= 3) {
		return (
			<Container>
				<Content>
					<TagGroup>{expandedView}</TagGroup>
				</Content>
			</Container>
		);
	}

	return (
		<Container>
			<Content>
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					placement="bottom-start"
					content={getContent}
					trigger={getTrigger}
				/>
			</Content>
		</Container>
	);
};

export default MultiValueField;
