import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notFoundHeader: {
		id: 'servicedesk-queues-available-queues-api.queue-provider.not-found-header',
		defaultMessage: "We couldn't find your queue",
		description: 'Heading for when QueueProvider could not fetch a queue',
	},
	notFoundDescription: {
		id: 'servicedesk-queues-available-queues-api.queue-provider.not-found-description',
		defaultMessage: 'Looks like we’re having trouble connecting, or it may have been removed.',
		description: 'Message for when QueueProvider could not fetch a queue',
	},
});
