import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type ServerProjectOption,
	PROJECT_ACTIVE,
	PROJECT_ARCHIVED,
	PROJECT_TRASHED,
	type ProjectStatus,
	type ProjectContext,
} from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	PROJECT_GQL_FIELD,
	PROJECT_PICKER_CF_TYPE,
	PROJECT_TYPE,
	SIMILAR_ISSUES_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type {
	JiraProjectStatus,
	JiraProjectType,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isJPDDeliveryLinkTypeFilteringEnabled } from '../../feature-flags.tsx';
import type { AggJiraProject } from '../types/fields-type.tsx';
import type { AggFieldConnectionEdge, AggFieldConnectionEdges } from '../types/issue-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

const getProjectStatus = (status?: JiraProjectStatus | null): ProjectStatus => {
	switch (status) {
		case 'ACTIVE':
			return PROJECT_ACTIVE;
		case 'ARCHIVED':
			return PROJECT_ARCHIVED;
		case 'DELETED':
			return PROJECT_TRASHED;
		default:
			// Based on `getProjectStatus` from src/packages/issue/issue-view/src/common/service/issue/issue-transformer.js
			return PROJECT_ACTIVE;
	}
};

export const getProjectType = (projectType?: JiraProjectType | null): ProjectType | null => {
	switch (projectType) {
		case 'SERVICE_DESK':
			return SERVICE_DESK_PROJECT;
		case 'BUSINESS':
			return CORE_PROJECT;
		case 'SOFTWARE':
			return SOFTWARE_PROJECT;
		case 'PRODUCT_DISCOVERY':
			return PRODUCT_DISCOVERY_PROJECT;
		default:
			return null;
	}
};

export const getSystemAggJiraProject = (fields?: AggFieldConnectionEdges): AggJiraProject => {
	/**
	 * We only want the system `PROJECT_TYPE` field.
	 * For custom fields, e.g., project picker, see `transformAggProjectToLegacyField`
	 */
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const projectField = (fields as AggFieldConnectionEdge[])?.find(
		(edge) =>
			edge?.node?.__typename === PROJECT_GQL_FIELD &&
			edge?.node?.type != null &&
			edge.node.type === PROJECT_TYPE,
	)?.node;

	// Needs the `PROJECT_GQL_FIELD` refinement so we can return as `AggJiraProject`
	if (projectField == null || projectField.__typename !== PROJECT_GQL_FIELD) {
		throw new Error(
			`Failed to get system project data: ${PROJECT_GQL_FIELD} with ${PROJECT_TYPE} 'fieldId' and 'type' not found [${JSON.stringify(
				fields ?? '',
			)}]`,
		);
	}

	if (projectField.project == null) {
		throw new Error(
			`Failed to get system project data: ${PROJECT_GQL_FIELD}.project is missing [${JSON.stringify(
				projectField,
			)}]`,
		);
	}

	return projectField.project;
};

export const isAggJiraProjectSimplified = (
	project: Pick<AggJiraProject, 'projectStyle'>,
): boolean => project?.projectStyle === 'TEAM_MANAGED_PROJECT';

export const isAggJiraProjectClassic = (project: Pick<AggJiraProject, 'projectStyle'>) =>
	!isAggJiraProjectSimplified(project);

const transformAggProjectToServerProjectOption = (
	project?: AggJiraProject | null,
): ServerProjectOption | null => {
	if (project == null) return null;

	const { projectId, key, name, avatar } = project;

	return {
		name,
		id: projectId ?? undefined,
		key: toProjectKey(key),
		avatarUrls: {
			'48x48': avatar.large,
		},
	};
};

export const transformAggProjectToProjectContext = (
	fields?: AggFieldConnectionEdges,
): ProjectContext => {
	const project = getSystemAggJiraProject(fields);
	const { projectId, name, key, avatar, status, projectType: type, navigationMetadata } = project;

	const projectType = getProjectType(type);

	if (projectType == null) {
		throw new Error(`Failed to transform system project: projectType ${String(type)} was invalid`);
	}

	return {
		projectId,
		projectType,
		projectName: name,
		projectKey: key,
		projectStatus: getProjectStatus(status),
		avatarUrl: avatar.medium,
		isSimplified: isAggJiraProjectSimplified(project),
		boardId: navigationMetadata?.boardId,
	};
};

const transformAggProjectToLegacyProjectPickerField: AggTransformerFunction<
	typeof PROJECT_GQL_FIELD
> = (node) => ({
	...transformAggNodeToLegacyField(node),
	allowedValues: [],
	value: transformAggProjectToServerProjectOption(node.project),
	schema: {
		configuration: null,
		items: null,
		renderer: PLAIN_TEXT,
		type: PROJECT_TYPE,
		custom: PROJECT_PICKER_CF_TYPE,
		system: null,
	},
});

const transformAggProjectToLegacySimilarIssuesField: AggTransformerFunction<
	typeof PROJECT_GQL_FIELD
> = (node) => {
	// Similar issues field only exist in JSM projects
	if (getProjectType(node.project?.projectType) !== SERVICE_DESK_PROJECT) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		key: SIMILAR_ISSUES_TYPE,
		title: SIMILAR_ISSUES_TYPE,
		value: node.project?.similarIssues?.featureEnabled === true,
		schema: {
			configuration: null,
			items: null,
			renderer: null,
			type: SIMILAR_ISSUES_TYPE,
			custom: null,
			system: SIMILAR_ISSUES_TYPE,
		},
	};
};

const transformAggProjectToLegacyProjectField: AggTransformerFunction<typeof PROJECT_GQL_FIELD> = (
	node,
) => {
	if (
		node?.__typename !== PROJECT_GQL_FIELD ||
		node?.type === null ||
		node?.type !== PROJECT_TYPE
	) {
		throw new Error(
			`Failed to get system project data: ${PROJECT_GQL_FIELD} with ${PROJECT_TYPE} 'fieldId' and 'type' not found [${JSON.stringify(
				node,
			)}]`,
		);
	}

	const projectField = node;

	if (projectField == null || projectField.__typename !== PROJECT_GQL_FIELD) {
		throw new Error(
			`Failed to get system project data: ${PROJECT_GQL_FIELD} with ${PROJECT_TYPE} 'fieldId' and 'type' not found [${JSON.stringify(
				node ?? '',
			)}]`,
		);
	}

	if (projectField.project == null) {
		throw new Error(
			`Failed to get system project data: ${PROJECT_GQL_FIELD}.project is missing [${JSON.stringify(
				projectField,
			)}]`,
		);
	}

	const project = projectField.project;
	const { projectType: type } = project;
	const projectType = getProjectType(type);

	if (projectType == null) {
		throw new Error(
			`Failed to transform project: projectType ${String(project?.projectType)} was invalid`,
		);
	}

	return {
		...transformAggNodeToLegacyField(node),
		value: {
			projectType,
		},
	};
};

const transformAggProjectToLegacyFieldByType: AggTransformerFunction<typeof PROJECT_GQL_FIELD> = (
	node,
) => {
	const projectType = getProjectType(node.project?.projectType);

	if (projectType === SERVICE_DESK_PROJECT) {
		// Similar issues field only exist in JSM projects
		return transformAggProjectToLegacySimilarIssuesField(node);
	}

	if (projectType === PRODUCT_DISCOVERY_PROJECT) {
		return transformAggProjectToLegacyProjectField(node);
	}

	return undefined;
};

export const transformAggProjectToLegacyField: AggTransformerFunction<typeof PROJECT_GQL_FIELD> = (
	node,
) => {
	if (isJPDDeliveryLinkTypeFilteringEnabled()) {
		switch (node.type) {
			case PROJECT_PICKER_CF_TYPE:
				return transformAggProjectToLegacyProjectPickerField(node);
			case PROJECT_TYPE:
				// For the system project, see `transformAggProjectToProjectContext`
				return transformAggProjectToLegacyFieldByType(node);
			default:
				return undefined;
		}
	} else {
		switch (node.type) {
			case PROJECT_PICKER_CF_TYPE:
				return transformAggProjectToLegacyProjectPickerField(node);
			case PROJECT_TYPE:
				// For the system project, see `transformAggProjectToProjectContext`
				return transformAggProjectToLegacySimilarIssuesField(node);
			default:
				return undefined;
		}
	}
};
