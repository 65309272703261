import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import getPendingValue, { getPendingAdfValue } from './get-pending-value.tsx';

/**
 * Returns the first available value for a given field.
 * editingValue => pendingValue => value
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (field: Field) =>
	typeof field.editingValue !== 'undefined' ? field.editingValue : getPendingValue(field);

/**
 * Returns the first available value for a given field.
 */
export const getEditingAdfValue = (field: Field) => field.editingValue || getPendingAdfValue(field);
