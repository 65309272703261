/**
 * Default Props for Table component
 */
export const DEFAULT_ROW_HEIGHT = 40;
export const AUTO_ROW_HEIGHT = true;
export const DRAGGABLE_ROWS = false;

// Pagination would be rendered if the queue has > 10K records.
export const MAX_ISSUES_PER_PAGE = 10000;

// Min scroll threshold to hide pagingation if visible.
export const MIN_SCROLL_THRESHOLD_FOR_PAGINATION = 100;
