import type { ContextPanel } from '@atlassian/jira-issue-shared-types/src/common/types/context-panel-type.tsx';
// TODO: The consts defined here may need to change/be tweaked, this was done so the jira-issue-view-layout-templates
//       reshuffle has somewhere to pull in the consts
// We need to properly consolidate the consts that we are using: https://jdog.jira-dev.com/browse/BENTO-7913

// Ecosystem
export const ECOSYSTEM_GLANCE_TYPE = 'ecosystemGlance' as const;
export const FORGE_GLANCE_TYPE = 'forgeGlance' as const;
export const ECOSYSTEM_CONTEXT_TYPE = 'ecosystemContext' as const;
export const FORGE_CONTEXT_TYPE = 'forgeContext' as const;

export const CONTEXT_AREA = 'context' as const;
export const CONTENT_AREA = 'content' as const;
export const CONTENT_REQUEST_AREA = 'content-request' as const;

// export const TAB_PANEL: 'tab-panel' = 'tab-panel'; ??

export const FIELD_TYPE = 'FIELD' as const;
const STATUS_TYPE = 'STATUS' as const;
const TAB_TYPE = 'TAB' as const;
const PANEL_TYPE = 'PANEL' as const;
const ECOSYSTEM_TYPE = 'ECOSYSTEM' as const;
const FORGE_TYPE = 'FORGE' as const;

export type Type =
	| typeof FIELD_TYPE
	| typeof STATUS_TYPE
	| typeof TAB_TYPE
	| typeof PANEL_TYPE
	| typeof ECOSYSTEM_TYPE
	| typeof FORGE_TYPE;

// React keys for optimising rendering on layout change
export const REACT_KEY_SUMMARY = 'SUMMARY';

export const CUSTOM_ITEM_TYPE = 'CustomItem';

export const FIELD_SECTION_ITEM = 'FieldSectionItem' as const;
export const TAB_LAYOUT = 'TabLayout' as const;
export const PANEL_SECTION_ITEM = 'PanelSectionItem' as const;
const ECOSYSTEM_SECTION_ITEM = 'EcosystemSectionItem' as const;
const FORGE_SECTION_ITEM = 'ForgeSectionItem' as const;

export type FieldSectionItem = {
	id: string;
	type: typeof FIELD_SECTION_ITEM;
};
export type TabLayout = {
	id: string;
	type: typeof TAB_LAYOUT;
};
export type PanelSectionItem = {
	id: string;
	type: typeof PANEL_SECTION_ITEM;
};
export type EcosystemSectionItem = {
	id: string;
	type: typeof ECOSYSTEM_SECTION_ITEM;
};
export type ForgeSectionItem = {
	id: string;
	type: typeof FORGE_SECTION_ITEM;
};

export type CustomItemType = ItemWithGlobalRef & {
	type: typeof CUSTOM_ITEM_TYPE;
	id: string;
	item: JSX.Element;
};

/**
 * @deprecated Use this in extend a layout item that can be targeted by the global spotlight
 * @see <GlobalSpotlightTargetDeprecated /> from `@atlassian/jira-servicedesk-common`
 * @see ISSUE_FIELD_SPOTLIGHT_PREFIX from `@atlassian/jira-issue-view-common-constants`
 */
type ItemWithGlobalRef = {
	/** Reference used by `GlobalSpotlightTargetDeprecated` to show a spotlight. Value is prefixed by `ISSUE_FIELD_SPOTLIGHT_PREFIX` */
	globalRef?: string;
};

/**
 * Used for issue glance for Forge & Connect
 * @deprecated this is being replaced by ContextPanelItem as issue glance will be eventually removed
 */
export type GlanceItem = {
	type: typeof ECOSYSTEM_GLANCE_TYPE | typeof FORGE_GLANCE_TYPE;
	id: string;
	payload: {
		appKey: string;
		label: string;
	};
};

// Used for issue context for Forge & Connect
export type ContextPanelItem = ItemWithGlobalRef & {
	type: typeof ECOSYSTEM_CONTEXT_TYPE | typeof FORGE_CONTEXT_TYPE;
	id: string;
	payload: ContextPanel;
};

export type LayoutContainerItemTypes = {
	field: 'JiraIssueItemFieldItem';
	panel: 'JiraIssueItemPanelItem';
	tab: 'JiraIssueItemTabContainer';
};

export const layoutContainerItemTypes: LayoutContainerItemTypes = {
	field: 'JiraIssueItemFieldItem',
	panel: 'JiraIssueItemPanelItem',
	tab: 'JiraIssueItemTabContainer',
};

export type LayoutContainerPanelItem = ItemWithGlobalRef & {
	type: typeof layoutContainerItemTypes.panel;
	panelItemId: string;
};

export type LayoutContainerFieldItem = ItemWithGlobalRef & {
	type: typeof layoutContainerItemTypes.field;
	fieldItemId: string;
};

export type LayoutContainerTabItem = ItemWithGlobalRef & {
	items: {
		nodes: (LayoutContainerFieldItem | CustomItemType)[];
	};
	name: string;
	tabContainerId: string;
	type: typeof layoutContainerItemTypes.tab;
};

/**
 * New layout node types, fields, panels and tabs
 * CustomItem is included to ensure that we can still render custom items.
 */
export type LayoutContainerNode =
	| LayoutContainerFieldItem
	| LayoutContainerPanelItem
	| CustomItemType
	| LayoutContainerTabItem;

export type LayoutContainerTemplateItem = LayoutContainerNode | ContextPanelItem | GlanceItem;

export const isLayoutContainerTabItem = (
	node: LayoutContainerNode | LayoutContainerTemplateItem,
): node is LayoutContainerTabItem => node.type === layoutContainerItemTypes.tab;

export type LayoutContainer = {
	containerType: string;
	items: {
		nodes: LayoutContainerNode[];
	};
};

export type ContainersByType = {
	__typename: string;
	containers: LayoutContainer[];
};
