import React from 'react';
import Spinner from '@atlaskit/spinner';
import { Blanket } from './styles.tsx';

type Props = {
	scrollOffset: number;
	width: number;
	shouldHideLoadingIndicator: boolean;
};

const SPINNER_DELAY = 500;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ shouldHideLoadingIndicator, ...props }: Props) => (
	<Blanket {...props}>
		{shouldHideLoadingIndicator ? null : <Spinner delay={SPINNER_DELAY} size="large" />}
	</Blanket>
);
