import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	serviceRequestsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.service-requests-header',
		defaultMessage: 'Get started with service requests',
		description:
			'Service requests queues empty state header when no request types are configured, admin view',
	},
	serviceRequestsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.service-requests-description',
		defaultMessage:
			'<p>To start using your new queue, add a request type to the service requests category. You can create a new request type, or assign an existing request type from this project. <a></a>.</p>',
		description:
			'Service request queues empty state description when no request types are configured, admin view',
	},
	incidentsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.incidents-header',
		defaultMessage: 'Get started with incident management',
		description:
			'Incidents queues empty state header when no request types are configured, admin view',
	},
	incidentsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.incidents-description',
		defaultMessage:
			'<p>To start using your new queue, add a request type to the incidents category. You can create a new request type, or assign an existing request type from this project. <a></a>.</p>',
		description:
			'Incidents queues empty state description when no request types are configured, admin view',
	},
	problemsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.problems-header',
		defaultMessage: 'Get started with problem management',
		description:
			'Problems queues empty state header when no request types are configured, admin view',
	},
	problemsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.problems-description',
		defaultMessage:
			'<p>To start using your new queue, add a request type to the problems category. You can create a new request type, or assign an existing request type from this project. <a></a>.</p>',
		description:
			'Problems queues empty state description when no request types are configured, admin view',
	},
	changesHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.changes-header',
		defaultMessage: 'Get started with change management',
		description:
			'Changes queues empty state header when no request types are configured, admin view',
	},
	changesDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.changes-description',
		defaultMessage:
			'<p>To start using your new queue, add a request type to the changes category. You can create a new request type, or assign an existing request type from this project. <a></a>.</p>',
		description:
			'Changes queues empty state description when no request types are configured, admin view',
	},
	postIncidentReviewsHeader: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.post-incident-reviews-header',
		defaultMessage: 'Get started with post-incident reviews',
		description:
			'Post-incident reviews queues empty state header when no request types are configured, admin view',
	},
	postIncidentReviewsDescription: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.post-incident-reviews-description',
		defaultMessage:
			'<p>To start using your new queue, create a new request type, or assign an existing request type to the post-incident review category. <a></a>.</p>',
		description:
			'Post-incident reviews queues empty state description when no request types are configured, admin view',
	},
	addRequestTypeButtonText: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-admin-view.add-request-type-button-text',
		defaultMessage: 'Add request types',
		description:
			'Button to be shown to admins on empty queues when no request types are configured',
	},
});
