import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const selectIssues =
	(selectedIssueKeys: IssueKey[], analyticsEvent?: UIAnalyticsEvent): Action<State> =>
	({ getState, setState }) => {
		const { issueKeys, limit } = getState();

		const newlySelectedIssueKeys = difference(selectedIssueKeys, issueKeys);

		if (!isEmpty(newlySelectedIssueKeys)) {
			const newIssueKeys = issueKeys.concat(...newlySelectedIssueKeys);
			setState({
				issueKeys: take(newIssueKeys, limit),
			});
			analyticsEvent &&
				fireTrackAnalytics(analyticsEvent, 'bulkSelection added', {
					selectionTruncated: newIssueKeys.length > limit,
					desiredIssuesCount: newIssueKeys.length,
					selectedIssuesCount: Math.min(newIssueKeys.length, limit),
					previousSelectedIssuesCount: issueKeys.length,
				});
		}
	};
