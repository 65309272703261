import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Description = styled.div({
	margin: `${token('space.300', '24px')} 0`,
	textAlign: 'left',
	width: '495px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearnMoreLink = styled.a({
	display: 'block',
	marginBottom: `${token('space.300', '24px')} 0`,
	textAlign: 'center',
});
