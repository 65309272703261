import React, { forwardRef, type ReactNode } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import mergeRefs from '@atlaskit/ds-lib/merge-refs';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { PopupTrigger } from '@atlaskit/popup/experimental';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { MenuItemBase } from '../menu-item';
import { MenuListItem } from '../menu-list-item';
import type { CommonMenuItemOnClick } from '../types';

export type FlyoutMenuItemTriggerProps = {
	children: ReactNode;
	iconBefore?: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => void;
	isDisabled?: boolean;
	isSelected?: boolean;
	/**
	 * A unique string that appears as data attribute data-testid in the rendered code, serving as a hook for automated tests.
	 */
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;

	/**
	 * Disable tooltip for menu item content. This should only be done when there is some other way
	 * to display the full menu content and description of a menu item close by (eg with another popup)
	 */
	isContentTooltipDisabled?: boolean;
};

/**
 * __FlyoutMenuItemTrigger__
 *
 * The button that toggles the flyout menu.
 */
export const FlyoutMenuItemTrigger = forwardRef<HTMLButtonElement, FlyoutMenuItemTriggerProps>(
	(
		{
			children,
			iconBefore,
			onClick,
			isDisabled,
			isSelected,
			interactionName,
			testId,
			isContentTooltipDisabled,
		},
		forwardedRef,
	) => (
		<MenuListItem>
			<PopupTrigger>
				{({
					ref,
					'aria-controls': ariaControls,
					'aria-expanded': ariaExpanded,
					'aria-haspopup': ariaHasPopup,
				}) => (
					<MenuItemBase
						testId={testId}
						ref={mergeRefs([ref, forwardedRef])}
						elemBefore={iconBefore}
						elemAfter={
							<Box paddingInline="space.075">
								<ChevronRightIcon label="" color={token('color.icon', '#44546F')} />
							</Box>
						}
						onClick={onClick as CommonMenuItemOnClick}
						ariaControls={ariaControls}
						ariaExpanded={ariaExpanded}
						ariaHasPopup={ariaHasPopup}
						isDisabled={isDisabled}
						interactionName={interactionName}
						isContentTooltipDisabled={isContentTooltipDisabled}
						isSelected={isSelected}
					>
						{children}
					</MenuItemBase>
				)}
			</PopupTrigger>
		</MenuListItem>
	),
);
