import React from 'react';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import type { Props } from '../types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ value }: Props) => {
	const status: StatusValue = {
		id: value.id,
		name: value.name,
		statusCategory: value.statusCategory,
	};
	return <IssueStatusFieldReadonly value={status} />;
};
