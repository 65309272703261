import type { MouseEvent, ReactNode } from 'react';

// SHOW_PAGINATION

export const SHOW_PAGINATION = 'state.actions.ui.SHOW_PAGINATION' as const;

export type SetPaginationVisibilityAction = {
	type: typeof SHOW_PAGINATION;
	payload: boolean;
};

export const setPaginationVisibility = (payload: boolean): SetPaginationVisibilityAction => ({
	type: SHOW_PAGINATION,
	payload,
});

// SET_EDITING_FIELD_POSITION

export const SET_EDITING_FIELD_POSITION = 'state.actions.ui.SET_EDITING_FIELD_POSITION' as const;

export type SetEditingFieldPositionAction = {
	type: typeof SET_EDITING_FIELD_POSITION;
	payload: {
		horizontalOffset: number;
		verticalOffset: number;
		fieldWidth: number;
		tableWidth: number;
		tableHeight: number;
	};
};

export const setEditingFieldPositionAction = (
	horizontalOffset: number,
	verticalOffset: number,
	fieldWidth: number,
	tableWidth: number,
	tableHeight: number,
): SetEditingFieldPositionAction => ({
	type: SET_EDITING_FIELD_POSITION,
	payload: {
		horizontalOffset,
		verticalOffset,
		fieldWidth,
		tableWidth,
		tableHeight,
	},
});

// SET_CURRENT_PAGE

export const SET_CURRENT_PAGE = 'state.actions.ui.SET_CURRENT_PAGE' as const;

export type SetCurrentPageAction = {
	type: typeof SET_CURRENT_PAGE;
	payload: number;
};

export const setCurrentPageAction = (payload: number): SetCurrentPageAction => ({
	type: SET_CURRENT_PAGE,
	payload,
});

// SET_IS_TABLE_DISABLED

export const SET_IS_TABLE_DISABLED = 'state.actions.ui.SET_IS_TABLE_DISABLED' as const;

export type SetIsTableDisabled = {
	type: typeof SET_IS_TABLE_DISABLED;
	payload: boolean;
};

export const setIsTableDisabled = (isDisabled: boolean): SetIsTableDisabled => ({
	type: SET_IS_TABLE_DISABLED,
	payload: isDisabled,
});

// DISABLE_USER_PICKER

export const DISABLE_USER_PICKER = 'state.actions.ui.DISABLE_USER_PICKER' as const;

export type DisableUserPickerAction = {
	type: typeof DISABLE_USER_PICKER;
};

export const disableUserPickerAction = (): DisableUserPickerAction => ({
	type: DISABLE_USER_PICKER,
});

// SET_ON_ISSUE_VIEW_TRANSITION

export const SET_ON_ISSUE_VIEW_TRANSITION =
	'state.actions.ui.SET_ON_ISSUE_VIEW_TRANSITION' as const;

export type SetOnIssueViewTransition = {
	type: typeof SET_ON_ISSUE_VIEW_TRANSITION;
	payload: (arg1: string, arg2: MouseEvent<HTMLElement>) => void;
};

export const setOnIssueViewTransition = (
	onIssueViewTransition: (arg1: string, arg2: MouseEvent<HTMLElement>) => void,
): SetOnIssueViewTransition => ({
	type: SET_ON_ISSUE_VIEW_TRANSITION,
	payload: onIssueViewTransition,
});

// SET_JSM_QUEUE_DURATION_EVENT

export const SET_JSM_QUEUE_DURATION_EVENT =
	'state.actions.ui.SET_JSM_QUEUE_DURATION_EVENT' as const;

export type SetJSMQueueDurationEvent = {
	type: typeof SET_JSM_QUEUE_DURATION_EVENT;
	payload: () => void;
};

export const setJSMQueueDurationEvent = (
	useJSMQueueDurationEvent: () => void,
): SetJSMQueueDurationEvent => ({
	type: SET_JSM_QUEUE_DURATION_EVENT,
	payload: useJSMQueueDurationEvent,
});

// SET_SIDE_BAR_ICON

export const SET_RENDER_SIDE_BAR_ICON = 'state.actions.ui.SET_RENDER_SIDE_BAR_ICON' as const;

export type SetRenderSidebarIcon = {
	type: typeof SET_RENDER_SIDE_BAR_ICON;

	payload: (arg1: string) => ReactNode;
};

export const setRenderSidebarIcon = (
	renderSidebarIcon: (arg1: string) => ReactNode,
): SetRenderSidebarIcon => ({
	type: SET_RENDER_SIDE_BAR_ICON,

	payload: renderSidebarIcon,
});

// SET_REFRESH_SIDE_BAR

export const SET_REFRESH_SIDE_BAR = 'state.actions.ui.SET_REFRESH_SIDE_BAR' as const;

export type SetRefreshSidebar = {
	type: typeof SET_REFRESH_SIDE_BAR;

	payload: () => void;
};

export const setRefreshSidebar = (refreshSidebar: () => void): SetRefreshSidebar => ({
	type: SET_REFRESH_SIDE_BAR,

	payload: refreshSidebar,
});
