import React from 'react';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/index.tsx';
import { EmptyQueueAdminView } from './empty-queue-admin-view/index.tsx';
import { EmptyQueueAgentView } from './empty-queue-agent-view/index.tsx';

export const EmptyUnconfiguredPracticeQueue = () => {
	const [{ data }] = useServiceDeskContext();
	const isAgentAdmin = data?.userRole === 'agent-project-admin';

	return isAgentAdmin ? <EmptyQueueAdminView /> : <EmptyQueueAgentView />;
};
