import React, { Component, type ComponentType } from 'react';
import type { VirtualBoundaries } from '../../../../../../model/index.tsx';
import type {
	RowHeightMapping,
	RowId,
	RowContentUpdateCallback,
	LoadingRowUnmountCallback,
} from '../../../../../../model/rows/index.tsx';
import { shouldDeferBuffering, type RenderState } from './utils.tsx';

type Props = {
	rowIds: RowId[];
	contentHeight: number;
	isAutoRowHeight: boolean;
	virtualBoundaries: VirtualBoundaries;
	isTableFacade: boolean;
	List: ComponentType<{
		rowIds: RowId[];
		displayStart: number;
		displayEnd: number;
		onRowContentUpdate: RowContentUpdateCallback;
		onLoadingRowUnmount: LoadingRowUnmountCallback;
	}>;
	onDeferredBuffering: (arg1: number, arg2: number) => void;
	onLoadingRowUnmount: LoadingRowUnmountCallback;
	setRowHeightMapping: (arg1: RowHeightMapping) => void;
	batchUpdateRowHeightMapping: (arg1: RowId[], arg2: RowHeightMapping) => void;
};

type State = VirtualBoundaries & {
	rowIds: RowId[];
	rowHeights: RowHeightMapping;
};

const stateToRenderState = (state: State): RenderState => {
	const { visibleStart, visibleEnd, displayStart, displayEnd, rowIds } = state;
	return {
		virtualBoundaries: { visibleStart, visibleEnd, displayStart, displayEnd },
		rowIds,
	};
};

// eslint-disable-next-line jira/react/no-class-components
export default class VirtualList extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			displayStart: 0,
			displayEnd: 0,
			visibleStart: 0,
			visibleEnd: 0,
			rowHeights: {},
			rowIds: props.rowIds,
		};
		const { rowIds } = props;

		this.rowIds = rowIds;
		this.rowHeightUpdateBatch = {};
		this.shouldUpdate = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { isTableFacade, isAutoRowHeight, virtualBoundaries, rowIds } = nextProps;

		const { visibleStart, visibleEnd } = virtualBoundaries;

		// 1) In case of TreeTable, update the list only when we scroll and
		// pass beyond the buffer rows, rather than for every scroll update
		//      1.1) make an inclusive equality comparison. we always want at least 1
		//      item beyond the viewport so we can keyboard navigate to it
		// 2) In case of Table, the list is updated when it's scrolled past even one row.
		// This is to address the weird experience where, when scrolling the table upwards,
		// with deferred row rendering, the rows render one-by-one downwards in batches of 5 (SHIELD-3990)
		if (
			(isTableFacade &&
				(visibleStart < this.state.visibleStart || visibleEnd > this.state.visibleEnd)) ||
			visibleStart <= this.state.displayStart ||
			visibleEnd >= this.state.displayEnd
		) {
			this.shouldUpdate = true;
			if (isTableFacade && shouldDeferBuffering(stateToRenderState(this.state), nextProps)) {
				this.setState({
					visibleEnd,
					visibleStart,
					displayStart: visibleStart,
					displayEnd: visibleEnd,
					rowIds,
				});
				this.needsDisplayBufferRendered = true;
			} else {
				this.needsDisplayBufferRendered = false;
				this.cancelBuffering();
				this.setState({
					...virtualBoundaries,
					rowIds,
				});
			}
		} else {
			this.shouldUpdate = false;
		}

		if (isAutoRowHeight) {
			const { batchUpdateRowHeightMapping } = nextProps;
			if (this.batchUpdateTimeoutId == null) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				this.batchUpdateTimeoutId = window.setTimeout(() => {
					this.batchUpdateTimeoutId = null;
					if (Object.keys(this.rowHeightUpdateBatch).length > 0) {
						batchUpdateRowHeightMapping(rowIds, this.rowHeightUpdateBatch);
					}
					this.rowHeightUpdateBatch = {};
				});
			}
		}
	}

	shouldComponentUpdate(nextProps: Props) {
		const { rowIds, contentHeight } = this.props;

		// table has been resized or entirely new rows: we need to re-adjust - end of story.
		if (contentHeight !== nextProps.contentHeight || rowIds !== nextProps.rowIds) {
			return true;
		}

		// the buffer rows have been surpassed, render a new set of rows and buffers
		return this.shouldUpdate;
	}

	componentDidUpdate() {
		this.shouldUpdate = false;
		if (this.needsDisplayBufferRendered) {
			this.needsDisplayBufferRendered = false;
			const { displayStart, displayEnd } = this.props.virtualBoundaries;
			this.scheduleBuffering(displayStart, displayEnd);
		}
	}

	componentWillUnmount() {
		this.cancelBuffering();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		this.batchUpdateTimeoutId && window.clearTimeout(this.batchUpdateTimeoutId);
	}

	afterAnimationFrame(): Promise<void> {
		return new Promise(
			(
				resolve: (result: Promise<undefined> | undefined) => void,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				reject: (error?: any) => void,
			) => {
				this.cancelNextAnimationFrameHandlers = () => {
					this.cancelNextAnimationFrameHandlers = null;
					reject(new Error('Cancelled'));
				};

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.requestAnimationFrame(() => {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.setTimeout(() => {
						this.cancelNextAnimationFrameHandlers = null;
						// @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
						resolve();
					});
				});
			},
		);
	}

	cancelBuffering() {
		this.cancelNextAnimationFrameHandlers && this.cancelNextAnimationFrameHandlers();
	}

	scheduleBuffering(displayStart: number, displayEnd: number) {
		this.cancelBuffering();
		this.afterAnimationFrame()
			.then(() => {
				this.shouldUpdate = true;
				const rowsRendered = this.state.displayEnd - this.state.displayStart;
				const rowsDeferred =
					this.state.displayStart - displayStart + (displayEnd - this.state.displayEnd);
				this.props.onDeferredBuffering(rowsRendered, rowsDeferred);
				this.setState({
					displayStart,
					displayEnd,
				});
			})
			.catch(() => {
				// presumably due to being cancelled
			});
	}

	updateStateRowHeight = (rowId: RowId, rowHeight: number) => {
		this.rowHeightUpdateBatch[rowId] = rowHeight;
	};

	rowHeightUpdateBatch: RowHeightMapping;

	// @ts-expect-error - TS2564 - Property 'batchUpdateTimeoutId' has no initializer and is not definitely assigned in the constructor.
	batchUpdateTimeoutId: number | null;

	// @ts-expect-error - TS2564 - Property 'cancelNextAnimationFrameHandlers' has no initializer and is not definitely assigned in the constructor.
	cancelNextAnimationFrameHandlers: (() => void) | null;

	// @ts-expect-error - TS2564 - Property 'needsDisplayBufferRendered' has no initializer and is not definitely assigned in the constructor.
	needsDisplayBufferRendered: boolean;

	shouldUpdate: boolean;

	rowIds: RowId[];

	getBoundaries() {
		if (__SERVER__) {
			return this.props.virtualBoundaries;
		}
		return this.state;
	}

	render() {
		const { rowIds, contentHeight, onLoadingRowUnmount, List } = this.props;
		const { displayStart, displayEnd } = this.getBoundaries();

		// @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'string[]' and 'number' have no overlap.
		if (contentHeight === 0 || rowIds === 0) {
			return null;
		}

		return (
			<List
				rowIds={rowIds}
				displayStart={displayStart}
				displayEnd={displayEnd}
				onRowContentUpdate={this.updateStateRowHeight}
				onLoadingRowUnmount={onLoadingRowUnmount}
			/>
		);
	}
}
