import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'servicedesk.queues.agent-view.layout.empty-filtered-queue.header',
		defaultMessage: "We couldn't find any requests that match your search",
		description: 'Description of empty state when table has no rows to show',
	},
	description: {
		id: 'servicedesk.queues.agent-view.layout.empty-filtered-queue.description',
		defaultMessage: 'Check for typos, or try another search term',
		description: 'Instructions for the user to clear the filter',
	},
});
