/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const baseStyle = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	opacity: 0.8,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	position: 'absolute',
	zIndex: 6,
});

export const Blanket = ({
	scrollOffset,
	width,
	children,
}: {
	scrollOffset: number;
	width: number;
	children?: ReactNode;
}) => (
	<div
		css={baseStyle}
		style={{
			top: `${scrollOffset}px`,
			bottom: `${-scrollOffset}px`,
			width: `${width}px !important`,
		}}
	>
		{children}
	</div>
);
