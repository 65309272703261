import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showLess: {
		id: 'issue-table.common.fields.cmdb-object.show-less',
		defaultMessage: '… Show less',
		description: 'Button to show only the first Insight object',
	},
	showMore: {
		id: 'issue-table.common.fields.cmdb-object.show-more',
		defaultMessage: '… ({numberOfObjects, plural, one {# more} other {# more}})',
		description: 'Button to reveal all of the available Insight objects',
	},
	none: {
		id: 'issue-table.common.fields.cmdb-object.none',
		defaultMessage: 'None',
		description: 'Text of message shown when there are no tags to show',
	},
});
