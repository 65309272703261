import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { ConnectSelectFieldOption } from '@atlassian/jira-issue-shared-types/src/common/types/select-field-option-type.tsx';
import type { AggJiraConnectMultiSelectFieldOptionNode } from '../types/fields-type.tsx';
import type { AggIssueField as AggField } from '../types/issue-type.tsx';
import type { AggConnection, AggConnectionTransformer } from '../types/transformer-type.tsx';

/**
 * Provides default legacy field values for an AGG field.
 * Overriding `value`, `schema`, and other fields are left to the transformer.
 */

export const transformAggNodeToLegacyField = (node: AggField): Field => ({
	ari: 'id' in node ? node.id : undefined,
	key: 'fieldId' in node && !!node.fieldId ? node.fieldId : '',
	title: 'name' in node ? node.name : '',
	editable: Boolean(
		'isEditableInIssueView' in node
			? node.isEditableInIssueView
			: 'fieldConfig' in node &&
					node.fieldConfig != null &&
					'isEditable' in node.fieldConfig &&
					node.fieldConfig.isEditable,
	),
	required: Boolean(
		'fieldConfig' in node &&
			node.fieldConfig != null &&
			'isRequired' in node.fieldConfig &&
			node.fieldConfig.isRequired,
	),
	autoCompleteUrl: 'searchUrl' in node ? node.searchUrl : null,
	allowedValues: [],
	value: null,
	schema: {
		type: 'type' in node ? node.type : '',
		custom: null,
		system: null,
	},
	description: 'description' in node && node.description ? node.description : undefined,
});

export const transformAggConnection: AggConnectionTransformer = <Node, TransformedNode>(
	connection?: AggConnection<Node> | null | undefined,
	transformCallback?: (node: Node) => TransformedNode,
) => {
	const edges = connection?.edges ?? [];

	if (transformCallback) {
		return edges
			.map((edge) => (edge?.node != null ? transformCallback(edge.node) : null))
			.filter(Boolean);
	}

	return edges.map((edge) => edge?.node).filter(Boolean);
};

// https://jdog.jira-dev.com/browse/BENTO-12624 - Connect fields return id as numbers
export const toLegacyConnectOption = (
	node: AggJiraConnectMultiSelectFieldOptionNode,
): ConnectSelectFieldOption | null => {
	if (!node) return null;

	return {
		value: node.value ?? null,
		id: parseInt(node.optionId, 10),
	};
};
