import {
	type FORGE_GROUPS_GQL_FIELD,
	ISSUE_FIELD_SINGLE_GROUP_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';
import isFieldMissingRenderer from './helpers/is-field-missing-renderer.tsx';

export const transformAggForgeGroupsToLegacyField: AggTransformerFunction<
	typeof FORGE_GROUPS_GQL_FIELD
> = (node) => {
	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	const value = node.selectedGroupsConnection?.edges?.length
		? transformAggConnection(node.selectedGroupsConnection, ({ name }) => ({
				name,
			}))
		: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			configuration: null,
			items: ISSUE_FIELD_SINGLE_GROUP_CF_TYPE,
			system: null,
			custom: node.type,
			renderer: node.renderer,
			type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
		},
	};
};
