import { createSelector, type Selector } from 'reselect';
import flatten from 'lodash/flatten';
import { fireOperationalFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/issue-panel/index.tsx';
import { isKeyMatchPrefix } from '@atlassian/jira-forge-ui-utils/src/utils/connect/index.tsx';
import type {
	ConnectActivity,
	EcosystemContentPanel as ConnectPanel,
	ForgeActivity,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';

import {
	CONNECT_ENTITY_TYPE,
	FORGE_ENTITY_TYPE,
} from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';

import type { Panel as ForgePanel } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';

import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	removeTimestamp,
	parseForgeIssuePanel,
} from '@atlassian/jira-issue-view-common-utils/src/ecosystem/module-key-helper.tsx';
import {
	analyticsSourceSelector,
	isEcosystemEnabledSelector,
} from '../common/state/selectors/context-selector.tsx';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';
import { canAdministerJiraPermissionsSelector } from '../common/state/selectors/permissions-selector.tsx';
import { ISSUE_CONTENT_PANEL_CUSTOMISED } from './ecosystem-property-keys.tsx';
import { ecosystemEntitiesSelector, forgeEntitiesSelector } from './entities-selector.tsx';
import {
	forgeIssueActionsSelector,
	forgeIssueActivitySelector,
	forgeGlancesSelector,
	forgePanelsSelector,
} from './forge/forge-extensions-selector.tsx';

type Activity = ForgeActivity | ConnectActivity;
type AddedForgePanel = ForgePanel & {
	dateAdded?: number;
	panelInstanceId?: string;
};
type AddedPanel =
	| AddedForgePanel // ForgePanel is here for the transition period only, will be removed after FY20Q4
	| ConnectPanel
	| ForgePanel;

/**
 State format:
 ecosystem: {
    "glances": {
        "app_key_1": {
            "module_key_1": {
                appKey: "",
                moduleKey: "",
                name: "",
                target: "",
                icon: "",
                label: "",
                status: "",
                options: { ... }
            }
        }
    }
}
 */

export const issueViewBackgroundScriptsSelector = createSelector(
	forgeEntitiesSelector,
	(forge) => forge.backgroundScripts || [],
);

export const issueContentPanelsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.issueContentPanels || [],
);

export const isIssueContentPanelCustomisedSelector = createSelector(
	entitiesSelector,
	(entities) =>
		!!entities.issueContentPanels &&
		entities.issueContentPanels.includes(ISSUE_CONTENT_PANEL_CUSTOMISED),
);

export const canInstallAddonsSelector = createSelector(
	canAdministerJiraPermissionsSelector,
	(canAdministerJira) => canAdministerJira,
);

export const isIssueCreatedAfterEcosystemOnSelector = createSelector(
	entitiesSelector,
	(entities) =>
		entities.ecosystem &&
		!!entities.ecosystem.ecosystemOnDate &&
		// @ts-expect-error - TS2345 - Argument of type 'AuthoredTimestamp | undefined' is not assignable to parameter of type 'string'.
		Date.parse(entities.createdDate) > entities.ecosystem.ecosystemOnDate,
);
const extractSecondLevelDeepValuesFromObject = <T,>(obj: Record<string, Record<string, T>>): T[] =>
	Object.keys(obj || {}).flatMap((key: string) => Object.values(obj ? obj[key] : {}));

const glanceEntitiesSelector = createSelector(ecosystemEntitiesSelector, (ecosystem) =>
	extractSecondLevelDeepValuesFromObject(ecosystem.glances || {}),
);

export const contextPanelEntitiesSelector = createSelector(ecosystemEntitiesSelector, (ecosystem) =>
	extractSecondLevelDeepValuesFromObject(ecosystem.contextPanels),
);

const contentPanelsEntitiesSelector = createSelector(ecosystemEntitiesSelector, (ecosystem) =>
	extractSecondLevelDeepValuesFromObject(ecosystem.contentPanels),
);

const activityPanelsEntitiesSelector = createSelector(ecosystemEntitiesSelector, (ecosystem) =>
	extractSecondLevelDeepValuesFromObject(ecosystem.activityPanels),
);

/**
 [{
    appKey: "",
    moduleKey: "",
    name: "",
    icon: "",
    label: "",
    status: "",
 },{
 {
    appKey: "",
    moduleKey: "",
    name: "",
    icon: "",
    label: "",
    status: "",
 }]
 */

export const createEcosystemContextVisibleSelector = <R,>(selector: Selector<State, R>) =>
	createSelector(
		isEcosystemEnabledSelector,
		selector,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(ecosystemEnabled, otherSelector) => (ecosystemEnabled && otherSelector) || ([] as R),
	);

export const glancesSelector = createEcosystemContextVisibleSelector(
	createSelector(glanceEntitiesSelector, (glanceEntities) =>
		glanceEntities.map((entity) => ({
			appKey: entity.appKey,
			moduleKey: entity.moduleKey,
			name: entity.name,
			icon: entity.icon &&
				entity.icon.url && {
					url: entity.icon.url,
				},
			label: entity.label,
			status: entity.status,
			type: entity.type,
			glanceType: CONNECT_ENTITY_TYPE,
		})),
	),
);

export const ecosystemGlancesSelector = createSelector(
	[forgeGlancesSelector, glancesSelector],
	(forge, connect) => [...forge, ...connect],
);

export const glancePanelSelector = (appKey: string, moduleKey: string) =>
	createSelector(
		ecosystemEntitiesSelector,
		(ecosystem) =>
			ecosystem &&
			ecosystem.glances &&
			ecosystem.glances[appKey] &&
			ecosystem.glances[appKey][moduleKey],
	);

export const allContentPanelsSelector = createEcosystemContextVisibleSelector(
	createSelector(contentPanelsEntitiesSelector, (contentPanelEntities) => contentPanelEntities),
);

export const ecosystemAllPanelsSelector = createSelector(
	[forgePanelsSelector, allContentPanelsSelector],
	(forge, connect) => [...forge, ...connect],
);

const panelsComparator = (firstPanel: AddedPanel, secondPanel: AddedPanel): number => {
	// The sort order rule:
	// 1. Forge panels without quick add button (no dateAdded prop)
	// 2. Forge panels sorted by date added (oldest > newest)
	// 3. Connect panels, sorting criteria won't be changed
	if (firstPanel.type === FORGE_ENTITY_TYPE) {
		// @ts-expect-error - TS2339 - Property 'dateAdded' does not exist on type 'AddedPanel'.
		const firstValue = firstPanel.dateAdded || 1;
		if (secondPanel.type === FORGE_ENTITY_TYPE) {
			// @ts-expect-error - TS2339 - Property 'dateAdded' does not exist on type 'AddedPanel'.
			const secondValue = secondPanel.dateAdded || 1;
			return firstValue - secondValue;
		}
		return firstValue;
	}

	return Infinity;
};

const sortPanels = (allPanelsToAddedPanels: (AddedPanel[] | AddedPanel)[]): AddedPanel[][] =>
	allPanelsToAddedPanels
		// @ts-expect-error - TS2769 - No overload matches this call.
		.map((panel) => [].concat(panel))
		.sort((firstPanel: AddedPanel[], secondPanel: AddedPanel[]) =>
			panelsComparator(firstPanel[0], secondPanel[0]),
		);

export const activitiesComparator = (firstActivity: Activity, secondActivity: Activity): number => {
	// The sort order rule:
	// 1. Forge activity ordered alphabetically.
	// 2. Connect panels, sorting criteria won't be changed
	if (firstActivity.type === FORGE_ENTITY_TYPE) {
		const firstValue = firstActivity.name.toLowerCase();
		if (secondActivity.type === FORGE_ENTITY_TYPE) {
			const secondValue = secondActivity.name.toLowerCase();

			if (firstValue < secondValue) {
				return -Infinity;
			}

			if (firstValue > secondValue) {
				return Infinity;
			}

			return 0;
		}

		return Infinity;
	}

	return Infinity;
};

// I tried adding the typing here but gave up, 🤯
export const ecosystemAddedContentPanelsSelector = createEcosystemContextVisibleSelector(
	createSelector(
		issueContentPanelsSelector,
		ecosystemAllPanelsSelector,
		isIssueContentPanelCustomisedSelector,
		isIssueCreatedAfterEcosystemOnSelector,
		analyticsSourceSelector,
		(selectedPanels, allPanels, customised, newIssue, analyticsSource) => {
			const allPanelsToAddedPanels = allPanels.map((panel) => {
				// Forge-defined panels
				if (panel.type === FORGE_ENTITY_TYPE) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					const forgePanel: ForgePanel = panel as any;
					// For a transition period we always render panels without a quick add button
					if (!forgePanel.iconUrl) {
						return forgePanel;
					}

					const prefix = `${forgePanel.appKey}/${forgePanel.moduleKey}-`;

					const filteredSelectedPanels = selectedPanels
						.filter((sPanel) => isKeyMatchPrefix(sPanel, prefix))
						.map((sPanel) => {
							const panelProperties = parseForgeIssuePanel(sPanel);

							if (panelProperties == null) {
								fireOperationalFailedEvent(analyticsSource, {
									error: Error(`Panel key doesn't match regexp: ${sPanel}`),
								});
							}

							return (
								panelProperties && {
									...forgePanel,
									dateAdded: panelProperties.added,
									panelInstanceId: panelProperties.id,
								}
							);
						})
						.filter(Boolean);

					return filteredSelectedPanels.length > 0 && filteredSelectedPanels;
				}

				// Connect-defined panels
				if (!customised && !newIssue) {
					return panel;
				}

				const isSelected =
					selectedPanels.includes(`${panel.appKey}_${panel.moduleKey}`) ||
					// BENTO-2491 SelectedPanels could be saved using keys without tailing digits
					selectedPanels.includes(`${panel.appKey}_${removeTimestamp(panel.moduleKey, 10)}`) ||
					panel.showByDefault;

				return isSelected && panel;
			});

			const addedPanels: AddedPanel[] = flatten(sortPanels(allPanelsToAddedPanels.filter(Boolean)));

			return addedPanels;
		},
	),
);

export const contentPanelSelector = (appKey: string, moduleKey: string) =>
	createSelector(
		ecosystemEntitiesSelector,
		(ecosystem) =>
			ecosystem &&
			ecosystem.contentPanels &&
			ecosystem.contentPanels[appKey] &&
			ecosystem.contentPanels[appKey][moduleKey],
	);

export const selectedContentPanelSelector = (appKey: string, moduleKey: string) =>
	createSelector(ecosystemEntitiesSelector, (ecosystem) =>
		Boolean(
			ecosystem &&
				ecosystem.selectedContentPanel &&
				(ecosystem.selectedContentPanel === `${appKey}_${moduleKey}` ||
					ecosystem.selectedContentPanel === `${appKey}_${removeTimestamp(moduleKey, 10)}`),
		),
	);

export const operationsSelector = createEcosystemContextVisibleSelector(
	createSelector(ecosystemEntitiesSelector, (ecosystem) => ecosystem.operations || []),
);

// JIV-16397: Remove when cleaning up issue-view-remove-connect-operations-from-critical-fetch_vtk4w
export const ecosystemOperationsSelector = createEcosystemContextVisibleSelector(
	createSelector([forgeIssueActionsSelector, operationsSelector], (forge, connect) => [
		...forge,
		...connect,
	]),
);

export const activityPanelsSelector = createSelector(
	isEcosystemEnabledSelector,
	activityPanelsEntitiesSelector,
	(isEcosystemEnabled, activityPanelEntities) =>
		isEcosystemEnabled
			? activityPanelEntities.map((entity) => ({
					name: entity.name,
					appKey: entity.appKey,
					moduleKey: entity.moduleKey,
					options: entity.options,
					type: CONNECT_ENTITY_TYPE,
				}))
			: [],
);

export const ecosystemActivityPanelsSelector = createSelector(
	[forgeIssueActivitySelector, activityPanelsSelector],
	(forge, connect) => [...forge, ...connect].sort(activitiesComparator),
);
