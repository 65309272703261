import { unset, updateIn } from 'icepick';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsetInSingle = (state: any, pathToParent: any, key: any) => {
	if (pathToParent.length === 0) {
		return unset(state, key);
	}
	const ret = updateIn(state, pathToParent, (parent) => (parent ? unset(parent, key) : undefined));
	return ret;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsetInMulti = (state: any, pathToParent: any, keys: any) =>
	// @ts-expect-error - TS7006 - Parameter 'folded' implicitly has an 'any' type. | TS7006 - Parameter 'subkey' implicitly has an 'any' type.
	keys.reduce((folded, subkey) => unsetInSingle(folded, pathToParent, subkey), state);

//   ['a', 'b', 'c'] - removes values at 'a/b/c'
//   ['a', ['b', 'c']] - removes values at 'a/b' and 'a/c'
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state: any, path: any) => {
	if (path.length === 0) {
		return state;
	}

	const pathToParent = path.slice(0, path.length - 1);
	const lastPathItem = path.slice(-1)[0];

	if (Array.isArray(lastPathItem)) {
		return unsetInMulti(state, pathToParent, lastPathItem);
	}
	return unsetInSingle(state, pathToParent, lastPathItem);
};
