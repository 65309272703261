import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { isRetrying } from '../../../state/selectors/ui/index.tsx';
import ErrorState from './view.tsx';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const reloadWindow = () => window.location.reload();

const mapStateToProps = (state: State) => ({
	isLoading: isRetrying(state),
});

const mapDispatchToProps = () => ({
	onRetry: reloadWindow,
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorState);
