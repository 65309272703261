import { BATCH } from 'redux-batched-actions';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withUnpackedBatchActions = (action$: ActionsObservable<any>) =>
	action$.mergeMap((action) => {
		if (action.type === BATCH) {
			return Observable.of(...action.payload);
		}
		return Observable.of(action);
	});
