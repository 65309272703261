import { createContext } from 'react';
import {
	type ReactReduxContextValue,
	connect as connectWrapper,
	createProvider,
} from '@atlassian/jira-react-redux/src/index.tsx';

const STORE_KEY = 'tableStore';

// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type
export const TableContext = createContext<ReactReduxContextValue>(null);

export const Provider = createProvider(TableContext);

export const connect = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapStateToProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapDispatchToProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mergeProps: any = undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: any = {},
) =>
	connectWrapper(mapStateToProps, mapDispatchToProps, mergeProps, {
		...options,
		context: TableContext,
		storeKey: STORE_KEY,
	});
