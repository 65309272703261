import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages.tsx';

type FieldConfigurations = {
	ascText: MessageDescriptor;
	descText: MessageDescriptor;
};

export type FieldConfigurationsMap = {
	reporter: FieldConfigurations;
	reporterWithIcon: FieldConfigurations;
	assignee: FieldConfigurations;
	['com.atlassian.servicedesk:sd-sla-field']: FieldConfigurations;
	components: FieldConfigurations;
	created: FieldConfigurations;
	issuekey: FieldConfigurations;
	issuetype: FieldConfigurations;
	summary: FieldConfigurations;
	updated: FieldConfigurations;
	date: FieldConfigurations;
};

// Map system field ids and custom field types to their configurations: max width, min width, asc text, desc text, etc.
const fieldConfigurationsMap: FieldConfigurationsMap = {
	assignee: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	reporterWithIcon: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	'com.atlassian.servicedesk:sd-sla-field': {
		ascText: messages.shortestToLongestOrder,
		descText: messages.longestToShortestOrder,
	},
	components: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	created: {
		ascText: messages.oldestToNewestOrder,
		descText: messages.newestToOldestOrder,
	},
	issuekey: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	issuetype: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	reporter: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	summary: {
		ascText: messages.aToZOrder,
		descText: messages.zToAOrder,
	},
	updated: {
		ascText: messages.oldestToNewestOrder,
		descText: messages.newestToOldestOrder,
	},
	date: {
		ascText: messages.oldestToNewestOrder,
		descText: messages.newestToOldestOrder,
	},
};

export default fieldConfigurationsMap;
