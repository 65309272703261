import React from 'react';
import Button from '@atlaskit/button';
import { type IntlShape, injectIntl } from '@atlassian/jira-intl/src/index.tsx';
import messages from '@atlassian/jira-servicedesk-queues-common/src/view/messages.tsx';

export type Props = {
	onResetSorting: () => void;
	intl: IntlShape;
};

export function ResetSortingButton(props: Props) {
	const {
		intl: { formatMessage },
		onResetSorting,
	} = props;

	return <Button onClick={onResetSorting}>{formatMessage(messages.resetSorting)}</Button>;
}

export default injectIntl(ResetSortingButton);
