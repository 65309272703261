// eslint-disable-next-line jira/restricted/react
import React, { type ReactNode, PureComponent } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import VirtualTable from '@atlassian/jira-virtual-table/src/app/table/index.tsx';
import type { TableRef as VirtualTableRef } from '@atlassian/jira-virtual-table/src/app/table/types.tsx';
import { createCellsCache, type CreateCellType } from '../../common/create-cell/view.tsx';
import { MAX_ISSUES_PER_PAGE } from '../../common/table/constants.tsx';
import type { ConnectProps } from '../../model/cell/index.tsx';
import type { PropsTransformer } from '../../state/selectors/table-props/index.tsx';

export type Props = ConnectProps & {
	tableRef: (arg1: VirtualTableRef | null) => void;
	transformProps: PropsTransformer;
	renderSidebarIcon?: (issueKey: string) => ReactNode;
	refreshSidebar?: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class Table extends PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.createCell = createCellsCache();
	}

	createCell: CreateCellType;

	render() {
		const { issueKeys } = this.props;
		const { transformProps, tableRef, ...otherProps } = this.props;

		if (issueKeys && issueKeys.length > MAX_ISSUES_PER_PAGE) {
			log.safeWarnWithoutCustomerData(
				'issue-table',
				`[FD-4474] Maximum number of issues allowed by IssueTable on a page is ${MAX_ISSUES_PER_PAGE}`,
			);
		}

		const tableProps = transformProps(otherProps, this.createCell);

		return <VirtualTable {...tableProps} ref={tableRef} />;
	}
}
