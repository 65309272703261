/* eslint-disable jira/react-no-inline-function-prop */
import React, { useContext } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';
import { QUEUES_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { FavoriteChangeContext } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { AvailableQueuesConsumer } from '@atlassian/jira-servicedesk-queues-available-queues/src/services/queues-api-data-provider/main.tsx';
import {
	type QueueId,
	fromQueueIdString,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';

type Props = {
	isFavorite: boolean;
	queueId: QueueId;
};

const FavoriteButton = ({ isFavorite, queueId }: Props) => {
	const { changeFavorite, items } = useContext(FavoriteChangeContext);

	const getIsPending = () => {
		const queueItemChanges = items[QUEUES_ITEM_TYPE];
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		const itemChanged = queueItemChanges[fromQueueIdString(queueId)];
		return itemChanged ? itemChanged.pending : false;
	};

	return (
		<AvailableQueuesConsumer>
			{() => (
				<FavoriteButtonStateless
					isFavorite={isFavorite}
					pending={getIsPending()}
					onClick={(analyticsEvent: UIAnalyticsEvent) => {
						changeFavorite(
							{
								id: fromQueueIdString(queueId),
								value: !isFavorite,
								type: QUEUES_ITEM_TYPE,
							},
							analyticsEvent,
						);
					}}
				/>
			)}
		</AvailableQueuesConsumer>
	);
};
export default FavoriteButton;
