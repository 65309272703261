import forEach from 'lodash/forEach';
import type { RowId, RowTree } from '../model/rows/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	rowId: RowId,
	rowTree: RowTree,
	expandedRowIdsHash: Record<RowId, boolean>,
): number => {
	let childLength = 0;
	// @ts-expect-error - TS2304 - Cannot find name 'Identifier'.
	const traverseChildren = (parentId: Identifier | RowId) => {
		const childrenIds = expandedRowIdsHash[parentId] ? rowTree.rows[parentId].childrenIds : [];
		childLength += childrenIds.length;
		forEach(childrenIds, (childId) => {
			traverseChildren(childId);
		});
	};
	traverseChildren(rowId);

	return childLength;
};
