import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	aToZOrder: {
		id: 'issue-table.model.fields.field-configurations-map.a-to-z-order',
		defaultMessage: 'A → Z',
		description:
			'Ascending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	zToAOrder: {
		id: 'issue-table.model.fields.field-configurations-map.z-to-a-order',
		defaultMessage: 'Z → A',
		description:
			'Descending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	oldestToNewestOrder: {
		id: 'issue-table.model.fields.field-configurations-map.oldest-to-newest-order',
		defaultMessage: 'oldest → newest',
		description:
			'Ascending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	newestToOldestOrder: {
		id: 'issue-table.model.fields.field-configurations-map.newest-to-oldest-order',
		defaultMessage: 'newest → oldest',
		description:
			'Descending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	shortestToLongestOrder: {
		id: 'issue-table.model.fields.field-configurations-map.shortest-to-longest-order',
		defaultMessage: 'shortest → longest',
		description:
			'Ascending order used by SLA fields which represent the duration to a specific goal. e.g. Time to resolution, time to first response, etc.',
	},
	longestToShortestOrder: {
		id: 'issue-table.model.fields.field-configurations-map.longest-to-shortest-order',
		defaultMessage: 'longest → shortest',
		description:
			'Descending order used by SLA fields which represent the duration to a specific goal. e.g. Time to resolution, time to first response, etc.',
	},
	lowestToHighestOrder: {
		id: 'issue-table.model.fields.field-configurations-map.lowest-to-highest-order',
		defaultMessage: 'lowest → highest',
		description: 'Ascending order used by the priority field.',
	},
	highestToLowestOrder: {
		id: 'issue-table.model.fields.field-configurations-map.highest-to-lowest-order',
		defaultMessage: 'highest → lowest',
		description: 'Descending order used by the priority field.',
	},
});
