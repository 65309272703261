// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import union from 'lodash/union';
import without from 'lodash/without';
import type { UpdateHiddenColumnsCallback } from '../../../../../model/callbacks/index.tsx';
import type { ColumnConfiguration as ConfiguredColumn } from '../../../../../model/columns/index.tsx';
import MultiSelectList from './flyout/multi-select-list/list/index.tsx';
import type { SubItem } from './flyout/multi-select-list/list/types.tsx';
import ListItem from './item.tsx';
import { getHiddenParentColumns, getAllHiddenColumns, determineItemColor } from './utils.tsx';

type Props = {
	columnConfiguration: ConfiguredColumn[];
	onChangeHiddenColumnsRequested: UpdateHiddenColumnsCallback | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnConfigurationList extends PureComponent<Props> {
	onSelect() {
		const { columnConfiguration, onChangeHiddenColumnsRequested } = this.props;
		if (onChangeHiddenColumnsRequested === undefined) {
			return noop;
		}
		const allHiddenColumns = getAllHiddenColumns(columnConfiguration);

		const columnsMap = reduce(
			columnConfiguration,
			(hash, item) => ({
				...hash,
				[item.id]: {
					isHidden: item.isHidden,
					hiddenParentColumns: getHiddenParentColumns(item, columnConfiguration),
				},
			}),
			{},
		);

		return (item: SubItem) => {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			const column = columnsMap[item.id];
			if (column.isHidden) {
				// show
				const columnsToShow = union([item.id], column.hiddenParentColumns);
				onChangeHiddenColumnsRequested(without(allHiddenColumns, ...columnsToShow));
			} else {
				// hide
				onChangeHiddenColumnsRequested(union(allHiddenColumns, [item.id]));
			}
		};
	}

	transformColumnsToItems() {
		const { columnConfiguration } = this.props;

		const items: SubItem[] = columnConfiguration.map((column: ConfiguredColumn) => {
			const hiddenParentColumns = getHiddenParentColumns(column, columnConfiguration);

			return {
				id: column.id,
				content: (
					<ListItem
						key={column.id}
						depth={column.depth}
						title={column.title}
						color={determineItemColor(column, hiddenParentColumns)}
						isDisabled={column.isDisabled}
						isChecked={!column.isHidden}
					/>
				),
				isDisabled: column.isDisabled,
			};
		});

		return [
			{
				items,
			},
		];
	}

	render() {
		return <MultiSelectList items={this.transformColumnsToItems()} onSelect={this.onSelect()} />;
	}
}
