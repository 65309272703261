import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	type PEOPLE_GQL_FIELD,
	type JSM_PEOPLE_GQL_FIELD,
	MULTI_USER_PICKER_GQL_FIELD,
	APPROVERS_LIST_CF_TYPE,
	PEOPLE_CF_TYPE,
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';
import { getSystemAggJiraProject, getProjectType } from './project-field-transformer.tsx';
import { transformJiraUserToLegacyServerUser } from './user-node-transformer.tsx';

export const transformAggMultipleUserPickerToLegacyField: AggTransformerFunction<
	typeof JSM_PEOPLE_GQL_FIELD | typeof MULTI_USER_PICKER_GQL_FIELD | typeof PEOPLE_GQL_FIELD
> = (node, context) => {
	const value = transformAggConnection(
		node.selectedUsersConnection,
		transformJiraUserToLegacyServerUser,
	);

	const legacyFieldValues: Field = {
		...transformAggNodeToLegacyField(node),
		value: value.length > 0 ? value : null,
		schema: {
			configuration: null,
			custom: node.type,
			items: 'user',
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};

	switch (node.type) {
		case REQUEST_PARTICIPANTS_CF_TYPE: {
			if (
				ff('relay-migration-issue-fields-request-participants_itocm') &&
				node.__typename === MULTI_USER_PICKER_GQL_FIELD
			) {
				return {
					...legacyFieldValues,
					value,
				};
			}

			const project = getSystemAggJiraProject(context);

			if (getProjectType(project.projectType) !== SERVICE_DESK_PROJECT) return undefined;
			// Empty array is the expected value when there are no request
			// participants.
			return {
				...legacyFieldValues,
				value,
			};
		}
		case PARTICIPANTS_CF_TYPE:
		case MULTI_USER_CF_TYPE:
			return legacyFieldValues;
		case APPROVERS_LIST_CF_TYPE:
		case PEOPLE_CF_TYPE: {
			const schemaConfig =
				'isMulti' in node && node.isMulti != null
					? [{ key: 'isMulti', value: String(node.isMulti) }]
					: null;

			return {
				...legacyFieldValues,
				autoCompleteUrl: node.searchUrl,
				schema: {
					...legacyFieldValues.schema,
					configuration: schemaConfig,
				},
			};
		}
		default:
			return undefined;
	}
};
