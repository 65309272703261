import React from 'react';
import { QueueItem as QueueItemDI } from './queue-item/main.tsx';
import type { QueueListProps } from './types.tsx';

export const QueueList = ({
	QueueItem = QueueItemDI,
	queues,
	section,
	projectKey,
	priorityGroupId,
	isQueueListInFlyOut = false,
}: QueueListProps) => {
	return (
		<>
			{queues.map((queue) => (
				<QueueItem
					key={queue.id}
					queue={queue}
					section={section}
					projectKey={projectKey}
					priorityGroupId={priorityGroupId}
					isQueueInFlyOut={isQueueListInFlyOut}
				/>
			))}
		</>
	);
};
