import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type TEAM_GQL_FIELD,
	TEAM_CF_TYPE,
	ISSUE_FIELD_ANY_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggTeamToLegacyField: AggTransformerFunction<typeof TEAM_GQL_FIELD> = (
	node,
) => {
	if (node.type !== TEAM_CF_TYPE) return undefined;

	const value = node.selectedTeam
		? {
				id: node.selectedTeam.teamId,
				title: node.selectedTeam.name,
				isShared: node.selectedTeam.isShared,
				avatarUrl: node.selectedTeam.avatar?.medium,
			}
		: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			configuration: null,
			custom: TEAM_CF_TYPE,
			items: null,
			renderer: PLAIN_TEXT,
			system: null,
			type: ISSUE_FIELD_ANY_CF_TYPE,
		},
	};
};
