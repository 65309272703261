import meanBy from 'lodash/meanBy';
import { isExpandedObservationData, type ObservationData } from '../types.tsx';
import {
	triggerAnalytics,
	generateLongTaskAnalyticsContinuousActionSummary,
	getAverageResultsFromContinuousActions,
} from './common.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const aggregatedDataTimeout: Record<string, any> = {};

export const virtualTableScrollReporter = (observationData: ObservationData) => {
	if (!isExpandedObservationData(observationData)) {
		return;
	}

	const startTime = observationData.events.map((event) => event.timestamp);
	const analyticsData = {
		...generateLongTaskAnalyticsContinuousActionSummary(
			'virtualTableScroll',
			observationData.events,
		),
		measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
	};

	const { appName } = observationData;
	if (aggregatedDataTimeout[`common.${appName}.virtualTableScroll`]) {
		aggregatedDataTimeout[`common.${appName}.virtualTableScroll`].data.push(analyticsData);
	} else {
		aggregatedDataTimeout[`common.${appName}.virtualTableScroll`] = {
			data: [analyticsData],
			extra: observationData.extra,
			timeout: setTimeout(() => {
				const { data, extra: extraData } =
					aggregatedDataTimeout[`common.${appName}.virtualTableScroll`];
				const triggerAppAnalytics = triggerAnalytics(`common.${appName}`);
				triggerAppAnalytics('long-tasks-virtual-table-scroll', {
					...getAverageResultsFromContinuousActions('virtualTableScroll', data),
					...extraData,
					avgScrollTimespan: meanBy(data, 'measureTimespan'),
				});
				delete aggregatedDataTimeout[`common.${appName}.virtualTableScroll`];
			}, 7000),
		};
	}
};
