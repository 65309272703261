import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assignToMe: {
		id: 'issue-table.common.fields.assignee.single-user-select.user-select.assign-to-me',
		defaultMessage: 'Assign to me',
		description:
			'Option in assignee select dropdown. Appears if issue is unassigned, or if not assigned to the current user',
	},

	unassigned: {
		id: 'issue-table.common.fields.assignee.single-user-select.user-select.unassigned',
		defaultMessage: 'Unassigned',
		description: 'Option in assignee select dropdown. Appears if issue is currently assigned.',
	},

	automatic: {
		id: 'issue-table.common.fields.assignee.single-user-select.user-select.automatic',
		defaultMessage: 'Automatic',
		description: 'Option in assignee select dropdown. used to assign issue automatically.',
	},
	invitePeopleOption: {
		id: 'issue-table.common.fields.assignee.single-user-select.user-select.invite-people-option',
		defaultMessage: 'Add teammate to Jira',
		description:
			'Text used in the dropdown item that, when select, open invite poeple drawer and allow user to invite new people. It will leave the issue unassigned.',
	},
	invitePeopleOptionAdmin: {
		id: 'issue-table.common.fields.assignee.single-user-select.user-select.invite-people-option-admin',
		defaultMessage: 'Invite teammate to Jira',
		description:
			'Text used in the dropdown item that, when select, open invite poeple drawer and allow admin user to add new people. It will leave the issue unassigned.',
	},
});
