import type { APIState } from '../../../model/api/index.tsx';

export const UPDATE_STATE_FROM_PROPS = 'ops.hydrate.update.UPDATE_STATE_FROM_PROPS' as const;

export type UpdateStateFromPropsAction = {
	type: typeof UPDATE_STATE_FROM_PROPS;
	payload: APIState;
};

export const updateStateFromProps = (payload: APIState): UpdateStateFromPropsAction => ({
	type: UPDATE_STATE_FROM_PROPS,
	payload,
});
