import meanBy from 'lodash/meanBy';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import type { ObservationDataEntry, LongTasksSloSettings } from '../types.tsx';

/**
 * @deprecated GasV2 has been cleaned up, and it has not been logging any events since July. Please replace implementation with sending GasV3 analytics: go/gasv3
 */
export const triggerAnalytics =
	(_appName: string) =>
	(_eventNameSuffix: string, _eventData: unknown): Promise<void> =>
		Promise.resolve(undefined);

export const generateLongTaskAnalyticsSummary = (
	prefix: string,
	longTasks: ObservationDataEntry[],
) => {
	const durations = longTasks.map((task) => task.duration);
	return {
		[`${prefix}LongTaskCount`]: longTasks.length,
		[`${prefix}LongTaskAvg`]:
			longTasks.length > 0
				? durations.reduce((sumAcc, duration) => sumAcc + duration, 0) / longTasks.length
				: 0,
		[`${prefix}LongTaskMax`]: longTasks.length > 0 ? Math.max(...durations) : 0,
	};
};

export const generateLongTaskAnalyticsSummaryWithSlo = (
	prefix: string,
	longTasks: ObservationDataEntry[],
	sloSettings?: LongTasksSloSettings,
) => {
	const summary = generateLongTaskAnalyticsSummary(prefix, longTasks);

	if (!sloSettings) {
		return summary;
	}

	const framerate =
		summary[`${prefix}LongTaskCount`] === 0 ? 20 : 1000 / summary[`${prefix}LongTaskAvg`];

	const blocktime =
		summary[`${prefix}LongTaskCount`] === 0
			? 0
			: summary[`${prefix}LongTaskCount`] * summary[`${prefix}LongTaskAvg`];
	const isSuccess =
		framerate >= sloSettings.framerateThreshold && blocktime < sloSettings.blocktimeThreshold;

	return {
		[`${prefix}LongTaskSloIsSuccess`]: isSuccess,
		...summary,
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAverageResultsFromContinuousActions = (prefix: string, data: any[]) => ({
	[`${prefix}ClusterCount`]: sumBy(data, `${prefix}ClusterCount`) || 0,
	[`${prefix}ClusterCountAvg`]: meanBy(data, `${prefix}ClusterCount`) || 0,
	[`${prefix}ClusterAvg`]: meanBy(data, `${prefix}ClusterAvg`) || 0,
	[`${prefix}ClusterDistanceAvg`]: meanBy(data, `${prefix}ClusterDistanceAvg`) || 0,
	[`${prefix}ClusterAvgLongTaskCount`]: meanBy(data, `${prefix}ClusterAvgLongTaskCount`) || 0,
	[`${prefix}ClusterAvgLongTaskAvg`]:
		data.length > 0
			? sum(
					data.map(
						(entry) =>
							entry[`${prefix}ClusterAvgLongTaskCount`] * entry[`${prefix}ClusterAvgLongTaskAvg`],
					),
				) / sumBy(data, `${prefix}ClusterAvgLongTaskCount`)
			: 0,
	[`${prefix}Count`]: data.length,
});

export const generateLongTaskAnalyticsContinuousActionSummary = (
	prefix: string,
	longTasks: ObservationDataEntry[],
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const clusters = longTasks.reduce<Array<any>>((foundClusters: any[], task) => {
		if (foundClusters.length === 0) {
			foundClusters.push({
				tasks: [],
				start: 0,
				stop: 0,
			});
		}
		const prevCluster = foundClusters[foundClusters.length - 1];
		const taskEnd = task.timestamp + task.duration;
		if (prevCluster.tasks.length === 0) {
			prevCluster.tasks.push(task);
			prevCluster.start = task.timestamp;
			prevCluster.stop = taskEnd;
		} else if (task.timestamp - prevCluster.stop < 100) {
			prevCluster.tasks.push(task);
			prevCluster.stop = taskEnd;
		} else {
			foundClusters.push({
				tasks: [task],
				start: task.timestamp,
				stop: taskEnd,
			});
		}
		return foundClusters;
	}, []);

	const clusterDurations = clusters.map((cluster) => cluster.stop - cluster.start);
	const tasksDurations = longTasks.map((task) => task.duration);
	const gapsBetweenClusters =
		clusters.length > 1
			? clusters // eslint-disable-next-line @typescript-eslint/no-explicit-any
					.reduce<Array<any>>((gaps: any[], cluster, i) => {
						if (gaps.length === 0) {
							gaps.push({
								start: cluster.stop,
								stop: null,
							});
							return gaps;
						}
						// eslint-disable-next-line no-param-reassign
						gaps[gaps.length - 1].stop = cluster.start;
						if (i !== clusters.length - 1) {
							gaps.push({
								start: cluster.stop,
								stop: null,
							});
						}
						return gaps;
					}, [])
					.map((gap) => gap.stop - gap.start)
			: [];
	return {
		[`${prefix}ClusterCount`]: clusters.length,
		[`${prefix}ClusterAvg`]:
			clusters.length > 0
				? clusterDurations.reduce((sumAcc, duration) => sumAcc + duration, 0) / clusters.length
				: 0,
		[`${prefix}ClusterDistanceAvg`]:
			gapsBetweenClusters.length > 0
				? gapsBetweenClusters.reduce((sumAcc, duration) => sumAcc + duration, 0) /
					gapsBetweenClusters.length
				: 0,
		[`${prefix}ClusterAvgLongTaskCount`]:
			clusters.length > 0
				? clusters
						.map((cluster) => cluster.tasks.length)
						.reduce((sumAcc, count) => sumAcc + count, 0) / clusters.length
				: 0,
		[`${prefix}ClusterAvgLongTaskAvg`]:
			longTasks.length > 0
				? tasksDurations.reduce((sumAcc, duration) => sumAcc + duration, 0) / longTasks.length
				: 0,
	};
};
