import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorSectionTitle: {
		id: 'servicedesk.queues.agent-view.layout.header.error-section-title',
		defaultMessage: 'We currently have an outage',
		description: 'Title of the error section message',
	},
	errorSectionBody: {
		id: 'servicedesk.queues.agent-view.layout.header.error-section-body',
		defaultMessage:
			"We're working hard to resolve temporary glitches with this page. But rest assured, the key functions on this page remain fully operational.",
		description: 'Body of the error section message',
	},
	reloadPageLinkText: {
		id: 'servicedesk.queues.agent-view.layout.header.reload-page-link-text',
		defaultMessage: 'Reload the page',
		description: 'Text for the reload page link',
	},
});
