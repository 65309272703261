import { connect } from '../../../../common/table-redux.tsx';
import {
	getVerticalScrollOffset,
	getTableWidthWithoutScrollbarOffset,
} from '../../../../state/internal/selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import Blanket from './view.tsx';

// @ts-expect-error - Expected 2-4 arguments, but got 1.
export default connect((state: State) => ({
	scrollOffset: getVerticalScrollOffset(state),
	width: getTableWidthWithoutScrollbarOffset(state),
}))(Blanket);
