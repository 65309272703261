import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { displayQueuesErrorHeaderAction } from '../../../state/actions/page/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { isQueuesErrorHeaderDisplayed } from '../../../state/selectors/ui/index.tsx';
import IssueCount from './issue-count/index.tsx';
import IssueSearch from './issue-search/index.tsx';
import QueuesDetails from './view.tsx';

const mapStateToProps = (state: State) => ({
	IssueCount,
	IssueSearch,
	isQueuesErrorHeaderDisplayed: isQueuesErrorHeaderDisplayed(state),
});

const mapDispatchToProps = {
	renderDisplayError: displayQueuesErrorHeaderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(QueuesDetails);
