import React, { useCallback, useEffect, useContext } from 'react';
import type { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';
import { useKeyboardShortcutsToggle as useKeyboardShortcutsToggleDI } from '../controller/index.tsx';
import handler from '../sequences-handler.tsx';
import createSequences from '../sequences.tsx';
import { type ShortcutRegistry, rootRegistry, type KeyMap } from '../shortcut-registry.tsx';
import { ShortcutRegistryContext } from '../shortcut-scope.tsx';
import type { KeySequence } from '../types.tsx';
import { bindKeyMapWithErrorHandling } from './utils.tsx';

let shortcutsCount = 0;
let subscription: Subscription | null = null;

export type ShortcutsProps = KeyMap & {
	useKeyboardShortcutsToggle?: typeof useKeyboardShortcutsToggleDI;
};

const ShortcutsSubscriber = __SERVER__
	? () => null
	: ({ keyMap, label, location }: KeyMap) => {
			const parentRegistry: ShortcutRegistry = useContext(ShortcutRegistryContext) || rootRegistry;
			const addKeyMap = useCallback(
				() =>
					parentRegistry.addKeyMap(() => ({
						keyMap: bindKeyMapWithErrorHandling(keyMap, location),
						label,
					})),
				[label, parentRegistry, keyMap, location],
			);

			useEffect(() => {
				shortcutsCount += 1;
				const removeKeyMap = addKeyMap();
				return () => {
					removeKeyMap();
					shortcutsCount -= 1;
					if (subscription && shortcutsCount === 0) {
						subscription.unsubscribe();
						subscription = null;
					}
				};
			}, [addKeyMap]);

			useEffect(() => {
				subscription =
					subscription ||
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(createSequences() as Observable<KeySequence | undefined>).subscribe(handler);
			}, [parentRegistry, keyMap]);

			return null;
		};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	useKeyboardShortcutsToggle = useKeyboardShortcutsToggleDI,
	...props
}: ShortcutsProps) => {
	const [{ isEnabled }] = useKeyboardShortcutsToggle();

	return isEnabled && !__SERVER__ ? <ShortcutsSubscriber {...props} /> : null;
};
