import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { setRowBorderWidth } from '../../../state/internal/actions.tsx';
import { withUnpackedBatchActions } from '../../common/batch/index.tsx';
import { errorHandlerFactory } from '../../common/errors/index.tsx';
import { SET_ROW_BORDER_WIDTH } from './action.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>) => {
	const errorHandler = errorHandlerFactory();
	return (
		withUnpackedBatchActions(action$)
			// @ts-expect-error - TS2339 - Property 'ofType' does not exist on type 'Observable<any>'.
			.ofType(SET_ROW_BORDER_WIDTH)
			// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
			.map((action) => setRowBorderWidth(action.payload))
			.catch(errorHandler)
	);
};
