import React, { Component, type ComponentType } from 'react';
import memoize from 'lodash/memoize';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	CellComponentProps,
	CellComponentType,
} from '@atlassian/jira-virtual-table/src/model/cell-component/index.tsx';
import type { FieldType } from '../../model/fields/types.tsx';
import type { FieldDataSelectorProps } from '../../model/index.tsx';
import ErrorField from '../fields/error/view.tsx';
import CellInternal from './cell/index.tsx';
import type { JsonComponentArgs, MergedColumnProps } from './cell/view.tsx';
import { CellWrapper } from './styled.tsx';

const LOCATION = 'issue-table.view.create-cell.view';

export const privacySafeRowId = (
	arg: string,
): {
	numericalValue: number | null;
	containsHyphen: boolean;
	length: number;
} => {
	const argSplitByHyphen: string[] = arg.split('-');
	return {
		containsHyphen: argSplitByHyphen.length > 1,
		numericalValue: parseInt(argSplitByHyphen[argSplitByHyphen.length - 1], 10),
		length: arg.length,
	};
};

type State = {
	hasError: boolean;
};

const cacheResolver = <T extends FieldType>(
	jsonComponentArgs: JsonComponentArgs<T>,
	mergedColumnProps: MergedColumnProps<T>,
): string => mergedColumnProps.fieldId;

export type CreateCellType<T extends FieldType = FieldType> = (
	jsonComponentArgs: JsonComponentArgs<T>,
	mergedColumnProps: MergedColumnProps<T>,
	FieldDataSelector: ComponentType<FieldDataSelectorProps>,
) => CellComponentType;

/**
 * Runs once per fieldId (see cacheResolver), so jsonComponentArgs, mergedColumnProps, FieldDataSelector are not updated, can use stale references
 * We use this to pass extra props to <Cell, on top of the ones passed by virtual-table (in CellComponentProps)
 */
export const createCellComponent = <T extends FieldType>(
	jsonComponentArgs: JsonComponentArgs<T>,
	mergedColumnProps: MergedColumnProps<T>,
	FieldDataSelector: ComponentType<FieldDataSelectorProps>,
): CellComponentType =>
	class Cell extends Component<CellComponentProps, State> {
		state = { hasError: false };

		componentDidCatch(error: Error) {
			const { fieldType, fieldId } = mergedColumnProps;
			const event = {
				fieldDescriptor: JSON.stringify({ fieldId, fieldType }),
				privacySafeRowId: JSON.stringify(privacySafeRowId(this.props.rowId)),
			};

			log.safeErrorWithoutCustomerData(
				LOCATION,
				`create cell component failed to render: ${String(error)}`,
				event,
			);

			fireErrorAnalytics({
				meta: {
					id: 'createCell',
					packageName: 'jiraIssueTable',
					teamName: 'jsd-shield',
				},
				attributes: {
					fieldDescriptor: JSON.stringify({ fieldId, fieldType }),
					privacySafeRowId: JSON.stringify(privacySafeRowId(this.props.rowId)),
				},
				// @ts-expect-error - TS2345 - Argument of type 'Error' is not assignable to parameter of type 'string'.
				error: new Error(error),
			});

			if (fieldType === 'com.atlassian.servicedesk:sd-sla-field') {
				fireErrorAnalytics({
					meta: {
						id: 'slaViewCell',
						packageName: 'jiraIssueTable',
					},
					// @ts-expect-error - TS2345 - Argument of type 'Error' is not assignable to parameter of type 'string'.
					error: new Error(error),
				});
			}

			this.setState({ hasError: true });
		}

		render() {
			if (this.state.hasError === true) {
				return (
					<CellWrapper>
						<ErrorField />
					</CellWrapper>
				);
			}

			const {
				rowId,
				columnId,
				isActive,
				width,
				onMount,
				onUpdate,
				canBeMultiLine,
				shouldHydrateFully,
			} = this.props;

			return (
				<CellInternal
					rowId={rowId}
					columnId={columnId}
					isActive={isActive}
					shouldHydrateFully={!!shouldHydrateFully}
					width={width}
					onMount={onMount}
					onUpdate={onUpdate}
					canBeMultiLine={canBeMultiLine}
					jsonComponentArgs={jsonComponentArgs}
					mergedColumnProps={mergedColumnProps}
					FieldDataSelector={FieldDataSelector}
				/>
			);
		}
	};

// Creates a new memoized function and therefore a new createCellComponent cache on each invocation
export const createCellsCache = <T extends FieldType>(): CreateCellType<T> =>
	// @ts-expect-error - TS2345: Type '[jsonComponentArgs: JsonComponentArgs<T>, mergedColumnProps: MergedColumnProps<T>, FieldDataSelector: ComponentType<FieldDataSelectorProps>]' is not assignable to type '[jsonComponentArgs: JsonComponentArgs<keyof DataSelectorPropsMap>, mergedColumnProps: MergedColumnProps<keyof DataSelectorPropsMap>]'.
	memoize(createCellComponent, cacheResolver);
