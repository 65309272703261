import React from 'react';
import capitalize from 'lodash/capitalize';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	UP,
	DOWN,
	type RowNavigationDirection,
} from '../../../../../../../model/navigation-directions/index.tsx';
import {
	type NavigateRow,
	type NavigationMode,
	ROW_NAVIGATION,
} from '../../../../../../../model/navigation/index.tsx';

type Props = {
	navigateToRow: (arg1: NavigateRow) => void;
	activeRowIndex: number | undefined;
	maxHorizontalScrollOffset: number;
	setHorizontalScrollOffset: (arg1: number) => void;
	// @ts-expect-error - TS2300 - Duplicate identifier 'horizontalScrollOffset'.
	horizontalScrollOffset: number;
	navigationMode: NavigationMode;
	isContentStale: boolean;
	// @ts-expect-error - TS2300 - Duplicate identifier 'horizontalScrollOffset'.
	horizontalScrollOffset: number;
};

const KeyboardShortcuts = (props: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const HORIZONTAL_SCROLL_DISTANCE = 40;
	const SHIFT_TAB = 'shiftTab';

	const analyticsEvent = createAnalyticsEvent({
		action: 'pressed',
		actionSubject: 'rowItem',
	});
	const handleTabKey = (tabKey: string) => {
		if (props.isContentStale || props.navigationMode !== ROW_NAVIGATION) {
			return;
		}
		fireUIAnalytics(analyticsEvent, tabKey === SHIFT_TAB ? 'tabFieldLeft' : 'tabFieldRight', {
			rowIndex: props.activeRowIndex,
			keyPressed: tabKey,
		});
	};
	const handleKeyUpOrDown = (direction: RowNavigationDirection, key: string, index?: number) => {
		if (props.isContentStale || props.navigationMode !== ROW_NAVIGATION) {
			return;
		}
		props.navigateToRow({ direction });

		fireUIAnalytics(analyticsEvent, `navigateRow${capitalize(direction)}`, {
			keyPressed: key,
			rowIndex: index,
		});
	};

	const handleKeyLeft = () => {
		if (props.isContentStale || props.navigationMode !== ROW_NAVIGATION) {
			return;
		}
		const newOffset = Math.max(0, props.horizontalScrollOffset - HORIZONTAL_SCROLL_DISTANCE);

		if (props.horizontalScrollOffset !== newOffset) {
			props.setHorizontalScrollOffset(newOffset);
		}
	};

	const handleKeyRight = () => {
		if (props.isContentStale || props.navigationMode !== ROW_NAVIGATION) {
			return;
		}
		const newOffset = Math.min(
			props.maxHorizontalScrollOffset,
			props.horizontalScrollOffset + HORIZONTAL_SCROLL_DISTANCE,
		);
		if (props.horizontalScrollOffset !== newOffset) {
			props.setHorizontalScrollOffset(newOffset);
		}
	};

	const mapping = {
		tab: {
			callback: () => handleTabKey('tab'),
		},
		'shift+tab': {
			callback: () => handleTabKey(SHIFT_TAB),
		},
		j: {
			callback: () => handleKeyUpOrDown(DOWN, 'j', props.activeRowIndex),
		},
		arrowdown: {
			callback: () => handleKeyUpOrDown(DOWN, 'arrowdown', props.activeRowIndex),
		},
		k: {
			callback: () => handleKeyUpOrDown(UP, 'k', props.activeRowIndex),
		},
		arrowup: {
			callback: () => handleKeyUpOrDown(UP, 'arrowup', props.activeRowIndex),
		},
		arrowleft: {
			callback: () => handleKeyLeft(),
		},
		arrowright: {
			callback: () => handleKeyRight(),
		},
	};
	return <Shortcuts keyMap={mapping} />;
};

KeyboardShortcuts.defaultProps = {
	isContentStale: false,
};

export default KeyboardShortcuts;
