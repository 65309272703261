import { createSelector } from 'reselect';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { isLoadingSelector } from './issue-selector.tsx';

export const timingSelector = (state: State) => state.timing;

export const serverTimingSelector = createSelector(
	timingSelector,
	isLoadingSelector,
	(timing, isLoading) => (isLoading ? undefined : timing.server),
);
