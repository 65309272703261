/** @jsx jsx */
import React, {
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	type ComponentType,
	type KeyboardEvent,
	type ReactNode,
} from 'react';
import { css, styled, jsx } from '@compiled/react';
import keycode from 'keycode';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { withTheme } from '../../../../../../../../../app/context/theme-context/index.tsx';
import {
	childrenOperationIndicatorWidth,
	levelIndentBaseline,
} from '../../../../../../../../../constants/index.tsx';
import type {
	RowCancelAddCallback,
	RowPersistAddCallback,
} from '../../../../../../../../../model/callbacks/index.tsx';
import type { Cell } from '../../../../../../../../../model/cells/index.tsx';
import type { ColumnId } from '../../../../../../../../../model/columns/index.tsx';
import type { RowAddState, RowId } from '../../../../../../../../../model/rows/index.tsx';
import type { CompiledTheme } from '../../../../../../../../../model/themes/index.tsx';

type Props = {
	id: RowId;
	depth: number;
	maxDepth: number;
	status: RowAddState;
	coreColumnIds: ColumnId[];
	additionalColumnIds: ColumnId[];
	isActive: boolean;
	onPersistAddedRowRequested: RowPersistAddCallback;
	onCancelAddedRowRequested: RowCancelAddCallback;
	setActiveItem: () => void;
	clearActiveItem: () => void;
	setMultiAddAnchor: (arg1: RowId) => void;
	AdditionalColumnsContainer: ComponentType<{
		children: ReactNode;
	}>;
	CellWrapper: ComponentType<
		Cell & {
			isRowTemporary: boolean;
			confirmToNavigate: boolean;
		}
	>;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class NewRow extends PureComponent<Props> {
	componentDidMount() {
		const { status, setActiveItem } = this.props;

		if (status === 'NEW') {
			setActiveItem();
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { id, status, isActive, onPersistAddedRowRequested } = this.props;

		if (prevProps.isActive && !isActive && status === 'NEW') {
			onPersistAddedRowRequested(id);
		}
	}

	componentWillUnmount() {
		/* If we are navigating by Cell.
		 * All cells in a new row will be unmounted with it, stopping the clear callback from occurring.
		 * This means anyone subscribing to the currently active cell may still refer to the temporary cells id,
		 * even when that temporary value has been resolved into a "real" one.
		 * We would not want to do this in the cell wrapper, as the problem does not exist for other rows, and could
		 * cause side-effects.
		 */
		this.props.clearActiveItem();
	}

	onCellKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		const { id, status, setMultiAddAnchor, onPersistAddedRowRequested, onCancelAddedRowRequested } =
			this.props;

		if (status === 'NEW') {
			switch (event.keyCode) {
				case keycode('enter'): {
					event.stopPropagation();
					setMultiAddAnchor(id);
					onPersistAddedRowRequested(id);
					break;
				}
				case keycode('escape'): {
					event.stopPropagation();
					onCancelAddedRowRequested(id);
					break;
				}

				default:
					break;
			}
		}
	};

	renderCoreColumns() {
		const { id, coreColumnIds, CellWrapper } = this.props;

		return coreColumnIds.map((columnId) => (
			<CellWrapper
				key={columnId}
				rowId={id}
				columnId={columnId}
				isRowTemporary
				confirmToNavigate={false}
			/>
		));
	}

	renderAdditionalColumns() {
		const { id, additionalColumnIds, AdditionalColumnsContainer, CellWrapper } = this.props;

		return additionalColumnIds.length > 0 ? (
			<AdditionalColumnsContainer>
				{additionalColumnIds.map((columnId) => (
					<CellWrapper
						key={columnId}
						rowId={id}
						columnId={columnId}
						isRowTemporary
						confirmToNavigate={false}
					/>
				))}
			</AdditionalColumnsContainer>
		) : null;
	}

	render() {
		const { depth, maxDepth, status, theme } = this.props;
		const indent = levelIndentBaseline + depth * levelIndentBaseline;

		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				css={rowWrapperStyles}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					background: status === 'PERSISTING' ? persistingGradient : colors.G50,
					marginLeft: `${indent}px`,
				}}
				onKeyDown={this.onCellKeyDown}
			>
				<FakeChildOperationIndicator isMaxDepth={depth === maxDepth} theme={theme} />
				{this.renderCoreColumns()}
				{this.renderAdditionalColumns()}
			</div>
		);
	}
}

export default withTheme(NewRow);

const persistingGradient = `
    repeating-linear-gradient(
        315deg,
        rgba(0,0,0,0.1), rgba(0,0,0,0.1) 1px,
        ${colors.N0} 0, ${colors.N0} 5px
    )
`;

const rowWrapperStyles = css({
	display: 'flex',
	boxSizing: 'border-box',
	borderTop: '1px solid transparent',
	borderBottom: '1px solid transparent',
	height: token('space.500', '40px'),
	marginTop: token('space.0', '0px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FakeChildOperationIndicator = styled.div<{
	isMaxDepth: boolean;
	theme: CompiledTheme;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isMaxDepth, theme }) =>
		`${
			isMaxDepth
				? theme.row.maxDepthChildrenOperationIndicatorWidth
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`${childrenOperationIndicatorWidth}px`
		}px`,
	flex: '0 0 auto',
	boxSizing: 'border-box',
});
