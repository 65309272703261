import { getConsumerState } from '../../../../state/consumer/selectors/index.tsx';
import { getAddedRows, getRowTree } from '../../../../state/consumer/selectors/rows/index.tsx';
import { setColumnSortOrder, setSortedRowIdsHash } from '../../../../state/internal/actions.tsx';
import {
	getSortedRowIdsHash,
	getActiveSortedColumnConfiguration,
} from '../../../../state/internal/selectors.tsx';
import getSortedInternalState from '../../../common/sorting/index.tsx';
import { defineTransaction } from '../../../common/transactions/index.tsx';
import { CLEAR_COLUMN_SORT_ORDER } from './action.tsx';

export default defineTransaction(CLEAR_COLUMN_SORT_ORDER, (action, state) => {
	const consumerState = getConsumerState(state);

	const sortedRowIdsHash = getSortedInternalState(
		getAddedRows(state),
		getRowTree(state),
		getActiveSortedColumnConfiguration(state),

		false, // sort override disabled
		undefined, // no active sort column configuration
		getSortedRowIdsHash(state),

		consumerState.addedRows,
		consumerState.rowTree,
		consumerState.columnComparators,
		consumerState.defaultComparator,
	);

	consumerState.callbacks.onSortedRowIdsChanged &&
		consumerState.callbacks.onSortedRowIdsChanged(sortedRowIdsHash);

	return [setColumnSortOrder(undefined), setSortedRowIdsHash(sortedRowIdsHash)] as const;
});
