import React from 'react';
import { FlagsBoundary } from '@atlassian/jira-flag-boundary/src/index.tsx';
import { type AsyncAutoDismissFlagProps, CommonFlag } from '../index.tsx';

const AkAutoDismissFlag = ({ packageName = 'flags', ...props }: AsyncAutoDismissFlagProps) => (
	<FlagsBoundary packageName={packageName}>
		<CommonFlag isAutoDismiss {...props} flagType="info" />
	</FlagsBoundary>
);

export default AkAutoDismissFlag;
