import createRequestService from '../create-request/index.tsx';
import getCreatedRequestsService from '../get-created-requests/index.tsx';
import getNextRequestAliasService from '../get-next-request-alias/index.tsx';
import type { CreateNextRequestPayload, CreateNextRequestService } from './types.tsx';

const service: CreateNextRequestService = async ({
	baseUrl,
	projectId,
	analyticsEvent,
}: CreateNextRequestPayload) => {
	const createdAliases = await getCreatedRequestsService({ baseUrl, projectId });
	const requestAlias = getNextRequestAliasService({ createdAliases });

	if (!requestAlias) {
		return;
	}

	await createRequestService({
		baseUrl,
		projectId,
		requestAlias,
		analyticsEvent,
	});
};

export default service;
