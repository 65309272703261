import concat from 'lodash/concat';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import type { ColumnId } from '../../../../model/columns/index.tsx';
import type { Optional } from '../../../../model/optional/index.tsx';

const getLevelIds = (sortedColumnIds: Optional<ColumnId[]>, columnIds: ColumnId[]) => {
	if (sortedColumnIds === undefined) {
		return columnIds;
	}

	const levelIdsNotInSortedArray = difference(columnIds, sortedColumnIds);
	const sortedIdsInLevel = intersection(sortedColumnIds, columnIds);

	/*  to cope with inconsistent data provided by the consumer - in case of overridden column sort order,
        we append ids that exist in the current level array but not in the sorted array (override), at the end.
     */
	return concat(sortedIdsInLevel, levelIdsNotInSortedArray);
};

export default getLevelIds;
