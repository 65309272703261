import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	getBaseUrl,
	getProjectId,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getCurrentQueueOrder } from '../../../state/selectors/queue/index.tsx';
import { isQueueVisible, isLoadingIssues } from '../../../state/selectors/ui/index.tsx';
import DemoDripFeed from './view.tsx';

const mapStateToProps = (state: State) => ({
	queueOrder: getCurrentQueueOrder(state),
	isQueueVisible: isQueueVisible(state),
	isLoadingIssues: isLoadingIssues(state),
	projectId: getProjectId(state),
	baseUrl: getBaseUrl(state),
});

export default connect(mapStateToProps, {})(DemoDripFeed);
