/* eslint-disable max-classes-per-file */
import React, { Children, Component, type ReactNode, type ComponentType } from 'react';
import type { Middleware } from 'redux';
// eslint-disable-next-line jira/restricted/prop-types
import PropTypes from 'prop-types';

type Props = {
	extraMiddleware: Middleware[];
	children: ReactNode;
};

/**
 * Add extraMiddleware into the context.
 */
// eslint-disable-next-line jira/react/no-class-components
export class ExtraMiddlewareProvider extends Component<Props> {
	static contextTypes = {
		extraMiddleware: PropTypes.array,
	};

	static childContextTypes = {
		extraMiddleware: PropTypes.array,
	};

	getChildContext() {
		const extraMiddleware = this.props.extraMiddleware;
		// @ts-expect-error - TS2571 - Object is of type 'unknown'. Caused by 3rd party lib
		const existingExtraMiddleware = this.context.extraMiddleware || [];
		return { extraMiddleware: [...existingExtraMiddleware, ...extraMiddleware] };
	}

	render() {
		return Children.only(this.props.children);
	}
}

/**
 * HOC to inject extraMiddleware into props.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withExtraMiddleware = (WrappedComponent: ComponentType<any>) =>
	// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
	class ExtraMiddlewareConsumer extends Component<any> {
		static contextTypes = {
			extraMiddleware: PropTypes.array,
		};

		render() {
			// @ts-expect-error - TS2339 - Property 'extraMiddleware' does not exist on type 'unknown'. Caused by 3rd party lib
			const { extraMiddleware } = this.context;
			return <WrappedComponent extraMiddleware={extraMiddleware || []} {...this.props} />;
		}
	};
