import React, { Component, type ReactNode, type ComponentType } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';
import Blanket from '@atlassian/jira-common-blanket/src/view.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	FieldComponentProps,
	FieldValue,
	JsonComponentProps,
} from '../../../../model/fields/types.tsx';
import { trackUpdatedField } from '../../../../services/field/update/index.tsx';
import PreventKeyboardPropWrapper from '../../prevent-keyboard-propagation-wrapper/view.tsx';
import {
	getCurrentAssignee,
	type CurrentAssignee,
} from '../single-user-select/rest/current-assignee/index.tsx';

const OVERLAY_TIMEOUT = 1000;
const LOCATION = 'issue-table.view.fields.common.editable-field';
const AUTOMATIC_ID_VALUE = '-1';

type Overlay<T> = {
	value: T;
};

type State<T> = {
	overlay?: Overlay<T>;
	key: string | undefined;
	inEditState: boolean;
};

type AdditionalProps = {
	onRefreshSidebar?: ({ issueKey }: { issueKey?: string }) => void;
};

export const transformCurrentAssignee = (response: CurrentAssignee | null) => {
	// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
	const { assignee } = response ? response.fields || {} : {};
	if (assignee !== null && assignee !== undefined) {
		const currentAssignee = {
			accountId: assignee.accountId,
			displayName: assignee.displayName || '',
			emailAddress: assignee.emailAddress,
			avatarUrl: assignee.avatarUrls['48x48'] || '',
		};
		return currentAssignee;
	}
	return undefined;
};

// This should be able to support all types of T: EditableFieldValue, but flow
// is a weak excuse for a type system and fails to constrain the type
// parameter correctly. Related to: https://github.com/facebook/flow/issues/2630
//
// Instead we won't use this to abstract component editing.  Instead we will
// move in the direction of each field providing its own edit experience in an
// isolated way, as the status component has done.
export default function createEditableField<T extends 'assignee' | 'reporterWithIcon'>(
	ChildComponent: ComponentType<FieldComponentProps<T>>,
) {
	// eslint-disable-next-line jira/react/no-class-components
	return class EditableField extends Component<
		JsonComponentProps<T> & AdditionalProps,
		State<FieldValue<T>>
	> {
		static getDerivedStateFromProps(props: JsonComponentProps<T>, state: State<FieldValue<T>>) {
			const {
				dataSelectorProps: { key },
			} = props;
			if (key !== state.key) {
				return {
					overlay: undefined,
					key,
				};
			}
			return null;
		}

		constructor(props: JsonComponentProps<T>) {
			super(props);
			this.state = { key: props.dataSelectorProps.key, inEditState: false };
		}

		componentDidUpdate(prevProps: JsonComponentProps<T>, prevState: State<FieldValue<T>>) {
			if (this.state.key !== prevState.key && this.overlayTimeoutId) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.clearTimeout(this.overlayTimeoutId);
			}
		}

		componentWillUnmount() {
			if (this.overlayTimeoutId) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.clearTimeout(this.overlayTimeoutId);
			}
		}

		onSuccess: (fieldValue: FieldValue<T>, event: UIAnalyticsEvent) => void = (
			fieldValue: FieldValue<T>,
			event: UIAnalyticsEvent,
		) => {
			const {
				dataSelectorProps: { onChange, value, fieldType },
				tableInjectedProps: { issueKey, issueFieldLockControls },
				onRefreshSidebar,
			} = this.props;
			this.timeoutOverlay();
			if (fieldValue && fieldValue.accountId === AUTOMATIC_ID_VALUE) {
				getCurrentAssignee(issueKey)
					.then((response) => {
						onChange && onChange(issueKey, transformCurrentAssignee(response));
						issueFieldLockControls.onFieldUnlock();
					})
					.catch((err) => {
						fireErrorAnalytics({
							meta: {
								id: 'getCurrentAssigneeFailure',
								packageName: 'jiraIssueTable',
								teamName: 'jsd-shield',
							},
							error: err,
							sendToPrivacyUnsafeSplunk: true,
						});
						onChange && onChange(issueKey, fieldValue);
						issueFieldLockControls.onFieldUnlock();
					});
			} else {
				onChange && onChange(issueKey, fieldValue);
				issueFieldLockControls.onFieldUnlock();
			}

			onRefreshSidebar?.({ issueKey });

			if (fieldType) {
				trackUpdatedField(event, fieldType, this.getAccountIds(fieldValue, value));
			}
		};

		onFailure = () => {
			this.setState({ overlay: undefined });
			const {
				tableInjectedProps: { issueFieldLockControls },
			} = this.props;
			issueFieldLockControls.onFieldUnlock();
		};

		onEditStart = () => {
			const {
				tableInjectedProps: { issueFieldLockControls, onEditStart, issueKey },
				dataSelectorProps: { fieldType },
			} = this.props;
			issueFieldLockControls.onFieldLock();
			onEditStart(fieldType, issueKey);
			this.isSelection = false;
			this.setState({ inEditState: true });
		};

		onEditCancel = () => {
			if (!this.isSelection) {
				this.setState({ overlay: undefined });
				const {
					tableInjectedProps: { issueFieldLockControls },
				} = this.props;
				issueFieldLockControls.onFieldUnlock();
			}
			this.isSelection = false;
			this.setState({ inEditState: false });
		};

		onEditSelection = () => {
			this.isSelection = true;
		};

		onSubmit: (fieldValue: FieldValue<T>) => void = (fieldValue: FieldValue<T>) => {
			this.childWrapperRef && this.childWrapperRef.focus();
			this.setState({
				overlay: { value: fieldValue },
				inEditState: false,
			});
		};

		onChildWrapperRefUpdate = (el: HTMLElement | null) => {
			this.childWrapperRef = el;
		};

		getValue(): FieldValue<T> {
			const { overlay } = this.state;
			if (overlay) {
				return overlay.value;
			}
			const {
				dataSelectorProps: { value },
			} = this.props;
			return value;
		}

		getAccountIds: (
			fieldValue: FieldValue<T>,
			value: FieldValue<T>,
		) => {
			newCategory?: string | number;
			newId?: string | null;
			oldCategory?: string | number;
			oldId?: string | null;
		} = (fieldValue: FieldValue<T>, value: FieldValue<T>) => ({
			oldId: value && value.accountId ? value.accountId : null,
			newId: fieldValue && fieldValue.accountId ? fieldValue.accountId : null,
		});

		// @ts-expect-error - TS2564 - Property 'isSelection' has no initializer and is not definitely assigned in the constructor.
		isSelection: boolean;

		overlayTimeoutId: number | undefined;

		// @ts-expect-error - TS2564 - Property 'childWrapperRef' has no initializer and is not definitely assigned in the constructor.
		childWrapperRef: HTMLElement | null;

		timeoutOverlay() {
			const {
				dataSelectorProps: { fieldType },
			} = this.props;

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			this.overlayTimeoutId = window.setTimeout(() => {
				this.setState({ overlay: undefined });
				delete this.overlayTimeoutId;
				log.safeWarnWithoutCustomerData(LOCATION, `Overlay timeout reached for ${fieldType} field`);
			}, OVERLAY_TIMEOUT);
		}

		renderChildComponent(isSaving: boolean) {
			const {
				dataSelectorProps: { fieldType, key, isEditable, overrideDisabledAppearance },
				tableInjectedProps: {
					width,
					issueKey,
					fieldId,
					rowListRef,
					onSetEditingFieldPosition,
					isFieldLocked,
					isUserPickerDisabled,
					isLastColumn,
				},
			} = this.props;
			const props = {
				value: this.getValue(),
				fieldType,
				fieldId,
				width,
				isEditable,
				issueKey,
				rowListRef,
				onSetEditingFieldPosition,
				isFieldLocked,
				isUserPickerDisabled,
				isLastColumn,
				overrideDisabledAppearance,
			};

			return (
				<ChildComponent
					{...props}
					key={key}
					onSubmit={this.onSubmit}
					onEditStart={this.onEditStart}
					onEditCancel={this.onEditCancel}
					// @ts-expect-error - TS2322 - Type '(fieldValue: FieldValue<T>, event: UIAnalyticsEventInterface) => void' is not assignable to type '(arg1: FieldValue<T>) => void'.
					onEditSuccess={this.onSuccess}
					onEditFailure={this.onFailure}
					isFieldLocked={isFieldLocked}
					isSaving={isSaving}
					onEditSelection={this.onEditSelection}
				/>
			);
		}

		renderWithFocusWrapper(children: ReactNode) {
			return (
				<PreventKeyboardPropWrapper inEditState={this.state.inEditState}>
					{/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
					<div style={{ outline: 'none' }} ref={this.onChildWrapperRefUpdate}>
						{children}
					</div>
				</PreventKeyboardPropWrapper>
			);
		}

		render() {
			const {
				tableInjectedProps: { isLastColumn },
			} = this.props;

			const { overlay } = this.state;

			return overlay
				? this.renderWithFocusWrapper(
						<Box paddingInlineEnd={isLastColumn ? 'space.0' : 'space.200'}>
							<Blanket>{this.renderChildComponent(true)}</Blanket>
						</Box>,
					)
				: this.renderWithFocusWrapper(this.renderChildComponent(false));
		}
	};
}
