import type { ComponentType } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldDataSelectorProps } from '@atlassian/jira-issue-table/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { changeIssueFieldValueAction } from '@atlassian/jira-servicedesk-queues-common/src/state/actions/issue/field/index.tsx';
import { ReadyForUser } from '@atlassian/jira-servicedesk-queues-common/src/view/queues-ready-for-user/main.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import {
	getFieldPropsSelector,
	isFirstFieldInTable,
	isFieldToExperienceTrack,
} from '../../../../state/selectors/issues/index.tsx';
import { getQueueId } from '../../../../state/selectors/queue/index.tsx';
import { isQueueVisible } from '../../../../state/selectors/ui/index.tsx';
import Field from './view.tsx';

type OwnProps = FieldDataSelectorProps;

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const {
		issueKey,
		fieldDescriptor: { fieldId },
	} = ownProps;
	const isCellFirstFieldInTable = isFirstFieldInTable(state, issueKey, fieldId);
	const queueVisible = isQueueVisible(state);
	return {
		queueId: getQueueId(state),
		onFieldDataSelection: getFieldPropsSelector(state),
		isFirstFieldInTable: isCellFirstFieldInTable,
		isFieldToExperienceTrack: isFieldToExperienceTrack(state, issueKey, fieldId),
		isQueueVisible: queueVisible,
		ReadyForUser: fg('remove_apdex_for_queues_page') ? undefined : ReadyForUser,
	};
};

const FieldDataSelector: ComponentType<FieldDataSelectorProps> = connect(mapStateToProps, {
	onFieldChange: changeIssueFieldValueAction,
})(Field);

export default FieldDataSelector;
