// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { DemoProjectDripFeed } from '@atlassian/jira-servicedesk-queues-demo-project-drip-feed/src/ui/index.tsx';

export type Props = {
	isQueueVisible: boolean;
	isLoadingIssues: boolean;
	baseUrl: string;
	projectId: number;
	queueOrder: number;
};

// eslint-disable-next-line jira/react/no-class-components
export default class DemoDripFeed extends PureComponent<Props> {
	shouldDripFeed() {
		const { queueOrder, isQueueVisible, isLoadingIssues } = this.props;

		// Ideally, we would like to drip-feed further issues only on the "All Open" queue of a demo project.
		// However, there is no reliable way to figure out the exact queue we are on. Thus, we rely on the initial
		// ordering of the queues. We accept the risk of users missing the drip-feed altogether when they re-order
		// their queues. The DemoProjectDripFeed component itself will check if we are actually on a demo project.
		// Also make sure that queues page is visible.
		if (queueOrder !== 0 || !isQueueVisible || isLoadingIssues) {
			return false;
		}
		return true;
	}

	render() {
		return this.shouldDripFeed() ? (
			<DemoProjectDripFeed baseUrl={this.props.baseUrl} projectId={this.props.projectId} />
		) : null;
	}
}
