import { useMemo } from 'react';
import { useRouter } from '@atlassian/react-resource-router';

/**
 * Required for enabling Nav4 cross-project navigation resource sharing in RRR
 * @param projectKey - Will override projectKey in RRR route params to fetch the correct project's data
 */
export const useQueueCustomRouterContext = (projectKey?: string) => {
	const [{ match }] = useRouter();

	return useMemo(
		() =>
			projectKey
				? {
						matchParams: {
							...match.params, // depending on the current route this could contain queueId and practiceType
							projectKey, // replace projectKey from the one passed as prop
						},
					}
				: undefined,
		[match.params, projectKey],
	);
};
