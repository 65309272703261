/** @jsx jsx */
// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { withTheme } from '../../../../../app/context/theme-context/index.tsx';
import { levelIndentBaseline, akButtonHeight } from '../../../../../constants/index.tsx';
import type { RowAddCallback } from '../../../../../model/callbacks/index.tsx';
import type { Optional } from '../../../../../model/optional/index.tsx';
import type { RowId } from '../../../../../model/rows/index.tsx';
import type { CompiledTheme } from '../../../../../model/themes/index.tsx';

type WrapperProps = {
	addBarOverflow: number;
	theme: CompiledTheme;
	padding?: string | number;
};

const wrapperBaseStyle = css({
	marginBottom: token('space.100', '8px'),
});

const Wrapper = ({
	addBarOverflow,
	theme,
	padding,
	children,
}: WrapperProps & { children?: ReactNode }) => (
	<div
		css={wrapperBaseStyle}
		style={{
			paddingRight: `${padding}px`,
			paddingTop: `${padding}px`,
			paddingBottom: `${padding}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			paddingLeft: `${levelIndentBaseline}px`,
			marginTop: `-${addBarOverflow + theme.row.borderWidth}px`,
		}}
	>
		{children}
	</div>
);

type Props = {
	addLinkCaption: Optional<string>;
	lastRowId: Optional<RowId>;
	isVisible: boolean;
	onRowAddRequested: Optional<RowAddCallback>;
	addBarOverflow: number;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class AddLink extends PureComponent<Props> {
	onRowAddRequested = () => {
		const { lastRowId, onRowAddRequested } = this.props;
		onRowAddRequested &&
			onRowAddRequested({
				anchorId: lastRowId,
				position: lastRowId ? 'AFTER' : 'LAST',
			});
	};

	renderAddLink() {
		const { addLinkCaption, addBarOverflow, theme } = this.props;

		return (
			<Wrapper
				addBarOverflow={addBarOverflow}
				theme={theme}
				padding={(theme.row.defaultHeight - akButtonHeight) / 2}
			>
				<Button
					onClick={this.onRowAddRequested}
					appearance="subtle-link"
					testId="virtual-table.table.content.utility-row.add-link.button"
				>
					{addLinkCaption}
				</Button>
			</Wrapper>
		);
	}

	render() {
		const { isVisible } = this.props;

		return isVisible ? this.renderAddLink() : null;
	}
}

export default withTheme(AddLink);
