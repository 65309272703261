// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { withTheme } from '../../../../app/context/theme-context/index.tsx';
import { scrollbarWidth } from '../../../../constants/index.tsx';
import type { CompiledTheme } from '../../../../model/themes/index.tsx';
import {
	BottomShadow,
	BottomWrapper,
	LeftShadow,
	LeftWrapper,
	RightShadow,
	RightWrapper,
} from './styled.tsx';

type Props = {
	getOffsetFromBottom: number;
	isHorizontalScrollVisible: boolean;
	offset: number;
	showBottomDecal: boolean;
	showLeftShadow: boolean;
	showRightShadow: boolean;
	tableHeight: number;
	tableWidth: number;
	theme: CompiledTheme;
};

export const BOTTOM_WRAPPER_HEIGHT = 20;

// eslint-disable-next-line jira/react/no-class-components
class HorizontalScrollOverlay extends PureComponent<Props> {
	calculateTopPositionForBottomShadow = (spaceTakenByHorizontalScrollbar: number) => {
		const { isHorizontalScrollVisible, tableHeight } = this.props;
		if (isHorizontalScrollVisible) {
			return tableHeight - spaceTakenByHorizontalScrollbar - BOTTOM_WRAPPER_HEIGHT;
		}

		return tableHeight - BOTTOM_WRAPPER_HEIGHT;
	};

	render() {
		const {
			offset,
			showLeftShadow,
			showRightShadow,
			tableHeight,
			tableWidth,
			isHorizontalScrollVisible,
			showBottomDecal,
			getOffsetFromBottom,
			theme,
		} = this.props;

		const showBottomShadow =
			(isHorizontalScrollVisible || showBottomDecal) && getOffsetFromBottom > 0;

		const spaceTakenByHorizontalScrollbar =
			scrollbarWidth + theme.scrollbar.marginTop + theme.scrollbar.marginBottom;

		const verticalShadowHeight = tableHeight - spaceTakenByHorizontalScrollbar;

		return (
			<div>
				{showLeftShadow && (
					<LeftWrapper offset={offset} height={verticalShadowHeight} theme={theme}>
						<LeftShadow theme={theme} />
					</LeftWrapper>
				)}
				{showRightShadow && (
					<RightWrapper height={verticalShadowHeight} theme={theme}>
						<RightShadow />
					</RightWrapper>
				)}
				{showBottomShadow && (
					<BottomWrapper
						height={BOTTOM_WRAPPER_HEIGHT}
						tableWidth={tableWidth}
						topPositionForBottomShadow={this.calculateTopPositionForBottomShadow(
							spaceTakenByHorizontalScrollbar,
						)}
						theme={theme}
					>
						<BottomShadow />
					</BottomWrapper>
				)}
			</div>
		);
	}
}

export default withTheme(HorizontalScrollOverlay);
