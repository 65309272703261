import React, { useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import QueuesAppBase from '@atlassian/jira-spa-apps-queues-app-base/src/index.tsx';
import QueuesAgentViewContent from '@atlassian/jira-spa-apps-servicedesk-queues-agent-view/src/ui/content/index.tsx';
import QueuesIssueViewContent from '@atlassian/jira-spa-apps-servicedesk-queues-issue-view/src/ui/content/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const isAgentViewMounted = useRef<boolean>(false);
	const [{ match }] = useRouter();
	const [issueKeys, setIssueKeys] = useState<(string | undefined)[]>([]);
	const [issuesCount, setIssuesCount] = useState<number>(0);

	const pageId = match.params.issueKey ? 'queues-issue-view' : 'queues-agent-view';

	const onIssuesChanged = (keys: (string | undefined)[]) => {
		if (!isEqual(keys, issueKeys)) {
			setIssueKeys(keys);
		}
	};

	const onIssuesCountChanged = (count: number) => {
		setIssuesCount(count);
	};

	const issueNavigatorData = useMemo(() => {
		const { issueKey } = match.params;
		return issueKey
			? {
					issueKey,
					issueKeys,
					knownIssueKeys: issueKeys.filter((key): key is string => !!key),
					issuesCount,
				}
			: undefined;
	}, [issueKeys, issuesCount, match.params]);

	return (
		<QueuesAppBase pageId={pageId}>
			{({ pageContext, queuesData, push, replace }) => {
				if (!pageContext) {
					return <Spinner />;
				}
				const shouldRenderAgentView = isAgentViewMounted.current || !match.params.issueKey;
				return (
					<>
						{match.params.issueKey && (
							<QueuesIssueViewContent
								pageContext={pageContext}
								issueNavigatorData={issueNavigatorData}
								// @ts-expect-error - TS2322 - Type 'QueuesPush' is not assignable to type '(url: string) => void'.
								push={push}
								match={match}
							/>
						)}
						{shouldRenderAgentView && (
							<QueuesAgentViewContent
								queuesData={queuesData}
								push={push}
								// @ts-expect-error - TS2322 - Type '((path: string) => void | Promise<void>) | undefined' is not assignable to type '(path: string) => void | Promise<void>'.
								replace={replace}
								queueId={match.params.queueId || ''}
								pageContext={pageContext}
								isQueueVisible={!match.params.issueKey}
								isAgentViewMountedRef={isAgentViewMounted}
								onIssuesChanged={onIssuesChanged}
								onIssuesCountChanged={onIssuesCountChanged}
							/>
						)}
					</>
				);
			}}
		</QueuesAppBase>
	);
};
