export const SET_ROW_LIST_REF = 'state.actions.table.SET_ROW_LIST_REF' as const;

export type SetRowListRefAction = {
	type: typeof SET_ROW_LIST_REF;
	payload: HTMLDivElement;
};

export const setRowListRefAction = (payload: HTMLDivElement): SetRowListRefAction => ({
	type: SET_ROW_LIST_REF,
	payload,
});
