import {
	SET_HAS_SELECTION,
	SET_LAST_CLICKED_ROW,
	SET_SELECTION_SERVICE_ACTIONS,
	type SetLastClickedRowAction,
	type SetSelectionServiceActionsAction,
} from '../../actions/bulk-selection/index.tsx';
import type { BulkSelection } from './types.tsx';

type Actions = SetLastClickedRowAction | SetSelectionServiceActionsAction;

export const DEFAULT_BULK_SELECTION_INITIALIZATION: BulkSelection = {
	lastClickedRow: undefined,
	selectionServiceActions: undefined,
	hasSelection: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: BulkSelection = DEFAULT_BULK_SELECTION_INITIALIZATION,
	action: Actions,
): BulkSelection => {
	switch (action.type) {
		case SET_LAST_CLICKED_ROW: {
			const { rowId: lastClickedRow } = action.payload;

			return {
				...state,
				lastClickedRow,
			};
		}
		case SET_SELECTION_SERVICE_ACTIONS: {
			const { actions } = action.payload;

			return {
				...state,
				selectionServiceActions: actions,
			};
		}
		// @ts-expect-error - TS2678 - Type '"state.actions.bulk-selection.SET_HAS_SELECTION"' is not comparable to type '"state.actions.bulk-selection.SET_LAST_CLICKED_ROW" | "state.actions.bulk-selection.SET_SELECTION_SERVICE_ACTIONS"'.
		case SET_HAS_SELECTION: {
			// @ts-expect-error - TS2339 - Property 'payload' does not exist on type 'never'.
			const { hasSelection } = action.payload;

			return {
				...state,
				hasSelection,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
