type ItemType = 'HEADING' | 'RADIO' | 'CHECKBOX' | 'DEFAULT';
type ItemTypes = Record<ItemType, ItemType>;

export const itemTypes: ItemTypes = {
	HEADING: 'HEADING',
	RADIO: 'RADIO',
	CHECKBOX: 'CHECKBOX',
	DEFAULT: 'DEFAULT',
};

export type { ItemType, ItemTypes };
