import { useMemo } from 'react';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useProjectPermissionsStore, useProjectPermissionsStoreActions } from './context.tsx';

export const useProjectPermissions = (projectKey: ProjectKey) => {
	const [permissions, { setProjectPermissions }] = useProjectPermissionsStore({ projectKey });

	const actions = useMemo(() => ({ setProjectPermissions }), [setProjectPermissions]);

	return [permissions, actions] as const;
};

export const useCanAdministerProject = (projectKey: ProjectKey) => {
	const [permissions] = useProjectPermissionsStore({ projectKey });
	return permissions.canAdministerProject;
};

export const useCanAdministerJira = (projectKey: ProjectKey) => {
	const [permissions] = useProjectPermissionsStore({ projectKey });
	return permissions.canAdministerJira;
};

export const useCanConfigureIssue = (projectKey: ProjectKey) => {
	const canConfigureAdministerProject = useCanAdministerProject(projectKey);
	const canAdministerJira = useCanAdministerJira(projectKey);

	return canConfigureAdministerProject || canAdministerJira;
};

export const useCanUseServiceDeskAgentFeatures = (projectKey: ProjectKey): boolean => {
	const [permissions] = useProjectPermissionsStore({ projectKey });
	const { appPermissions } = useTenantContext();

	return permissions.canUseServiceDeskAgentFeatures && appPermissions?.hasServiceDeskAccess;
};

export const useProjectPermissionsActions = () => {
	const [, { setProjectPermissions }] = useProjectPermissionsStoreActions();

	const actions = useMemo(
		() => ({
			setProjectPermissions,
		}),
		[setProjectPermissions],
	);

	return [null, actions] as const;
};
