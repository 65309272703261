import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import {
	type Column,
	type Queues,
	toQueueId,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type {
	Page,
	PageResponseColumn,
} from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types.tsx';
import type { PrefetcherPageResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/page/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <TRest, TDomain>(
	rest: ObservableType<TRest>,
	transformFunc: (arg1: TRest) => TDomain,
): ObservableType<TDomain> =>
	rest.mergeMap((payload: TRest): ObservableType<TDomain> => Observable.of(transformFunc(payload)));

const transformColumns = (responseColumns: PageResponseColumn[]): Column[] =>
	responseColumns.map((responseColumn) => {
		const column = {
			id: responseColumn.fieldId,
			title: responseColumn.title,
			fieldType: responseColumn.fieldType,
			sortable: responseColumn.sortable,
		};

		if (responseColumn.sortOrder) {
			return {
				...column,
				currentSorting: responseColumn.sortOrder,
			};
		}

		return column;
	});

export const pageToQueues = (page: Page[]): Queues =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	page.reduce<Record<string, any>>((acc, queue) => {
		acc[queue.id] = {
			id: toQueueId(queue.id),
			name: queue.name,
			jql: queue.jql,
			completeJql: queue.completeJql,
			order: queue.order,
			columns: transformColumns(queue.columnsDetails),
			// British spelling from legacy Backend code
			isFavorite: queue.favourite,
			category: queue.category,
			canBeHidden: queue.canBeHidden,
		};
		return acc;
	}, {});

// Utilised in the jira-spa world to update the pre-fetch cache between route transitions
export const queuesToPage = (prefetchData: Page[], queues?: Queues): Page[] => {
	if (queues === undefined) {
		return [];
	}
	// @ts-expect-error - TS2322 - Type '{ favourite: boolean; columns: string[]; columnsDetails: { title: string; fieldType: string; sortable: boolean; fieldId: string; sortOrder: SortOrder | undefined; }[]; id: number; name: string; ... 8 more ...; shouldSkipIssueCountRefresh?: boolean | undefined; }[]' is not assignable to type 'Page[]'.
	return Object.keys(queues).map((queueId) => {
		const updatedQueue = queues[queueId];
		const { isFavorite, columns, isTransient, ...queuesData } = updatedQueue;
		const cachedPage = prefetchData.find((q) => String(q.id) === queueId);
		return {
			...cachedPage,
			...queuesData,
			favourite: isFavorite,
			columns: columns.map(({ id }) => id),
			columnsDetails: columns.map(({ id, currentSorting, ...remaining }) => ({
				fieldId: id,
				sortOrder: currentSorting,
				...remaining,
			})),
		};
	});
};

export const getPrefetchedPagePromise = (): Promise<PrefetcherPageResponse> | undefined =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.JSD_QUEUES_PRELOAD_PROMISES && window.JSD_QUEUES_PRELOAD_PROMISES.queues;

export const unsetPrefetchedPagePromise = (): void => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.JSD_QUEUES_PRELOAD_PROMISES) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.JSD_QUEUES_PRELOAD_PROMISES.queues = undefined;
	}
};
