import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/take';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import once from 'lodash/once';
import { Observable } from 'rxjs/Observable';
import type { Observer } from 'rxjs/Observer';

type Token = {
	returnToken: () => void;
};

export type TokenDispenser = {
	readonly takeToken: () => Observable<Token>;
	readonly getAvailableTokenCount: () => number;
};

const tokenDispenser = (initialTokens: number): TokenDispenser => {
	let availableTokens = initialTokens;

	const obs$ = Observable.create((observer: Observer<string>) => {
		availableTokens -= 1;
		observer.next('TOKEN');
	});

	const returnToken = () => {
		availableTokens += 1;
	};

	return {
		takeToken: () => {
			if (availableTokens) {
				return obs$.take(1).mergeMap(() =>
					Observable.of({
						returnToken: once(returnToken),
					}),
				);
			}
			return Observable.empty<never>();
		},
		getAvailableTokenCount: () => availableTokens,
	};
};

export default tokenDispenser;
