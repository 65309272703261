import type { State } from '../../../state/reducers/types.tsx';
import { getRefreshSidebar } from '../../../state/selectors/ui/index.tsx';
import { connect } from '../../../state/store/index.tsx';
import createEditableField from './editable-field/view.tsx';
import SingleUserSelect from './single-user-select/index.tsx';

const mapStateToProps = (state: State) => ({
	onRefreshSidebar: getRefreshSidebar(state),
});

// @ts-expect-error - TS2345 - Argument of type '<T extends UserFieldType>(props: FieldComponentProps<T>) => JSX.Element' is not assignable to parameter of type 'ComponentType<FieldComponentProps<"assignee" | "reporterWithIcon">>'.
const AssigneeField = createEditableField(SingleUserSelect);

export default connect(mapStateToProps, {})(AssigneeField);
