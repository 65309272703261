import type { ColumnProps } from '../../../model/index.tsx';

export const UPDATE_COLUMNS = 'state.actions.columns.UPDATE_COLUMNS' as const;

export type UpdateColumnsAction = {
	type: typeof UPDATE_COLUMNS;
	payload: {
		columns: ColumnProps[];
	};
};

export const updateColumnsAction = (columns: ColumnProps[]): UpdateColumnsAction => ({
	type: UPDATE_COLUMNS,
	payload: {
		columns,
	},
});

export const INITIALIZE_COLUMN = 'state.actions.columns.INITIALIZE_COLUMN' as const;

export type InitializeColumnAction = {
	type: typeof INITIALIZE_COLUMN;
	payload: {
		column: ColumnProps;
	};
};

export const initializeColumnAction = (column: ColumnProps): InitializeColumnAction => ({
	type: INITIALIZE_COLUMN,
	payload: {
		column,
	},
});
