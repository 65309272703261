import React, { Component, type ReactNode } from 'react';
import Tooltip from '@atlaskit/tooltip';

const SORT_ORDER_CHANGED_TOOLTIP_DELAY = 0;
const TOOLTIP_DELAY = 1000;
const TOOLTIP_POSITION = 'top';

type Props = {
	isSortOrderChanged: boolean;
	children: ReactNode;
	tooltipText: string;
	onShow: () => void;
};

type State = {
	tooltipComponentKey: number;
};

// eslint-disable-next-line jira/react/no-class-components
export default class SortOrderTooltip extends Component<Props, State> {
	static getDerivedStateFromProps(nextProps: Props, state: State) {
		if (nextProps.isSortOrderChanged) {
			return {
				// force re-rendering the tooltip component to let the new delay value take into effect
				tooltipComponentKey: state.tooltipComponentKey + 1,
			};
		}
		return null;
	}

	state = {
		tooltipComponentKey: 0,
	};

	onShow = () => {
		this.props.onShow && this.props.onShow();
	};

	render() {
		const { isSortOrderChanged, tooltipText, children } = this.props;
		const delay = isSortOrderChanged ? SORT_ORDER_CHANGED_TOOLTIP_DELAY : TOOLTIP_DELAY;

		return (
			<Tooltip
				delay={delay}
				position={TOOLTIP_POSITION}
				content={tooltipText}
				onShow={this.onShow}
				key={this.state.tooltipComponentKey}
			>
				{children}
			</Tooltip>
		);
	}
}
