import type { Observable } from 'rxjs/Observable';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { fg } from '@atlassian/jira-feature-gating';
import { fetchJson$ } from '@atlassian/jira-servicedesk-common/src/utils/fetch/index.tsx';
import {
	type QueueId,
	fromQueueId,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, projectKey: string, queueId: QueueId): Observable<void> => {
	if (fg('add_api_timing_to_perf_portal_for_queues_page')) {
		const fetchStartTime = performance.now();
		const result = fetchJson$(
			`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues-history?queueId=${fromQueueId(
				queueId,
			)}`,
			{ method: 'POST' },
		);
		addSpanToAll(
			'fetch',
			'rest/servicedesk/1/servicedesk/projectKey/queues-history?queueId=',
			[{ name: 'network' }],
			fetchStartTime,
			performance.now(),
		);
		return result;
	}

	const result = fetchJson$(
		`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues-history?queueId=${fromQueueId(
			queueId,
		)}`,
		{ method: 'POST' },
	);
	return result;
};
