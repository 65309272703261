import type { UserForEditing } from '../../model/index.tsx';
import searchAssignee, { type AssigneeSearchResponse } from '../../rest/search-assignee/index.tsx';

const transformResponse = (response: AssigneeSearchResponse) =>
	response.map((userResponse) => ({
		id: userResponse.accountId,
		avatarUrl: userResponse.avatarUrls['48x48'],
		name: userResponse.displayName,
		byline: userResponse.emailAddress,
		value: {
			accountId: userResponse.accountId,
			avatarUrl: userResponse.avatarUrls['48x48'],
			displayName: userResponse.displayName,
			emailAddress: userResponse.emailAddress,
		},
	}));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issueKey: string, query: string): Promise<UserForEditing[]> =>
	searchAssignee(issueKey, query).then(
		(response) =>
			new Promise(
				(resolve: (result: Promise<Array<UserForEditing>> | Array<UserForEditing>) => void) => {
					resolve(transformResponse(response));
				},
			),
	);
