import {
	type BOOLEAN_GQL_FIELD,
	SERVICEDESK_INCIDENT_MANAGEMENT_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggBooleanToLegacyField: AggTransformerFunction<typeof BOOLEAN_GQL_FIELD> = (
	node,
) => {
	switch (node.type) {
		case SERVICEDESK_INCIDENT_MANAGEMENT_TYPE:
			return {
				...transformAggNodeToLegacyField(node),
				value: {
					alertLinking: {
						enabled: node.value,
					},
				},
				schema: {
					configuration: null,
					items: null,
					renderer: null,
					custom: null,
					system: SERVICEDESK_INCIDENT_MANAGEMENT_TYPE,
					type: SERVICEDESK_INCIDENT_MANAGEMENT_TYPE,
				},
			};
		default:
			return undefined;
	}
};
