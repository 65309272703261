import { DIALOG_DATA } from '../common/constants.tsx';

type MessageId =
	| 'thanksIssueUpdated'
	| 'thanksIssueAssigned'
	| 'thanksIssueCommented'
	| 'thanksIssueWorklogged'
	| 'thanksIssueVoted'
	| 'thanksIssueWatched'
	| 'thanksIssueMoved'
	| 'thanksIssueLinked'
	| 'thanksIssueCloned'
	| 'thanksIssueLabelled'
	| 'thanksIssueDeleted'
	| 'thanksIssueAttached';

export const getMessageId = (styleClass: string): MessageId =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "issueaction-assign-issue": { readonly id: "assign-dialog"; readonly issueMsg: "thanksIssueAssigned"; }; readonly "issueaction-assign-to-me hide-from-opsbar": { readonly id: "assign-dialog"; readonly issueMsg: "thanksIssueAssigned"; }; ... 5 more ...; readonly "issueaction-edit-labels": { ...; }; }'.
	DIALOG_DATA[styleClass]?.issueMsg ?? 'thanksIssueUpdated';
