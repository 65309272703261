import React, { Component, type ReactElement } from 'react';
import Context from '../context.tsx';
import {
	type Props as BaseProps,
	baseDefaultProps,
	checkAndEmitApdexSignal,
} from '../submit-apdex-mark/index.tsx';

type Props = BaseProps & {
	children: (arg1: { submit: () => void }) => ReactElement;
};

// eslint-disable-next-line jira/react/no-class-components
class ApdexActions extends Component<Props> {
	static defaultProps = baseDefaultProps;

	render() {
		const { appName, metricKey, extra, children, metric } = this.props;

		checkAndEmitApdexSignal(appName, metricKey);

		return (
			<Context.Consumer>
				{/* @ts-expect-error - TS2339 - Property 'onReady' does not exist on type 'String'. */}
				{({ onReady }) =>
					children({
						submit: () =>
							onReady(
								metricKey || `jira.fe.spa.initial-render.${String(appName)}`,
								metricKey || `jira.fe.spa.transition.${String(appName)}`,
								extra,
								metric,
							),
					})
				}
			</Context.Consumer>
		);
	}
}

export default ApdexActions;
