import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteQueue: {
		id: 'servicedesk.queues.common.delete-queue',
		defaultMessage: 'Delete queue',
		description: 'Used in scenarios where user wants to delete one of their queues.',
	},
	resetSorting: {
		id: 'servicedesk.queues.common.reset-sorting',
		defaultMessage: 'Reset sorting',
		description:
			'Button or dropdown option on queue page. Allows admin or agent to reset manual column sorting of queue',
	},
	openWithIssueNav: {
		id: 'servicedesk.queues.common.open-with-issue-nav',
		defaultMessage: 'Open in issue navigator',
		description:
			"Button to open the Jira issue navigator in a new tab with this queue's JQL preloaded",
	},
});
