import { createSelector } from 'reselect';
import {
	LOADED,
	LOADING,
	FAILED,
	type Validators,
	type ProjectHasSubtaskValidators,
} from '@atlassian/jira-issue-view-common-types/src/project-validator-type.tsx';

const hasSubtaskValidators = (hasValidators: ProjectHasSubtaskValidators) =>
	hasValidators.status === LOADED && hasValidators.value;

const unknown = (hasValidators: ProjectHasSubtaskValidators) =>
	hasValidators.status === LOADING || hasValidators.status === FAILED;

export const projectHasSubtaskValidatorsSelector = createSelector(
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	(state) => state.validators,
	(validators: Validators) => {
		const val = validators.projectHasSubtaskValidators;
		return hasSubtaskValidators(val) || unknown(val);
	},
);
