import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { getQueueFragment } from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import type { ErrorResponse } from '@atlassian/jira-servicedesk-common/src/utils/fetch/index.tsx';
import { getProjectId } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import updateQueue from '../../../services/update-queues/index.tsx';
import type { QueueUpdate } from '../../../services/update-queues/types.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import {
	setDeleteModalVisibilityAction,
	DELETE_QUEUE,
	deleteQueueFailureAction,
	setModalIsDeletingAction,
} from '../../../state/actions/ui/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getQueueName, getCategory } from '../../../state/selectors/queue/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(DELETE_QUEUE).switchMap((action) => {
			const state = store.getState();
			const {
				payload: { queueId, onDeleteComplete },
				meta: { analyticsEvent },
			} = action;

			const queueName = getQueueName(state);
			const payload: QueueUpdate = {
				deleted: [queueId],
			};

			return updateQueue(payload, state)
				.flatMap(() => {
					const analyticsProperties = {
						action: 'deleted',
						containerType: 'project',
						containerId: getProjectId(state).toString(),
						objectType: 'queue',
						objectId: queueId,
						attributes: {
							redesign: true,
						},
					};
					// @ts-expect-error TS2345 | TS2345 - Argument of type '{ action: string; containerType: string; containerId: string; objectType: string; objectId: number; attributes: { redesign: boolean; }; }' is not assignable to parameter of type 'Readonly<Readonly<{ name?: string | undefined; containerId?: string | undefined; containers?: Containers | undefined; containerType?: string | undefined; objectType?: string | undefined; objectId?: string | undefined; readonly tags?: readonly string[] | undefined; attributes?: Readonly<...> | undefined; }> & { ...; }>'. | Argument of type '{ action: string; containerType: string; containerId: string; objectType: string; objectId: number; attributes: { redesign: boolean; }; }' is not assignable to parameter of type 'Readonly<Readonly<{ name?: string | undefined; containerId?: string | undefined; containers?: Containers | undefined; containerType?: string | undefined; objectType?: string | undefined; objectId?: string | undefined; readonly tags?: readonly string[] | undefined; attributes?: Readonly<...> | undefined; }> & { ...; }>'.
					fireTrackAnalytics(analyticsEvent, analyticsProperties);
					onDeleteComplete(queueId);

					if (push) {
						const category = getCategory(state);
						return push(getQueueFragment(undefined, category));
					}

					return Observable.of(
						setDeleteModalVisibilityAction(false),
						// @ts-expect-error - TS2769 - No overload matches this call.
						setModalIsDeletingAction(false),
					);
				})
				.catch((error: ErrorResponse) =>
					Observable.of(
						setDeleteModalVisibilityAction(false),
						// @ts-expect-error - TS2769 - No overload matches this call.
						deleteQueueFailureAction(queueName, error.response),
						setModalIsDeletingAction(false),
					),
				);
		});
