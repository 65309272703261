import React, { type ComponentType } from 'react';
import camelCase from 'lodash/camelCase';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { lazyAfterPaint, LazySuspense } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

const AsyncSla: ComponentType<Props> = lazyAfterPaint(
	() => import(/* webpackChunkName: "jira-issue-sla" */ './main'),
);

const AsyncLazySla = (props: Props) => {
	const { viewMode, onError } = props;
	const boundary = camelCase(`sla ${viewMode} View`);

	return (
		<ErrorBoundary id={boundary} packageName="jira-issue-sla" onError={onError}>
			<LazySuspense fallback={null}>
				<AsyncSla {...props} />
			</LazySuspense>
		</ErrorBoundary>
	);
};

export { AsyncLazySla };
