import { FROTHER } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type MULTI_VERSION_GQL_FIELD,
	MULTI_VERSION_CF_TYPE,
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';
import { transformAggVersionToLegacyVersion } from './version-node-transformer.tsx';

const defaultSchema = {
	configuration: null,
	custom: null,
	items: 'version',
	renderer: FROTHER,
	system: null,
	type: 'array',
} as const;

export const transformAggMultipleVersionPickerToLegacyField: AggTransformerFunction<
	typeof MULTI_VERSION_GQL_FIELD
> = (node) => {
	if (
		node.type !== MULTI_VERSION_CF_TYPE &&
		node.type !== AFFECTS_VERSIONS_TYPE &&
		node.type !== FIX_VERSIONS_TYPE
	) {
		return undefined;
	}

	const legacyFieldValues = {
		...transformAggNodeToLegacyField(node),
		allowedValues: [],
		value: transformAggConnection(
			node.selectedVersionsConnection,
			transformAggVersionToLegacyVersion,
		),
	};

	switch (node.type) {
		case MULTI_VERSION_CF_TYPE:
			return {
				...legacyFieldValues,
				value: legacyFieldValues.value.length === 0 ? null : legacyFieldValues.value,
				schema: {
					...defaultSchema,
					custom: MULTI_VERSION_CF_TYPE,
				},
			};
		case AFFECTS_VERSIONS_TYPE:
		case FIX_VERSIONS_TYPE:
			return {
				...legacyFieldValues,
				schema: {
					...defaultSchema,
					system: node.type,
				},
			};
		default:
			return undefined;
	}
};
