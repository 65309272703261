import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncGettingStartedPanelShouldRender: ComponentType<Record<any, any>> = lazyAfterPaint(() =>
	import(
		/* webpackChunkName: "jsm-getting-started-panel-async-should-render" */ './ui/should-render/async'
	).then(({ JsdGettingStartedPanelShouldRender }) => JsdGettingStartedPanelShouldRender),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncGettingStartedPanel: ComponentType<Record<any, any>> = lazyAfterPaint(() =>
	import(/* webpackChunkName: "jsm-getting-started-panel-async-panel" */ './ui/panel/async').then(
		({ JsdGettingStartedPanel }) => JsdGettingStartedPanel,
	),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncGettingStartedPanelOpenButton: ComponentType<Record<any, any>> = lazyAfterPaint(() =>
	import(
		/* webpackChunkName: "jsm-getting-started-panel-async-open-button" */ './ui/open-button/async'
	).then(({ JsdGettingStartedPanelOpenButton }) => JsdGettingStartedPanelOpenButton),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncGettingStartedPanelReopenSpotlight: ComponentType<Record<any, any>> = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "jsm-getting-started-panel-async-reopen-spotlight" */ './ui/reopen-spotlight/async'
		).then(({ JsdGettingStartedPanelReopenSpotlight }) => JsdGettingStartedPanelReopenSpotlight),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JsdGettingStartedPanelShouldRender: ComponentType<Record<any, any>> = () => (
	<Placeholder name="jsm-getting-started-panel-async-should-render" fallback={null}>
		<AsyncGettingStartedPanelShouldRender />
	</Placeholder>
);
JsdGettingStartedPanelShouldRender.displayName = 'AsyncJsdGettingStartedPanelShouldRender';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JsdGettingStartedPanel: ComponentType<Record<any, any>> = () => (
	<Placeholder name="jsm-getting-started-panel-async-panel" fallback={null}>
		<AsyncGettingStartedPanel />
	</Placeholder>
);
JsdGettingStartedPanel.displayName = 'AsyncJsdGettingStartedPanel';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JsdGettingStartedPanelOpenButton: ComponentType<Record<any, any>> = () => (
	<Placeholder name="jsm-getting-started-panel-async-open-button" fallback={null}>
		<AsyncGettingStartedPanelOpenButton />
	</Placeholder>
);
JsdGettingStartedPanelOpenButton.displayName = 'AsyncJsdGettingStartedPanelOpenButton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JsdGettingStartedPanelReopenSpotlight: ComponentType<Record<any, any>> = () => (
	<Placeholder name="jsm-getting-started-panel-async-reopen-spotlight" fallback={null}>
		<AsyncGettingStartedPanelReopenSpotlight />
	</Placeholder>
);
JsdGettingStartedPanelReopenSpotlight.displayName = 'AsyncJsdGettingStartedPanelReopenSpotlight';
