import { connect } from '../../../../../../../../../common/table-redux.tsx';
import type { ColumnId } from '../../../../../../../../../model/columns/index.tsx';
import { getColumnColor } from '../../../../../../../../../state/consumer/selectors/columns/index.tsx';
import type { State } from '../../../../../../../../../state/types.tsx';
import ChildBar from './index-dumb.tsx';

type Props = {
	columnId: ColumnId;
};

export default connect(
	(state: State, { columnId }: Props) => ({
		backgroundColor: getColumnColor(state, columnId),
	}),
	{},
)(ChildBar);
