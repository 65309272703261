import type { FieldDescriptor } from '../../../model/index.tsx';
import type { LockedIssuesByKey } from '../../../model/locking/index.tsx';
import type { State } from '../../reducers/types.tsx';
import { getPersisted } from '../common/index.tsx';

export const getLockedIssues = (state: State): LockedIssuesByKey =>
	getPersisted(state).lockedIssues;

export const getLockedIssueCountAssumingIssueKeyWillBeLocked = (
	state: State,
	issueKey: string,
): number => {
	const issueKeys = Object.keys(getLockedIssues(state));
	issueKeys.push(issueKey);
	return new Set(issueKeys).size;
};

type PropsWithFieldCoordinates = {
	issueKey: string;
	fieldDescriptor: FieldDescriptor;
};

export const getIsFieldLocked = (state: State, props: PropsWithFieldCoordinates): boolean => {
	const {
		issueKey,
		fieldDescriptor: { fieldId },
	} = props;
	const lockedIssue = getLockedIssues(state)[issueKey];
	return lockedIssue && !!lockedIssue.fields[fieldId];
};
