import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import {
	setVerticalScrollOffset,
	setHorizontalScrollOffset,
	setVerticalScrollDelta,
} from '../../../state/internal/actions.tsx';
import { getVerticalScrollOffset } from '../../../state/internal/selectors.tsx';
import { errorHandlerFactory } from '../../common/errors/index.tsx';
import { SET_SCROLL_OFFSET, type SetScrollOffsetAction } from './action.tsx';

const errorHandler = errorHandlerFactory();

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: any): Observable<any> =>
	action$
		.ofType(SET_SCROLL_OFFSET)
		// @ts-expect-error - TS2345 - Argument of type '(action: SetScrollOffsetAction) => Observable<SetVerticalScrollOffsetAction | SetVerticalScrollDeltaAction> | Observable<...>' is not assignable to parameter of type '(value: SetScrollOffsetAction, index: number) => ObservableInput<SetVerticalScrollOffsetAction | SetVerticalScrollDeltaAction>'.
		.mergeMap((action: SetScrollOffsetAction) => {
			if (action.payload.direction === 'VERTICAL') {
				const lastPos = getVerticalScrollOffset(store.getState());
				const delta = lastPos - action.payload.offset;
				return Observable.concat(
					Observable.of(setVerticalScrollOffset(action.payload.offset)),
					Observable.of(setVerticalScrollDelta(delta)),
				);
			}
			if (action.payload.direction === 'HORIZONTAL') {
				return Observable.of(setHorizontalScrollOffset(action.payload.offset));
			}
			throw new Error(`The direction property ${action.payload.direction} value is illegal.`);
		})
		.catch(errorHandler);
