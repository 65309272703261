import { connect } from '../common/table-redux.tsx';
import { set as setTableSize } from '../ops/table-size/action.tsx';
import { isAutoHeight } from '../state/consumer/selectors/index.tsx';
import { getTableWidth } from '../state/internal/selectors.tsx';
import { getTableHeight } from '../state/selectors/index.tsx';
import type { State } from '../state/types.tsx';
import DetailsPanel from './details/index.tsx';
import Table from './index-dumb.tsx';
import TableMain from './table/index.tsx';

export default connect(
	(state: State) => ({
		isAutoHeight: isAutoHeight(state),
		width: getTableWidth(state),
		height: getTableHeight(state),
		TableMain,
		DetailsPanel,
	}),
	{ setTableSize },
)(Table);
