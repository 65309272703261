import type { AssigneeInitialization } from '../../../../model/fields/json-fields/system-fields/types.tsx';
import type { State } from '../../../reducers/types.tsx';
import { getFieldInitialization } from '../../common/index.tsx';

const getAssigneeInitialization = (state: State): AssigneeInitialization | undefined =>
	getFieldInitialization(state).assignee;

export const getAvatarUrl = (state: State): string | undefined => {
	const assignee = getAssigneeInitialization(state);
	return assignee ? assignee.avatarUrl : undefined;
};

export const getDisplayName = (state: State): string | undefined => {
	const assignee = getAssigneeInitialization(state);
	return assignee ? assignee.displayName : undefined;
};

export const getEmailAddress = (state: State): string | undefined => {
	const assignee = getAssigneeInitialization(state);
	return assignee ? assignee.emailAddress : undefined;
};
