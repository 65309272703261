import { connect } from '../../../../../../common/table-redux.tsx';
import { getPremiumSLAColumns } from '../../../../../../state/consumer/selectors/index.tsx';
import type { State } from '../../../../../../state/types.tsx';
import ErrorInlineMessage from './error-inline-message/index.tsx';
import { ConditionalErrorBoundary } from './view.tsx';

export default connect(
	(state: State) => ({
		columnsToIgnore: getPremiumSLAColumns(state),
		ErrorInlineMessage,
	}),
	{},
)(ConditionalErrorBoundary);
