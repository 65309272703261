const ISSUE_DETAILS_ANALYTICS_PREFIX = 'ISSUE_DETAILS';

const FETCH_FIELD_DATA_REQUEST = 'FETCH_FIELD_DATA';
const FETCH_INITIAL_COMMENTS_REQUEST = 'FETCH_INITIAL_COMMENTS';
const FETCH_ISSUE_LINK_TYPES_REQUEST = 'FETCH_ISSUE_LINK_TYPES';
const FETCH_SUBTASK_TYPES_REQUEST = 'FETCH_SUBTASK_TYPES';
const FETCH_UPLOAD_CONTEXT_REQUEST = 'FETCH_LOAD_CONTEXT';
const FETCH_USER_AUTH_REQUEST = 'FETCH_USER_AUTH';
const FETCH_VIEW_CONTEXT_REQUEST = 'FETCH_VIEW_CONTEXT';
const FETCH_ISSUE_PROPERTIES_REQUEST = 'FETCH_ISSUE_PROPERTIES';

export const fetchFieldDataPerf = {
	key: FETCH_FIELD_DATA_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchCommentsPerf = {
	key: FETCH_INITIAL_COMMENTS_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchIssueLinkPerf = {
	key: FETCH_ISSUE_LINK_TYPES_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchSubtaskTypesPerf = {
	key: FETCH_SUBTASK_TYPES_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchUploadPerf = {
	key: FETCH_UPLOAD_CONTEXT_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchUserAuthPerf = {
	key: FETCH_USER_AUTH_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchViewContextPerf = {
	key: FETCH_VIEW_CONTEXT_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;

export const fetchIssuePropertiesPerf = {
	key: FETCH_ISSUE_PROPERTIES_REQUEST,
	prefix: ISSUE_DETAILS_ANALYTICS_PREFIX,
} as const;
