import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	thanksIssueUpdated: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-updated',
		defaultMessage: '{issueKey} has been updated.',
		description: 'Text in notification flag to alert user an issue has been updated.',
	},
	thanksIssueAssigned: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-assigned',
		defaultMessage: '{issueKey} has been assigned.',
		description: 'Text in notification flag to alert user an issue has been assigned.',
	},
	thanksIssueCommented: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-commented',
		defaultMessage: '{issueKey} has been updated with your comment.',
		description:
			'Text in notification flag to alert user their comment has been added to an issue.',
	},
	thanksIssueWorklogged: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-worklogged',
		defaultMessage: 'Work has been logged on {issueKey}.',
		description:
			'Text in notification flag to alert user that their work has been logged on an issue.',
	},
	thanksIssueVoted: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-voted',
		defaultMessage: 'Your vote on {issueKey} has been updated.',
		description:
			'Text in notification flag to alert user that their vote has been added to an issue.',
	},
	thanksIssueWatched: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-watched',
		defaultMessage: 'Your watcher status on {issueKey} has been updated.',
		description: 'Text in notification flag to alert user that they are watching an issue.',
	},
	thanksIssueMoved: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-moved',
		defaultMessage: '{issueKey} has been moved.',
		description:
			'Text in notification flag to alert user that their work has been logged on an issue.',
	},
	thanksIssueLinked: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-linked',
		defaultMessage: 'The links on {issueKey} have been updated.',
		description: 'Text in notification flag to alert user that they have added links to an issue.',
	},
	thanksIssueCloned: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-cloned',
		defaultMessage: '{issueKey} has been cloned.',
		description: 'Text in notification flag to alert user that they successfully cloned an issue.',
	},
	thanksIssueLabelled: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-labelled',
		defaultMessage: 'The labels on {issueKey} have been updated.',
		description:
			'Text in notification flag to alert user that they have updated labels on an issue.',
	},
	thanksIssueDeleted: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-deleted',
		defaultMessage: '{issueKey} has been deleted.',
		description: 'Text in notification flag to alert user that they have deleted an issue',
	},
	thanksIssueAttached: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.thanks-issue-attached',
		defaultMessage: 'The file(s) have been attached to {issueKey}.',
		description:
			'Text in notification flag to alert user that they have attached files to an issue',
	},
	successTitle: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.success-title',
		defaultMessage: 'Issue updated',
		description: 'Text in notification flag to alert user an issue has been updated.',
	},
	errorTitle: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.error-title',
		defaultMessage: 'Error',
		description: 'Text in notification flag to alert user that their issue operation failed',
	},
	errorDesc: {
		id: 'issue-table.common.fields.issue-operations.notification-flag-group.error-desc',
		defaultMessage: 'Your operation on {issueKey} could not be performed.',
		description: 'Text in notification flag to alert user that their issue operation failed',
	},
});
