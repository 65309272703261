import type { ColumnId, ColumnTree } from '../../../../model/columns/index.tsx';
import type { Optional } from '../../../../model/optional/index.tsx';
import getSortedLevelIds from './get-sorted-level-ids.tsx';

const traverseColumns = (
	columnTree: ColumnTree,
	columnIds: ColumnId[],
	sortedColumnIds: Optional<ColumnId[]>,
	result: ColumnId[],
) => {
	getSortedLevelIds(sortedColumnIds, columnIds).forEach((columnId) => {
		const childrenIds = columnTree.columns[columnId].childrenIds || [];

		result.push(columnId);
		traverseColumns(columnTree, childrenIds, sortedColumnIds, result);
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	columnTree: ColumnTree,
	rootColumnIds: ColumnId[],
	sortedColumnIds: Optional<ColumnId[]>,
): ColumnId[] => {
	const result: Array<ColumnId> = [];
	traverseColumns(columnTree, rootColumnIds, sortedColumnIds, result);
	return result;
};
