import { combineReducers } from 'redux';
import bulkSelection from './bulk-selection/index.tsx';
import fieldInitialization from './field-initialization/index.tsx';
import persisted from './persisted/index.tsx';
import ui from './ui/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noopReducer = (state: any) => state || {};

export default combineReducers({
	persisted,
	ui,
	fieldInitialization,
	bulkSelection,
	initialAppProps: noopReducer,
});
