import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type MULTI_SELECT_GQL_FIELD,
	MULTI_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';

export const transformAggMultipleSelectToLegacyField: AggTransformerFunction<
	typeof MULTI_SELECT_GQL_FIELD
> = (node) => {
	switch (node.type) {
		case MULTI_SELECT_CF_TYPE: {
			const value = transformAggConnection(node.selectedOptions, (option) => ({
				id: option.optionId,
				value: option.value ?? null,
				ari: option.id,
			}));

			return {
				...transformAggNodeToLegacyField(node),
				value: value.length ? value : null,
				allowedValues: [],
				schema: {
					type: 'array',
					renderer: PLAIN_TEXT,
					custom: node.type,
					system: null,
					items: 'option',
					configuration: null,
				},
			};
		}
		default:
			return undefined;
	}
};
