import { createSelector } from 'reselect';
import without from 'lodash/without';
import {
	NO_CATEGORY,
	type ItsmPractice,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { State } from '../../reducers/types.tsx';
import { getPersisted } from '../common/index.tsx';
import { getCategory } from '../queue/index.tsx';

const getProjectPractices = (state: State): ItsmPractice[] => getPersisted(state).practices;

export const getEnabledPractices = createSelector(getCategory, (category = NO_CATEGORY) =>
	category === NO_CATEGORY ? [] : [category],
);

export const isItsmProject = createSelector(
	getProjectPractices,
	(practiceList) => without(practiceList, NO_CATEGORY).length > 0,
);
