import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { LOAD_ISSUES_ACTION_SOURCE_VIEW } from '../../../model/index.tsx';
import { loadIssuesAction } from '../../../state/actions/issue/index.tsx';
import { CHANGE_VISIBILITY, setQueueVisibilityAction } from '../../../state/actions/page/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getFetchStartIndex } from '../../../state/selectors/queue/index.tsx';
import { isQueueVisible } from '../../../state/selectors/ui/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(CHANGE_VISIBILITY).switchMap((action) => {
		const state = store.getState();
		const newVisibility = action.payload.isQueueVisible;
		if (isQueueVisible(state) === newVisibility) {
			return Observable.of();
		}
		const result = Observable.of(setQueueVisibilityAction(newVisibility));
		if (newVisibility) {
			return result.concat(
				Observable.of(loadIssuesAction(getFetchStartIndex(state), LOAD_ISSUES_ACTION_SOURCE_VIEW)),
			);
		}
		return result;
	});
