// SHUTDOWN

export const SHUTDOWN = 'state.actions.lifecycle.SHUTDOWN' as const;

export type ShutdownAction = {
	type: typeof SHUTDOWN;
};

export const shutdownAction = (): ShutdownAction => ({
	type: SHUTDOWN,
});

export type Action = ShutdownAction;
