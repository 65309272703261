import type { Observable } from 'rxjs/Observable';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types.tsx';
import transform from '@atlassian/jira-servicedesk-queues-common/src/services/issue/index.tsx';
import {
	getBaseUrl,
	getProjectKey,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import type { SortedQueues } from '../../model/index.tsx';
import put from '../../rest/update-queues/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { pageToQueues } from '../common/index.tsx';
import type { QueueUpdate } from './types.tsx';

export const pageResponseTransformFunc = (page: Page[]): SortedQueues => pageToQueues(page);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (payload: QueueUpdate, state: State): Observable<SortedQueues> =>
	// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	transform(put(getProjectKey(state), getBaseUrl(state), payload), pageResponseTransformFunc);
