export const HTTP = 'HTTP' as const;
export const UNKNOWN = 'UNKNOWN' as const;
export const REDUX = 'REDUX' as const;

type ExceptionBody = {
	error?: string;
};

export type GenericErrorDescription = {
	type: typeof UNKNOWN;
	message: string;
	stack: string | undefined;
};

export type ReduxErrorDescription = {
	type: typeof REDUX;
	message: string;
	stack: string | undefined;
};

export type HttpErrorDescription = {
	type: typeof HTTP;
	message: string;
	body: ExceptionBody;
	statusCode: number;
};

export type ErrorDescription =
	| GenericErrorDescription
	| HttpErrorDescription
	| ReduxErrorDescription;
