import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tootltipContent: {
		id: 'major-incident.major-incident-lozenge.tootltip-content',
		defaultMessage: 'This incident is marked as a major incident to increase visibility.',
		description: 'Message in the tooltip of the major incident lozenge at the top of issue view',
	},
	lozengeLabel: {
		id: 'major-incident.major-incident-lozenge.lozenge-label',
		defaultMessage: 'MAJOR INCIDENT',
		description: 'Message in the tooltip of the major incident lozenge at the top of issue view',
	},
});
