import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { canManageQueue, isQueueTransient } from '../../../../state/selectors/queue/index.tsx';
import { isManuallySorted } from '../../../../state/selectors/sorting/index.tsx';
import { showIssueFailureError } from '../../../../state/selectors/ui/index.tsx';
import ActionsMenu from './async-actions-menu/index.tsx';
import FavoriteButton from './favorite-button/index.tsx';
import OpenInIssueNavButton from './open-in-issue-nav-button/index.tsx';
import ResetSortingButton from './reset-sorting-button/index.tsx';
import Actions from './view.tsx';

const mapStateToProps = (state: State) => ({
	ActionsMenu,
	ResetSortingButton,
	FavoriteButton,
	OpenInIssueNavButton,
	canManageQueue: canManageQueue(state),
	isManuallySorted: isManuallySorted(state),
	isFavoriteEnabled: !isQueueTransient(state),
	showIssueFailureError: showIssueFailureError(state),
});

export default connect(mapStateToProps, {})(Actions);
