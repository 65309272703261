export const DEFAULT_COLUMNS: string[] = [
	'issuetype',
	'issuekey',
	'summary',
	'reporter',
	'assignee',
	'status',
	'created',
];

export const DEFAULT_QUEUE_ID = Number.MIN_VALUE;

// The number of issue keys that would be fetched on scroll on queues agent view
export const FETCH_BATCH_SIZE = 100;
