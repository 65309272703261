import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { PriorityField } from '@atlassian/jira-issue-shared-types/src/common/types/fields/priority-field-type.tsx';
import type { ServerRestPriority } from '@atlassian/jira-issue-shared-types/src/common/types/priority-type.tsx';
import {
	type PRIORITY_GQL_FIELD,
	PRIORITY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggJiraPriority } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';

const transformJiraPriorityToLegacyPriority = (
	priority: AggJiraPriority | null,
): ServerRestPriority | null => {
	if (priority == null) return null;

	return {
		id: priority.priorityId,
		iconUrl: String(priority.iconUrl || ''), // iconUrl is AGG$URL in the schema
		name: priority.name || '',
		sequence: priority.sequence || undefined,
	};
};

export const transformAggPriorityToLegacyField: AggTransformerFunction<
	typeof PRIORITY_GQL_FIELD
> = (node): PriorityField | undefined => {
	if (node.type !== PRIORITY_TYPE) return undefined;

	const allowedValues = transformAggConnection(
		'priorities' in node ? node.priorities : null,
		transformJiraPriorityToLegacyPriority,
	);

	return {
		...transformAggNodeToLegacyField(node),
		allowedValues,
		// @ts-expect-error - Argument of type '{ readonly color: string | null | undefined; readonly iconUrl: string | null | undefined; readonly name: string | null | undefined; readonly priorityId: string; } | { readonly iconUrl: string | ... 1 more ... | undefined; readonly name: string | ... 1 more ... | undefined; readonly priorityId: string; } | { ...; } |...' is not assignable to parameter of type 'AggJiraPriority | null'.
		value: transformJiraPriorityToLegacyPriority(node.priority),
		schema: {
			type: PRIORITY_TYPE,
			custom: null,
			system: PRIORITY_TYPE,
			configuration: null,
			items: null,
			renderer: PLAIN_TEXT,
		},
	};
};
