import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type {
	JIRA_COMMENT_VISIBILITY_TYPE_GROUP,
	JIRA_COMMENT_VISIBILITY_TYPE_ROLE,
} from '@atlassian/jira-issue-shared-types/src/common/types/comment-type.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const COMMENT_VISIBILITY_TYPE_GROUP = 'group' as const;
export const COMMENT_VISIBILITY_TYPE_ROLE = 'role' as const;
export const COMMENT_VISIBILITY_TYPE_PUBLIC = 'public' as const;

export type CommentVisibilityType =
	| typeof COMMENT_VISIBILITY_TYPE_GROUP
	| typeof COMMENT_VISIBILITY_TYPE_ROLE
	| typeof COMMENT_VISIBILITY_TYPE_PUBLIC
	| typeof JIRA_COMMENT_VISIBILITY_TYPE_GROUP
	| typeof JIRA_COMMENT_VISIBILITY_TYPE_ROLE;

export type CommentVisibility = {
	type: CommentVisibilityType;
	value: string;
	groupId?: string;
};

export type NormalizedComment = {
	id: string;
	authorId: AccountId | null;
	updateAuthorId: AccountId | null;
	bodyAdf: ADF;
	bodyHtml: string;
	createdDate: string;
	updatedDate: string;
	edited: boolean;
	visibility: CommentVisibility;
	isInternal: boolean;
	/*
	 *  TODO : https://hello.atlassian.net/browse/GCS-1009 Make jsdAuthorCanSeeRequest mandatory when FF is cleaned
	 */
	jsdAuthorCanSeeRequest?: boolean; // JSM specific field for identifying whether user is request participant or not
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	replies?: string[];
	parentId?: string;
	isDeleted?: boolean;
};

export type NormalizedComments = {
	[key: string]: NormalizedComment;
};

export type NormalizedUser = {
	id: AccountId;
	displayName: string;
	avatarUrl: string;
	timeZone?: string;
};

export type NormalizedUsers = {
	[key: string]: NormalizedUser;
};

export type NormalizedLoadedComments = {
	users: NormalizedUsers;
	comments: NormalizedComments;
	loadedComments: number;
	commentsStartIndex: number;
	totalComments: number;
};

export type CommentsTransformResult = NormalizedLoadedComments & {
	totalComments: number;
	commentsStartIndex: number;
};
