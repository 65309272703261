import type { ServiceDeskContextData } from '@atlassian/jira-navigation-types/src/types.tsx';

export const getPortalUrlWithQueuesReferrer = (
	servicedeskContext: ServiceDeskContextData | null,
) => {
	const urlSearchParams = new URLSearchParams();
	urlSearchParams.set('referrer', 'queues');

	return servicedeskContext?.portalUrl
		? `${servicedeskContext?.portalUrl}?${urlSearchParams.toString()}`
		: '';
};
