import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type TIME_TRACKING_GQL_FIELD,
	TIME_TRACKING_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

const getEstimateValues = (
	estimate: null | Readonly<{
		timeInSeconds: AGG$Long | null | undefined;
	}>,
	key: string,
) => {
	if (estimate) {
		return {
			[key]: estimate.timeInSeconds || 0,
		};
	}

	return {};
};

/**
 * Transforms a JiraTimeTrackingField node to a legacy field.
 * The transformer are not returning renderedValue, value.remainingEstimate
 * and value.timeSpent here as it is not used by the time tracking field.
 */
export const transformAggTimeTrackingToLegacyField: AggTransformerFunction<
	typeof TIME_TRACKING_GQL_FIELD
> = (node) => {
	if (node.type !== TIME_TRACKING_TYPE) return undefined;

	const originalEstimate = getEstimateValues(
		node.originalEstimate || null,
		'originalEstimateSeconds',
	);
	const remainingEstimate = getEstimateValues(
		node.remainingEstimate || null,
		'remainingEstimateSeconds',
	);
	const timeSpent = getEstimateValues(node.timeSpent || null, 'timeSpentSeconds');

	return {
		...transformAggNodeToLegacyField(node),
		value: {
			...originalEstimate,
			...remainingEstimate,
			...timeSpent,
		},
		schema: {
			configuration: null,
			custom: null,
			items: null,
			renderer: PLAIN_TEXT,
			system: TIME_TRACKING_TYPE,
			type: TIME_TRACKING_TYPE,
		},
	};
};
