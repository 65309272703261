import uuid from 'uuid';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	FIELD_TYPE_MAP,
	DEFAULT_ATTRIBUTES,
	END_ACTION,
	END_ACTION_SUBJECT,
	END_ACTION_SUBJECT_ID,
	START_ACTION,
	START_ACTION_SUBJECT,
	START_ACTION_SUBJECT_ID,
} from './constants.tsx';
import type { EventAttributes, UpdateEvent } from './types.tsx';

/**
 * A singleton class to provide a sessionId to track update of fields
 */
class EventFlowManager {
	readonly ids = new Map<string, string>();

	extraAttributes = new Map<string, Partial<EventAttributes>>();

	reset() {
		this.ids.clear();
		this.extraAttributes.clear();
	}

	push(key: string) {
		const id = uuid.v4();
		this.ids.set(key, id);
		return id;
	}

	pull(key: string, shouldDelete = true) {
		const value = this.ids.get(key);
		if (shouldDelete) {
			this.ids.delete(key);
		}
		return value;
	}
}

export const eventFlowManager = new EventFlowManager();

/**
 * Concatenates updateSessionId, default attributes and the attributes sent through
 *  the event and/or setAttributes
 */
const concatAttributes = (
	fieldKey: string,
	updateSessionId: string | undefined,
	attributes: EventAttributes,
) => {
	const { fieldType: fieldTypeValue } = attributes;
	const extraAttributes = eventFlowManager.extraAttributes.get(fieldKey);
	const fieldType =
		fieldTypeValue && FIELD_TYPE_MAP[fieldTypeValue]
			? FIELD_TYPE_MAP[fieldTypeValue]
			: fieldTypeValue;

	return {
		updateSessionId,
		...DEFAULT_ATTRIBUTES,
		...attributes,
		...extraAttributes,
		fieldKey,
		fieldType,
	};
};

const hasError = (e: unknown): e is Error => e instanceof Error;

/**
 * Provides three events START {@link fireAnalyticsStart},
 * END {@link fireAnalyticsEnd} to track the user flow when updating
 * a field and setAttributes{@link setAttributes} to set specific
 * fields attributes
 */
const updateAnalyticsFlowHelper = {
	fireAnalyticsStart(fieldKey: string, params: UpdateEvent) {
		try {
			const sessionid = eventFlowManager.push(fieldKey);
			const attributes = concatAttributes(fieldKey, sessionid, params.attributes);

			fireUIAnalytics(
				params.analytics,
				`${START_ACTION_SUBJECT} ${START_ACTION}`,
				START_ACTION_SUBJECT_ID,
				attributes,
			);
		} catch (e: unknown) {
			log.safeErrorWithoutCustomerData(
				'issue.analytics.services.update-issue-field.fireAnalyticsStart',
				hasError(e) ? e.toString() : 'unknown error',
			);
		}
	},
	fireAnalyticsEnd(fieldKey: string, params: UpdateEvent, isEndOfSession = true) {
		try {
			const sessionid = eventFlowManager.pull(fieldKey, isEndOfSession);
			const attributes = concatAttributes(fieldKey, sessionid, params.attributes);
			if (isEndOfSession) {
				eventFlowManager.extraAttributes.delete(fieldKey);
			}

			fireTrackAnalytics(
				params.analytics,
				`${END_ACTION_SUBJECT} ${END_ACTION}`,
				END_ACTION_SUBJECT_ID,
				attributes,
			);
		} catch (e: unknown) {
			log.safeErrorWithoutCustomerData(
				'issue.analytics.services.update-issue-field.fireAnalyticsEnd',
				hasError(e) ? e.toString() : 'unknown error',
			);
		}
	},
	setAttributes(fieldKey: string, attributes: Partial<EventAttributes>) {
		eventFlowManager.extraAttributes.set(fieldKey, attributes);
	},
	reset() {
		eventFlowManager.reset();
	},
};

export const getUpdateAnalyticsFlowHelper = () => updateAnalyticsFlowHelper;
