import React from 'react';
import { MAJOR_INCIDENT } from '@atlassian/jira-major-incident/src/common/constants.tsx';
import { MajorIncidentLozenge } from '@atlassian/jira-major-incident/src/ui/major-incident-lozenge/index.tsx';
import type { MAJOR_INCIDENT_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';

type Props = JsonComponentProps<typeof MAJOR_INCIDENT_CUSTOM_FIELD_TYPE>;

const MajorIncident = ({ dataSelectorProps: { value } }: Props) =>
	value === MAJOR_INCIDENT ? <MajorIncidentLozenge /> : null;

export default MajorIncident;
