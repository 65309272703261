import type { TreeNode } from '../../model/tree/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <T extends TreeNode<string>>(
	tree: {
		[key: string]: T;
	},
	id: string,
): string[] => {
	const path: string[] = [id];
	let currentId: string = id;
	while (tree[currentId].parentId) {
		const { parentId } = tree[currentId];
		// @ts-expect-error - TS2345 - Argument of type 'Optional<string>' is not assignable to parameter of type 'string'.
		path.unshift(parentId);
		// @ts-expect-error - TS2322 - Type 'Optional<string>' is not assignable to type 'string'.
		currentId = parentId;
	}
	return path;
};
