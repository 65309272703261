import get from 'lodash/get';
import { getMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import {
	appBundleReadyMark,
	prefetchApiReadyMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import { getPrefixedMarkName } from '@atlassian/jira-providers-spa-apdex-analytics/src/transform.tsx';

const PREFIX = 'servicedesk.queues';

/**
 * @deprecated GasV2 has been cleaned up, and it has not been logging any events since July. Please replace implementation with sending GasV3 analytics: go/gasv3
 */
const triggerAnalyticsEvent =
	(_appName: string) =>
	(_eventNameSuffix: string, _eventData: unknown): Promise<void> =>
		Promise.resolve(undefined);

const triggerAnalyticsFunction = triggerAnalyticsEvent(PREFIX);

/**
 * @deprecated GasV2 has been cleaned up, and it has not been logging any events since July. Please replace implementation with sending GasV3 analytics: go/gasv3
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (eventNameSuffix: string, data: any = undefined) => {
	triggerAnalyticsFunction(eventNameSuffix, data);
};

const getName = (mark: null | PerformanceEntry) => get(mark, 'name', undefined);

export const getAgentViewMarks = (): string[] =>
	[
		appBundleReadyMark,
		prefetchApiReadyMark,
		getName(getMark('jsd.performance.profile.queues.issuetable.mount')),
		getName(getMark('jsd.performance.profile.queues.firstfield.render')),
		getName(getMark('jsd.performance.profile.queues.issues.loaded')),
		getName(getMark('jsd.performance.profile.queues.localstorage.received')),
	]
		.filter(Boolean)
		.map(getPrefixedMarkName);
