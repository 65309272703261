import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { USER_JSM_PROJECT_ROLE } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/constants.tsx';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/index.tsx';
import {
	DEVELOPER_ESCALATION,
	INCIDENTS,
	POST_INCIDENT_REVIEWS,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

// A more granularly DI-able useFieldValue for injecting just practices values.
export const usePracticesFieldValue = ({ issueKey }: { issueKey: IssueKey }) =>
	useFieldValue({ issueKey, fieldKey: SERVICEDESK_PRACTICES_TYPE });

export const useIsAgentOrProjectAdmin = () => {
	const [{ data, error }] = useServiceDeskContext();
	if (error) return false;
	return (
		data?.userRole === USER_JSM_PROJECT_ROLE.AGENT_ROLE ||
		data?.userRole === USER_JSM_PROJECT_ROLE.AGENT_PROJECT_ADMIN_ROLE
	);
};

export const useIsIssueOfIncidentsPractice = () => {
	const issueKey = useIssueKey();
	const [practices] = usePracticesFieldValue({ issueKey });
	return Array.isArray(practices) && practices?.includes(INCIDENTS);
};

export const useIsIssueOfPostIncidentReviewPractice = () => {
	const issueKey = useIssueKey();
	const [practices] = usePracticesFieldValue({ issueKey });
	return Array.isArray(practices) && practices?.includes(POST_INCIDENT_REVIEWS);
};

export const useIsIssueOfDeveloperEscalationPractice = () => {
	const issueKey = useIssueKey();
	const [practices] = usePracticesFieldValue({ issueKey });
	return Array.isArray(practices) && practices?.includes(DEVELOPER_ESCALATION);
};
