import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import log from '../../log/index.tsx';

export const localExpirableStorageProvider =
	createLocalExpirableStorageProvider('servicedesk_queues');
const TIME_BEFORE_EXPIRY = 14 * 24 * 60 * 60 * 1000; // 14 days

export const getFromLocalStorage = <T = unknown,>(key: string): T | null => {
	try {
		return localExpirableStorageProvider.get(key);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		// .get() can trigger an exception due to a bug in 'storejs', where if
		// LS is not able to be read or written, it will not initialise the store.
		log.safeWarnWithoutCustomerData(
			'servicedesk.queues.agent-view.services',
			'Local storage failure on read',
		);
		return null;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setInLocalStorage = (key: string, value: any): boolean => {
	try {
		const now = Date.now();
		localExpirableStorageProvider.set(key, value, now + TIME_BEFORE_EXPIRY);
		localExpirableStorageProvider.removeExpiredKeys();
		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		log.safeWarnWithoutCustomerData(
			'servicedesk.queues.agent-view.services',
			'Local storage failure',
		);
		return false;
	}
};
