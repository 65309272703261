import { colors } from '@atlaskit/theme';

/**
 * Default theme
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	list: {
		paddingTop: 0,
	},
	row: {
		height: 40,
		minHeight: 40,
		borderColor: colors.N40,
		borderRadius: 0,
		borderWidth: 1,
		maxDepthChildrenOperationIndicatorWidth: 8,
		activeColor: colors.T50,
		hoveredColor: colors.N20,
	},
	header: {
		height: 35,
		borderWidth: 1,
		borderColor: colors.N60,
		paddingBottom: 0,
		paddingTop: 0,
		sideMargin: 0,
		hasShadow: false,
	},
	scrollbar: {
		offset: 0,
		marginTop: 0,
		marginBottom: 0,
		paddingTop: 0,
		paddingBottom: 0,
		computeHeight: false,
	},
	shadow: {
		softenShadow: false,
		right: {
			display: 'none',
		},
		left: {
			borderWidth: 1,
			computeHeight: false,
		},
		bottom: {
			display: 'none',
		},
	},
	decal: {
		borderWidth: 0,
		borderColor: colors.N50,
	},
};
