import React from 'react';
import ErrorState from '@atlassian/jira-common-components-error-state/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	isLoading: boolean;
	onRetry: () => void;
};

function Error(props: Props) {
	const { isLoading, onRetry } = props;
	const { formatMessage } = useIntl();

	return (
		<ErrorState
			isLoading={isLoading}
			onRetry={onRetry}
			header={formatMessage(messages.errorHeader)}
			description={formatMessage(messages.errorMessage)}
		/>
	);
}

Error.defaultProps = {
	isLoading: false,
};

export default Error;
