import { RouterManager } from '@atlassian/servicedesk-frontend-routing';

export type BeforeNavigateCallbackLegacy = (fragment: string) => void;

export class ServiceDeskNavigationBridge {
	beforeNavigateCallback: BeforeNavigateCallbackLegacy | undefined;

	resolveFn: (() => void) | undefined | ((result?: Promise<undefined> | undefined) => void);

	rejectFn: (() => void) | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	promise: Promise<any> | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onBeforeNavigate = (fragment: string): Promise<any> => {
		if (!this.beforeNavigateCallback) {
			return Promise.resolve(null);
		}

		this.promise = new Promise(
			(
				resolve: (result: Promise<undefined> | undefined) => void,
				reject: (error?: Error) => void,
			) => {
				this.resolveFn = resolve;
				this.rejectFn = reject;
			},
		);

		this.beforeNavigateCallback(fragment);

		return this.promise;
	};

	cleanUpPromiseCallBack() {
		delete this.resolveFn;
		delete this.rejectFn;
	}

	confirmNavigation = () => {
		this.resolveFn && this.resolveFn();
		this.cleanUpPromiseCallBack();
	};

	rejectNavigation = () => {
		this.rejectFn && this.rejectFn();
		this.cleanUpPromiseCallBack();
	};

	/**
	 * Calling setBeforeNavigateListener is deferred to here, so that we don't go through a state
	 * where setBeforeNavigateListener fails to register the new listener because there is already
	 * a listener from another page that hasn't finished cleaning up yet.
	 * This is because an instance of the old page and the new page will both be active during a
	 * navigation. I.e. we need componentWillUnmount of the old page to call #disable before setting
	 * the new listener.
	 */
	block(beforeNavigateCallback: BeforeNavigateCallbackLegacy) {
		RouterManager.deleteBeforeNavigateListener();
		RouterManager.setBeforeNavigateListener(this.onBeforeNavigate);

		this.beforeNavigateCallback = beforeNavigateCallback;
	}

	unblock() {
		this.beforeNavigateCallback = undefined;
	}

	disable = () => {
		RouterManager.deleteBeforeNavigateListener();
	};
}

export const createMonolithNavigationBridge = () => new ServiceDeskNavigationBridge();
