import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AssociatedIssue, ServerAssociatedIssue } from './associated-issue-type.tsx';
import type { NormalizedIssue } from './issue-type.tsx';

export const INWARD_LINK_DIRECTION = 'INWARD' as const;
export const OUTWARD_LINK_DIRECTION = 'OUTWARD' as const;
export const BOTH_LINK_DIRECTION = 'BOTH' as const;

export type ServerIssueLinkType = {
	id: string;
	name: string;
	outward: string;
	inward: string;
	self?: string;
};

/**
 * Defines the issue link returned by the legacy GraphQL.
 */
export type ServerIssueLink = {
	id: string;
	type: ServerIssueLinkType;
	outwardIssue: ServerAssociatedIssue | null;
	inwardIssue: ServerAssociatedIssue | null;
	self?: string;
};
export type ServerIssueLinks = (ServerIssueLink | null | undefined)[];

export type IssueLinkDirection =
	| typeof INWARD_LINK_DIRECTION
	| typeof OUTWARD_LINK_DIRECTION
	| typeof BOTH_LINK_DIRECTION;

export const IssueLinkDirectionTypes = {
	[INWARD_LINK_DIRECTION]: INWARD_LINK_DIRECTION,
	[OUTWARD_LINK_DIRECTION]: OUTWARD_LINK_DIRECTION,
	[BOTH_LINK_DIRECTION]: BOTH_LINK_DIRECTION,
} as const;

/**
 * Defines the shape from transforming `ServerIssueLinkType`.
 *
 * See: `transformIssueLinkTypes()` in `issue-view/issue-links-type-transformer`
 */
export type IssueLinkType = {
	id: string;
	label: string;
	direction: IssueLinkDirection;
	// isOutward only exists in Plan's Program Board
	isOutward?: boolean;
};

export type IssueLinkTypes = {
	isIssueLinkingEnabled: boolean;
	issueLinkTypes: ServerIssueLinkType[];
};

/**
 * A type defining the UI state used by issue link line card
 */
export type IssueLinkUIState = {
	isLoading?: boolean;
	hasError?: boolean;
	isSaved?: boolean;
	isDeleted?: boolean;
	isOptimistic?: boolean;
};

/**
 * A type defining the actual link between two issues
 */
export type IssueLink = {
	id: IssueId;
	direction: IssueLinkDirection;
	linkedIssueKey: string;
	typeName: string;
	typeId: string;
} & IssueLinkUIState;

/**
 * An issue linked to another issue
 */
export type LinkedIssue = AssociatedIssue & {
	issueKey: string; // Make it required since `issueKey` can be optional in top-level issues
	issueProjectType?: string; // Optional issue project type for incident management analytics usage.
	issueItsmPractice?: string;
};

export type NormalizedIssueLinks = NormalizedIssue<IssueLink>;
export type NormalizedLinkedIssues = NormalizedIssue<LinkedIssue>;

export type SimilarIssueLink = {
	id: string;
	iconUrl: string;
	value: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fullObject: any;
};

export type IssueLinksMeta = {
	issueKey: string;
	isResolved: boolean;
};

/**
 * A mapping of counts for IssueLinks by typeName
 */
export type IssueLinks = {
	issueLinks: NormalizedIssueLinks;
	linkedIssues: NormalizedLinkedIssues;
	linkedIssueKeys?: IssueKey[];
	linkedIssueMetas?: IssueLinksMeta[];
	isOpenedFromJsmSimilarIssues?: boolean;
	quickAddClicked?: number;
	quickAddClickedIssue?: SimilarIssueLink;
	deleteModalLinkId?: string;
	canCreateLinkedIssue?: boolean;
	aiLinkedIssueSuggestion?: boolean;
};
