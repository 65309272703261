// EXPERIENCE_TRACKING_RESET

export const EXPERIENCE_TRACKING_RESET =
	'state.actions.experience-tracking.EXPERIENCE_TRACKING_RESET' as const;

export type ExperienceTrackingResetAction = {
	type: typeof EXPERIENCE_TRACKING_RESET;
};

export const experienceTrackingResetAction = (): ExperienceTrackingResetAction => ({
	type: EXPERIENCE_TRACKING_RESET,
});
