import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/switchMap';
import { UPDATE_COLUMNS, initializeColumnAction } from '../../../state/actions/columns/index.tsx';
import { getJsonColumnConfiguration } from '../../../state/selectors/table-props/columns/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const initializeActionDispatchedFields: {
		[key: string]: Set<string>;
	} = {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (action$: ActionsObservable<any>) =>
		action$.ofType(UPDATE_COLUMNS).switchMap((action) => {
			const initializationColumnsArray = action.payload.columns
				// @ts-expect-error - TS7006 - Parameter 'columnProp' implicitly has an 'any' type.
				.filter((columnProp) => {
					const fieldConfiguration = getJsonColumnConfiguration(columnProp.fieldType);
					return fieldConfiguration && fieldConfiguration.needsInitialization;
				})
				// @ts-expect-error - TS7006 - Parameter 'columnProp' implicitly has an 'any' type.
				.filter((columnProp) => {
					const fieldIdSet: Set<string> | undefined =
						initializeActionDispatchedFields[columnProp.fieldType];
					const needsInitialize = !fieldIdSet || !fieldIdSet.has(columnProp.id);
					if (fieldIdSet) {
						fieldIdSet.add(columnProp.id);
					} else {
						initializeActionDispatchedFields[columnProp.fieldType] = new Set([columnProp.id]);
					}
					return needsInitialize;
				})
				.map(initializeColumnAction);
			return Observable.from(initializationColumnsArray);
		});
};
