import React, { useMemo } from 'react';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import type { Section } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import ExpandableMenuItemTrigger from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/expandable-menu-item-trigger-with-analytics/index.tsx';
import MenuLinkItem, {
	type MenuLinkItemWithAnalyticsProps,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/menu-link-item-with-analytics/index.tsx';
import { useGetLinkProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-link-props/index.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	MenuList,
	MenuSection,
	Divider,
	MenuListItem,
} from '@atlassian/navigation-system';
import type { ForgeItemPropsWithAnalyticsItemId } from '../../../types.tsx';
import { ForgeIcon } from '../forge-icon/index.tsx';

type ItemProps = {
	id: string;
	url: string;
	section: Section;
	showIcon: boolean;
	analytics: MenuLinkItemWithAnalyticsProps['analytics'];
};

const createPageUrl = (extensionBaseUrl: string, route: string) =>
	route ? `${extensionBaseUrl}/${String(route).trim().replace(/^\/+/, '')}` : extensionBaseUrl;

const ForgeItemExpandableWithHeader = (props: ItemProps) => {
	const { getLinkProps } = useGetLinkProps();
	const { showIcon, id: itemId, url, section, analytics } = props;

	const subItemAnalytics = useMemo(
		() =>
			analytics && {
				...analytics,
				level: analytics && analytics.level + 1,
			},
		[analytics],
	);

	return (
		<MenuList>
			<ExpandableMenuItem isDefaultExpanded>
				<ExpandableMenuItemTrigger analytics={analytics}>
					{section.header}
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					{section.pages.map((page) => (
						<MenuLinkItem
							key={page.route}
							elemBefore={showIcon ? <ForgeIcon url={page.icon} /> : undefined}
							{...getLinkProps(createPageUrl(url, page.route))}
							analytics={subItemAnalytics}
							onClick={() => {
								forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
							}}
						>
							{page.title}
						</MenuLinkItem>
					))}
				</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		</MenuList>
	);
};

const ForgeItemExpandableWithoutHeader = (props: ItemProps) => {
	const { getLinkProps } = useGetLinkProps();
	const { showIcon, id: itemId, url, section, analytics } = props;

	return (
		<MenuList>
			{section.pages.map((page) => (
				<MenuLinkItem
					key={page.route}
					elemBefore={showIcon ? <ForgeIcon url={page.icon} /> : undefined}
					{...getLinkProps(createPageUrl(url, page.route))}
					analytics={analytics}
					onClick={() => {
						forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
					}}
				>
					{page.title}
				</MenuLinkItem>
			))}
		</MenuList>
	);
};

export const ForgeItemExpandable = (props: ForgeItemPropsWithAnalyticsItemId) => {
	const { showIcon, id: itemId, url, sections, analytics } = props;
	const hasSectionsWithHeader = sections?.some((section) => !!section.header);

	return sections?.map((section: Section, index: number) => (
		<MenuListItem key={index}>
			<MenuSection>
				{typeof section.header === 'string' ? (
					<ForgeItemExpandableWithHeader
						{...{ id: itemId, section, url, showIcon }}
						analytics={analytics}
					/>
				) : (
					<>
						{!hasSectionsWithHeader && index !== 0 && <Divider />}
						<ForgeItemExpandableWithoutHeader
							{...{ id: itemId, section, url, showIcon }}
							analytics={analytics}
						/>
					</>
				)}
			</MenuSection>
		</MenuListItem>
	));
};
