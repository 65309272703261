export const CLEAR_ACTIVE_ITEM = 'ops.items.active.clear.CLEAR_ACTIVE_ITEM' as const;

export type ClearActiveItemAction = {
	type: typeof CLEAR_ACTIVE_ITEM;
	payload: undefined;
};

export const clear = (): ClearActiveItemAction => ({
	type: CLEAR_ACTIVE_ITEM,
	payload: undefined,
});
