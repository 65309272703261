import type { Observable } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-servicedesk-common/src/utils/fetch/index.tsx';
import type {
	QueueCategory,
	QueueId,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';

export type IssueListFetchArgs = {
	projectKey: string;
	baseUrl: string;
	jql: string;
	columnNames: string[];
	columnTypesAsJson: string[];
	startIndex: number;
	issuesPerPage: number;
	orderBy: string | undefined;
	isAscendingOrder: boolean;
	category?: QueueCategory;
	queueId?: QueueId;
	shouldDeleteSorting?: boolean;
	filterClause: string | null;
};

export type UpdatedFetchArgs = IssueListFetchArgs & {
	issueHash: string;
};

const postParams = (
	jql: string,
	columnNames: Array<string>,
	columnTypesAsJson: Array<string>,
	startIndex: number,
	issuesPerPage: number,
	currentIssueHash: null | string,
	orderBy: undefined | string,
	isAscendingOrder: boolean,
	category: undefined | string | QueueCategory,
	filterClause: null | string,
	queueId?: QueueId,
	shouldDeleteSorting?: boolean,
) => ({
	jql,
	columnNames,
	columnTypesAsJson,
	startIndex,
	issuesPerPage,
	currentIssueHash: currentIssueHash || '',
	...(orderBy
		? {
				orderBy,
				isAscendingOrder,
			}
		: {}),
	...(category && category !== NO_CATEGORY
		? {
				category,
			}
		: {}),
	...{ filterClause },
	queueId,
	shouldDeleteSorting,
});

export const switchingFetch = (
	args: IssueListFetchArgs | UpdatedFetchArgs,
): Observable<IssueListResponse> => {
	const {
		projectKey,
		baseUrl,
		jql,
		columnNames,
		columnTypesAsJson,
		startIndex,
		issuesPerPage,
		orderBy,
		isAscendingOrder,
		category,
		queueId,
		filterClause,
		shouldDeleteSorting,
	} = args;
	// @ts-expect-error - TS2339 - Property 'issueHash' does not exist on type 'IssueListFetchArgs | UpdatedFetchArgs'.
	const issueHash = args.issueHash || null;

	const fetchUrl = `${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/issuelist${
		issueHash ? '/updated' : ''
	}`;

	const fetchOptions = {
		method: 'POST',
		body: JSON.stringify(
			postParams(
				jql,
				columnNames,
				columnTypesAsJson,
				startIndex,
				issuesPerPage,
				issueHash,
				orderBy,
				isAscendingOrder,
				category,
				filterClause,
				queueId,
				shouldDeleteSorting,
			),
		),
	};

	return fetchJson$(fetchUrl, fetchOptions);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (args: IssueListFetchArgs) => switchingFetch(args);
