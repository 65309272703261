import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { getAppProps } from '../../../../state/selectors/common/index.tsx';
import { BoardPromoSpotlightView } from './view.tsx';

const mapStateToProps = (state: State) => ({
	isAdmin: getAppProps(state).isAdmin || getAppProps(state).isJiraAdmin,
	projectKey: getAppProps(state).projectKey,
});

export default connect(mapStateToProps, {})(BoardPromoSpotlightView);
