import { useCallback, useEffect } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { performGetRequest } from '@atlassian/jira-servicedesk-common/src/utils/fetch/requests.tsx';
import {
	type ItsmPractice,
	NO_CATEGORY,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { PracticeRequestTypesResponse } from './types.tsx';

const usePracticeRequestTypesService = (projectId: number, practice: ItsmPractice) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const request = useCallback(
		() =>
			performGetRequest(
				`/rest/servicedesk/1/servicedesk/${projectId}/request-types?practices=${practice}`,
			),
		[projectId, practice],
	);
	const { data, loading, error, fetch } = useService<PracticeRequestTypesResponse>(request);

	useEffect(() => {
		if (practice !== NO_CATEGORY) {
			fetch();
		}
	}, [practice, fetch]);

	useEffect(() => {
		if (data) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'practiceRequestTypes fetched');
		}
	}, [data, createAnalyticsEvent]);

	useEffect(() => {
		if (error) {
			fireErrorAnalytics({
				meta: {
					id: 'practiceRequestTypes',
					packageName: 'jiraServicedeskQueuesCommon',
				},
				error,
				event: createAnalyticsEvent({}),
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [error, createAnalyticsEvent]);

	return {
		data,
		loading,
		error,
	};
};

export default usePracticeRequestTypesService;
