import React from 'react';
import { useIsItsmSampleProject } from '../../services/is-itsm-sample-project/index.tsx';

export const withShouldRenderWithinItsmSampleProject = <
	Props,
	Component extends Flow.AbstractComponent<Props>,
>(
	WrappedComponent: Component,
	shouldRenderWithinItsmSampleProject: boolean,
): Flow.AbstractComponent<Props> => {
	const WithShouldRenderWithinItsmSampleProject = (props: Props) => {
		const { data: isItsmSampleProject } = useIsItsmSampleProject();
		const shouldRender = shouldRenderWithinItsmSampleProject === isItsmSampleProject;
		// @ts-expect-error - TS2322 - Type 'Props' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<Component, PropsWithChildren<PropsWithoutRef<Props> & RefAttributes<any>>>'.
		return shouldRender && <WrappedComponent {...props} />;
	};

	WithShouldRenderWithinItsmSampleProject.displayName = `WithShouldRenderWithinItsmSampleProject(${
		WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'
	})`;

	// @ts-expect-error - TS2322 - Type '{ (props: Props): false | JSX.Element; displayName: string; }' is not assignable to type 'AbstractComponent<Props, any>'.
	return WithShouldRenderWithinItsmSampleProject;
};
