import { appendOperationQuery } from '../../common/utils/append-operation-query.tsx';
import type { IssueViewFetchOperations } from '../../types.tsx';
import getItemDataGraphQlQuery from './fragments/index.tsx';

export const getGraphQlUrl = (baseUrl: string, operation?: IssueViewFetchOperations): string =>
	`${baseUrl}/rest/graphql/1/${appendOperationQuery(operation)}`;

export const getFieldDataOptions = (issueKey: string, projectKey: string) => ({
	method: 'POST',
	body: JSON.stringify({
		query: getItemDataGraphQlQuery(issueKey, projectKey),
	}),
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { gqlTagRest } from './fragments';
