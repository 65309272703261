import { combineReducers } from 'redux';
import analytic from './analytic/index.tsx';
import experienceTracking from './experience-tracking/index.tsx';
import filter from './filter/index.tsx';
import issues from './issues/index.tsx';
import practices from './practices/index.tsx';
import queue from './queue/index.tsx';
import ssrIssuesData from './ssr-issues-data/index.tsx';
import updateMetadata from './update-metadata/index.tsx';

const combinedReducer = combineReducers({
	issues,
	queue,
	updateMetadata,
	experienceTracking,
	practices,
	filter,
	analytic,
	ssrIssuesData,
});
export default combinedReducer;
