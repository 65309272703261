import type { NavigateCell } from '../../../model/navigation/index.tsx';

export const NAVIGATE_TO_CELL = 'ops.cells.navigate.NAVIGATE_TO_CELL' as const;

export type NavigateToCellAction = {
	type: typeof NAVIGATE_TO_CELL;
	payload: NavigateCell;
};

export const navigateToCell = (payload: NavigateCell): NavigateToCellAction => ({
	type: NAVIGATE_TO_CELL,
	payload,
});
