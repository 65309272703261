import React, { Component, type ReactNode, type ComponentType } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { type AppName, toPackageName } from '../../../utils/app-names/index.tsx';

export type Props = {
	appName: AppName;
	children: ReactNode | null;
	ErrorView?: ComponentType<{}>;
	onErrorHandler?: (arg1: string | Error) => undefined | undefined;
};

type State = {
	hasError: boolean;
};

/**
 * @deprecated GasV2 has been cleaned up, and it has not been logging any events since July. Please replace implementation with sending GasV3 analytics: go/gasv3
 */
const triggerAnalyticsEvent =
	(_appName: string) =>
	(_eventNameSuffix: string, _eventData: unknown): Promise<void> =>
		Promise.resolve(undefined);

const toErrorOrUndefined = (error: string | Error): Error | undefined => {
	if (error instanceof Error) {
		return error;
	}

	if (typeof error === 'string') {
		return new Error(error);
	}

	return undefined;
};

/**
 * @deprecated Use <JSErrorBoundary/> from '@atlassian/jira-error-boundaries' instead.
 * Ideally with following props
 *  <JSErrorBoundary
 *      id={`routeApp.${String(route.name)}`}
 *      packageName="jiraSpa"
 *      sendToPrivacyUnsafeSplunk
 *  />
 * in order to be compatible with the new recommended <ServiceDeskAppBase/>
 * https://hello.atlassian.net/wiki/spaces/~103943786/pages/1056129074/Jira%2Broutes%2Bnow%2Bwith%2Bfree%2Bbespoke%2Berror%2Breporting
 */
export class ServiceDeskErrorBoundary extends Component<Props, State> {
	static getDerivedStateFromError() {
		return { hasError: true };
	}

	constructor(props: Props) {
		super(props);
		this.triggerEvent = triggerAnalyticsEvent(`${props.appName}`);
	}

	state = { hasError: false };

	componentDidCatch(error: string | Error) {
		const { onErrorHandler, ErrorView, appName } = this.props;

		const data =
			error instanceof Error ? { message: error.message, stack: error.stack } : { message: error };
		this.triggerEvent('error-boundary.error-caught', data);

		fireErrorAnalytics({
			meta: {
				id: 'unhandled',
				packageName: toPackageName(appName) || 'jiraServicedeskCommon',
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			event: this.triggerEvent as unknown as UIAnalyticsEvent,
			error: toErrorOrUndefined(error),
			sendToPrivacyUnsafeSplunk: true,
		});

		// If error view is present then allow error view to handle errors.
		if (ErrorView) {
			return;
		}

		// Some of the non spa apps uses this call back function which doesn't have any error view
		if (onErrorHandler) {
			onErrorHandler(error);
		} else {
			// Rethrow for error boundary handling by app-base
			throw error;
		}
	}

	triggerEvent: (eventName: string, data?: unknown) => void;

	render() {
		const { hasError } = this.state;
		const { ErrorView, children } = this.props;

		if (hasError && ErrorView) {
			return <ErrorView />;
		}

		return children;
	}
}
