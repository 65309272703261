import memoizeOne from 'memoize-one';
import type {
	Field,
	FieldsState,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	AFFECTED_SERVICES_GQL_FIELD,
	BOOLEAN_GQL_FIELD,
	CASCADING_SELECT_GQL_FIELD,
	CHECKBOX_GQL_FIELD,
	COMPONENTS_GQL_FIELD,
	COLOR_GQL_FIELD,
	CONNECT_DATE_TIME_GQL_FIELD,
	CONNECT_RICH_TEXT_GQL_FIELD,
	CONNECT_TEXT_GQL_FIELD,
	CONNECT_NUMBER_GQL_FIELD,
	CONNECT_MULTI_SELECT_GQL_FIELD,
	CONNECT_SINGLE_SELECT_GQL_FIELD,
	DATE_PICKER_GQL_FIELD,
	DATE_TIME_GQL_FIELD,
	EPIC_LINK_GQL_FIELD,
	FLAG_GQL_FIELD,
	ISSUE_RESTRICTION_GQL_FIELD,
	ISSUE_TYPE_GQL_FIELD,
	JSM_ASSETS_GQL_FIELD,
	JSM_ENTITLEMENTS_GQL_FIELD,
	JSM_SENTIMENT_GQL_FIELD,
	JSM_PEOPLE_GQL_FIELD,
	JSM_REQUEST_FEEDBACK_GQL_FIELD,
	JSM_REQUEST_LANGUAGE_GQL_FIELD,
	JSM_ORGANIZATION_GQL_FIELD,
	JSM_REQUEST_TYPE_GQL_FIELD,
	LABEL_GQL_FIELD,
	MULTI_GROUP_PICKER_GQL_FIELD,
	MULTI_SELECT_GQL_FIELD,
	NUMBER_GQL_FIELD,
	ORIGINAL_ESTIMATE_GQL_FIELD,
	PEOPLE_GQL_FIELD,
	PRIORITY_GQL_FIELD,
	RADIO_GQL_FIELD,
	PROJECT_GQL_FIELD,
	RICH_TEXT_GQL_FIELD,
	RESOLUTION_GQL_FIELD,
	SECURITY_GQL_FIELD,
	SINGLE_GROUP_PICKER_GQL_FIELD,
	SINGLE_SELECT_GQL_FIELD,
	SPRINT_GQL_FIELD,
	STATUS_GQL_FIELD,
	TEAM_VIEW_GQL_FIELD,
	TEAM_GQL_FIELD,
	TEXT_GQL_FIELD,
	TIME_TRACKING_GQL_FIELD,
	URL_GQL_FIELD,
	USER_PICKER_GQL_FIELD,
	VERSION_GQL_FIELD,
	MULTI_USER_PICKER_GQL_FIELD,
	VOTES_GQL_FIELD,
	FORGE_STRING_GQL_FIELD,
	FORGE_STRINGS_GQL_FIELD,
	FORGE_NUMBER_GQL_FIELD,
	FORGE_DATETIME_GQL_FIELD,
	FORGE_OBJECT_GQL_FIELD,
	FORGE_GROUP_GQL_FIELD,
	FORGE_USER_GQL_FIELD,
	FORGE_GROUPS_GQL_FIELD,
	FORGE_USERS_GQL_FIELD,
	MULTI_VERSION_GQL_FIELD,
	WATCHES_GQL_FIELD,
	JSM_APPROVAL_GQL_FIELD,
	JSM_RESPONDERS_GQL_FIELD,
	JSM_MAJOR_INCIDENT_FIELD,
	PROFORMA_FORMS_GQL_FIELD,
	CMDB_GQL_FIELD,
	FORGE_DATE_GQL_FIELD,
	DATA_CLASSIFICATION_FIELD,
	GOALS_GQL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggFieldConnectionEdge } from '../types/issue-type.tsx';
import type {
	AggFieldsTransformerFunction,
	AggTransformerFunction,
	AggTransformerFunctionMap,
} from '../types/transformer-type.tsx';
import { transformAggAffectedServicesToLegacyField } from './affected-services-field-transformer.tsx';
import { transformAggAssetToLegacyField } from './asset-field-transformer.tsx';
import { transformAggBooleanToLegacyField } from './boolean-field-transformer.tsx';
import { transformAggCascadingSelectToLegacyField } from './cascading-select-field-transformer.tsx';
import { transformAggCheckboxToLegacyField } from './checkbox-field-transformer.tsx';
import { transformAggCMDBToLegacyField } from './cmdb-field-transformer.tsx';
import { transformAggColorToLegacyField } from './color-field-transformer.tsx';
import { transformAggComponentsToLegacyField } from './components-field-transformer.tsx';
import { transformAggConnectDateTimeToLegacyField } from './connect-date-time-field-transformer.tsx';
import { transformAggConnectMultipleSelectToLegacyField } from './connect-multiple-select-field-transformer.tsx';
import { transformAggConnectNumberToLegacyField } from './connect-number-field-transformer.tsx';
import { transformAggConnectRichTextToLegacyField } from './connect-rich-text-field-transformer.tsx';
import { transformAggConnectSingleSelectToLegacyField } from './connect-single-select-field-transformer.tsx';
import { transformAggConnectTextToLegacyField } from './connect-text-field-transformer.tsx';
import { transformAggDataClassificationToLegacyField } from './data-classification-level-transformer.tsx';
import { transformAggDatePickerToLegacyField } from './date-picker-field-transformer.tsx';
import { transformAggDateTimePickerToLegacyField } from './date-time-picker-field-transformer.tsx';
import { transformAggEpicLinkToLegacyField } from './epic-link-field-transformer.tsx';
import { transformAggFlagToLegacyField } from './flag-field-transformer.tsx';
import { transformAggForgeDateToLegacyField } from './forge-date-field-transformer.tsx';
import { transformAggForgeDateTimeToLegacyField } from './forge-datetime-field-transformer.tsx';
import { transformAggForgeGroupToLegacyField } from './forge-group-field-transformer.tsx';
import { transformAggForgeGroupsToLegacyField } from './forge-groups-field-transformer.tsx';
import { transformAggForgeNumberToLegacyField } from './forge-number-field-transformer.tsx';
import { transformAggForgeObjectToLegacyField } from './forge-object-field-transformer.tsx';
import { transformAggForgeStringToLegacyField } from './forge-string-field-transformer.tsx';
import { transformAggForgeStringsToLegacyField } from './forge-strings-field-transformer.tsx';
import { transformAggForgeUserToLegacyField } from './forge-user-field-transformer.tsx';
import { transformAggForgeUsersToLegacyField } from './forge-users-field-transformer.tsx';
import { transformAggGoalsToLegacyField } from './goals-field-transformer.tsx';
import { transformAggIssueRestrictionToLegacyField } from './issue-restriction-field-transformer.tsx';
import { transformAggIssueTypeToLegacyField } from './issue-type-field-transformer.tsx';
import { transformAggJSMApprovalsToLegacyField } from './jsm-approvals-field-transformer.tsx';
import { transformAggJSMEntitlementsToLegacyField } from './jsm-entitlements-field-transformer.tsx';
import { transformAggJSMMajorIncidentToLegacyField } from './jsm-major-incident-field-transformer.tsx';
import { transformAggJSMOrganizationToLegacyField } from './jsm-organization-field-transformer.tsx';
import { transformAggJSMRequestFeedbackToLegacyField } from './jsm-request-feedback-transformer.tsx';
import { transformAggJSMRequestLanguageToLegacyField } from './jsm-request-language-transformer.tsx';
import { transformAggJSMRequestTypeToLegacyField } from './jsm-request-type-field-transformer.tsx';
import { transformAggJSMRespondersToLegacyField } from './jsm-responders-field-transformer.tsx';
import { transformAggJSMSentimentToLegacyField } from './jsm-sentiment-field-transformer.tsx';
import { transformAggLabelsNodeToLegacyField } from './labels-field-transformer.tsx';
import { transformAggMultiGroupPickerToLegacyField } from './multiple-group-picker-field-transformer.tsx';
import { transformAggMultipleSelectToLegacyField } from './multiple-select-field-transformer.tsx';
import { transformAggMultipleUserPickerToLegacyField } from './multiple-user-picker-field-transformer.tsx';
import { transformAggMultipleVersionPickerToLegacyField } from './multiple-version-picker-field-transformer.tsx';
import { transformAggNumberToLegacyField } from './number-field-transformer.tsx';
import { transformAggOriginalEstimateToLegacyField } from './original-estimate-field-transformer.tsx';
import { transformAggPriorityToLegacyField } from './priority-field-transformer.tsx';
import { transformAggProformaFormsToLegacyField } from './proforma-forms-field-transformer.tsx';
import { transformAggProjectToLegacyField } from './project-field-transformer.tsx';
import { transformAggRadioSelectToLegacyField } from './radio-select-field-transformer.tsx';
import { transformAggResolutionToLegacyField } from './resolution-field-transformer.tsx';
import { transformAggRichTextToLegacyField } from './rich-text-field-transformer.tsx';
import { transformAggSecurityLevelToLegacyField } from './security-level-field-transformer.tsx';
import { transformAggSingleGroupPickerToLegacyField } from './single-group-picker-field-transformer.tsx';
import { transformAggSingleLineTextToLegacyField } from './single-line-text-field-transformer.tsx';
import { transformAggSingleSelectToLegacyField } from './single-select-field-transformer.tsx';
import { transformAggSingleSelectUserPickerToLegacyField } from './single-select-user-picker-field-transformer.tsx';
import { transformAggSingleVersionPickerToLegacyField } from './single-version-picker-field-transformer.tsx';
import { transformAggSprintToLegacyField } from './sprint-field-transformer.tsx';
import { transformAggStatusToLegacyField } from './status-field-transformer.tsx';
import { transformAggTeamToLegacyField } from './team-field-transformer.tsx';
import { transformAggTeamViewToLegacyField } from './team-view-field-transformer.tsx';
import { transformAggTimeTrackingToLegacyField } from './time-tracking-field-transformer.tsx';
import { transformAggUrlToLegacyField } from './url-field-transformer.tsx';
import { transformAggVotesToLegacyField } from './votes-field-transformer.tsx';
import { transformAggWatchesToLegacyField } from './watches-field-transformer.tsx';

// We're memoising this just in case we FF another field
const getTransformersNonMemoised = (): AggTransformerFunctionMap => ({
	[AFFECTED_SERVICES_GQL_FIELD]: transformAggAffectedServicesToLegacyField,
	[BOOLEAN_GQL_FIELD]: transformAggBooleanToLegacyField,
	[CASCADING_SELECT_GQL_FIELD]: transformAggCascadingSelectToLegacyField,
	[CHECKBOX_GQL_FIELD]: transformAggCheckboxToLegacyField,
	[CMDB_GQL_FIELD]: transformAggCMDBToLegacyField,
	[MULTI_SELECT_GQL_FIELD]: transformAggMultipleSelectToLegacyField,
	[COMPONENTS_GQL_FIELD]: transformAggComponentsToLegacyField,
	[CONNECT_DATE_TIME_GQL_FIELD]: transformAggConnectDateTimeToLegacyField,
	[CONNECT_MULTI_SELECT_GQL_FIELD]: transformAggConnectMultipleSelectToLegacyField,
	[CONNECT_NUMBER_GQL_FIELD]: transformAggConnectNumberToLegacyField,
	[CONNECT_RICH_TEXT_GQL_FIELD]: transformAggConnectRichTextToLegacyField,
	[CONNECT_SINGLE_SELECT_GQL_FIELD]: transformAggConnectSingleSelectToLegacyField,
	[CONNECT_TEXT_GQL_FIELD]: transformAggConnectTextToLegacyField,
	[COLOR_GQL_FIELD]: transformAggColorToLegacyField,
	[DATE_PICKER_GQL_FIELD]: transformAggDatePickerToLegacyField,
	[DATE_TIME_GQL_FIELD]: transformAggDateTimePickerToLegacyField,
	[EPIC_LINK_GQL_FIELD]: transformAggEpicLinkToLegacyField,
	[FLAG_GQL_FIELD]: transformAggFlagToLegacyField,
	[FORGE_DATETIME_GQL_FIELD]: transformAggForgeDateTimeToLegacyField,
	[FORGE_DATE_GQL_FIELD]: transformAggForgeDateToLegacyField,
	[FORGE_GROUP_GQL_FIELD]: transformAggForgeGroupToLegacyField,
	[FORGE_GROUPS_GQL_FIELD]: transformAggForgeGroupsToLegacyField,
	[FORGE_NUMBER_GQL_FIELD]: transformAggForgeNumberToLegacyField,
	[FORGE_OBJECT_GQL_FIELD]: transformAggForgeObjectToLegacyField,
	[FORGE_STRING_GQL_FIELD]: transformAggForgeStringToLegacyField,
	[FORGE_STRINGS_GQL_FIELD]: transformAggForgeStringsToLegacyField,
	[FORGE_USER_GQL_FIELD]: transformAggForgeUserToLegacyField,
	[FORGE_USERS_GQL_FIELD]: transformAggForgeUsersToLegacyField,
	[ISSUE_RESTRICTION_GQL_FIELD]: transformAggIssueRestrictionToLegacyField,
	[ISSUE_TYPE_GQL_FIELD]: transformAggIssueTypeToLegacyField,
	[JSM_APPROVAL_GQL_FIELD]: transformAggJSMApprovalsToLegacyField,
	[JSM_ASSETS_GQL_FIELD]: transformAggAssetToLegacyField,
	[JSM_MAJOR_INCIDENT_FIELD]: transformAggJSMMajorIncidentToLegacyField,
	[JSM_ORGANIZATION_GQL_FIELD]: transformAggJSMOrganizationToLegacyField,
	[JSM_ENTITLEMENTS_GQL_FIELD]: transformAggJSMEntitlementsToLegacyField,
	[JSM_SENTIMENT_GQL_FIELD]: transformAggJSMSentimentToLegacyField,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	[JSM_PEOPLE_GQL_FIELD]: transformAggMultipleUserPickerToLegacyField as AggTransformerFunction<
		typeof JSM_PEOPLE_GQL_FIELD
	>,
	[JSM_REQUEST_FEEDBACK_GQL_FIELD]: transformAggJSMRequestFeedbackToLegacyField,
	[JSM_REQUEST_LANGUAGE_GQL_FIELD]: transformAggJSMRequestLanguageToLegacyField,
	[JSM_REQUEST_TYPE_GQL_FIELD]: transformAggJSMRequestTypeToLegacyField,
	[JSM_RESPONDERS_GQL_FIELD]: transformAggJSMRespondersToLegacyField,
	[LABEL_GQL_FIELD]: transformAggLabelsNodeToLegacyField,
	[PRIORITY_GQL_FIELD]: transformAggPriorityToLegacyField,
	[MULTI_GROUP_PICKER_GQL_FIELD]: transformAggMultiGroupPickerToLegacyField,
	[MULTI_USER_PICKER_GQL_FIELD]:
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		transformAggMultipleUserPickerToLegacyField as AggTransformerFunction<
			typeof MULTI_USER_PICKER_GQL_FIELD
		>,
	[NUMBER_GQL_FIELD]: transformAggNumberToLegacyField,
	[ORIGINAL_ESTIMATE_GQL_FIELD]: transformAggOriginalEstimateToLegacyField,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	[PEOPLE_GQL_FIELD]: transformAggMultipleUserPickerToLegacyField as AggTransformerFunction<
		typeof PEOPLE_GQL_FIELD
	>,
	[PROFORMA_FORMS_GQL_FIELD]: transformAggProformaFormsToLegacyField,
	[PROJECT_GQL_FIELD]: transformAggProjectToLegacyField,
	[MULTI_VERSION_GQL_FIELD]: transformAggMultipleVersionPickerToLegacyField,
	[RADIO_GQL_FIELD]: transformAggRadioSelectToLegacyField,
	[RICH_TEXT_GQL_FIELD]: transformAggRichTextToLegacyField,
	[RESOLUTION_GQL_FIELD]: transformAggResolutionToLegacyField,
	[SECURITY_GQL_FIELD]: transformAggSecurityLevelToLegacyField,
	[SPRINT_GQL_FIELD]: transformAggSprintToLegacyField,
	[STATUS_GQL_FIELD]: transformAggStatusToLegacyField,
	[SINGLE_GROUP_PICKER_GQL_FIELD]: transformAggSingleGroupPickerToLegacyField,
	[SINGLE_SELECT_GQL_FIELD]: transformAggSingleSelectToLegacyField,
	[TEAM_GQL_FIELD]: transformAggTeamToLegacyField,
	[TEAM_VIEW_GQL_FIELD]: transformAggTeamViewToLegacyField,
	[TEXT_GQL_FIELD]: transformAggSingleLineTextToLegacyField,
	[TIME_TRACKING_GQL_FIELD]: transformAggTimeTrackingToLegacyField,
	[USER_PICKER_GQL_FIELD]: transformAggSingleSelectUserPickerToLegacyField,
	[URL_GQL_FIELD]: transformAggUrlToLegacyField,
	[VERSION_GQL_FIELD]: transformAggSingleVersionPickerToLegacyField,
	[VOTES_GQL_FIELD]: transformAggVotesToLegacyField,
	[WATCHES_GQL_FIELD]: transformAggWatchesToLegacyField,
	[DATA_CLASSIFICATION_FIELD]: transformAggDataClassificationToLegacyField,
	[GOALS_GQL_FIELD]: transformAggGoalsToLegacyField,
});

const getTransformers = memoizeOne(getTransformersNonMemoised);

export const transformAggFieldToLegacyField: AggTransformerFunction = (node, context) => {
	const typename = node.__typename;
	const transformer = getTransformers()[typename];

	return transformer
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(transformer as AggTransformerFunction<typeof typename>)(node, context)
		: undefined;
};

export const transformAggFieldsToLegacyFields: AggFieldsTransformerFunction = (fields) => {
	if (fields == null) return {};

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (fields as AggFieldConnectionEdge[]).reduce<FieldsState>((fieldsState, fieldEdge) => {
		if (fieldEdge?.node != null) {
			const transformedField = transformAggFieldToLegacyField(fieldEdge.node, fields);

			if (transformedField != null) {
				const fieldData =
					'key' in transformedField
						? // `transformedField` is a `Field`
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							{ [(transformedField as Field).key]: transformedField }
						: // `transformedField` is a `FieldsState`
							transformedField;

				Object.assign(fieldsState, fieldData);
			}
		}

		return fieldsState;
	}, {});
};
