import type { Dispatch } from 'redux';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { submitFieldUpdateAction } from '../../../../../state/actions/field/update/index.tsx';
import { disableUserPickerAction } from '../../../../../state/actions/ui/index.tsx';
import type { State } from '../../../../../state/reducers/types.tsx';
import {
	getAvatarUrl,
	getDisplayName,
	getEmailAddress,
} from '../../../../../state/selectors/fields/initialization/index.tsx';
import { getAtlassianAccountId } from '../../../../../state/selectors/initial-app-props/index.tsx';
import { connect } from '../../../../../state/store/index.tsx';
import onSearchUser from '../services/index.tsx';
import AsyncUserPicker from './async-user-picker/index.tsx';
import UserSelect from './view.tsx';

const mapStateToProps = (state: State) => ({
	avatarUrl: getAvatarUrl(state),
	atlassianAccountId: getAtlassianAccountId(state),
	displayName: getDisplayName(state),
	emailAddress: getEmailAddress(state),
	AsyncUserPicker,
	onSearchUser,
	isAdmin: getTenantContext_DEPRECATED_DO_NOT_USE().isAdmin,
});

// @ts-expect-error - Parameter 'ownProps' implicitly has an 'any' type.
export default connect(mapStateToProps, (dispatch: Dispatch, ownProps) => ({
	// @ts-expect-error - TS7006 - Parameter 'issueKey' implicitly has an 'any' type. | TS7006 - Parameter 'fieldId' implicitly has an 'any' type. | TS7006 - Parameter 'fieldValue' implicitly has an 'any' type.
	onChange: (issueKey, fieldId, fieldValue) => {
		ownProps.onSubmit(fieldValue);
		return dispatch(
			submitFieldUpdateAction(
				issueKey,
				fieldId,
				fieldValue,
				ownProps.onEditSuccess,
				ownProps.onEditFailure,
			),
		);
	},
	onComponentLoadFailure: () => {
		ownProps.onComponentLoadFailure && ownProps.onComponentLoadFailure();
		dispatch(disableUserPickerAction());
		log.safeErrorWithoutCustomerData(
			'issue-table.view.fields.common.single-user-select.user-select',
			'[FD-4424] Async user select failed to load',
		);

		fireErrorAnalytics({
			meta: {
				id: 'asyncUserSelectLoadFailure',
				packageName: 'jiraIssueTable',
				teamName: 'jsd-shield',
			},
		});
	},
}))(UserSelect);
