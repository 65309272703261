import type {
	QueuesCategoryState,
	QueueNavState,
	WithLatestUpdateTimestamp,
} from '../../types/index.tsx';

export const LOCAL_STORAGE_KEY_NAV_LOCATION = 'queue-groups-nav-state';
export const LOCAL_STORAGE_KEY_CATEGORY = 'queues-category-state';

export const getQueueGroupsNavStateFromLocalStorage = (): QueueNavState | undefined => {
	try {
		const state = localStorage.getItem(LOCAL_STORAGE_KEY_NAV_LOCATION);
		if (state) {
			return JSON.parse(state);
		}
		return undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return undefined;
	}
};

export const setQueueGroupsNavStateInLocalStorage = (value: QueueNavState) => {
	try {
		localStorage.setItem(LOCAL_STORAGE_KEY_NAV_LOCATION, JSON.stringify(value));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		/* empty */
	}
};

export const getQueuesCategoryStateFromLocalStorage = (): QueuesCategoryState | undefined => {
	try {
		const state = localStorage.getItem(LOCAL_STORAGE_KEY_CATEGORY);
		if (state) {
			return JSON.parse(state);
		}
		return undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return undefined;
	}
};

export const setQueuesCategoryStateInLocalStorage = (value: QueuesCategoryState) => {
	try {
		localStorage.setItem(LOCAL_STORAGE_KEY_CATEGORY, JSON.stringify(value));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		/* empty */
	}
};

export const getEvictedState = <S extends WithLatestUpdateTimestamp>(
	state: Record<string, S>,
	maxCacheLength: number,
	maxCacheTime: number,
): Record<string, S> => {
	const stateEntries = Object.entries(state);
	const stateLen = stateEntries.length;

	const sortedDescStateEntries = stateEntries.sort(
		(a, b) => b[1].latestUpdateTimestamp - a[1].latestUpdateTimestamp,
	);
	const numStateValuesToKeep = Math.min(stateLen, maxCacheLength);
	const stateValuesToKeep = sortedDescStateEntries.slice(0, numStateValuesToKeep);

	const oldestAllowedTimestamp = Date.now() - maxCacheTime;
	const newState: Record<string, S> = {};
	stateValuesToKeep.forEach(([key, value]) => {
		if (value.latestUpdateTimestamp >= oldestAllowedTimestamp) {
			newState[key] = state[key];
		}
	});
	return newState;
};
