import { useCallback, type ReactElement } from 'react';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import { navigate } from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import { useRouterActions, type RouterActionReplace } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueuesPush = ((path: string) => Observable<any>) | undefined;

export type Props = {
	projectKey: string;
	children: (arg1: { push: QueuesPush; replace?: RouterActionReplace }) => ReactElement;
};

/* Intended usage is to replace direct usage of history.push from react-dom-router with a Jira SPA compatible push history */
const QueuesPushProvider = (props: Props) => {
	const { projectKey, children } = props;

	const { push, replace } = useRouterActions();

	const pushWithSpaCompatibleUrl = useCallback(
		(path: string) => {
			const pathWithLeadingSlash = path.charAt(0) === '/' ? `${path}` : `/${path}`;
			let fullPath = pathWithLeadingSlash;
			// Don't update the url if it is already in the right format
			if (pathWithLeadingSlash.indexOf('/jira/servicedesk/projects') !== 0) {
				fullPath = `/jira/servicedesk/projects/${projectKey}${pathWithLeadingSlash}`;
			}
			push(fullPath);
			// Some existing consumers in queues expect this to return an observable
			return Observable.empty<never>();
		},
		[projectKey, push],
	);

	const navigateWithProjectKey = useCallback(
		(path: string) => navigate(projectKey, path),
		[projectKey],
	);

	if (!projectKey) {
		return children({ push: undefined });
	}

	return __SPA__
		? children({ push: pushWithSpaCompatibleUrl, replace })
		: children({ push: navigateWithProjectKey });
};

export default QueuesPushProvider;
