import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cipherDeprecationMessage: {
		id: 'spa-apps-servicedesk-queues-agent-view.content.cipher-deprecation-banner.cipher-deprecation-message',
		defaultMessage:
			'Verify that your <button>email server uses the latest ciphers</button> to receive requests and comments from connected email accounts.',
		description: 'Cipher deprecation banner message displayed on top of page heading',
	},
});
