import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import once from 'lodash/once';
import { Observable } from 'rxjs/Observable';
import createTokenDispenser, { type TokenDispenser } from './token-dispenser.tsx';

const DEFAULT_LEASE_TIME = 10000;

export default class StateContainer<S> {
	constructor(
		availableTokenCount: number,
		injectCallback: (arg1: S) => void,
		leaseTime: number = DEFAULT_LEASE_TIME,
	) {
		this.tokenDispenser = createTokenDispenser(availableTokenCount);
		this.injectCallback = injectCallback;
		this.leaseTime = leaseTime;
		this.availableTokenCount = availableTokenCount;
	}

	tokenDispenser: TokenDispenser;

	bufferedValue: S | null = null;

	injectCallback: (arg1: S) => void;

	leaseTime: number;

	availableTokenCount: number;

	areAllTokensTaken() {
		return this.tokenDispenser.getAvailableTokenCount() === 0;
	}

	onReturnToken = () => {
		if (this.bufferedValue) {
			const value = this.bufferedValue;
			this.bufferedValue = null;
			this.injectCallback(value);
		}
	};

	withToken(value: S): Observable<{
		value: S;
		returnToken: () => void;
	}> {
		if (this.areAllTokensTaken()) {
			this.bufferedValue = value;
			return Observable.empty<never>();
		}
		return this.tokenDispenser.takeToken().switchMap((token) => {
			const returnToken = once(() => {
				token.returnToken();
				this.onReturnToken();
			});

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const leaseTimeoutId = window.setTimeout(returnToken, this.leaseTime);
			return Observable.of({
				returnToken: () => {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.clearTimeout(leaseTimeoutId);
					returnToken();
				},
				value,
			});
		});
	}
}
