import type { ComponentType } from 'react';
import BaseReduxAnalyticsData, {
	type ConnectOptions,
} from '@atlassian/jira-analytics-web-react/src/components/redux-analytics-data.tsx';
import type { AnalyticsDataPayload } from '@atlassian/jira-analytics-web-react/src/index.tsx';
import { IssueTableContext } from '../../state/store/index.tsx';

export const ReduxAnalyticsData = <T,>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapStateToAnalytics: (state: any, ownProps: T) => AnalyticsDataPayload,
	{ storeKey, ...restConnectOptions }: ConnectOptions = {},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((arg1: ComponentType<any>) => ComponentType<any>) =>
	BaseReduxAnalyticsData(mapStateToAnalytics, {
		...restConnectOptions,
		context: IssueTableContext,
	});
