import { freeze } from 'icepick';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type {
	IgnoredFieldCheck,
	CheckFieldTypeDefinitions,
	CheckedLegacyFieldTypeDefinitions,
} from '@atlassian/jira-platform-field-config/src/common/types/checked-fields.tsx';
import type {
	BuiltinJsonColumnConfigurationMap,
	BuiltinHtmlColumnConfigurationMap,
	BuiltinHtmlColumnConfiguration,
} from '../../model/columns/index.tsx';
import {
	SLA_CUSTOM_FIELD_TYPE,
	SERVICE_ENTITY_CUSTOM_FIELD_TYPE,
	CMDB_OBJECT_CUSTOM_FIELD_TYPE,
	RESPONDERS_CUSTOM_FIELD_TYPE,
	MAJOR_INCIDENT_CUSTOM_FIELD_TYPE,
	WORK_CATEGORY_CUSTOM_FIELD_TYPE,
	MULTI_CHECKBOX_CUSTOM_FIELD_TYPE,
	MULTI_SELECT_CUSTOM_FIELD_TYPE,
	MULTI_USER_CUSTOM_FIELD_TYPE,
	MULTI_VERSION_CUSTOM_FIELD_TYPE,
	MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE,
	ENTITLEMENT_CUSTOM_FIELD_TYPE,
	SENTIMENT_CUSTOM_FIELD_TYPE,
	ORGANIZATION_CUSTOM_FIELD_TYPE,
} from '../../model/fields/json-fields/custom-fields/types.tsx';
import { REQUEST_PARTICIPANTS_FIELD_TYPE } from '../../model/fields/json-fields/system-fields/types.tsx';
import type { BuiltinColumnConfiguration } from '../../model/index.tsx';
import { AsyncAffectedServices } from '../fields/affected-services/async.tsx';
import Assignee from '../fields/assignee/index.tsx';
import { CmdbObjects as CmdbObject } from '../fields/cmdb-object/view.tsx';
import Creator from '../fields/creator/view.tsx';
import DateField from '../fields/date/view.tsx';
import Entitlement from '../fields/entitlement/index.tsx';
import IssueKey from '../fields/issue-key/index.tsx';
import IssueOperations from '../fields/issue-operations/view.tsx';
import IssueType from '../fields/issue-type/view.tsx';
import Labels from '../fields/labels/index.tsx';
import MajorIncident from '../fields/major-incident/index.tsx';
import MultiUserField from '../fields/multi-user-field/index.tsx';
import MultiValueField from '../fields/multi-value-field/index.tsx';
import Organization from '../fields/organization/index.tsx';
import Priority from '../fields/priority/view.tsx';
import Reporter from '../fields/reporter/view.tsx';
import Responders from '../fields/responders/index.tsx';
import Sentiment from '../fields/sentiment/index.tsx';
import Sla from '../fields/sla/index.tsx';
import Status from '../fields/status/view.tsx';
import Summary from '../fields/summary/index.tsx';
import WorkCategory from '../fields/work-category/view.tsx';

type FieldConfigurationCategory = {
	fieldTypes: string[];
	configuration: BuiltinColumnConfiguration;
};

type BaseColumnConfigurationMap = {
	[key: string]: Partial<BuiltinColumnConfiguration>;
};

export const FALLBACK_COLUMN_CONFIGURATION: BuiltinColumnConfiguration = freeze({
	minWidth: gridSize * 20,
	maxWidth: gridSize * 20,
	canBeMultiLine: true,
});

const USER_FIELD_CONFIGURATION = {
	minWidth: 100,
	maxWidth: 300,
	canBeMultiLine: true,
} as const;

const USER_FIELD_TYPES = [
	'creator',
	'reporter',
	'com.atlassian.jira.plugin.system.customfieldtypes:userpicker',
];

const MULTI_USER_FIELD_CONFIGURATION = {
	minWidth: 100,
	maxWidth: 200,
	canBeMultiLine: true,
} as const;

const MULTI_USER_FIELD_TYPES = [
	'com.atlassian.servicedesk:sd-request-participants',
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker',
];

const FEATURED_DATE_FIELD_CONFIGURATION = {
	minWidth: 90,
	maxWidth: 90,
	doNotRenderTooltip: true,
	canBeMultiLine: true,
} as const;

const FEATURED_DATE_FIELD_TYPES = [
	'created',
	'updated',
	'com.atlassian.jira.plugin.system.customfieldtypes:datepicker',
];

const STANDARD_DATE_FIELD_CONFIGURATION = {
	minWidth: 90,
	maxWidth: 90,
	canBeMultiLine: true,
} as const;

const STANDARD_DATE_FIELD_TYPES = ['duedate', 'resolutiondate'];

const DATETIME_FIELD_CONFIGURATION = {
	minWidth: gridSize * 11,
	maxWidth: gridSize * 18,
	doNotRenderTooltip: true,
} as const;

const DATETIME_FIELD_TYPES = [
	'lastViewed',
	'com.atlassian.jira.plugin.system.customfieldtypes:datetime',
];

const SHORT_TEXT_FIELD_CONFIGURATION = {
	minWidth: gridSize * 12,
	maxWidth: gridSize * 25,
	canBeMultiLine: true,
} as const;

const SHORT_TEXT_FIELD_TYPES = [
	'com.atlassian.servicedesk:vp-origin',
	'components',
	'com.atlassian.jira.plugin.system.customfieldtypes:select',
	'com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons',
	'resolution',
	'timespent',
	'aggregatetimespent',
	'timeoriginalestimate',
	'com.atlassian.servicedesk.customer-context:sd-csm-entitlement',
	'com.atlassian.servicedesk.sentiment:sd-sentiment',
	'com.atlassian.servicedesk.customer-context:sd-csm-organization',
];

const LONG_TEXT_FIELD_CONFIGURATION = {
	minWidth: gridSize * 20,
	maxWidth: gridSize * 75,
	canBeMultiLine: true,
} as const;

const LONG_TEXT_FIELD_TYPES = [
	'summary',
	'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
];

const MULTI_SELECT_FIELD_CONFIGURATION = {
	minWidth: gridSize * 12,
	maxWidth: gridSize * 20,
	canBeMultiLine: true,
} as const;

// Atlaskit/Tag doesn't truncate long tags until 180px
const JSON_MULTI_SELECT_FIELD_CONFIGURATION = {
	minWidth: gridSize * 21,
	maxWidth: gridSize * 21,
	canBeMultiLine: true,
} as const;

const MULTI_SELECT_FIELD_TYPES = [
	'com.atlassian.servicedesk:sd-customer-organizations',
	'com.atlassian.jira.plugin.system.customfieldtypes:labels',
	'com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect',
	'fixVersions',
	'issuelinks',
];

const JSON_MULTI_SELECT_FIELD_TYPES = [
	'labels',
	'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype',
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
	'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes',
	'com.atlassian.jira.plugin.system.customfieldtypes:multiversion',
	'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker',
];

const MULTILINE_TEXT_FIELD_CONFIGURATION = {
	minWidth: gridSize * 20,
	maxWidth: gridSize * 75,
	canBeMultiLine: true,
} as const;

const MULTILINE_TEXT_FIELD_TYPES = [
	'description',
	'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
];

const NUMERIC_FIELD_CONFIGURATION = {
	minWidth: gridSize * 8,
	maxWidth: gridSize * 16,
} as const;

const NUMERIC_FIELD_TYPES = ['com.atlassian.jira.plugin.system.customfieldtypes:float'];

const FIELD_CONFIGURATION_CATEGORIES: FieldConfigurationCategory[] = [
	{
		fieldTypes: USER_FIELD_TYPES,
		configuration: USER_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: MULTI_USER_FIELD_TYPES,
		configuration: MULTI_USER_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: FEATURED_DATE_FIELD_TYPES,
		configuration: FEATURED_DATE_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: STANDARD_DATE_FIELD_TYPES,
		configuration: STANDARD_DATE_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: DATETIME_FIELD_TYPES,
		configuration: DATETIME_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: SHORT_TEXT_FIELD_TYPES,
		configuration: SHORT_TEXT_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: LONG_TEXT_FIELD_TYPES,
		configuration: LONG_TEXT_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: MULTILINE_TEXT_FIELD_TYPES,
		configuration: MULTILINE_TEXT_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: MULTI_SELECT_FIELD_TYPES,
		configuration: MULTI_SELECT_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: JSON_MULTI_SELECT_FIELD_TYPES,
		configuration: JSON_MULTI_SELECT_FIELD_CONFIGURATION,
	},
	{
		fieldTypes: NUMERIC_FIELD_TYPES,
		configuration: NUMERIC_FIELD_CONFIGURATION,
	},
];

const buildFieldConfigurationsFromCategories = (categories: FieldConfigurationCategory[]) =>
	categories.reduce(
		(
			acc: BaseColumnConfigurationMap,
			{ fieldTypes, configuration }: FieldConfigurationCategory,
		) => {
			fieldTypes.forEach((fieldType) => {
				if (acc[fieldType] != null) {
					throw new Error(`Field configuration for ${fieldType} already defined`);
				}
				acc[fieldType] = configuration;
			});
			return acc;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as BaseColumnConfigurationMap,
	);

export const BASE_FIELD_CONFIGURATION: BaseColumnConfigurationMap = freeze({
	...buildFieldConfigurationsFromCategories(FIELD_CONFIGURATION_CATEGORIES),
	issuekey: {
		minWidth: 85,
		maxWidth: 100,
	},
	issuetype: {
		minWidth: 16,
		maxWidth: 16,
		// Prevent ellipsis from rendering in IE & Edge
		canBeMultiLine: true,
	},
	status: {
		minWidth: 150,
		maxWidth: 200,
		doNotRenderTooltip: true,
	},
	priority: {
		minWidth: 16,
		maxWidth: 16,
	},
	assignee: {
		minWidth: 100,
		maxWidth: 220,
		canBeMultiLine: true,
		needsInitialization: true,
	},
	'com.atlassian.servicedesk:sd-sla-field': {
		minWidth: 182,
		maxWidth: 200,
	},
	'com.atlassian.servicedesk:sd-request-feedback': {
		minWidth: gridSize * 11,
		maxWidth: gridSize * 11,
	},
	summary: {
		minWidth: gridSize * 20,
		maxWidth: gridSize * 75,
		canBeMultiLine: true,
	},
	date: {
		minWidth: gridSize * 25,
		maxWidth: gridSize * 25,
		doNotRenderTooltip: true,
	},
	'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype': {
		minWidth: gridSize * 25,
		maxWidth: gridSize * 25,
		doNotRenderTooltip: true,
	},
	'com.atlassian.jira.modules.servicemanagement.responders-entity:responders-entity-field-cftype': {
		minWidth: gridSize * 25,
		maxWidth: gridSize * 25,
	},
	meatball: {
		minWidth: gridSize * 3,
		maxWidth: gridSize * 3,
	},
	'com.atlassian.jira.modules.servicemanagement.major-incident-entity:major-incident-entity-field-cftype':
		{
			minWidth: gridSize * 22,
			maxWidth: gridSize * 25,
		},

	'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype': {
		minWidth: gridSize * 15,
		maxWidth: gridSize * 20,
	},
});

// 48 is the minimum width a column requires to render one letter and the sorting icon. This is to ensure no columns are narrower than this.
export const MIN_WIDTH = 48;
export const BUILTIN_COLUMN_CONFIGURATIONS: BuiltinJsonColumnConfigurationMap = freeze({
	assignee: {
		...BASE_FIELD_CONFIGURATION.assignee,
		canBeMultiLine: false,
		FieldComponent: Assignee,
	},
	reporter: {
		...BASE_FIELD_CONFIGURATION.reporter,
		FieldComponent: Reporter,
	},
	reporterWithIcon: {
		...BASE_FIELD_CONFIGURATION.assignee,
		canBeMultiLine: false,
		FieldComponent: Assignee,
	},
	creator: {
		...BASE_FIELD_CONFIGURATION.creator,
		FieldComponent: Creator,
	},
	issuekey: {
		...BASE_FIELD_CONFIGURATION.issuekey,
		FieldComponent: IssueKey,
	},
	issuetype: {
		...BASE_FIELD_CONFIGURATION.issuetype,
		FieldComponent: IssueType,
	},
	'com.atlassian.servicedesk:sd-sla-field': {
		...BASE_FIELD_CONFIGURATION[SLA_CUSTOM_FIELD_TYPE],
		FieldComponent: Sla,
	},
	'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype': {
		...BASE_FIELD_CONFIGURATION[SERVICE_ENTITY_CUSTOM_FIELD_TYPE],
		FieldComponent: AsyncAffectedServices,
	},
	labels: {
		...BASE_FIELD_CONFIGURATION.labels,
		FieldComponent: Labels,
	},
	status: {
		...BASE_FIELD_CONFIGURATION.status,
		FieldComponent: Status,
	},
	summary: {
		...BASE_FIELD_CONFIGURATION.summary,
		FieldComponent: Summary,
	},
	priority: {
		...BASE_FIELD_CONFIGURATION.priority,
		FieldComponent: Priority,
	},
	date: {
		...BASE_FIELD_CONFIGURATION.date,
		FieldComponent: DateField,
	},
	'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype': {
		...BASE_FIELD_CONFIGURATION[CMDB_OBJECT_CUSTOM_FIELD_TYPE],
		FieldComponent: CmdbObject,
	},
	'com.atlassian.jira.modules.servicemanagement.responders-entity:responders-entity-field-cftype': {
		...BASE_FIELD_CONFIGURATION[RESPONDERS_CUSTOM_FIELD_TYPE],
		FieldComponent: Responders,
	},
	meatball: {
		...BASE_FIELD_CONFIGURATION.meatball,
		FieldComponent: IssueOperations,
	},
	'com.atlassian.jira.modules.servicemanagement.major-incident-entity:major-incident-entity-field-cftype':
		{
			...BASE_FIELD_CONFIGURATION[MAJOR_INCIDENT_CUSTOM_FIELD_TYPE],
			FieldComponent: MajorIncident,
		},

	'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype': {
		...BASE_FIELD_CONFIGURATION[WORK_CATEGORY_CUSTOM_FIELD_TYPE],
		FieldComponent: WorkCategory,
	},
	'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes': {
		...BASE_FIELD_CONFIGURATION[MULTI_CHECKBOX_CUSTOM_FIELD_TYPE],
		FieldComponent: MultiValueField,
	},
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect': {
		...BASE_FIELD_CONFIGURATION[MULTI_SELECT_CUSTOM_FIELD_TYPE],
		FieldComponent: MultiValueField,
	},
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker': {
		...BASE_FIELD_CONFIGURATION[MULTI_USER_CUSTOM_FIELD_TYPE],
		FieldComponent: MultiUserField,
	},
	'com.atlassian.servicedesk:sd-request-participants': {
		...BASE_FIELD_CONFIGURATION[REQUEST_PARTICIPANTS_FIELD_TYPE],
		FieldComponent: MultiUserField,
	},
	'com.atlassian.jira.plugin.system.customfieldtypes:multiversion': {
		...BASE_FIELD_CONFIGURATION[MULTI_VERSION_CUSTOM_FIELD_TYPE],
		FieldComponent: MultiValueField,
	},
	'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker': {
		...BASE_FIELD_CONFIGURATION[MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE],
		FieldComponent: MultiValueField,
	},
	'com.atlassian.servicedesk.customer-context:sd-csm-entitlement': {
		...BASE_FIELD_CONFIGURATION[ENTITLEMENT_CUSTOM_FIELD_TYPE],
		FieldComponent: Entitlement,
	},
	'com.atlassian.servicedesk.sentiment:sd-sentiment': {
		...BASE_FIELD_CONFIGURATION[SENTIMENT_CUSTOM_FIELD_TYPE],
		FieldComponent: Sentiment,
	},
	'com.atlassian.servicedesk.customer-context:sd-csm-organization': {
		...BASE_FIELD_CONFIGURATION[ORGANIZATION_CUSTOM_FIELD_TYPE],
		FieldComponent: Organization,
	},
});

// @ts-expect-error - TS2322 - Type '{ [x: string]: Partial<BuiltinColumnConfiguration>; }' is not assignable to type 'BuiltinHtmlColumnConfigurationMap'.
export const BUILTIN_HTML_COLUMN_CONFIGURATIONS: BuiltinHtmlColumnConfigurationMap = freeze({
	...BASE_FIELD_CONFIGURATION,
});

type BuiltinJsonColumnConfiguration = BuiltinHtmlColumnConfiguration;

// Assert that every 'checked' issue field has either been explicitly flagged
// ignored or otherwise has been implemented. In this way authors of new
// fields are made aware of every field context which must be supported.
//
// See the doc/playbooks/issue-fields.md for more information.
//
// Fields declared ignored suggest that it does not have a JSON definition and
// will instead be rendered using the backend-generated field HTML. Any new
// fields added here should be done so temporarily and an issue created to
// promote them to BUILTIN_COLUMN_CONFIGURATIONS.
type IgnoredFieldChecks<T> = {
	['com.atlassian.jconnect.jconnect-plugin:location']: IgnoredFieldCheck<T>;
	['com.atlassian.jconnect.jconnect-plugin:uuid']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:firstresponsedate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:timeinstatus']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:datepicker']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:datetime']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:flagged']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:float']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:grouppicker']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:labels']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:multiselect']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:multiversion']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:people']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:project']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:select']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:servicedesk-approvers-list']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:storypoint']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:textarea']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:textfield']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:url']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:userpicker']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:version']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:LastCommentDate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:assigneedomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:attachments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:comments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:dayslastcommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastupdateorcommenter']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastusercommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:message']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:participants']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:reporterdomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:userproperty']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:viewmessage']: IgnoredFieldCheck<T>;
	['com.atlassian.jpo:jpo-custom-field-baseline-end']: IgnoredFieldCheck<T>;
	['com.atlassian.jpo:jpo-custom-field-baseline-start']: IgnoredFieldCheck<T>;
	['com.atlassian.jpo:jpo-custom-field-parent']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk.approvals-plugin:sd-approvals']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk.assets-plugin:assetfield']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk.servicedesk-lingo-integration-plugin:sd-request-language']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-customer-organizations']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-feedback']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-feedback-date']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-participants']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:vp-origin']: IgnoredFieldCheck<T>;
	['com.atlassian.teams:rm-teams-custom-field-team']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-color']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-label']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-link']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-status']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-lexo-rank']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-sprint']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:jsw-issue-color']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:jsw-story-points']: IgnoredFieldCheck<T>;
	aggregateprogress: IgnoredFieldCheck<T>;
	aggregatetimeestimate: IgnoredFieldCheck<T>;
	aggregatetimeoriginalestimate: IgnoredFieldCheck<T>;
	attachment: IgnoredFieldCheck<T>;
	comment: IgnoredFieldCheck<T>;
	components: IgnoredFieldCheck<T>;
	created: IgnoredFieldCheck<T>;
	description: IgnoredFieldCheck<T>;
	devSummary: IgnoredFieldCheck<T>;
	duedate: IgnoredFieldCheck<T>;
	environment: IgnoredFieldCheck<T>;
	fixVersions: IgnoredFieldCheck<T>;
	issuelinks: IgnoredFieldCheck<T>;
	issuerestriction: IgnoredFieldCheck<T>;
	lastViewed: IgnoredFieldCheck<T>;
	progress: IgnoredFieldCheck<T>;
	project: IgnoredFieldCheck<T>;
	releases: IgnoredFieldCheck<T>;
	resolution: IgnoredFieldCheck<T>;
	resolutiondate: IgnoredFieldCheck<T>;
	security: IgnoredFieldCheck<T>;
	statuscategorychangedate: IgnoredFieldCheck<T>;
	subtasks: IgnoredFieldCheck<T>;
	timeestimate: IgnoredFieldCheck<T>;
	timeoriginalestimate: IgnoredFieldCheck<T>;
	timespent: IgnoredFieldCheck<T>;
	timetracking: IgnoredFieldCheck<T>;
	updated: IgnoredFieldCheck<T>;
	versions: IgnoredFieldCheck<T>;
	votes: IgnoredFieldCheck<T>;
	watches: IgnoredFieldCheck<T>;
	worklog: IgnoredFieldCheck<T>;
	workratio: IgnoredFieldCheck<T>;
};
// @ts-expect-error - Property 'parent' is missing
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
({
	...BUILTIN_COLUMN_CONFIGURATIONS,
}) as CheckFieldTypeDefinitions<
	CheckedLegacyFieldTypeDefinitions<BuiltinJsonColumnConfiguration>,
	IgnoredFieldChecks<BuiltinJsonColumnConfiguration>
>;
