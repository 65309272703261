import React from 'react';
import { useFilteredItems } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/use-filtered-connect-items/index.tsx';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/types.tsx';
import { ConnectItem } from './connect-item/index.tsx';

export type ConnectMenuProps = {
	hasSeparator?: boolean;
	shouldFilter?: boolean;
	items: ConnectDataItem[] | null | undefined;
	showIcons?: boolean;
	connectItemAnalytics: {
		actionSubjectId: string;
		level: number;
		[attribute: string]: boolean | number | string | undefined;
	};
};

export const ConnectMenu = ({
	items,
	showIcons = false,
	shouldFilter = true,
	connectItemAnalytics,
}: ConnectMenuProps) => {
	const filteredItems = useFilteredItems(items || null, shouldFilter);
	if (filteredItems == null || !filteredItems.length) return null;

	return (
		<>
			{filteredItems.map((item) => (
				<ConnectItem
					key={item.id}
					item={item}
					showIcons={showIcons}
					analytics={connectItemAnalytics}
				/>
			))}
		</>
	);
};
