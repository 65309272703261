import { createSelector } from 'reselect';
import { hasUnsupportedType, issueTypesSelector } from './issue-types-selector.tsx';

export const subtaskTypesSelector = createSelector(issueTypesSelector, (issueTypes) =>
	issueTypes.filter((type) => type.subtask),
);

export const isSubtaskTypesPresentSelector = createSelector(
	subtaskTypesSelector,
	(subtaskTypes) => subtaskTypes.length >= 1,
);

export const hasUnsupportedTypeSelector = createSelector(
	subtaskTypesSelector,
	(subtaskTypes) => !!subtaskTypes.find(hasUnsupportedType),
);
