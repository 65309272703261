import type { QueueResourceCustomContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { issueListSsrResource } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import type { RouteResourceError } from '@atlassian/react-resource-router';

type UseSSRIssuesDataResponse = {
	data: IssueListResponse | null;
	error: RouteResourceError | null;
};

export const useSSRIssuesData = (
	customContext?: QueueResourceCustomContext,
): UseSSRIssuesDataResponse => {
	const { data, error } = useResourceWithCustomRouterContext(issueListSsrResource, customContext);

	return { data: data ?? null, error };
};
