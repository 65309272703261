import { createSelector } from 'reselect';
import { permissionsSelector } from '../common/state/selectors/permissions-selector.tsx';

export const canCloneIssueSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canCloneIssue,
);

export const canDeleteIssueSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canDeleteIssue,
);

export const canMoveIssueSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canMoveIssue,
);

export const canEditIssuesSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canEditIssues,
);

export const canConfigureClassicIssuesSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canAdministerJira || permissions.canAdministerProject,
);

export const canAssignIssuesSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canAssignIssues,
);

export const canLinkIssuesSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canLinkIssues,
);
