import type { RowId } from '../../../model/rows/index.tsx';

export const SCROLL_TO_ROW = 'ops.scrolling.to-row.SCROLL_TO_ROW' as const;

export type ScrollToRowAction = {
	type: typeof SCROLL_TO_ROW;
	payload: RowId;
};

export const scrollToRow = (payload: RowId): ScrollToRowAction => ({
	type: SCROLL_TO_ROW,
	payload,
});
