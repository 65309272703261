import type { ViewModeType } from '@atlassian/jira-issue-view-common-types/src/context-type.tsx';

const getViewMode = (): string =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	(window.localStorage && window.localStorage.getItem('gh.ghx-issue-fragment-view-mode')) ||
	'default';

const mapPredefinedViewMode = (predefinedViewMode: ViewModeType | null) => {
	switch (predefinedViewMode) {
		case 'MODAL':
			return 'modal';
		case 'SIDEBAR':
			return 'sidebar';
		default:
			return null;
	}
};

export const getSafeViewMode = (predefinedViewMode: ViewModeType | null = null) =>
	mapPredefinedViewMode(predefinedViewMode) || getViewMode().replace(/"/g, '');
