import { connect } from '../../../../../common/table-redux.tsx';
import { navigateToRow } from '../../../../../ops/rows/navigate/action.tsx';
import { setHorizontal as setHorizontalScrollOffset } from '../../../../../ops/scrolling/offset/action.tsx';
import { set as setScrollbarSize } from '../../../../../ops/scrolling/size/action.tsx';
import {
	getNavigationMode,
	getOnRowListFocusFunctionChangedCallback,
	isAutoHeight,
	getOnReactPortalContainerRefChangedCallback,
} from '../../../../../state/consumer/selectors/index.tsx';
import { clearActiveItem } from '../../../../../state/internal/actions.tsx';
import { getHorizontalScrollOffset } from '../../../../../state/internal/selectors.tsx';
import { getMaxHorizontalScrollOffset } from '../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import AutoHeightList from './auto-height/index.tsx';
import RowList from './index-dumb.tsx';
import VirtualList from './virtual/index.tsx';

export default connect(
	(state: State) => ({
		isAutoHeight: isAutoHeight(state),
		maxHorizontalScrollOffset: getMaxHorizontalScrollOffset(state),
		onRowListFocusFunctionChanged: getOnRowListFocusFunctionChangedCallback(state),
		onReactPortalContainerRefChanged: getOnReactPortalContainerRefChangedCallback(state),
		horizontalScrollOffset: getHorizontalScrollOffset(state),
		navigationMode: getNavigationMode(state),
		AutoHeightList,
		VirtualList,
	}),
	{
		setHorizontalScrollOffset,
		setScrollbarSize,
		navigateToRow,
		clearActiveItem,
	},
)(RowList);
