import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { AssociatedIssueErrorType as ErrorType } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { RemoteIssueLinkUIFields } from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-type.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ChildIssue, ErrorType };

export type LinkedIssue = ChildIssue & {
	projectType: ProjectType | null;
} & RemoteIssueLinkUIFields; // Remote issue only

export const CHILD_ISSUES_LIMIT_EXCEEDED = 'CHILD_ISSUES_LIMIT_EXCEEDED' as const;

export const getChildIssuesLimitExceededAnalyticsCount = (childIssuesLimit: number) =>
	`>${childIssuesLimit}`;
