import { connect } from '../../../../../../../../common/table-redux.tsx';
import type { ColumnId } from '../../../../../../../../model/columns/index.tsx';
import { isVisibleCoreColumn } from '../../../../../../../../state/consumer/selectors/columns/index.tsx';
import type { State } from '../../../../../../../../state/types.tsx';
import ChildBar from './child-bar/index.tsx';
import ColumnChildOperationIndicator from './column-child-operation/index.tsx';
import ColumnSortOperationIndicator from './column-sort-operation/index.tsx';
import DumbColumn from './index-dumb.tsx';

type Props = {
	id: ColumnId;
};

export default connect(
	(state: State, props: Props) => ({
		ChildBar,
		ColumnChildOperationIndicator,
		ColumnSortOperationIndicator,
		isCoreColumn: isVisibleCoreColumn(state, props.id),
	}),
	{},
)(DumbColumn);
