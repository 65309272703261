import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type NUMBER_GQL_FIELD,
	ISSUE_FIELD_NUMBER_CF_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	STORY_POINTS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggNumberToLegacyField: AggTransformerFunction<typeof NUMBER_GQL_FIELD> = (
	node,
) => {
	const fieldType = node.isStoryPointField === true ? STORY_POINTS_TYPE : node.type;

	switch (fieldType) {
		case NUMBER_CF_TYPE:
		case STORY_POINT_ESTIMATE_CF_TYPE:
		case STORY_POINTS_TYPE: // Considered as a 'system' field, see `issue-view/configurations`
			return {
				...transformAggNodeToLegacyField(node),
				value: node.number ?? null,
				schema: {
					configuration: null,
					items: null,
					renderer: PLAIN_TEXT,
					custom: fieldType,
					system: null,
					type: ISSUE_FIELD_NUMBER_CF_TYPE,
				},
			};
		default:
			return undefined;
	}
};
