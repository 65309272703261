import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type JSM_ASSETS_GQL_FIELD,
	ASSETS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggNodeToLegacyField,
	transformAggConnection,
} from './default-node-transformer.tsx';

export const transformAggAssetToLegacyField: AggTransformerFunction<typeof JSM_ASSETS_GQL_FIELD> = (
	node,
) => {
	switch (node.type) {
		case ASSETS_CF_TYPE: {
			const hasValue = Boolean(node.selectedAssetsConnection?.edges?.length);
			const assetValue = hasValue
				? transformAggConnection(
						node.selectedAssetsConnection,
						({ appKey, originId, serializedOrigin, value }) => ({
							appKey,
							originId,
							serializedOrigin,
							value,
						}),
					)
				: null;

			return {
				...transformAggNodeToLegacyField(node),
				autoCompleteUrl: node.searchUrl,
				value: assetValue,
				schema: {
					custom: node.type,
					system: null,
					items: 'sd-asset',
					type: 'array',
					renderer: PLAIN_TEXT,
					configuration: null,
				},
			};
		}
		default:
			return undefined;
	}
};
