export const SET_ROW_BORDER_WIDTH = 'ops.rows.row-border.SET_ROW_BORDER_WIDTH' as const;

export type SetRowBorderWidthAction = {
	type: typeof SET_ROW_BORDER_WIDTH;
	payload: number;
};

export const set = (width: number): SetRowBorderWidthAction => ({
	type: SET_ROW_BORDER_WIDTH,
	payload: width,
});
