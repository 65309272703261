import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorHeader: {
		id: 'servicedesk.queues.agent-view.layout.filter-query-failed.error-header',
		defaultMessage: "We couldn't load your search results",
		description:
			'Header of error message shown to user when the user enter invalid search query to queue filter',
	},
	errorMessage: {
		id: 'servicedesk.queues.agent-view.layout.filter-query-failed.error-message',
		defaultMessage: 'Check for typos, or try another search term',
		description:
			'Error message shown to user when the user enter invalid search query to queue filter',
	},
	clearSearch: {
		id: 'servicedesk.queues.agent-view.layout.filter-query-failed.clear-search',
		defaultMessage: 'Clear search',
		description:
			'Button users can press when the queues page has failed to load, to clear search query.',
	},
});
