import React, { type ComponentType } from 'react';
import type { RowId } from '../../../../../../model/rows/index.tsx';

type Props = {
	rowIds: RowId[];
	List: ComponentType<{
		rowIds: RowId[];
		displayStart: number;
		displayEnd: number;
	}>;
};

export default function AutoHeightList(props: Props) {
	const { rowIds, List } = props;

	return <List rowIds={rowIds} displayStart={0} displayEnd={rowIds.length} />;
}
