import forEach from 'lodash/forEach';
import reject from 'lodash/reject';
import getRowsRecursiveChildLength from '../../../../common/get-row-recursive-child-length.tsx';
import type { Optional } from '../../../../model/optional/index.tsx';
import type { RowId, RowTree, RowIdHash, AnchorPosition } from '../../../../model/rows/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <
	T extends {
		anchorId?: RowId;
		position: AnchorPosition;
	},
>(
	baseRowIds: RowId[],
	rowTree: RowTree,
	expandedRowIdsHash: RowIdHash,
	anchoredRows: Optional<Record<RowId, T>>,
): RowId[] => {
	if (!anchoredRows) {
		return baseRowIds;
	}

	// remove added rows from their sorted order, to be positioned by anchor instead
	const result: RowId[] = reject(
		baseRowIds,
		(baseRowId) => anchoredRows !== undefined && anchoredRows[baseRowId] !== undefined,
	);

	// for each added row, insert based on the anchor
	forEach(anchoredRows, (anchorRow: T, anchoredRowId: RowId) => {
		const { anchorId, position } = anchorRow;

		if (position === 'FIRST') {
			result.unshift(anchoredRowId);
		} else if (position === 'LAST') {
			result.push(anchoredRowId);
		} else if (anchorId) {
			const anchorRowLocation = result.indexOf(anchorId);
			// the anchor row may not be in the set of currently visible things
			if (anchorRowLocation !== -1) {
				if (position === 'BEFORE') {
					result.splice(anchorRowLocation, 0, anchoredRowId);
				} else if (position === 'AFTER') {
					const spliceOffset =
						1 + getRowsRecursiveChildLength(anchorId, rowTree, expandedRowIdsHash);
					result.splice(anchorRowLocation + spliceOffset, 0, anchoredRowId);
				} else if (position === 'INSIDE' && expandedRowIdsHash[anchorId]) {
					result.splice(anchorRowLocation + 1, 0, anchoredRowId);
				}
			}
		}
	});

	return result;
};
