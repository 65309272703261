import React, { Component, type ComponentType } from 'react';
import type {
	FieldType,
	DataSelectorJsonProps,
	TableInjectedProps,
	JsonComponentProps,
} from '../../../../model/fields/types.tsx';

type State = {
	isEditable: boolean;
};

export type Props<T extends FieldType> = {
	FieldComponent: ComponentType<JsonComponentProps<T>>;
	tableInjectedProps: TableInjectedProps;
	fieldData: DataSelectorJsonProps<T>;
};

// eslint-disable-next-line jira/react/no-class-components
export default class JsonCell<T extends FieldType> extends Component<Props<T>, State> {
	static getDerivedStateFromProps<T extends FieldType>(props: Props<T>) {
		if (props.tableInjectedProps.isTableDisabled) {
			return { isEditable: false };
		}
		return null;
	}

	state = {
		// @ts-expect-error - TS2339 - Property 'isEditable' does not exist on type 'SlaDataSelectorProps | ServiceEntityDataSelectorProps | CmdbObjectDataSelectorProps | ... 13 more ... | MeatballMenuDataSelectorProps'.
		isEditable: this.props.fieldData.isEditable || false,
	};

	componentDidUpdate(prevProps: Props<T>) {
		if (
			prevProps.tableInjectedProps.isTableDisabled === true &&
			this.props.tableInjectedProps.isTableDisabled === false
		) {
			this.setState({
				// @ts-expect-error - TS2339 - Property 'isEditable' does not exist on type 'SlaDataSelectorProps | ServiceEntityDataSelectorProps | CmdbObjectDataSelectorProps | ... 13 more ... | MeatballMenuDataSelectorProps'.
				isEditable: this.props.fieldData.isEditable || false,
			});
		}
	}

	render() {
		const { FieldComponent, fieldData, tableInjectedProps } = this.props;
		const { isEditable } = this.state;

		return (
			<FieldComponent
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				dataSelectorProps={{ ...fieldData, isEditable } as DataSelectorJsonProps<T>}
				tableInjectedProps={tableInjectedProps}
			/>
		);
	}
}
