/**
 * Defines the time gaps between retries in seconds for rate limited requests.
 * The first value is 0, because the first retry should be immediate.
 */
export const timeGapsBetweenRetries = [0, 3, 8, 21];

export function calculateRateLimitedUntil(failedEventsCount: number): number {
	const currentDate = new Date();
	const gap =
		timeGapsBetweenRetries[Math.min(timeGapsBetweenRetries.length - 1, failedEventsCount)];
	return gap ? new Date(currentDate.getTime() + gap * 1000).getTime() : 0;
}
