import {
	UP,
	DOWN,
	type RowNavigationDirection,
} from '../../../model/navigation-directions/index.tsx';
import { getNextActiveRowIndex, getPrevActiveRowIndex } from '../../../state/selectors/index.tsx';
import type { State } from '../../../state/types.tsx';
import { defineTransaction } from '../../common/transactions/index.tsx';
import { setActiveRow } from '../../items/active/set/action.tsx';
import { NAVIGATE_TO_ROW, type NavigateToRowAction } from './action.tsx';

const getNextRowIndex = (state: State, direction: RowNavigationDirection) => {
	switch (direction) {
		case UP:
			return getPrevActiveRowIndex(state);
		case DOWN:
			return getNextActiveRowIndex(state);
		default:
			throw new Error('illegal value of the direction property.');
	}
};

export default defineTransaction(NAVIGATE_TO_ROW, (action: NavigateToRowAction, state: State) =>
	setActiveRow({
		rowIndex: getNextRowIndex(state, action.payload.direction),
		setFocusOnRow: true, // when we move via keys, we should set the focus on the row
	}),
);
