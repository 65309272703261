import type { VerticalPositioning } from '@atlassian/jira-virtual-table/src/model/callbacks/index.tsx';
import { MAX_ISSUES_PER_PAGE } from '../../../../common/table/constants.tsx';
import type { ConnectProps } from '../../../../model/cell/index.tsx';

const DIRECTION_UP = 'UP' as const;
const DIRECTION_DOWN = 'DOWN' as const;

// The number of issue keys that would be fetched on scroll
const FETCH_BATCH_SIZE = 100;

// The number of issue keys that would be fetched on scroll
const OVERFETCH_SIZE = 3;

// Fetch issues only if the index changed (after scrolling) by 25% of buffer in store
const ISSUE_FETCH_SCROLL_THRESHOLD = 0.25;

// load more issues in the direction of scroll
const FETCH_BATCH_DIRECTIONAL_BIAS = 0.8;

// min rows scrolled before a fetch is requested
const MIN_SCROLL_BOUNDARY_DELTA = 3;

// validation functions
const isFirstBatch = (prevPageIssueCount: number, startIndex: number): boolean =>
	startIndex === prevPageIssueCount;

const isLastBatch = (issueKeyCount: number, startIndex: number): boolean =>
	startIndex === issueKeyCount - FETCH_BATCH_SIZE + OVERFETCH_SIZE;

const hasScrolledPastThreshold = (
	issueCountForThresholdCheck: number,
	scrollDelta: number,
): boolean => Math.abs(scrollDelta) > issueCountForThresholdCheck * ISSUE_FETCH_SCROLL_THRESHOLD;

/**
 * @function calculateStartIndex
 * Calculates the start index of the window of issue data to be fetched based on the virtual boundary
 *
 * @param {number} issueKeyCount: number of issues
 * @param {number} currentPage: Current page the user is on (pagination)
 * @param {number} displayStart: start index of rendered issues (virtual boundary)
 * @param {number} displayEnd: end index of rendered issues (virtual boundary)
 * @param {boolean} isScrollingUp: whether the table is being scrolled upwards or downwards
 *
 * @returns {number} start index
 */
export const getFetchBoundaries = (
	issueKeyCount: number,
	prevPageIssueCount: number,
	isLastPage: boolean,
	displayStart: number,
	displayEnd: number,
	isScrollingUp: boolean,
): {
	startIndex: number;
	extraIssuesInScrollDirection: number;
	extraIssuesOppositeScrollDirection: number;
} => {
	// Find out how many issues are rendered and how many more are needed for a batch of 100 issues.
	const extraIssuesToBeFetched = FETCH_BATCH_SIZE - (displayEnd - displayStart);
	// count of issues in either direction based on scroll direction
	const extraIssuesInScrollDirection = Math.floor(
		extraIssuesToBeFetched * FETCH_BATCH_DIRECTIONAL_BIAS,
	);
	const extraIssuesOppositeScrollDirection = extraIssuesToBeFetched - extraIssuesInScrollDirection;

	const extraIssuesAtTopOfTable = isScrollingUp
		? extraIssuesInScrollDirection
		: extraIssuesOppositeScrollDirection;

	return {
		startIndex: Math.min(
			Math.max(displayStart + prevPageIssueCount - extraIssuesAtTopOfTable, prevPageIssueCount),
			prevPageIssueCount + issueKeyCount - FETCH_BATCH_SIZE + (isLastPage ? OVERFETCH_SIZE : 0), // overfetch if it's the bottom most batch on last page
		),
		extraIssuesInScrollDirection,
		extraIssuesOppositeScrollDirection,
	};
};

/**
 * @function shouldRequestData
 * Evaluates whether or not to send an API request to fetch next batch of data, based on the index value and the amount of scroll
 *
 * @param {number} issueKeyCount: number of issues
 * @param {number} issueCountForThresholdCheck: number of issues based on scroll direction for checking if user scrolled past threshold
 * @param {number} startIndex: start index of the fetch batch
 * @param {number} scrollDelta: number of rows by which the user scrolled
 *
 * @returns {boolean}
 */
export const shouldRequestData = (
	issueKeyCount: number,
	issueCountForThresholdCheck: number,
	prevPageIssueCount: number,
	startIndex: number,
	prevStartIndex: number,
	scrollDelta: number,
): boolean => {
	// fetch only if the index changes by 25% of the buffer in store in the direction of scroll
	// or if it's the first or the last batch for that page
	if (
		scrollDelta &&
		startIndex !== prevStartIndex &&
		(hasScrolledPastThreshold(issueCountForThresholdCheck, scrollDelta) ||
			isLastBatch(issueKeyCount, startIndex) ||
			isFirstBatch(prevPageIssueCount, startIndex))
	) {
		return true;
	}
	return false;
};

// We are executing multiple IssueTable callbacks which are all tied to vertical scroll offset changes in VirtualTable
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	// to keep a track of dispay boundary, used in identifying the scroll direction and offset
	let prevVisibleStart = 0;
	// to keep a track of prev indices, to not do anything if index didn't change
	let prevStartIndex: number;
	// to keep a track of any changes in scroll direction
	let prevScrollDirection = DIRECTION_DOWN;

	return (props: ConnectProps) => (verticalPosition: VerticalPositioning) => {
		const {
			onUpdateVerticalScrollOffset,
			onVerticalScrollOffsetChanged,
			onIssueDataRequested,
			issueKeys,
			currentPage,
			totalPages,
		} = props;

		const { displayStart, displayEnd, visibleStart, offsetFromTop, offsetFromBottom } =
			verticalPosition;
		const issueKeyCount = issueKeys.length;

		const topAndBottomOffset = {
			offsetFromTop,
			offsetFromBottom,
		};
		onUpdateVerticalScrollOffset(totalPages, topAndBottomOffset, onVerticalScrollOffsetChanged);

		const scrollDelta = visibleStart - prevVisibleStart;

		// if all issues are already rendered, or no change in display boundaries, no need to fetch any more
		if (issueKeyCount < FETCH_BATCH_SIZE || Math.abs(scrollDelta) < MIN_SCROLL_BOUNDARY_DELTA) {
			return;
		}

		const scrollDirection = visibleStart > prevVisibleStart ? DIRECTION_DOWN : DIRECTION_UP;
		// take pagination into account
		const prevPageIssueCount = (currentPage - 1) * MAX_ISSUES_PER_PAGE;
		const isLastPage = currentPage === totalPages;

		const { startIndex, extraIssuesInScrollDirection, extraIssuesOppositeScrollDirection } =
			getFetchBoundaries(
				issueKeyCount,
				prevPageIssueCount,
				isLastPage,
				displayStart,
				displayEnd,
				scrollDirection === DIRECTION_UP,
			);

		// if the scroll direction was changed, the threshold must be smaller because there are less issues loaded in that direction
		const issueCountForThresholdCheck =
			scrollDirection === prevScrollDirection
				? extraIssuesInScrollDirection
				: extraIssuesOppositeScrollDirection;

		// @ts-expect-error - TS2322 - Type '"UP" | "DOWN"' is not assignable to type '"DOWN"'.
		prevScrollDirection = scrollDirection;

		const shouldFetchData = shouldRequestData(
			issueKeyCount,
			issueCountForThresholdCheck,
			prevPageIssueCount,
			startIndex,
			prevStartIndex,
			scrollDelta,
		);

		onIssueDataRequested && shouldFetchData && onIssueDataRequested(startIndex);

		// unless the scroll is not enough to enforce a new fetch, do not update the previous values
		if (shouldFetchData) {
			prevStartIndex = startIndex;
			prevVisibleStart = visibleStart;
		}
	};
};
