import React, { Component, type ComponentType, type KeyboardEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { SetActiveRowCallback } from '../../../../../../../../model/navigation/index.tsx';
import {
	IS_LOADING,
	DELAYED_RENDER,
	type RowId,
	type LoadingRowComponent,
	type RowContentMountCallback,
	type RowContentUpdateCallback,
	type LoadingRowUnmountCallback,
	type OnFocusCallback,
} from '../../../../../../../../model/rows/index.tsx';

type Props = {
	id: RowId;
	isInCreation: boolean;
	isLoading: boolean;
	rowIndex: number;
	onLoadingRowUnmount: LoadingRowUnmountCallback;
	NewRow: ComponentType<{
		id: RowId;
	}>;
	LoadingRow: LoadingRowComponent;
	delayRowRenderOnScroll: boolean;
	isInRowNavigationMode: boolean;
	DefaultRow: ComponentType<{
		id: RowId;
		onRowContentMount: RowContentMountCallback;
		onRowContentUpdate: RowContentUpdateCallback;
		onFocusCallback: OnFocusCallback;
		onClick: (arg1: KeyboardEvent<HTMLDivElement>, arg2: UIAnalyticsEvent) => void;
	}>;
	onRowContentMount: RowContentMountCallback;
	onRowContentUpdate: RowContentUpdateCallback;
	onSetActiveRow: SetActiveRowCallback;
};

type State = {
	delayRender: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class RowItem extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			delayRender: props.delayRowRenderOnScroll,
		};
	}

	componentDidMount() {
		if (this.state.delayRender) {
			setTimeout(() => this.setState({ delayRender: false }), 16);
		}
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		return (
			this.props.id !== nextProps.id ||
			this.props.isInCreation !== nextProps.isInCreation ||
			this.state.delayRender !== nextState.delayRender ||
			this.props.rowIndex !== nextProps.rowIndex
		);
	}

	onFocusCallback = () => {
		const { isInRowNavigationMode, onSetActiveRow, rowIndex } = this.props;

		if (isInRowNavigationMode) {
			onSetActiveRow({ rowIndex });
		}
	};

	onClick = (event: KeyboardEvent<HTMLDivElement>, analyticsEvent: UIAnalyticsEvent) => {
		this.props.onSetActiveRow({ rowIndex: this.props.rowIndex });

		fireUIAnalytics(analyticsEvent, 'click', {
			rowIndex: this.props.rowIndex,
		});
	};

	render() {
		const {
			id,
			isInCreation,
			isLoading,
			rowIndex,
			onLoadingRowUnmount,
			NewRow,
			LoadingRow,
			onRowContentMount,
			onRowContentUpdate,
			DefaultRow,
		} = this.props;

		if (isInCreation) {
			return <NewRow id={id} />;
		}
		if (LoadingRow && (isLoading || this.state.delayRender)) {
			return (
				<LoadingRow
					id={id}
					onClick={this.onClick}
					onUnmount={isLoading || this.state.delayRender ? onLoadingRowUnmount : noop}
					reasonType={isLoading ? IS_LOADING : DELAYED_RENDER}
					rowIndex={rowIndex}
					onRowContentUpdate={onRowContentUpdate}
					onFocusCallback={this.onFocusCallback}
				/>
			);
		}
		return (
			<DefaultRow
				id={id}
				onClick={this.onClick}
				onRowContentMount={onRowContentMount}
				onRowContentUpdate={onRowContentUpdate}
				onFocusCallback={this.onFocusCallback}
			/>
		);
	}
}
