import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { SINGLE_GROUP_PICKER_GQL_FIELD } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggSingleGroupPickerToLegacyField: AggTransformerFunction<
	typeof SINGLE_GROUP_PICKER_GQL_FIELD
> = (node) => {
	const value =
		node.selectedGroup != null
			? {
					groupId: node.selectedGroup.groupId,
					name: node.selectedGroup.name,
				}
			: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			type: 'group',
			renderer: PLAIN_TEXT,
			custom: node.type,
			system: null,
			items: null,
			configuration: null,
		},
	};
};
