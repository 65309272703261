import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const getCurrentlySelectedIssues =
	() =>
	({ getState }: StoreActionApi<State>) => {
		const { issueKeys } = getState();

		return issueKeys;
	};
