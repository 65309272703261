import React from 'react';
import SubmitApdex, {
	type Props as SubmitApdexProps,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';

export const JsmPageInteractiveEvent = (args: SubmitApdexProps) => <SubmitApdex {...args} />;

JsmPageInteractiveEvent.defaultProps = {
	isExpectedToHaveSsr: false,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	withMarks: [] as string[],
	extra: {},
};

JsmPageInteractiveEvent.displayName = 'JsmPageInteractiveEvent';
