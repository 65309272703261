// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import { withTheme } from '../../../../../app/context/theme-context/index.tsx';
import type { CompiledTheme } from '../../../../../model/themes/index.tsx';
import { StyledScrollbars } from '../common/scrollbars/styled.tsx';
import type { ScrollValues, ScrollbarsRef } from '../common/scrollbars/view.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledScrollbar = styled(StyledScrollbars)<{
	left: number;
	width: number;
	theme: CompiledTheme;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	position: 'absolute !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ left }) => `${left}px`,
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width, theme }) => `${width - theme.scrollbar.offset}px !important`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '5px !important',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: '10px !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FakeContent = styled.div<{ width: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	height: '1px',
});

type Props = {
	viewportWidth: number;
	contentWidth: number;
	left: number;
	scrollLeft: number;
	setHorizontalScrollOffset: (offset: number) => void;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class HorizontalScrollbar extends PureComponent<Props> {
	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { scrollRef } = this;
		if (scrollRef) {
			scrollRef.scrollLeft(nextProps.scrollLeft);
		}
	}

	onHorizontalScroll = ({ scrollLeft }: ScrollValues) => {
		const { setHorizontalScrollOffset } = this.props;
		setHorizontalScrollOffset(scrollLeft);
	};

	setScrollRef = (ref: ScrollbarsRef) => {
		this.scrollRef = ref;
	};

	// @ts-expect-error - TS2564 - Property 'scrollRef' has no initializer and is not definitely assigned in the constructor.
	scrollRef: ScrollbarsRef;

	render() {
		const { viewportWidth, contentWidth, left, theme } = this.props;

		return (
			<StyledScrollbar
				ref={this.setScrollRef}
				width={viewportWidth}
				left={left}
				onScrollFrame={this.onHorizontalScroll}
				theme={theme}
			>
				<FakeContent width={contentWidth} />
			</StyledScrollbar>
		);
	}
}

export default withTheme(HorizontalScrollbar);
