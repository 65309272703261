import { connect } from '../../../../../../../common/table-redux.tsx';
import { navigateToRow } from '../../../../../../../ops/rows/navigate/action.tsx';
import { setHorizontal as setHorizontalScrollOffset } from '../../../../../../../ops/scrolling/offset/action.tsx';
import {
	getNavigationMode,
	isContentStale,
} from '../../../../../../../state/consumer/selectors/index.tsx';
import { clearActiveItem } from '../../../../../../../state/internal/actions.tsx';
import { getHorizontalScrollOffset } from '../../../../../../../state/internal/selectors.tsx';
import {
	getActiveRowIndex,
	getMaxHorizontalScrollOffset,
} from '../../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../../state/types.tsx';
import KeyboardShortcuts from './index-dumb.tsx';

const mapDispatchToProps = {
	navigateToRow,
	clearActiveItem,
	setHorizontalScrollOffset,
} as const;

export default connect(
	(state: State) => ({
		maxHorizontalScrollOffset: getMaxHorizontalScrollOffset(state),
		horizontalScrollOffset: getHorizontalScrollOffset(state),
		navigationMode: getNavigationMode(state),
		activeRowIndex: getActiveRowIndex(state),
		isContentStale: isContentStale(state),
	}),
	mapDispatchToProps,
)(KeyboardShortcuts);
