import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/switchMap';
import {
	getActiveItemChangedCallback,
	getActiveRowIdChangedCallback,
} from '../../../../state/consumer/selectors/index.tsx';
import { clearActiveItem } from '../../../../state/internal/actions.tsx';
import {
	SET_ACTIVE_CELL,
	SET_ACTIVE_ROW,
	type SetActiveCellAction,
	type SetActiveRowAction,
} from '../set/action.tsx';
import { CLEAR_ACTIVE_ITEM, type ClearActiveItemAction } from './action.tsx';

/* We don't want a cell to blur if we are only doing so to focus another cell. */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action: ActionsObservable<ClearActiveItemAction | SetActiveCellAction | SetActiveRowAction>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: any,
) =>
	action.ofType(CLEAR_ACTIVE_ITEM).switchMap(() =>
		Observable.timer(50)
			.map(() => {
				getActiveItemChangedCallback(store.getState())(undefined);
				const getActiveRowIdChanged = getActiveRowIdChangedCallback(store.getState());
				if (getActiveRowIdChanged != null) {
					getActiveRowIdChanged(undefined);
				}
				return clearActiveItem();
			})
			.takeUntil(action.ofType(SET_ACTIVE_CELL, SET_ACTIVE_ROW)),
	);
