import { useMemo } from 'react';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { MAJOR_INCIDENT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export type MajorIncidentConfig = {
	majorIncidentFieldIdKey: string;
	searchUrl: string;
	fieldType: string;
};

export const useMajorIncidentConfig: (issueKey: IssueKey) => MajorIncidentConfig = (
	issueKey: IssueKey,
) => {
	const [{ value: fieldConfig }] = useIssueFieldConfig(issueKey);

	const [majorIncidentFieldIdKey, searchUrl, fieldType] = useMemo(() => {
		if (fieldConfig != null) {
			const fieldId = Object.keys(fieldConfig).find(
				(fieldKey) => fieldConfig[fieldKey].schema.custom === MAJOR_INCIDENT_CF_TYPE,
			);
			if (fieldId != null) {
				const majorIncidentConfig = fieldConfig[fieldId];
				const {
					autoCompleteUrl,
					schema: { type },
				} = majorIncidentConfig;
				return [fieldId, autoCompleteUrl || '', type];
			}
		}
		return ['', '', 'string'] as const;
	}, [fieldConfig]);

	return { majorIncidentFieldIdKey, searchUrl, fieldType };
};
