import React, { type ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preventKeyDownProp = (inEditState: boolean) => (e: any) => {
	if (inEditState || e.key === 'Enter') {
		e.stopPropagation();
	}
};

type Props = {
	children: ReactNode;
	inEditState: boolean;
};

const PreventKeyboardPropWrapper = ({ children, inEditState }: Props) => (
	<div onKeyDown={preventKeyDownProp(inEditState)} role="presentation">
		{children}
	</div>
);

export default PreventKeyboardPropWrapper;
