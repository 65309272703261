import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorHeader: {
		id: 'servicedesk.queues.agent-view.layout.error-state.error-header',
		defaultMessage: "We couldn't load your queue",
		description:
			'Header of error message shown to user when the queues page fails to properly load.',
	},
	errorMessage: {
		id: 'servicedesk.queues.agent-view.layout.error-state.error-message',
		defaultMessage: 'Give it a few seconds, then try again.',
		description: 'Error message shown to user when the queues page fails to properly load.',
	},
	reload: {
		id: 'servicedesk.queues.agent-view.layout.error-state.reload',
		defaultMessage: 'Reload',
		description:
			'Button users can press when the queues page has failed to load, to try reload their page.',
	},
});
