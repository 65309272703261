import { createSelector } from 'reselect';
import sortedIndex from 'lodash/sortedIndex';
import { minRowsForTreeTableVirtualisation } from '../../constants/index.tsx';
import { type ColumnId, type SortOrder, ASCENDING } from '../../model/columns/index.tsx';
import type { VirtualBoundaries } from '../../model/index.tsx';
import { ACTIVE_CELL_TYPE, ACTIVE_ROW_TYPE } from '../../model/navigation/index.tsx';
import type { Optional } from '../../model/optional/index.tsx';
import type { RowId } from '../../model/rows/index.tsx';
import {
	getColumnWidths,
	getColumnTree,
	getDefaultComparator,
	getVisibleCoreColumnsWidth,
} from '../consumer/selectors/columns/index.tsx';
import {
	isAutoHeight,
	isDetailsPanelOpen,
	getShouldSkipVirtualization,
	getRowAddCallback,
	getTotalDynamicColumnMaxWidth,
	getTotalDynamicColumnMinWidth,
	getVisibleColumnIds,
	getVisibleAdditionalColumnIds,
	isInRowNavigationMode,
	isTableFacade,
} from '../consumer/selectors/index.tsx';
import {
	getRowTree,
	getRowsConfiguration,
	getAddBarOverflow,
	getAddLinkCaption,
	getTemporaryAddedRow,
	isAnyRowBeingAdded,
	getStaticRowPathAndDepth,
	isRowBeingAdded,
	getAbsoluteRowHeight,
	getRowHeightMapping,
	getRowCanAddSibling,
	getRowCanAddChildren,
} from '../consumer/selectors/rows/index.tsx';
import {
	getActiveSortedColumnConfiguration,
	// FSN-4037 Remove draggingRowId related logic during FF cleanup
	getDraggingRowId,
	getTableWidth,
	getTableHeight as getTableHeightInternal,
	getTableWidthWithoutScrollbarOffset,
	getTableRowMargin,
	getHorizontalScrollOffset,
	getVerticalScrollOffset,
	getHeaderHeight,
	getActiveItem,
	getActiveCell,
	getActiveRow,
} from '../internal/selectors.tsx';
import type { State } from '../types.tsx';
import {
	getVisibleRowsCount,
	getFlattenedWithAddedRowIds as getVisibleRowIds,
	getVisibleIndexForRowId,
} from './visible-row-ids/index.tsx';

export const getRowDepth = (state: State, rowId: RowId): number => {
	const rowDepthAndPath = getStaticRowPathAndDepth(state);
	if (isRowBeingAdded(state, rowId)) {
		// @ts-expect-error - TS2339 - Property 'anchorId' does not exist on type 'Optional<TemporaryAddedRow>'. | TS2339 - Property 'position' does not exist on type 'Optional<TemporaryAddedRow>'.
		const { anchorId, position } = getTemporaryAddedRow(state);
		if (anchorId) {
			return position === 'INSIDE'
				? rowDepthAndPath[anchorId].depth + 1
				: rowDepthAndPath[anchorId].depth;
		}
		/* FIRST or LAST rows will be designated to the highest level */
		return 0;
	}
	if (rowDepthAndPath[rowId] === undefined) {
		return 0;
	}
	return rowDepthAndPath[rowId].depth;
};

export const getVisibleLoadingRowIds = createSelector(
	getVisibleRowIds,
	getRowsConfiguration,
	(visibleRowIds, rowsConfiguration) =>
		visibleRowIds.filter((rowId) => {
			const rowConfiguration = rowsConfiguration[rowId];
			if (!rowConfiguration) {
				return false;
			}
			return rowConfiguration.isLoading;
		}),
);

export const isAddLinkShown = (state: State): boolean =>
	getRowAddCallback(state) !== undefined &&
	getAddLinkCaption(state) !== undefined &&
	!isAnyRowBeingAdded(state);

export const isGlobalAddEnabled = createSelector(
	getRowAddCallback,
	isAnyRowBeingAdded,
	getDraggingRowId,
	(rowAddCallback, rowBeingAdded, draggingRowId) =>
		rowAddCallback !== undefined && !rowBeingAdded && !draggingRowId,
);

// FSN-4037 Remove isGlobalRowDragEnabled related logic during FF cleanup
export const isGlobalRowDragEnabled = createSelector(
	getActiveSortedColumnConfiguration,
	getDefaultComparator,
	isAnyRowBeingAdded,
	(activeSortedColumnConfiguration, defaultComparator, anyRowBeingAdded) => {
		if (anyRowBeingAdded) {
			return false;
		}

		// If we are sorted by something, it's still ok IF it would match the default sort order
		return (
			!activeSortedColumnConfiguration ||
			(typeof defaultComparator === 'string' &&
				activeSortedColumnConfiguration.columnId === defaultComparator &&
				activeSortedColumnConfiguration.sortOrder === ASCENDING)
		);
	},
);
// FSN-4037 Remove allowDraggableRows related logic during FF cleanup
export const allowDraggableRows = (state: State): boolean => state.consumer.draggableRows;

const isDraggablePerRow = createSelector(
	getRowTree,
	getRowsConfiguration,
	(rowTree, rowsConfiguration) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const isDraggableHash: Record<string, any> = {};

		Object.keys(rowTree.rows).forEach((rowId) => {
			const rowConfiguration = rowsConfiguration[rowId];

			if (rowConfiguration) {
				// if it is configured on a per-row basis
				isDraggableHash[rowId] =
					rowConfiguration.isDraggable !== undefined ? rowConfiguration.isDraggable : true;
			} else {
				// if the callback is implemented, and it has not been
				// configured, default to allow dragging
				isDraggableHash[rowId] = true;
			}
		});

		return isDraggableHash;
	},
);

// FSN-4037 Remove isRowDraggable related logic during FF cleanup
export const isRowDraggable = (state: State, rowId: RowId): boolean =>
	allowDraggableRows(state) && isDraggablePerRow(state)[rowId];

export const canLastRowAddSiblingOrChildren = (state: State): boolean => {
	const rowIds = getVisibleRowIds(state);
	const lastRowId = rowIds[rowIds.length - 1];
	const lastRowConfig = getRowsConfiguration(state)[lastRowId];
	if (lastRowConfig) {
		return getRowCanAddSibling(state, lastRowId) || getRowCanAddChildren(state, lastRowId);
	}
	return false;
};

export const getTableHeight = createSelector(
	getTableHeightInternal,
	getVisibleRowsCount,
	isAutoHeight,
	isAddLinkShown,
	getAbsoluteRowHeight,
	canLastRowAddSiblingOrChildren,
	getAddBarOverflow,
	getHeaderHeight,
	(
		tableHeightInternal,
		visibleRowsCount,
		autoHeight,
		addLinkShown,
		absoluteRowHeight,
		hasAddBar,
		addBarOverflow,
		headerHeight,
	) => {
		if (autoHeight) {
			const rowMultiplier = visibleRowsCount + (addLinkShown ? 1 : 0);
			return (
				rowMultiplier * absoluteRowHeight +
				(hasAddBar || addLinkShown ? addBarOverflow : 0) +
				headerHeight
			);
		}
		return tableHeightInternal;
	},
);

export const getTableContentWidth = createSelector(
	isDetailsPanelOpen,
	getVisibleCoreColumnsWidth,
	getTableWidth,
	(isDetailsOpen, visibleCoreColumnWidth, tableWidth) =>
		isDetailsOpen ? visibleCoreColumnWidth : tableWidth,
);

// because height is initialized to 0, for one cycle there would be a negative height
export const getTableContentHeight = (state: State): number =>
	Math.max(0, getTableHeight(state) - getHeaderHeight(state));

export const getTableRowsHeight = createSelector(
	getVisibleRowsCount,
	getAbsoluteRowHeight,
	canLastRowAddSiblingOrChildren,
	isAddLinkShown,
	getAddBarOverflow,
	(visibleRowsCount, absoluteRowHeight, hasAddBar, addLinkShown, addBarOverflow) =>
		visibleRowsCount * absoluteRowHeight + (hasAddBar || addLinkShown ? addBarOverflow : 0),
);

export const getHorizontalScrollWidth = (state: State): number =>
	getTableWidth(state) - getVisibleCoreColumnsWidth(state);

export const getColumnWidthHash = createSelector(
	getTotalDynamicColumnMinWidth,
	getTotalDynamicColumnMaxWidth,
	getColumnTree,
	getTableWidthWithoutScrollbarOffset,
	getTableRowMargin,
	getVisibleColumnIds,
	getColumnWidths,
	(
		totalDynamicMinWidth,
		totalDynamicMaxWidth,
		columnTree,
		tableWidth,
		_rowMargin,
		visibleColumnIds,
	) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		const totalStaticColumnWidth = 0;
		const distributableWidth = tableWidth - totalStaticColumnWidth;
		visibleColumnIds.forEach((columnId) => {
			if (columnTree.columns[columnId] === undefined) {
				return;
			}

			if (totalDynamicMaxWidth <= distributableWidth) {
				// Plenty of space; consume as much as possible
				result[columnId] = columnTree.columns[columnId].maxWidth;
			} else if (totalDynamicMinWidth >= distributableWidth) {
				// Less space than required; consume as little as possible
				result[columnId] = columnTree.columns[columnId].minWidth;
			} else {
				// Between min & max; distribute remaining space based on relative min widths.
				const { minWidth } = columnTree.columns[columnId];
				const { maxWidth } = columnTree.columns[columnId];
				const scalingFactor = (maxWidth - minWidth) / (totalDynamicMaxWidth - totalDynamicMinWidth);
				result[columnId] = Math.min(
					maxWidth,
					minWidth + scalingFactor * (distributableWidth - totalDynamicMinWidth),
				);
			}
		});
		return result;
	},
);

export const getColumnWidth = (state: State, columnId: ColumnId): number =>
	getColumnWidthHash(state)[columnId];

export const getAllColumnWidth = (state: State): number =>
	Math.round(Object.values(getColumnWidthHash(state)).reduce((sum, width) => sum + width, 0));

export const getRowPositionArray = createSelector(
	getVisibleRowIds,
	getRowHeightMapping,
	getAbsoluteRowHeight,
	(visibleRowIds, rowHeightMapping, approximateRowHeight) => {
		if (visibleRowIds.length === 0) {
			return [];
		}

		// We map heights to _start_ positions of the rows, hence starting
		// with "[0]" and deleting the last. We deliberately exclude the last
		// row.
		const rowPositions: number[] = [0];
		for (let i = 0; i < visibleRowIds.length - 1; i += 1) {
			const lastHeight = rowPositions[rowPositions.length - 1];
			const nextHeight = lastHeight + (rowHeightMapping[visibleRowIds[i]] || approximateRowHeight);
			rowPositions.push(nextHeight);
		}
		return rowPositions;
	},
);

export const getScrollOffsetForRowIndex = createSelector(
	getRowPositionArray,
	// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'rowIndex' implicitly has an 'any' type.
	(_, rowIndex) => rowIndex,
	(rowPositionArray, rowIndex) => {
		if (rowIndex < 0) {
			return 0;
		}
		if (rowIndex >= rowPositionArray.length) {
			return rowPositionArray[rowPositionArray.length - 1];
		}
		return rowPositionArray[rowIndex];
	},
);

export const getMaxVerticalScrollOffset = createSelector(
	getRowPositionArray,
	getTableContentHeight,
	getRowHeightMapping,
	getVisibleRowIds,
	(rowPositionArray, tableHeight, rowHeightMapping, visibleRowIds) => {
		const lastRowStartPosition = rowPositionArray[rowPositionArray.length - 1];
		const lastRowHeight = rowHeightMapping[visibleRowIds[visibleRowIds.length - 1]];
		return lastRowStartPosition + lastRowHeight - tableHeight;
	},
);

export const hasHorizontalScrollbar = createSelector(
	getAllColumnWidth,
	getTableWidthWithoutScrollbarOffset,
	(allColumnWidth, tableWidth) => allColumnWidth > tableWidth,
);

export const hasVerticalScrollbar = (state: State): boolean =>
	getMaxVerticalScrollOffset(state) > 0;

export const isHorizontalScrollLeftShadowVisible = createSelector(
	isDetailsPanelOpen,
	getHorizontalScrollOffset,
	(isDetailsOpen, horizontalScrollOffset) => !isDetailsOpen && horizontalScrollOffset > 0,
);

export const getMaxHorizontalScrollOffset = createSelector(
	getAllColumnWidth,
	getTableWidthWithoutScrollbarOffset,
	(allColumnWidth, tableWidth) => allColumnWidth - tableWidth,
);

export const isHorizontalScrollRightShadowVisible = createSelector(
	isDetailsPanelOpen,
	getHorizontalScrollOffset,
	getMaxHorizontalScrollOffset,
	(isDetailsOpen, horizontalScrollOffset, maxHorizontalScrollOffset) =>
		!isDetailsOpen && horizontalScrollOffset < maxHorizontalScrollOffset,
);

export const getVisibleAdditionalColumnsWidth = createSelector(
	getVisibleAdditionalColumnIds,
	getColumnWidthHash,
	(additionalColumns, columnWidthHash) =>
		additionalColumns.reduce((acc, columnId) => acc + columnWidthHash[columnId], 0),
);

export const pixelDistance = (distance: number) => Math.max(0, Math.floor(distance));

export const getOffsetFromBottom = (state: State): number =>
	pixelDistance(getMaxVerticalScrollOffset(state) - getVerticalScrollOffset(state));

export const getOffsetFromTop = (state: State): number =>
	pixelDistance(getVerticalScrollOffset(state));

export const getOffsetFromLeft = (state: State): number =>
	pixelDistance(getHorizontalScrollOffset(state));

export const getOffsetFromRight = (state: State): number =>
	pixelDistance(getMaxHorizontalScrollOffset(state) - getHorizontalScrollOffset(state));

const getSortOrderForColumns = createSelector(
	getColumnTree,
	getDefaultComparator,
	getActiveSortedColumnConfiguration,
	(columnTree, defaultComparator, activeSortedColumnConfiguration) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const columnSortOrderHash: Record<string, any> = {};

		if (activeSortedColumnConfiguration === undefined) {
			/* When the default comparator is specified as a column id,
			 * rather than the function, we have to be explicit since
			 * sorting behaviour differs. In this case, if not actively
			 * sorting by anything, we are actively sorting by the default
			 * column (compared to passively).
			 */
			if (typeof defaultComparator === 'string') {
				columnSortOrderHash[defaultComparator] = ASCENDING;
			}

			return columnSortOrderHash;
		}

		Object.keys(columnTree.columns).forEach((columnId) => {
			// need to check truthy-ness again since flow disregards check outside a function call
			if (
				activeSortedColumnConfiguration !== undefined &&
				columnId === activeSortedColumnConfiguration.columnId
			) {
				columnSortOrderHash[columnId] = activeSortedColumnConfiguration.sortOrder;
			}
		});

		return columnSortOrderHash;
	},
);

export const getColumnSortOrder = (state: State, columnId: ColumnId): Optional<SortOrder> =>
	getSortOrderForColumns(state)[columnId];

const DEFAULT_BOUNDARIES: VirtualBoundaries = {
	visibleStart: 0,
	visibleEnd: 0,
	displayStart: 0,
	displayEnd: 0,
};

const DEFAULT_BUFFER_SIZE = 5;

const getVisibleRowIndexAtOffset = (rowPositions: Array<number>, offsetPosition: number) => {
	if (rowPositions.length === 0) {
		return 0;
	}
	// sortedIndex for log(n) performance.
	const idx = sortedIndex(rowPositions, offsetPosition);
	// sortedIndex always returns the position after the equality. To make
	// this easier to reason about, we'll check whether the index prior starts
	// at exactly the offsetPosition, and return that instead if it does.
	if (rowPositions[idx] === offsetPosition) {
		return idx;
	}
	return idx - 1;
};

const calculateVirtualBoundaries = (
	rowIds: RowId[],
	scrollTop: number,
	contentHeight: number,
	rowPositionArray: number[],
	shouldVirtualize: boolean,
	virtualBufferSize: number,
): VirtualBoundaries => {
	if (contentHeight === 0 || rowIds.length === 0) {
		return DEFAULT_BOUNDARIES;
	}

	const numberOfTotalRows = rowIds.length - 1;

	const visibleStart = Math.max(getVisibleRowIndexAtOffset(rowPositionArray, scrollTop), 0);
	const visibleEnd = Math.min(
		getVisibleRowIndexAtOffset(rowPositionArray, scrollTop + contentHeight),
		numberOfTotalRows,
	);

	const bufferSize = shouldVirtualize ? virtualBufferSize : numberOfTotalRows;

	// if the total number of rows is rather small (up to the provided threshold) then we render all, always.
	const displayStart = Math.max(0, visibleStart - bufferSize);
	const displayEnd = Math.min(visibleEnd + bufferSize, numberOfTotalRows);

	return {
		visibleStart,
		visibleEnd,
		displayStart,
		displayEnd,
	};
};

export const getShouldVirtualize = createSelector(
	isTableFacade,
	getVisibleRowIds,
	getShouldSkipVirtualization,
	(tableFacade, rowIds, shouldSkipVirtualization) => {
		if (shouldSkipVirtualization ?? false) {
			return false;
		}
		return tableFacade || rowIds.length > minRowsForTreeTableVirtualisation;
	},
);

export const getVirtualBoundaries = createSelector(
	getVisibleRowIds,
	getVerticalScrollOffset,
	getTableContentHeight,
	getRowPositionArray,
	getShouldVirtualize,
	(rowIds, scrollTop, contentHeight, rowPositionArray, shouldVirtualize) =>
		// render all rows when there are not more than minRowsForVirtualisation
		calculateVirtualBoundaries(
			rowIds,
			scrollTop,
			contentHeight,
			rowPositionArray,
			shouldVirtualize,
			DEFAULT_BUFFER_SIZE,
		),
);

export const getLoadingRowsInDisplayBoundary = createSelector(
	getVisibleRowIds,
	getRowsConfiguration,
	getVirtualBoundaries,
	(visibleRowIds, rowsConfiguration, virtualBoundaries) => {
		const { displayStart, displayEnd } = virtualBoundaries;
		return visibleRowIds.slice(displayStart, displayEnd).filter((rowId) => {
			const rowConfiguration = rowsConfiguration[rowId];
			if (!rowConfiguration) {
				return false;
			}
			return rowConfiguration.isLoading;
		});
	},
);

export const getNumberOfLoadingRowsInDisplayBoundary = (state: State): number =>
	getLoadingRowsInDisplayBoundary(state).length;

export const getActiveRowIndex = (state: State): Optional<number> => {
	const activeItem = getActiveItem(state);

	if (activeItem && activeItem.type === ACTIVE_ROW_TYPE) {
		return activeItem.rowIndex;
	}

	if (activeItem && activeItem.type === ACTIVE_CELL_TYPE) {
		return getVisibleIndexForRowId(state, activeItem.rowId);
	}

	return undefined;
};

export const getNextActiveRowIndex = (state: State): number => {
	const currRow = getActiveRowIndex(state);

	if (currRow === undefined) {
		return 0;
	}

	const lastVisibleRow = getVisibleRowsCount(state) - 1;
	return Math.min(currRow + 1, lastVisibleRow);
};

export const getPrevActiveRowIndex = (state: State): number => {
	const currRow = getActiveRowIndex(state);

	if (currRow === undefined) {
		return 0;
	}

	return Math.max(currRow - 1, 0);
};

export const rowIndexToRowId = (state: State, rowIndex?: number): Optional<RowId> => {
	const visibleRowIds = getVisibleRowIds(state);
	return rowIndex == null ? undefined : visibleRowIds[rowIndex];
};

export const getActiveRowId = (state: State): Optional<RowId> => {
	const activeItem = getActiveItem(state);
	if (!activeItem) {
		return undefined;
	}

	switch (activeItem.type) {
		case ACTIVE_CELL_TYPE: {
			return activeItem.rowId;
		}
		case ACTIVE_ROW_TYPE: {
			const { rowIndex } = activeItem;
			return rowIndexToRowId(state, rowIndex);
		}
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(activeItem as never);
			return undefined;
	}
};

export const isActiveRowId = (state: State, rowId: RowId): boolean =>
	getActiveRowId(state) === rowId;

export const isActiveRowItem = (state: State, rowId: RowId): boolean =>
	isInRowNavigationMode(state) && isActiveRowId(state, rowId);

export const getSetFocusOnRow = (state: State, rowId: RowId): boolean => {
	const activeRow = getActiveRow(state);
	return !!activeRow && isActiveRowId(state, rowId) && !!activeRow.setFocusOnRow;
};

export const isCellActive = (state: State, rowId: RowId, columnId: ColumnId): boolean => {
	const activeItem = getActiveCell(state);
	return !!activeItem && activeItem.rowId === rowId && activeItem.columnId === columnId;
};

export const getActiveAndAdjacentRowIds = createSelector(
	getVisibleRowIds,
	getActiveRowIndex,
	(visibleRowIds, activeRowIndex) => {
		if (activeRowIndex === undefined || activeRowIndex >= visibleRowIds.length) {
			return [];
		}

		return [...Array(3).keys()]
			.map((adjacencyIndex) => activeRowIndex + adjacencyIndex - 1)
			.filter((rowIndex) => rowIndex >= 0 && rowIndex < visibleRowIds.length)
			.map((idx) => visibleRowIds[idx]);
	},
);

export const isFirstRow = (state: State, rowId: RowId): boolean =>
	getVisibleRowIds(state)[0] === rowId;

export const shouldRowHydrateFully = createSelector(
	getActiveAndAdjacentRowIds,
	isFirstRow,
	(_, rowId) => rowId,
	(activeAndAdjacentRowIds, firstRow, rowId) =>
		(activeAndAdjacentRowIds.length === 0 && firstRow) ||
		activeAndAdjacentRowIds.some((activeOrAdjacentRowId) => activeOrAdjacentRowId === rowId),
);
