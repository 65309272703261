import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';

const navigateWithStrategy = (
	items: string[],
	currentItemId: string,
	searchStrategy: (selectedIndex: number) => number,
): string => {
	const selectedIndex = findIndex(items, (item) => item === currentItemId);
	// Selected item is not found; go to top
	if (selectedIndex === -1) {
		return items[0];
	}

	const nextIndex = searchStrategy(selectedIndex);
	if (nextIndex === -1) {
		return items[0];
	}
	return items[nextIndex];
};

const buildSearchStrategy =
	(
		searchFn: (selectedIndex: number) => number,
		fallbackFn: (selectedIndex: number) => number,
	): ((selectedIndex: number) => number) =>
	(selectedIndex) => {
		const searchResult = searchFn(selectedIndex);
		if (searchResult === -1) {
			return fallbackFn(selectedIndex);
		}
		return searchResult;
	};

export const navigateUp = (
	items: string[],
	currentItemId: string,
	ineligibleItemsSet: Set<string>,
): string =>
	navigateWithStrategy(
		items,
		currentItemId,
		buildSearchStrategy(
			(selectedIndex) =>
				findLastIndex(items, (item) => !ineligibleItemsSet.has(item), selectedIndex - 1),
			() => findLastIndex(items, (item) => !ineligibleItemsSet.has(item), items.length - 1),
		),
	);

export const navigateDown = (
	items: string[],
	currentItemId: string,
	ineligibleItemsSet: Set<string>,
): string =>
	navigateWithStrategy(
		items,
		currentItemId,
		buildSearchStrategy(
			(selectedIndex) =>
				findIndex(items, (item) => !ineligibleItemsSet.has(item), selectedIndex + 1),
			() => findIndex(items, (item) => !ineligibleItemsSet.has(item), 0),
		),
	);
