import type { AnError } from '@atlassian/jira-servicedesk-common/src/model/error/anerror/index.tsx';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { QueuesWithSorting } from '../../../model/index.tsx';

// SET_QUEUE_VISIBILITY

export const SET_QUEUE_VISIBILITY = 'state.actions.page.SET_QUEUE_VISIBILITY' as const;

export type SetQueueVisibilityAction = {
	type: typeof SET_QUEUE_VISIBILITY;
	payload: {
		isQueueVisible: boolean;
	};
};

export const setQueueVisibilityAction = (isQueueVisible: boolean): SetQueueVisibilityAction => ({
	type: SET_QUEUE_VISIBILITY,
	payload: {
		isQueueVisible,
	},
});

// CHANGE_VISIBILITY

export const CHANGE_VISIBILITY = 'state.actions.page.CHANGE_VISIBILITY' as const;

export type ChangeVisibilityAction = {
	type: typeof CHANGE_VISIBILITY;
	payload: {
		isQueueVisible: boolean;
	};
};

export const changeVisibilityAction = (isQueueVisible: boolean): ChangeVisibilityAction => ({
	type: CHANGE_VISIBILITY,
	payload: {
		isQueueVisible,
	},
});

// RESET_STATE

export const RESET_STATE = 'state.actions.page.RESET_STATE' as const;

export type ResetStateAction = {
	type: typeof RESET_STATE;
	payload: {
		queueId: QueueId;
	};
};

export const resetStateAction = (queueId: QueueId): ResetStateAction => ({
	type: RESET_STATE,
	payload: {
		queueId,
	},
});

// LOAD_PAGE_DATA

export const LOAD_PAGE_DATA = 'state.actions.page.LOAD_PAGE_DATA' as const;

export type LoadPageDataAction = {
	type: typeof LOAD_PAGE_DATA;
	payload: {
		queueId: QueueId;
	};
};

export const loadPageDataAction = (queueId: QueueId): LoadPageDataAction => ({
	type: LOAD_PAGE_DATA,
	payload: { queueId },
});

// RELOAD_PAGE_DATA

export const RELOAD_PAGE_DATA = 'state.actions.page.RELOAD_PAGE_DATA' as const;

export type ReloadPageDataAction = {
	type: typeof RELOAD_PAGE_DATA;
	payload: {
		queueId: QueueId;
	};
};

export const reloadPageDataAction = (queueId: QueueId): ReloadPageDataAction => ({
	type: RELOAD_PAGE_DATA,
	payload: {
		queueId,
	},
});

// LOAD_PAGE_DATA_SUCCESS

export const LOAD_PAGE_DATA_SUCCESS = 'state.actions.page.LOAD_PAGE_DATA_SUCCESS' as const;

export type LoadPageDataSuccessAction = {
	type: typeof LOAD_PAGE_DATA_SUCCESS;
	payload: {
		queuesWithSorting: QueuesWithSorting;
	};
};

export const loadPageDataSuccessAction = (
	queuesWithSorting: QueuesWithSorting,
): LoadPageDataSuccessAction => ({
	type: LOAD_PAGE_DATA_SUCCESS,
	payload: {
		queuesWithSorting,
	},
});

// LOAD_PAGE_DATA_FAILURE

export const LOAD_PAGE_DATA_FAILURE = 'state.actions.page.LOAD_PAGE_DATA_FAILURE' as const;

export type LoadPageDataFailureAction = {
	type: typeof LOAD_PAGE_DATA_FAILURE;
	payload: {
		error: AnError;
		statusCode: number | undefined;
	};
};

export const loadPageDataFailureAction = (
	error: AnError,
	statusCode?: number,
): LoadPageDataFailureAction => ({
	type: LOAD_PAGE_DATA_FAILURE,
	payload: {
		error,
		statusCode,
	},
});

// DISPLAY_QUEUES_ERROR_HEADER

export const DISPLAY_QUEUES_ERROR_HEADER =
	'state.actions.page.DISPLAY_QUEUES_ERROR_HEADER' as const;

export type DisplayQueuesErrorHeaderAction = {
	type: typeof DISPLAY_QUEUES_ERROR_HEADER;
};

export const displayQueuesErrorHeaderAction = (): DisplayQueuesErrorHeaderAction => ({
	type: DISPLAY_QUEUES_ERROR_HEADER,
});

export type Action =
	| SetQueueVisibilityAction
	| ChangeVisibilityAction
	| ResetStateAction
	| LoadPageDataAction
	| ReloadPageDataAction
	| LoadPageDataSuccessAction
	| LoadPageDataFailureAction
	| DisplayQueuesErrorHeaderAction;
