import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { MIN_SCROLL_THRESHOLD_FOR_PAGINATION } from '../../../common/table/constants.tsx';
import {
	UPDATE_VERTICAL_OFFSET,
	setVerticalOffsetAction,
} from '../../../state/actions/offsets/index.tsx';
import { setPaginationVisibility } from '../../../state/actions/ui/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { shouldShowPagination } from '../../../state/selectors/ui/index.tsx';

// exported for testing
export const calculatePaginationVisibility = (
	prevShowPagination: boolean,
	offsetFromBottom: number,
	totalPages: number,
) => {
	if (totalPages > 1) {
		return (
			// If pagination is already shown but the table has not been scrolled back up enough
			(prevShowPagination && offsetFromBottom < MIN_SCROLL_THRESHOLD_FOR_PAGINATION) ||
			// Or, if no pagination is shown and table has been scrolled to the bottom
			(!prevShowPagination && offsetFromBottom === 0)
		);
	}
	return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(UPDATE_VERTICAL_OFFSET).switchMap((action) => {
		const { totalPages, verticalPosition, onVerticalScrollOffsetChanged } = action.payload;
		const showPagination = shouldShowPagination(store.getState());
		const { offsetFromTop, offsetFromBottom } = verticalPosition;
		const nextShowPagination = calculatePaginationVisibility(
			showPagination,
			offsetFromBottom,
			totalPages,
		);

		onVerticalScrollOffsetChanged && onVerticalScrollOffsetChanged(verticalPosition);

		// @ts-expect-error - TS2304 - Cannot find name 'SetVerticalOffsetAction'. | TS2304 - Cannot find name 'SetPaginationVisibilityAction'.
		const actions: Array<SetVerticalOffsetAction | SetPaginationVisibilityAction> = [];
		if (nextShowPagination !== showPagination) {
			actions.push(setPaginationVisibility(nextShowPagination));
		}

		actions.push(setVerticalOffsetAction(offsetFromTop));
		return Observable.of(...actions);
	});
