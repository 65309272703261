import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFieldUpdate = (fieldKey: string, value: any) => ({
	fields: {
		[fieldKey]: value,
	},
});

export const defaultSaveField = async <T,>(
	issueKey: IssueKey,
	fieldKey: string,
	newValue: T,
	_baseUrl: string,
) =>
	performPutRequest(`/rest/api/3/issue/${issueKey}/`, {
		body: JSON.stringify(getFieldUpdate(fieldKey, newValue)),
	});

/**
 * Performes PUT request to update the issue fields and returns full shape of updated data.
 * It uses `returnIssue` query param to enable the non empty response.
 */
export const defaultSaveFieldById = async <T,>(
	issueKey: IssueKey,
	fieldKey: string,
	newValue: T,
	_baseUrl: string,
) =>
	performPutRequest(`/rest/api/3/issue/${issueKey}/?returnIssue=true`, {
		body: JSON.stringify(getFieldUpdate(fieldKey, newValue)),
	});

export const defaultAddValuesToField = async <T,>(
	issueKey: IssueKey,
	fieldKey: string,
	valuesToAdd: T,
	_baseUrl: string,
) => {
	const updateParam = {
		update: {
			[fieldKey]: [
				{
					operation: 'add',
					value: valuesToAdd,
				},
			],
		},
	};
	return performPutRequest(`/rest/api/3/issue/${issueKey}/`, {
		body: JSON.stringify(updateParam),
	});
};

export const defaultRemoveValuesFromField = async <T,>(
	issueKey: IssueKey,
	fieldKey: string,
	valuesToRemove: T,
	_baseUrl: string,
) => {
	const updateParam = {
		update: {
			[fieldKey]: [
				{
					operation: 'remove',
					value: valuesToRemove,
				},
			],
		},
	};
	return performPutRequest(`/rest/api/3/issue/${issueKey}/`, {
		body: JSON.stringify(updateParam),
	});
};
