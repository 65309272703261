import { createHook, createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { State, Permissions, ProjectPermissionSelectorProps } from './types.tsx';

export type Actions = typeof actions;

export const defaultPermissions: Readonly<Permissions> = {
	canAddComments: false,
	canAdministerJira: false,
	canAdministerProject: false,
	canAssignIssues: false,
	canBeAssignedToIssues: false,
	canCloneIssue: false,
	canCreateAttachments: false,
	canCreateChildren: false,
	canCreateSubtask: false,
	canDeleteAllAttachments: false,
	canDeleteAllComments: false,
	canDeleteAllWorklogs: false,
	canDeleteIssue: false,
	canDeleteOwnAttachments: false,
	canDeleteOwnComments: false,
	canDeleteOwnWorklogs: false,
	canEditAllComments: false,
	canEditAllWorklogs: false,
	canEditIssues: false,
	canEditOwnComments: false,
	canEditOwnWorklogs: false,
	canLinkIssues: false,
	canLogWork: false,
	canManageWatchers: false,
	canMoveIssue: false,
	canModifyReporter: false,
	canScheduleIssues: false,
	canUseServiceDeskAgentFeatures: false,
	canBrowseUsers: false,
	canViewDevTools: false,
	canViewWatchers: false,
	canViewVoters: false,
	canCreateIssuesInClassicProjectEpic: false,
	canResolveIssues: false,
	canExportIssue: false,
	canArchiveIssue: false,
	canUnarchiveIssue: false,
};

export const store = createStore<State, Actions>({
	name: 'project-permissions',
	initialState: {},
	actions,
});

const getProjectPermissionSelector = (
	state: State,
	{ projectKey }: ProjectPermissionSelectorProps,
) => state[projectKey] || defaultPermissions;

export const useProjectPermissionsStore = createHook(store, {
	selector: getProjectPermissionSelector,
});

export const useProjectPermissionsStoreActions = createHook(store, {
	selector: null,
});
