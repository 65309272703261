import React from 'react';
import {
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { IssueNavigatorData } from '@atlassian/jira-issue-context-service/src/types.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { parseIssueKey } from '@atlassian/jira-issue-view-common-utils/src/entry/index.tsx';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { listenToExternalActionFromLegacy } from '@atlassian/jira-issue-view-store/src/actions/legacy-external-action.tsx';
import { markFragmentReady } from '@atlassian/jira-issue-view/src/common/metrics/index.tsx';
import { QUEUE_ROUTES } from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import IssueView from './view/view.tsx';

let dispatchExternalAction: (arg1: ExternalAction) => void;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getIssueKey = (): IssueKey => parseIssueKey(window.location.href);

const getPreviousUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const urlParts = window.location.href.split('/');
	return urlParts.slice(0, urlParts.length - 1).join('/');
};

const queueRoutesPattern = Object.values(QUEUE_ROUTES).join('|').replace(/\//g, '\\/');

const QUEUES_URL_REGEX = new RegExp(
	`.*\\/projects\\/[^/]+\\/(${queueRoutesPattern})\\/custom\\/.*`,
);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isComingFromQueue = () => window.location.pathname.match(QUEUES_URL_REGEX);

const REPORTS_URL_REGEX = /.*\/projects\/[^/]+\/reports\/.*/;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isComingFromReport = () => window.location.pathname.match(REPORTS_URL_REGEX);

const isComingFromQueueOrReport = () => isComingFromQueue() || isComingFromReport();

const redirectAfterSuccessfulDelete = () => () => {
	const issueKey = getIssueKey();
	const projectKey = extractProjectKey(issueKey);

	let url;

	if (isComingFromQueueOrReport()) {
		url = getPreviousUrl();
	} else {
		url = `/projects/${projectKey}`;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.assign(url);
};

listenToExternalActionFromLegacy(() => ({
	dispatchExternalAction,
	modifiedIssueIssueKey: getIssueKey(),
}));

const onDispatchExternalActionRef = (ref?: undefined) => {
	if (ref) {
		dispatchExternalAction = ref;
	}
};

const getAnalyticsSourceFromUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { pathname } = window.location;
	if (pathname.includes('section')) {
		return SERVICEDESK_QUEUES_ISSUE;
	}
	if (pathname.includes('queues')) {
		return SERVICEDESK_QUEUES_ISSUE;
	}
	return SERVICEDESK_REPORTS_ISSUE;
};

type Props = {
	issueNavigatorData?: IssueNavigatorData;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ issueNavigatorData }: Props = {}) => {
	markFragmentReady();
	const issueKey = getIssueKey();

	return (
		<UFOSegment name="jsm-issue-view">
			<IssueView
				backButtonUrl={isComingFromQueueOrReport() ? getPreviousUrl() : undefined}
				issueNavigatorData={issueNavigatorData}
				analyticsSource={getAnalyticsSourceFromUrl()}
				issueKey={issueKey}
				onIssueDeleteSuccess={redirectAfterSuccessfulDelete()}
				onDispatchExternalActionRef={onDispatchExternalActionRef}
			/>
		</UFOSegment>
	);
};
