import React from 'react';
import { SimpleTag as Tag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import { USER_ARI_PREFIX } from './constants.tsx';
import type { Props } from './types.tsx';

export const RespondersInlineView = ({ values }: Props) => {
	const respondersRender = values.map((it) => {
		const accountId = it.ari.startsWith(USER_ARI_PREFIX)
			? it.ari.substring(USER_ARI_PREFIX.length)
			: null;

		const renderTagWithProfileCardWrapper = (accountIdValue: string) => (
			<AsyncProfileCardNext key={it.ari} accountId={accountIdValue}>
				<Tag testId="responders.ui.inline.async-tag" key={it.ari} text={it.name} />
			</AsyncProfileCardNext>
		);

		return accountId !== null ? (
			renderTagWithProfileCardWrapper(accountId)
		) : (
			<Tag testId="responders.ui.inline.tag" key={it.ari} text={it.name} />
		);
	});
	return <TagGroup>{respondersRender}</TagGroup>;
};

export default RespondersInlineView;
