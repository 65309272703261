import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import type {
	Column,
	ColumnTree,
	ColumnId,
	HiddenColumns,
	ColumnTitleType,
	HeaderComponentType,
	ResizeStrategy,
} from '../../../../model/columns/index.tsx';
import type { Optional } from '../../../../model/optional/index.tsx';
import type { ComparatorHash, DefaultComparator } from '../../../../model/sorting/index.tsx';
import type { State } from '../../../types.tsx';
import defaultComparatorFunc from './default-comparator-func.tsx';
import flattenAllColumnIds from './flatten-all-column-ids.tsx';
import flattenVisibleColumnIds from './flatten-visible-column-ids.tsx';
import generateColumnConfiguration from './generate-column-configuration.tsx';

export const getColumnTree = (state: State): ColumnTree => state.consumer.columnTree;
export const getExpandedColumnIds = (state: State) => state.consumer.expandedColumnIds;
export const getHiddenColumnIds = (state: State): HiddenColumns => state.consumer.hiddenColumnIds;
export const getColumnWidths = (state: State) => state.consumer.columnWidths;
export const getColumnComparators = (state: State): ComparatorHash =>
	state.consumer.columnComparators;
export const getDefaultComparator = (state: State): DefaultComparator =>
	state.consumer.defaultComparator;
export const getMinAdditionalColumnsWidth = (state: State) =>
	state.consumer.minAdditionalColumnsWidth;
export const getSortedColumnIds = (state: State) => state.consumer.sortedColumnIds;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCellComponent = (state: State, columnId: ColumnId): any =>
	getColumnTree(state).columns[columnId].CellComponent;

export const getDefaultComparatorFunction = createSelector(
	getDefaultComparator,
	getColumnComparators,
	(defaultComparator, columnComparators) =>
		defaultComparatorFunc(defaultComparator, columnComparators),
);

export const getExpandedColumnIdsHash = createSelector(getExpandedColumnIds, (expandedColumnIds) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	expandedColumnIds.reduce<Record<string, any>>((acc, next) => {
		// for effeciency sake we are mutating the accumulator to build up our hash
		acc[next] = true;
		return acc;
	}, {}),
);

export const getHiddenColumnsLookupHash = createSelector(
	getHiddenColumnIds,
	(hiddenColumnIds: ColumnId[]): Record<ColumnId, boolean> =>
		reduce(hiddenColumnIds, (hash, id) => ({ ...hash, [id]: true }), {}),
);

export const getVisibleCoreColumnIds = createSelector(
	getColumnTree,
	getExpandedColumnIdsHash,
	getHiddenColumnsLookupHash,
	getSortedColumnIds,
	(columnTree, expandedColumnIdsHash, hiddenColumnIdsHash, sortedColumnIds) =>
		flattenVisibleColumnIds(
			columnTree,
			columnTree.rootCoreIds,
			expandedColumnIdsHash,
			hiddenColumnIdsHash,
			sortedColumnIds,
		),
);

export const getVisibleCoreColumnsWidth = createSelector(
	getVisibleCoreColumnIds,
	getColumnTree,
	getColumnWidths,
	(coreColumns, columnTree, columnWidths) =>
		coreColumns.length === 0
			? 0
			: coreColumns.reduce((acc, columnId) => {
					const columnWidth = columnWidths[columnId] || columnTree.columns[columnId].defaultWidth;
					return acc + columnWidth;
				}, 54),
);

export const getColumnConfiguration = createSelector(
	getColumnTree,
	getHiddenColumnsLookupHash,
	getSortedColumnIds,
	(columnTree, hiddenColumnIdsHash, sortedColumnIds) =>
		generateColumnConfiguration(
			columnTree,
			columnTree.rootCoreIds.concat(columnTree.rootAdditionalIds),
			hiddenColumnIdsHash,
			sortedColumnIds,
		),
);

export const getVisibleCoreColumnsLookupHash = createSelector(
	getVisibleCoreColumnIds,
	(visibleCoreColumnIds: ColumnId[]): Record<ColumnId, boolean> =>
		reduce(visibleCoreColumnIds, (hash, id) => ({ ...hash, [id]: true }), {}),
);

export const getColumnTitle = (state: State, columnId: ColumnId): ColumnTitleType =>
	getColumnTree(state).columns[columnId].title;

export const getColumnHeaderComponent = (
	state: State,
	columnId: ColumnId,
): HeaderComponentType | undefined => getColumnTree(state).columns[columnId].HeaderComponent;

export const getColumnTooltip = (state: State, columnId: ColumnId): Optional<string | Element> =>
	getColumnTree(state).columns[columnId].tooltip;

export const getColumnMinWidth = (state: State, columnId: ColumnId): number => {
	const columnTree = getColumnTree(state);
	return columnTree.columns[columnId].minWidth;
};

export const getColumnMaxWidth = (state: State, columnId: ColumnId): number => {
	const columnTree = getColumnTree(state);
	return columnTree.columns[columnId].maxWidth;
};

export const getColumnColor = (state: State, columnId: ColumnId): string =>
	getColumnTree(state).columns[columnId].color || '#333';

export const getColumnHasChildren = (state: State, columnId: ColumnId): boolean => {
	const columnTree = getColumnTree(state);
	return (
		!!columnTree.columns[columnId].childrenIds && // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
		columnTree.columns[columnId].childrenIds.length > 0
	);
};

export const getColumnParentId = (state: State, columnId: ColumnId): Optional<ColumnId> => {
	const columnTree = getColumnTree(state);
	return columnTree.columns[columnId].parentId;
};

// FSN-4987 Remove resize strategy related logic during cleanup
export const getResizeStrategy = (state: State, columnId: ColumnId): ResizeStrategy =>
	getColumnTree(state).columns[columnId].resizeStrategy;

export const isColumnExpanded = (state: State, columnId: ColumnId): boolean => {
	const expandedColumnIdsHash = getExpandedColumnIdsHash(state);
	return !!expandedColumnIdsHash[columnId];
};

export const isColumnSortable = (state: State, columnId: ColumnId): boolean => {
	const columnComparators = getColumnComparators(state);
	return !!columnComparators[columnId];
};

export const isParentColumn = (state: State, columnId: ColumnId): boolean =>
	!!getColumnTree(state).columns[columnId].childrenIds;

export const isChildColumn = (state: State, columnId: ColumnId): boolean =>
	!!getColumnTree(state).columns[columnId].parentId;

export const getParentId = (state: State, columnId: ColumnId): Optional<ColumnId> =>
	getColumnTree(state).columns[columnId].parentId;

export const isFirstColumn = (state: State, columnId: ColumnId): boolean =>
	getVisibleCoreColumnIds(state)[0] === columnId;

export const isVisibleCoreColumn = (state: State, columnId: ColumnId) => {
	const visibleColumnsLookupHash = getVisibleCoreColumnsLookupHash(state);
	return !!visibleColumnsLookupHash[columnId];
};

export const canCellBeMultiLine = (state: State, columnId: ColumnId): boolean =>
	getColumnTree(state).columns[columnId].canBeMultiLine || false;

export const hasAnyMultiLineColumn = createSelector(getColumnTree, (columnTree) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const columns: Column[] = Object.values(columnTree.columns) as any;
	return columns.filter((column) => column.canBeMultiLine).length > 0;
});

export const getRootColumnIds = createSelector(getColumnTree, (columnTree: ColumnTree) => [
	...columnTree.rootCoreIds,
	...columnTree.rootAdditionalIds,
]);

export const getAllColumnIds = createSelector(
	getSortedColumnIds,
	getColumnTree,
	getRootColumnIds,
	(sortedColumnIds: Optional<ColumnId[]>, columnTree: ColumnTree, rootColumnIds: ColumnId[]) =>
		flattenAllColumnIds(columnTree, rootColumnIds, sortedColumnIds),
);
