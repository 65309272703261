// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactNode, type ComponentProps } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import {
	ModalBoundary,
	type ModalBoundaryProps,
} from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/index.tsx';

export type IssueBoundaryProps = {
	isEmbedView?: boolean;
	children: ReactNode;
} & Flow.Diff<
	JSX.LibraryManagedAttributes<typeof ErrorBoundary, ComponentProps<typeof ErrorBoundary>>,
	{
		id?: string;
	}
>;

export const IssueBoundary = ({ children, isEmbedView = true, ...props }: IssueBoundaryProps) => (
	<ErrorBoundary id="issue" {...props}>
		<Placeholder name="issue-boundary" fallback={<IssueViewSkeleton isEmbedView={isEmbedView} />}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);

export type IssueModalBoundaryProps = Flow.Diff<
	ModalBoundaryProps,
	{
		id: string;
	}
>;

export const IssueModalBoundary = ({ children, ...props }: IssueModalBoundaryProps) => (
	<ModalBoundary id="issue-modal" {...props}>
		<Placeholder name="issue-modal-boundary" fallback={<IssueViewSkeleton isModalView />}>
			{children}
		</Placeholder>
	</ModalBoundary>
);
