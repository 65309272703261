import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import memoizeOne from 'memoize-one';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LockedIssue, LockedIssuesByKey } from '../../../model/locking/index.tsx';
import type { State } from '../../reducers/types.tsx';
import { getPersisted } from '../common/index.tsx';
import { getLockedIssues } from '../field-lock/index.tsx';
import { getIndexesOfStringArray, IteratorWrapper } from './utils.tsx';

export const getProvidedIssueKeys = (state: State): string[] =>
	getPersisted(state).providedIssueKeys;

export const getIndexesOfIssueKeys = memoizeOne(getIndexesOfStringArray);

const areLockedIssuesAlreadyInPosition = (
	lockedIssuesByKey: LockedIssuesByKey,
	originalIssueKeys: string[],
) =>
	Object.entries(lockedIssuesByKey).every(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		(([issueKey, lockedIssue]: [any, any]) =>
			originalIssueKeys[lockedIssue.positionIndex] === issueKey) as (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			arg1: [string, any],
		) => boolean,
	);

const desparsify = (
	issueKeys: string[],
): {
	issueKey: string;
	positionIndex: number;
}[] => {
	const resultArray: Array<{
		issueKey: string;
		positionIndex: number;
	}> = [];
	issueKeys.forEach((issueKey, positionIndex) => resultArray.push({ issueKey, positionIndex }));
	return resultArray;
};

export const mergeIssueKeysWithLockedIssues = createSelector(
	getLockedIssues,
	getProvidedIssueKeys,
	(lockedIssuesByKey: LockedIssuesByKey, originalIssueKeys: string[]) => {
		// Performance shortcut
		if (areLockedIssuesAlreadyInPosition(lockedIssuesByKey, originalIssueKeys)) {
			return originalIssueKeys;
		}

		const resultIssueKeys: string[] = [];

		const originalIssueKeysIterator = new IteratorWrapper(desparsify(originalIssueKeys));
		const lockedRowsIterator: IteratorWrapper<LockedIssue> = new IteratorWrapper(
			sortBy(lockedIssuesByKey, (lockedIssue) => lockedIssue.positionIndex),
		);

		let issueIndexOffset = 0;
		let newArrayLength = originalIssueKeys.length + issueIndexOffset;

		do {
			const nextIssue = originalIssueKeysIterator.current;
			const nextLockedIssue = lockedRowsIterator.current;

			if (nextIssue.done) {
				if (!nextLockedIssue.done) {
					const latestPossibleInsertionPoint = Math.min(
						nextLockedIssue.value.positionIndex,
						newArrayLength,
					);
					resultIssueKeys[latestPossibleInsertionPoint] = nextLockedIssue.value.issueKey;
					lockedRowsIterator.next();
					issueIndexOffset += 1;
				}
			} else {
				const realNextIssueIndex = nextIssue.value.positionIndex + issueIndexOffset;
				if (nextLockedIssue.done) {
					if (!lockedIssuesByKey[nextIssue.value.issueKey]) {
						resultIssueKeys[realNextIssueIndex] = nextIssue.value.issueKey;
					} else {
						issueIndexOffset -= 1;
					}
					originalIssueKeysIterator.next();
				} else if (realNextIssueIndex >= nextLockedIssue.value.positionIndex) {
					if (
						realNextIssueIndex === nextLockedIssue.value.positionIndex &&
						nextLockedIssue.value.issueKey === nextIssue.value.issueKey
					) {
						resultIssueKeys[nextLockedIssue.value.positionIndex] = nextLockedIssue.value.issueKey;
						originalIssueKeysIterator.next();
						lockedRowsIterator.next();
					} else {
						resultIssueKeys[nextLockedIssue.value.positionIndex] = nextLockedIssue.value.issueKey;
						lockedRowsIterator.next();
						issueIndexOffset += 1;
					}
				} else if (lockedIssuesByKey[nextIssue.value.issueKey]) {
					originalIssueKeysIterator.next();
					issueIndexOffset -= 1;
				} else {
					resultIssueKeys[realNextIssueIndex] = nextIssue.value.issueKey;
					originalIssueKeysIterator.next();
				}
			}
			newArrayLength = originalIssueKeys.length + issueIndexOffset;
		} while (!originalIssueKeysIterator.current.done || !lockedRowsIterator.current.done);

		resultIssueKeys.length = Math.max(0, newArrayLength);

		return resultIssueKeys;
	},
);

export const getAllKnownIssueKeys: (state: State) => IssueKey[] = (state) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mergeIssueKeysWithLockedIssues(state).reduce<Array<any>>((result, issueKey) => {
		!isEmpty(issueKey) && result.push(toIssueKey(issueKey));
		return result;
	}, []);

export const getIssueKeyPositionIndex = createSelector(
	mergeIssueKeysWithLockedIssues,
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'issueKeyToFind' implicitly has an 'any' type.
	(state, issueKeyToFind) => issueKeyToFind,
	(issueKeys, issueKeyToFind) => getIndexesOfIssueKeys(issueKeys)[issueKeyToFind],
);

export const isIssueKnown = (state: State, issueKey: string): boolean =>
	getIssueKeyPositionIndex(state, issueKey) !== undefined;
