import type { metrics } from '@atlassian/browser-metrics';
import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import * as marks from '@atlassian/jira-issue-view-common-constants/src/mark-types.tsx';
import { createBlockedOnBackendEntryLog } from '@atlassian/jira-metrics-blocked-on-backend/src/services/common/entry-log/index.tsx';
import { getSimplifiedBlockingTime } from '@atlassian/jira-metrics-blocked-on-backend/src/services/reporters/simplified-reporter/index.tsx';

export type IssueViewLoadMetric = ReturnType<(typeof metrics)['custom']> & {
	startTime: number;
	marks: {};
	mark: (arg1: string, arg2: number) => void;
};
type Args = {
	issueViewLoad: IssueViewLoadMetric;
};
type BlockingTimeReport = {
	issueDataAvailable: number;
	issueReadyForData: number;
};

// Ideally we'd put data like this in the UFO interaction context, but this is a lesser version of the proper FEBENE metric that should be getting to UFO eventually
// More details: https://hello.atlassian.net/wiki/spaces/~919906851/pages/2365833207/PSWARM+FEBENE+in+Jira
// So in the meantime, this is the easiest way to get this metric working
export const blockedOnBackendEntryLog = createBlockedOnBackendEntryLog();
function measureBlockingEntry(
	measureName: string,
	backendBlockingStart: number,
	backendBlockingEnd: number,
) {
	setMark(`${measureName}Start`, { startTime: backendBlockingStart });
	setMark(`${measureName}End`, { startTime: backendBlockingEnd });
	setMeasure(`${measureName}`, `${measureName}Start`, `${measureName}End`);
}

/**
 * From the known times spent blocking on backend data, generate a simplified report of a start and end timestamp
 *
 * This will take the earliest blocking start time, sum together all of the known spans (eliminating overlap time)
 * and set the "blocking end" as the point after the earliest blocking start + the summed blocking time.
 *
 * We do this because what we need the blocked on backend time for is mostly for judging how much
 * it's worth speeding up the backend by, we don't need to report all the details
 * @param issueViewLoad
 */
export const getBackendBlockingTime = (args: Args): BlockingTimeReport => {
	const { blockingStart: blockingStartOrZero, totalBlockingDuration } =
		getSimplifiedBlockingTime(blockedOnBackendEntryLog);
	const backendBlockingStart = Math.max(args.issueViewLoad.startTime, blockingStartOrZero);
	// Add the marks to the issueViewLoad BM3 event
	const backendBlockingEnd = backendBlockingStart + totalBlockingDuration;
	args.issueViewLoad.mark(marks.ISSUE_DATA_AVAILABLE, backendBlockingEnd);
	args.issueViewLoad.mark(marks.ISSUE_READY_FOR_DATA, backendBlockingStart);

	blockedOnBackendEntryLog
		.getBlockedOnBackendEntries()
		.forEach(([blockingReason, { blockingStart, blockingEnd }]) =>
			measureBlockingEntry(
				`issueView_blockedOnBackend_${blockingReason}`,
				blockingStart,
				blockingEnd,
			),
		);
	return { issueDataAvailable: backendBlockingEnd, issueReadyForData: backendBlockingStart };
};
