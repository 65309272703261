// FILTER_QUERY_CHANGED

export const FILTER_QUERY_CHANGED = 'state.actions.filter.FILTER_QUERY_CHANGED' as const;

export type FilterQueryChangedAction = {
	type: typeof FILTER_QUERY_CHANGED;
	payload: {
		filterQuery: String | null;
	};
};

export const filterQueryChangedAction = (filterQuery: String | null): FilterQueryChangedAction => ({
	type: FILTER_QUERY_CHANGED,
	payload: {
		filterQuery,
	},
});

// FILTER_QUERY_SUBMITTED

export const FILTER_QUERY_SUBMITTED = 'state.actions.filter.FILTER_QUERY_SUBMITTED' as const;

export type FilterQuerySubmittedAction = {
	type: typeof FILTER_QUERY_SUBMITTED;
	payload: {
		filterQuery: String | null;
	};
};

export const filterQuerySubmittedAction = (
	filterQuery: String | null,
): FilterQuerySubmittedAction => ({
	type: FILTER_QUERY_SUBMITTED,
	payload: {
		filterQuery,
	},
});

// IS_NEW_FILTER_QUERY_PENDING

export const IS_NEW_FILTER_QUERY_PENDING =
	'state.actions.filter.IS_NEW_FILTER_QUERY_PENDING' as const;

export type IsNewFilterQueryPendingAction = {
	type: typeof IS_NEW_FILTER_QUERY_PENDING;
	payload: {
		isNewFilterQueryPending: boolean;
	};
};

export const isNewFilterQueryPendingAction = (
	isNewFilterQueryPending: boolean,
): IsNewFilterQueryPendingAction => ({
	type: IS_NEW_FILTER_QUERY_PENDING,
	payload: {
		isNewFilterQueryPending,
	},
});

// IS_FILTER_FOCUSED

export const IS_FILTER_FOCUSED = 'state.actions.filter.IS_FILTER_FOCUSED' as const;

export type IsFilterFocusedAction = {
	type: typeof IS_FILTER_FOCUSED;
	payload: {
		isFilterFocused: boolean;
	};
};

export const isFilterFocusedAction = (isFilterFocused: boolean): IsFilterFocusedAction => ({
	type: IS_FILTER_FOCUSED,
	payload: {
		isFilterFocused,
	},
});

// IS_FILTER_QUERY_FAILED_ACTION
export const IS_FILTER_FAILED = 'state.actions.filter.IS_FILTER_FAILED' as const;

export type FilterBadQueryAction = {
	type: typeof IS_FILTER_FAILED;
	payload: {
		isNewFilterQueryPending: boolean;
	};
};

export const filterBadQueryAction = (isNewFilterQueryPending: boolean): FilterBadQueryAction => ({
	type: IS_FILTER_FAILED,
	payload: {
		isNewFilterQueryPending,
	},
});

export type Action =
	| FilterQueryChangedAction
	| FilterQuerySubmittedAction
	| IsNewFilterQueryPendingAction
	| IsFilterFocusedAction
	| FilterBadQueryAction;
