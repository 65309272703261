// ROW BODY
export const minRowsForTreeTableVirtualisation = 100;

// ROW OPERATIONS / INTERACTION
export const childrenOperationIndicatorWidth = 24;
export const maxDepthChildrenOperationIndicatorWidth = 8;
export const steadyDragIndicatorWidth = 3;
export const levelIndentBaseline = steadyDragIndicatorWidth + childrenOperationIndicatorWidth;
export const maxLevelIndentBaseline =
	steadyDragIndicatorWidth + maxDepthChildrenOperationIndicatorWidth;
// FSN-4037 Remove dragHandleWidth related logic during FF cleanup
export const dragHandleWidth = 12;
export const addBarHeight = 5;
export const addBarCircleSize = 24;
export const akButtonHeight = 32;

// COLUMN
export const sortIndicatorWidth = 8;
export const scrollbarWidth = 5;
