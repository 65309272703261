// when input is like 'my-issue-content-panel-5794110541888792068', output is 'my-issue-content-panel'
export const removeTimestamp = (str: string, minDigitsLen = 0) => {
	const indexOfHyphen = str.lastIndexOf('-');
	const tail = str.substr(indexOfHyphen + 1);
	if (/^\d+$/.test(tail) && tail.length >= minDigitsLen) {
		return str.substr(0, indexOfHyphen);
	}

	return str;
};

export const parseForgeIssuePanel = (panelKey: string) => {
	const match = panelKey.match(/^(.*)-(\w+)-(\d+)$/);
	if (match == null) {
		return null;
	}

	const [, extensionId, id, timestamp] = match;
	const added = Number(timestamp);

	return {
		extensionId,
		id,
		added,
	};
};
