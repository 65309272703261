import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div<{ isIndented: boolean }>({
	boxSizing: 'border-box',
	height: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.green.subtle', colors.G200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border.inverse', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border.inverse', colors.N0)}`,
	opacity: 0,
	cursor: 'pointer',
	position: 'relative',
	top: token('space.025', '2px'),
	marginTop: token('space.negative.050', '-4px'),
	'&:hover': {
		opacity: 1,
		transition: 'opacity 0.1s ease-in',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ isIndented }) =>
		isIndented ? token('space.negative.200', '-16px') : token('space.negative.150', '-12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: token('space.300', '24px'),
	width: token('space.300', '24px'),
	marginTop: token('space.negative.150', '-12px'),
	marginLeft: token('space.negative.025', '-2px'),
});
