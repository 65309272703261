export const VerticalScrollDirection = 'VERTICAL' as const;
export const HorizontalScrollDirection = 'HORIZONTAL' as const;

export type ScrollDirection = typeof VerticalScrollDirection | typeof HorizontalScrollDirection;

export type ScrollOffset = {
	offset: number;
	direction: ScrollDirection;
};

export const SET_SCROLL_OFFSET = 'ops.scrolling.offset.SET_SCROLL_OFFSET' as const;

export type SetScrollOffsetAction = {
	type: typeof SET_SCROLL_OFFSET;
	payload: ScrollOffset;
};

export const set = (payload: ScrollOffset): SetScrollOffsetAction => ({
	type: SET_SCROLL_OFFSET,
	payload,
});

// convenience action creators

export const setVertical = (offset: number): SetScrollOffsetAction => ({
	type: SET_SCROLL_OFFSET,
	payload: {
		offset,
		direction: VerticalScrollDirection,
	},
});

export const setHorizontal = (offset: number): SetScrollOffsetAction => ({
	type: SET_SCROLL_OFFSET,
	payload: {
		offset,
		direction: HorizontalScrollDirection,
	},
});
