const isArrayEqual = (array1 = [], array2 = []) => {
	if (array1 === array2) {
		return true;
	}

	// check one level deep
	return array1.length === array2.length && array1.every((item, index) => item === array2[index]);
};

// one level check to see if object references are equal
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (obj1: any, obj2: any) => {
	if (obj1 === obj2) {
		return true;
	}

	if (!obj1 || !obj2) {
		return false;
	}

	const item1Keys = Object.keys(obj1).sort();
	const item2Keys = Object.keys(obj2).sort();

	// are keys equal?
	// @ts-expect-error - TS2345 - Argument of type 'string[]' is not assignable to parameter of type 'never[]'.
	if (!isArrayEqual(item1Keys, item2Keys)) {
		return false;
	}

	// are the values equal?
	const areValuesEqual = item2Keys.every((key) => {
		const value = obj1[key];
		const nextValue = obj2[key];

		if (value === nextValue) {
			return true;
		}

		// @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'never[]'.
		return Array.isArray(nextValue) && Array.isArray(value) && isArrayEqual(value, nextValue);
	});

	return areValuesEqual;
};
