export const PACKAGE_NAME = 'jiraServicedeskQueuesDemoProjectDripFeed';
export const CREATED_DEMO_REQUESTS_PROPERTY_KEY = 'jsd.demo-project.created-demo-requests';

export const REQUEST_ALIAS = {
	triageRequests: 'TRIAGE_REQUESTS',
	highPriority: 'HIGH_PRIORITY',
	trackPerformance: 'TRACK_PERFORMANCE',
} as const;

export const REQUEST_CREATION_ORDER = [
	REQUEST_ALIAS.triageRequests,
	REQUEST_ALIAS.highPriority,
	REQUEST_ALIAS.trackPerformance,
] as const;
