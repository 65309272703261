// STOP_POLL

export const STOP_POLL = 'state.actions.update-metadata.STOP_POLL' as const;

export type StopPollAction = {
	type: typeof STOP_POLL;
};

export const stopPollAction = (): StopPollAction => ({
	type: STOP_POLL,
});

// RESTART_POLL

export const RESTART_POLL = 'state.actions.update-metadata.RESTART_POLL' as const;

export type RestartPollAction = {
	type: typeof RESTART_POLL;
	payload: {
		skipInitialDelay: boolean;
	};
};

export const restartPollAction = (skipInitialDelay = false): RestartPollAction => ({
	type: RESTART_POLL,
	payload: {
		skipInitialDelay,
	},
});

// POLL_SUCCESS
export const POLL_SUCCESS = 'state.actions.update-metadata.POLL_SUCCESS' as const;

export type PollSuccessAction = {
	type: typeof POLL_SUCCESS;
	payload: {
		projectStateHash: string;
	};
};

export const pollSuccessAction = (projectStateHash: string): PollSuccessAction => ({
	type: POLL_SUCCESS,
	payload: {
		projectStateHash,
	},
});

// POLL_CONTENT_NOT_MODIFIED

export const POLL_CONTENT_NOT_MODIFIED =
	'state.actions.update-metadata.POLL_CONTENT_NOT_MODIFIED' as const;

export type PollContentNotModifiedAction = {
	type: typeof POLL_CONTENT_NOT_MODIFIED;
};

export const pollContentNotModifiedAction = (): PollContentNotModifiedAction => ({
	type: POLL_CONTENT_NOT_MODIFIED,
});

// POLL_FAILURE

export const POLL_FAILURE = 'state.actions.update-metadata.POLL_FAILURE' as const;

export type PollFailureAction = {
	type: typeof POLL_FAILURE;
};

export const pollFailureAction = (): PollFailureAction => ({
	type: POLL_FAILURE,
});

// RESET_PROJECT_STATE_HASH

export const RESET_PROJECT_STATE_HASH =
	'state.actions.update-metadata.RESET_PROJECT_STATE_HASH' as const;

export type ResetProjectStateHashAction = {
	type: typeof RESET_PROJECT_STATE_HASH;
};

export const resetProjectStateHashAction = (): ResetProjectStateHashAction => ({
	type: RESET_PROJECT_STATE_HASH,
});

// DISPLAY_UNSUCCESSFUL_POLL_WARNING

export const DISPLAY_UNSUCCESSFUL_POLL_WARNING =
	'state.actions.update-metadata.DISPLAY_UNSUCCESSFUL_POLL_WARNING' as const;

export type DisplayUnsuccessfulPollWarningAction = {
	type: typeof DISPLAY_UNSUCCESSFUL_POLL_WARNING;
};

export const displayUnsuccessfulPollWarningAction = (): DisplayUnsuccessfulPollWarningAction => ({
	type: DISPLAY_UNSUCCESSFUL_POLL_WARNING,
});

// DISMISS_ALL_FLAGS

export const DISMISS_ALL_FLAGS = 'state.actions.update-metadata.DISMISS_ALL_FLAGS' as const;

export type DismissAllFlagsAction = {
	type: typeof DISMISS_ALL_FLAGS;
};

export const dismissAllFlagsAction = (): DismissAllFlagsAction => ({
	type: DISMISS_ALL_FLAGS,
});

// DISMISS_WARNING_FLAG

export const DISMISS_WARNING_FLAG = 'state.actions.update-metadata.DISMISS_WARNING_FLAG' as const;

export type DismissWarningFlagAction = {
	type: typeof DISMISS_WARNING_FLAG;
};

export const dismissWarningFlagAction = (): DismissWarningFlagAction => ({
	type: DISMISS_WARNING_FLAG,
});

export type Action =
	| StopPollAction
	| RestartPollAction
	| PollSuccessAction
	| PollContentNotModifiedAction
	| PollFailureAction
	| ResetProjectStateHashAction
	| DisplayUnsuccessfulPollWarningAction
	| DismissAllFlagsAction
	| DismissWarningFlagAction;
