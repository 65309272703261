import { Component } from 'react';
import type { TableStateConnectorProps } from '../../../../model/cell/index.tsx';
import type {
	IssueFieldLockControls,
	IssueViewTransitionCallback,
} from '../../../../model/fields/types.tsx';
import type { FieldDataSelectorChildrenProps } from '../../../../model/index.tsx';

export type ConnectProps = {
	isLastColumn: boolean;
	isFieldLocked: boolean;
	issueFieldLockControls: IssueFieldLockControls;
	isTableDisabled: boolean;
	fieldName: string;
} & TableStateConnectorProps & {
		onSetEditingFieldPosition: (
			arg1: number,
			arg2: number,
			arg3: number,
			arg4: number,
			arg5: number,
		) => void;
		onIssueViewTransition: IssueViewTransitionCallback;
	};

export type State = {
	cachedFieldData: FieldDataSelectorChildrenProps | null;
};

// eslint-disable-next-line jira/react/no-class-components
export default class TableStateConnector extends Component<ConnectProps, State> {
	static getDerivedStateFromProps(props: ConnectProps, prevState: State) {
		const { isFieldLocked, fieldData } = props;
		if (!isFieldLocked && fieldData && prevState.cachedFieldData !== fieldData) {
			return { cachedFieldData: fieldData };
		}
		return null;
	}

	state = {
		cachedFieldData: null,
	};

	componentWillUnmount() {
		const {
			isFieldLocked,
			issueFieldLockControls: { onFieldUnlock },
		} = this.props;
		// unlock, if locked, when unmounting
		isFieldLocked && onFieldUnlock && onFieldUnlock();
	}

	render() {
		const {
			children,
			issueFieldLockControls,
			isFieldLocked,
			fieldData: givenFieldData,
			isLastColumn,
			fieldName,
			onSetEditingFieldPosition,
			isTableDisabled,
			onIssueViewTransition,
		} = this.props;
		const { cachedFieldData } = this.state;

		const fieldData = !isFieldLocked && givenFieldData ? givenFieldData : cachedFieldData;

		return children({
			issueFieldLockControls,
			fieldData,
			isLastColumn,
			fieldName,
			onSetEditingFieldPosition,
			isFieldLocked,
			isTableDisabled,
			onIssueViewTransition,
		});
	}
}
