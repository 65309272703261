import has from 'lodash/has';
import isNil from 'lodash/isNil';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import { FORGE_CUSTOM_FIELD_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

export const isForgeField = (field?: Field | FieldConfiguration<unknown>) =>
	field &&
	has(field, 'schema.custom') &&
	!isNil(field.schema.custom) &&
	field.schema.custom.includes('ari:cloud:ecosystem::extension');

export const getForgeFieldType = (field: FieldConfiguration<unknown>) => {
	if (!isForgeField(field)) {
		return null;
	}

	if (__SERVER__) {
		return field.schema.type;
	}

	return FORGE_CUSTOM_FIELD_TYPE;
};
