import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { getCreateAnalyticsEvent } from '../../../../state/selectors/analytic/index.tsx';
import type { InitialLoadSsrAnalyticEvent } from './types.tsx';

const STALENESS_THRESHOLD_FOR_FETCH = 60 * 60 * 1000;
const STALENESS_THRESHOLD_FOR_UPDATE = 3 * 60 * 1000;

// @ts-expect-error - TS7006 - Parameter 'payload' implicitly has an 'any' type.
const fireManualOperationalAnalytics = (payload) =>
	getAnalyticsWebClient().getInstance().sendOperationalEvent(payload);

export const sendIssuelistStalenessAnalytics = (
	result: IssueListResponse,
	isUpdate: boolean,
): void => {
	const { metadata } = result;
	const currentTimeInMs = Date.now();
	const delayInMs = currentTimeInMs - metadata.lastUpdatedTimeInMs;
	const thresholdInMs = isUpdate ? STALENESS_THRESHOLD_FOR_UPDATE : STALENESS_THRESHOLD_FOR_FETCH;
	const jiraShard = getMeta('ajs-shard') || '';

	fireManualOperationalAnalytics({
		source: 'viewQueueScreen',
		actionSubject: 'issuelist',
		action: isUpdate ? 'updated' : 'fetched',
		attributes: {
			issuelistStale: delayInMs > thresholdInMs,
			delayInMs,
			currentTimeInMs,
			thresholdInMs,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			browserTabVisibilityState: document.visibilityState,
			jiraShard,
			...metadata,
		},
	});
};

export const fireInitialLoadSsrAnalyticEventWithState = (state: State) => {
	return (event: InitialLoadSsrAnalyticEvent) => {
		if (!fg('jsm_flash_issuelist_analytics')) {
			return;
		}
		const createAnalyticsEvent = getCreateAnalyticsEvent(state);
		if (createAnalyticsEvent) {
			fireOperationalAnalytics(createAnalyticsEvent({}), `jsmIssueList ${event}`, {});
		}
	};
};
