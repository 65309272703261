import React from 'react';
import { ReadView as OrganizationFieldReadView } from '@atlassian/jira-servicedesk-customer-service-custom-fields/src/common/ui/organization-read-view/index.tsx';
import { getNameFromOrganizationFieldData } from '@atlassian/jira-servicedesk-customer-service-custom-fields/src/common/utils.tsx';
import type { ORGANIZATION_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import { UnselectableChildGutterPaddingContainer } from '../common/styled/index.tsx';

type Props = JsonComponentProps<typeof ORGANIZATION_CUSTOM_FIELD_TYPE>;

const Organization = ({
	dataSelectorProps: { value },
	tableInjectedProps: { isLastColumn },
}: Props) =>
	value ? (
		<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
			<OrganizationFieldReadView name={getNameFromOrganizationFieldData(value)} />
		</UnselectableChildGutterPaddingContainer>
	) : null;

export default Organization;
