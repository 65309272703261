type MetaTagSupportOptions = {
	shouldIncludeAjsPrefix: boolean;
};

const getTagKey = (key: string, shouldIncludeAjsPrefix: boolean) =>
	`${shouldIncludeAjsPrefix ? 'ajs-' : ''}${key}`;

const deleteMetaTag = (key: string, shouldIncludeAjsPrefix: boolean) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const existingMetaTag = document.querySelector(
		`meta[name='${getTagKey(key, shouldIncludeAjsPrefix)}']`,
	);
	existingMetaTag &&
		existingMetaTag.parentNode &&
		existingMetaTag.parentNode.removeChild(existingMetaTag);
};

const createMetaTag = (key: string, content: string, shouldIncludeAjsPrefix: boolean) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const existingMetaTag = document.querySelector(
		`meta[name='${getTagKey(key, shouldIncludeAjsPrefix)}']`,
	);
	if (!existingMetaTag) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const meta = document.createElement('meta');
		meta.setAttribute('name', getTagKey(key, shouldIncludeAjsPrefix));
		meta.setAttribute('content', content);
		return { meta, previousValue: undefined };
	}

	const previousValue = existingMetaTag.getAttribute('content');
	existingMetaTag.setAttribute('content', content);

	return { meta: existingMetaTag, previousValue };
};

export const addMetaTags = (
	metaDataForPage: {
		[key: string]: string | null | undefined;
	},
	{ shouldIncludeAjsPrefix }: MetaTagSupportOptions = { shouldIncludeAjsPrefix: true },
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const previousValues: Record<string, any> = {};
	Object.keys(metaDataForPage).forEach((key) => {
		if (!metaDataForPage[key]) {
			deleteMetaTag(key, shouldIncludeAjsPrefix);
		} else {
			const { meta, previousValue } = createMetaTag(
				key,
				// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
				metaDataForPage[key],
				shouldIncludeAjsPrefix,
			);
			if (meta && !previousValue) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.head.appendChild(meta);
			}

			previousValues[key] = previousValue;
		}
	});

	return () => {
		addMetaTags(previousValues);
	};
};
