import React, { type ReactNode } from 'react';
import { getQueueUrl } from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import QueuesBreadcrumbManager from '@atlassian/jira-servicedesk-queues-breadcrumb/src/ui/queues-breadcrumb-manager/index.tsx';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	children: ReactNode;
	projectKey: ProjectKey;
	projectName: string;
	baseUrl: BaseUrl;
	category: QueueCategory;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ children, category, ...rest }: Props) => (
	<QueuesBreadcrumbManager
		{...rest}
		category={category}
		href={getQueueUrl({ projectKey: rest.projectKey, category, queueId: null })}
	>
		{children}
	</QueuesBreadcrumbManager>
);
