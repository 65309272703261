import noop from 'lodash/noop';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import { Subject } from 'rxjs/Subject';

import 'rxjs/add/observable/merge';

import 'rxjs/add/operator/do';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const COMPLETE: any = 'servicedesk.common.epic-util.concurrency-limiter.COMPLETE_VALUE';

export type InjectableSource<S> = {
	readonly injectableSource$: Observable<S>;
	readonly inject: (arg1: S) => void;
};

export const createInjectableSource = <S,>(source: Observable<S>): InjectableSource<S> => {
	const buffer$: Subject<S> = new Subject();
	const completeStream = () => {
		buffer$.next(COMPLETE);
	};

	return {
		// When the source completes, we must also complete the buffer.
		injectableSource$: Observable.merge(
			source.do(noop, completeStream, completeStream),
			buffer$.takeWhile((v) => v !== COMPLETE),
		),
		inject: (value) => {
			buffer$.next(value);
		},
	};
};
