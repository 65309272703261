import { useCallback } from 'react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import queryString from 'query-string';
import { useRouter } from '@atlassian/react-resource-router';

export const useQueryParam = (
	queryParam: string,
): readonly [string, (value?: string | null) => void] => {
	const [
		{
			location: { pathname },
			query,
		},
		{ push },
	] = useRouter();

	const queryParamValue = query[queryParam];

	const setQueryParam = useCallback(
		(newValue?: string | null) => {
			const parsedSearch = omitBy(
				{
					...query,
					[queryParam]: newValue,
				},
				(value) => isNil(value),
			);

			const strSearch = queryString.stringify(parsedSearch);
			push(pathname + (strSearch !== '' ? `?${strSearch}` : ''));
		},
		[pathname, push, query, queryParam],
	);

	return [queryParamValue, setQueryParam] as const;
};
