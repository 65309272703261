import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { CompiledTheme } from '../../../model/themes/index.tsx';

type StyleProps = {
	hasScrolled: boolean;
	leftShadowVisible: boolean;
	rightShadowVisible: boolean;
	theme: CompiledTheme;
	width: number;
};

const marginMultiplier = (hasScrolled: boolean, isShadowVisible: boolean) => {
	if (isShadowVisible) return 0;
	if (hasScrolled) return -1;
	return 1;
};

const getShadowWidth = ({ width, leftShadowVisible, rightShadowVisible }: StyleProps) =>
	width + (leftShadowVisible ? 0 : 8) + (rightShadowVisible ? 0 : 8);

const getMargin = (hasScrolled: boolean, isShadowVisible: boolean) =>
	8 * marginMultiplier(hasScrolled, isShadowVisible);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div<StyleProps>({
	height: 'auto',
	borderBottom: `${token('space.025', '2px')} solid ${token('color.border', 'gray')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ hasScrolled, leftShadowVisible }) =>
		`${getMargin(hasScrolled, leftShadowVisible)}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ hasScrolled, rightShadowVisible }) =>
		`${getMargin(hasScrolled, rightShadowVisible)}px`,
	paddingBottom: token('space.050', '4px'),
	paddingTop: token('space.100', '8px'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InnerWrapper = styled.div<StyleProps>({
	height: 'auto',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ hasScrolled, leftShadowVisible }) =>
		`${getMargin(hasScrolled, leftShadowVisible) * -1}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ hasScrolled, rightShadowVisible }) =>
		`${getMargin(hasScrolled, rightShadowVisible) * -1}px`,
	overflowY: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ShadowWrapper = styled.div<StyleProps>({
	position: 'absolute',
	right: token('space.0', '0'),
	bottom: token('space.negative.150', '-12px'),
	left: token('space.0', '0'),
	overflow: 'hidden',
	height: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${getShadowWidth(props)}px`,
	zIndex: 1,
	pointerEvents: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderShadow = styled.div<StyleProps>({
	position: 'absolute',
	top: token('space.negative.100', '-8px'),
	right: token('space.0', '0'),
	bottom: token('space.negative.100', '-8px'),
	left: token('space.0', '0'),
	height: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${getShadowWidth(props)}px`,
});
