import type { Optional } from '../../../../model/optional/index.tsx';
import type {
	Comparator,
	ComparatorHash,
	DefaultComparator,
} from '../../../../model/sorting/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	defaultComparator: DefaultComparator,
	columnComparators: ComparatorHash,
): Optional<Comparator> => {
	if (!defaultComparator) {
		return undefined;
	}
	return typeof defaultComparator === 'function'
		? defaultComparator
		: columnComparators[defaultComparator];
};
