import React, { Component } from 'react';
import noop from 'lodash/noop';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { PAGE_EVENT_ACTION } from '../utils/events.tsx';

type Props = {
	onMount: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
class ViewTracker extends Component<Props> {
	componentDidMount() {
		this.props.onMount();
	}

	render() {
		return null;
	}
}

const ViewTrackerWithAnalytics = withAnalyticsContext({
	componentName: 'view-tracker',
})(
	withAnalyticsEvents({
		onMount: (createEvent) => createEvent({ action: PAGE_EVENT_ACTION }).fire(),
	})(ViewTracker),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <ViewTrackerWithAnalytics onMount={noop} />;
