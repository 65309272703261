import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME } from '../../common/constants.tsx';
import { post } from '../create-demo-request/index.tsx';
import type { CreateRequestPayload, CreateRequestService } from './types.tsx';

const service: CreateRequestService = async ({
	baseUrl,
	projectId,
	requestAlias,
	analyticsEvent,
}: CreateRequestPayload): Promise<boolean> => {
	try {
		await post(baseUrl, projectId, requestAlias);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		!isClientFetchError(error) &&
			fireErrorAnalytics({
				error,
				meta: {
					id: 'createRequest',
					packageName: PACKAGE_NAME,
					teamName: 'jsm-getting-started',
				},
				attributes: {
					demoDripFeedRequestAlias: requestAlias,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		return false;
	}

	analyticsEvent &&
		fireTrackAnalytics(analyticsEvent, `${PACKAGE_NAME} requestCreated`, {
			demoDripFeedRequestAlias: requestAlias,
		});
	return true;
};

export default service;
