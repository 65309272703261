import {
	performDeleteRequest,
	performPostRequest,
	performPutRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const createGroup = ({
	cloudId,
	projectId,
	category,
	name,
	queueIds,
}: {
	cloudId: string;
	projectId: string;
	category: string;
	name: string;
	queueIds: number[];
}) => {
	const createGroupUrl = `/gateway/api/jsm/queues/cloudid/${cloudId}/api/v1/group`;
	const payload = {
		projectId,
		category,
		name,
		queueIds,
	};
	return performPostRequest(createGroupUrl, {
		body: JSON.stringify(payload),
	});
};
export const updateGroup = ({
	cloudId,
	id,
	version,
	name,
	queueIds,
}: {
	cloudId: string;
	id: string;
	version: number;
	name: string;
	queueIds: number[];
}) => {
	const updateGroupUrl = `/gateway/api/jsm/queues/cloudid/${cloudId}/api/v1/group/${id}`;
	const payload = {
		version,
		name,
		queueIds,
	};
	return performPutRequest(updateGroupUrl, {
		body: JSON.stringify(payload),
	});
};

export const deleteGroup = ({ cloudId, id }: { cloudId: string; id: string }) => {
	const deleteGroupUrl = `/gateway/api/jsm/queues/cloudid/${cloudId}/api/v1/group/${id}`;
	return performDeleteRequest(deleteGroupUrl);
};

export const updateQueueGroupsByGroupId = ({
	cloudId,
	projectId,
	category,
	queueId,
	groupIds,
}: {
	cloudId: string;
	projectId: string;
	category: string;
	queueId: number;
	groupIds: string[];
}) => {
	const batchUpdateQueueGroupsUrl = `/gateway/api/jsm/queues/cloudid/${cloudId}/api/v1/group/batch/update-queue-groups/${projectId}/${category}`;
	const payload = {
		projectId,
		category,
		queueId,
		groupIds,
	};
	return performPutRequest(batchUpdateQueueGroupsUrl, {
		body: JSON.stringify(payload),
	});
};
