import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	CONTROL,
	NOT_ENROLLED,
	VARIATION,
} from '@atlassian/jira-common-util-get-experiment-group/src/index.tsx';
import {
	createStore,
	createHook,
	createContainer,
	createSubscriber,
	defaultRegistry,
} from '@atlassian/react-sweet-state';
import type { InvitePromptOnLinKInsertContext } from '../common/types.tsx';
import {
	fireDrawerTriggeredAnalytics,
	fireModalTriggeredAnalytics,
	fireInviteFromAssignModalExposedAnalytics,
	fireAddTeammatetoJiraAnalytics,
} from './analytics/index.tsx';
import type {
	State,
	Actions,
	ContainerProps,
	ContextConfig,
	InviteAssignModelContext,
} from './types.tsx';

const CONTROLLER_NAME = 'invite-people-drawer';

const initialState: State = {
	isOpen: false,
	inviteFlow: 'unknown',
	continueUrl: undefined,
};

export const resolveInvitefromAssignModalCohort = ({
	inviteFromAssignModalCohort,
}: InviteAssignModelContext): { cohort: string; ineligibilityReasons: string[] } => {
	let resolvedCohort = { cohort: '', ineligibilityReasons: [''] };

	if (inviteFromAssignModalCohort === NOT_ENROLLED) {
		resolvedCohort = { cohort: NOT_ENROLLED, ineligibilityReasons: [] };
	}

	if (inviteFromAssignModalCohort === CONTROL) {
		resolvedCohort = { cohort: CONTROL, ineligibilityReasons: [] };
	}

	if (inviteFromAssignModalCohort === VARIATION) {
		resolvedCohort = { cohort: VARIATION, ineligibilityReasons: [] };
	}

	return resolvedCohort;
};

export const shouldDisplayModal = (inviteAssignModelContext: InviteAssignModelContext) => {
	if (resolveInvitefromAssignModalCohort(inviteAssignModelContext).cohort === VARIATION) {
		return true;
	}
	return false;
};

const Store = createStore<State, Actions>({
	name: CONTROLLER_NAME,
	initialState,
	actions: {
		openDrawer:
			(
				createAnalyticsEvent: CreateUIAnalyticsEvent | null,
				{ inviteFlow, analyticsSource, continueUrl }: ContextConfig,
				inviteAssignModelContext?: InviteAssignModelContext,
				invitePromptOnLinkInsertContext?: InvitePromptOnLinKInsertContext,
			) =>
			({ setState }) => {
				const displayModal =
					inviteAssignModelContext && shouldDisplayModal(inviteAssignModelContext);

				let processedInviteAssignModalContext;

				if (inviteAssignModelContext) {
					processedInviteAssignModalContext = { ...inviteAssignModelContext };

					const { cohort: resultCohort, ineligibilityReasons } =
						resolveInvitefromAssignModalCohort(inviteAssignModelContext);

					fireAddTeammatetoJiraAnalytics(createAnalyticsEvent, resultCohort, analyticsSource);

					fireInviteFromAssignModalExposedAnalytics(
						createAnalyticsEvent,
						inviteAssignModelContext.inviteFromAssignModalCohort,
						resultCohort,
						ineligibilityReasons,
						analyticsSource,
					);

					processedInviteAssignModalContext.inviteFromAssignModalCohort = resultCohort;
				}

				if (displayModal) {
					fireModalTriggeredAnalytics(
						createAnalyticsEvent,
						inviteFlow,
						inviteAssignModelContext,
						analyticsSource,
					);
				} else {
					fireDrawerTriggeredAnalytics(createAnalyticsEvent, inviteFlow, analyticsSource);
				}

				setState({
					isOpen: true,
					inviteFlow,
					continueUrl,
					inviteAssignModelContext: processedInviteAssignModalContext,
					invitePromptOnLinkInsertContext,
				});
			},
		closeDrawer:
			() =>
			({ setState }) =>
				setState(initialState),
	},
});

export const useInvitePeopleDrawer = createHook(Store);
export const InvitePeopleDrawerProvider = createContainer<State, Actions, ContainerProps>(Store, {
	onInit:
		() =>
		({ setState }, { isOpen }) => {
			setState({ isOpen });
		},
});

export const InvitePeopleDrawerSubscriber = createSubscriber<
	State,
	Actions,
	boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store, {
	displayName: 'InvitePeopleDrawerSubscriber',
	selector: (state: State) => state.isOpen,
});

export const triggerOpenDrawer = (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	contextConfig: ContextConfig,
	inviteAssignModelContext?: InviteAssignModelContext,
) => {
	defaultRegistry
		.getStore(Store)
		?.actions.openDrawer(createAnalyticsEvent, contextConfig, inviteAssignModelContext);
};
