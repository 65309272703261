import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { SortedQueue } from '../../../model/index.tsx';

// EDIT_QUEUE

export const EDIT_QUEUE = 'state.actions.queue.EDIT_QUEUE' as const;

export type EditQueueAction = {
	type: typeof EDIT_QUEUE;
};

export const editQueueAction = (): EditQueueAction => ({
	type: EDIT_QUEUE,
});

// CLONE_QUEUE

export const CLONE_QUEUE = 'state.actions.queue.CLONE_QUEUE' as const;

export type CloneQueueAction = {
	type: typeof CLONE_QUEUE;
	payload: {
		category: QueueCategory;
	};
};

export const cloneQueueAction = (category: QueueCategory): CloneQueueAction => ({
	type: CLONE_QUEUE,
	payload: { category },
});

// QUEUE_CHANGED

export const QUEUE_CHANGED = 'state.actions.queue.QUEUE_CHANGED' as const;

// SHIELD-3608: payload should be of type Queue. Currently used as SortedQueue
// to support legacy
export type QueueChangedAction = {
	type: typeof QUEUE_CHANGED;
	payload: {
		queue: SortedQueue;
	};
};

export const queueChangedAction = (queue: SortedQueue): QueueChangedAction => ({
	type: QUEUE_CHANGED,
	payload: { queue },
});

// SET_QUEUE

export const SET_QUEUE = 'state.actions.queue.SET_QUEUE' as const;

export type SetQueueAction = {
	type: typeof SET_QUEUE;
	payload: {
		queue: SortedQueue;
	};
};

export const setQueueAction = (queue: SortedQueue): SetQueueAction => ({
	type: SET_QUEUE,
	payload: { queue },
});

export type Action = EditQueueAction | QueueChangedAction | SetQueueAction | CloneQueueAction;
