export const LOADING = 'LOADING' as const;
export const FAILED = 'FAILED' as const;
export const LOADED = 'LOADED' as const;

export type ProjectHasSubtaskValidators =
	| {
			status: typeof LOADING;
	  }
	| {
			status: typeof FAILED;
	  }
	| {
			status: typeof LOADED;
			value: boolean;
	  };

export type Validators = {
	projectHasSubtaskValidators: ProjectHasSubtaskValidators;
};
