/* eslint-disable jira/react-arrow-function-property-naming */
import React, { Component, type ComponentType, type ElementRef } from 'react';
import { styled } from '@compiled/react';
import { withAnalyticsEvents, type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { triggerOpenDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers/index.tsx';
import { fireUIAnalytics, type Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { UserProps } from '../../../../../model/fields/json-fields/common/types.tsx';
import type { FieldType } from '../../../../../model/fields/types.tsx';
import { ASSIGNEE_CONTEXT, type UserForEditing, type FieldContext } from '../model/index.tsx';
import addIcon from './assets/add.png';
import {
	MENU_MIN_WIDTH,
	INVITE_PEOPLE_ID,
	UNASSIGNED_ID,
	ASSIGN_TO_ME_ID,
	AUTOMATIC_ID,
	AUTOMATIC_ID_VALUE,
} from './constants.tsx';
import messages from './messages.tsx';

export type Props = {
	issueKey: string;
	isSaving: boolean;
	isAdmin: boolean;
	fieldType: FieldType;
	fieldId: string;
	value: UserProps | undefined;
	width: undefined | number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AsyncUserPicker: ComponentType<any>;
	avatarUrl: string | undefined;
	atlassianAccountId: string;
	emailAddress: string | undefined;
	displayName: string | undefined;
	rowListRef: HTMLDivElement | undefined;
	enablePeopleInvite: boolean;
	onSearchUser: (arg1: string, arg2: string, arg3: FieldContext) => Promise<UserForEditing[]>;
	// FREE-3021 refactor the responsibility for data transformation.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange: (arg1: string, arg2: string, arg3?: any) => void;
	onComponentLoadFailure: () => void;
	onEditCancel: () => void;
	onEditSelection: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSetEditingRef: (ref: ElementRef<any> | null) => void;
};

type PropsWithAnalytics = Props & {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};
export type PropsWithIntl = PropsWithAnalytics & {
	intl: IntlShape;
};

const toUserForEditing = (value?: UserProps): UserForEditing | undefined => {
	if (!value) {
		return undefined;
	}
	return {
		id: value.accountId,
		name: value.displayName || value.emailAddress || '',
		avatarUrl: value.avatarUrl,
		value,
	};
};

type State = {
	isOpen: boolean;
};

const fireInviteItemRendererEvent = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	fieldId: string,
	suggestionsCount: number,
	isAdmin: boolean,
) => {
	if (createAnalyticsEvent === undefined || createAnalyticsEvent === null) {
		return;
	}

	const action = 'rendered';
	const actionSubject = 'inviteItem';

	const event = createAnalyticsEvent({
		action,
		actionSubject,
	});

	const attrs: Attributes = {
		fieldId,
		userRole: isAdmin ? 'admin' : 'basic',
		suggestionsCount,
		source: 'assigneeField',
	};
	fireUIAnalytics(event, `${actionSubject} ${action}`, attrs);
};

// eslint-disable-next-line jira/react/no-class-components
export class EditAssignee extends Component<PropsWithIntl, State> {
	static defaultProps = {
		isAdmin: false,
		enablePeopleInvite: true,
	};

	state = { isOpen: true };

	onChange = (item: UserForEditing) => {
		const { onChange, issueKey, fieldId, fieldType, intl, enablePeopleInvite } = this.props;

		if (enablePeopleInvite !== true || item.id !== INVITE_PEOPLE_ID) {
			if (item.value) {
				onChange(issueKey, fieldId, { ...item.value, fieldType });
			} else {
				onChange(issueKey, fieldId, {
					displayName: intl.formatMessage(messages.unassigned),
					fieldType,
				});
			}
		}
	};

	onBlur = () => {
		this.setState({ isOpen: false });
		this.props.onEditCancel();
	};

	onFocus = () => {
		this.setState({ isOpen: true });
	};

	onSelection = (item: UserForEditing) => {
		const { onEditSelection, createAnalyticsEvent, enablePeopleInvite } = this.props;
		if (enablePeopleInvite === true && item.id === INVITE_PEOPLE_ID) {
			triggerOpenDrawer(createAnalyticsEvent ?? null, { inviteFlow: 'assignee' });
		} else {
			onEditSelection();
		}
	};

	loadAssignToMeUser(): UserForEditing | undefined {
		const { value, avatarUrl, atlassianAccountId, emailAddress, displayName, intl } = this.props;

		if (value && value.accountId === atlassianAccountId) {
			return undefined;
		}

		if (!emailAddress && !avatarUrl && !displayName) {
			log.safeWarnWithoutCustomerData(
				'issue-table.view.fields.common.single-user-select.user-select',
				'Assign-to-me not initialized in time',
				{},
			);
			return undefined;
		}

		const assignToMeUser = {
			id: ASSIGN_TO_ME_ID,
			name: intl.formatMessage(messages.assignToMe),
			avatarUrl,
			value: {
				emailAddress,
				accountId: atlassianAccountId,
				avatarUrl,
				displayName,
			},
		};

		return assignToMeUser;
	}

	loadUnassignedUser(): UserForEditing | undefined {
		const { value, intl } = this.props;

		// While the 'Unassigned' option is defined as value being undefined, the value can briefly have a displayName
		// of "Unassigned" when selected from the user-picker, before the issue-table polls again. To avoid this, added
		// a check of accountId as the 'Unassigned' option does not have an accountId.
		if (!value || !value.accountId) {
			return undefined;
		}

		const unassignedUser = {
			id: UNASSIGNED_ID,
			name: intl.formatMessage(messages.unassigned),
		};

		return unassignedUser;
	}

	loadAutomaticUser(): UserForEditing | undefined {
		const { value, intl } = this.props;

		if (value && value.accountId === AUTOMATIC_ID_VALUE) {
			return undefined;
		}

		const automaticUser = {
			id: AUTOMATIC_ID,
			name: intl.formatMessage(messages.automatic),
			value: {
				accountId: AUTOMATIC_ID_VALUE,
				displayName: intl.formatMessage(messages.automatic),
				avatarUrl: '',
				emailAddress: '',
			},
		};

		return automaticUser;
	}

	getInvitePeopleOption(): UserForEditing {
		const { intl, isAdmin } = this.props;

		return {
			id: INVITE_PEOPLE_ID,
			avatarUrl: addIcon,
			name: intl.formatMessage(
				isAdmin ? messages.invitePeopleOptionAdmin : messages.invitePeopleOption,
			),
		};
	}

	loadUsers = (query: string): (UserForEditing | Promise<UserForEditing[]>)[] => {
		const { issueKey, onSearchUser, enablePeopleInvite, createAnalyticsEvent, isAdmin, fieldId } =
			this.props;

		const searchPromise = onSearchUser(issueKey, query, ASSIGNEE_CONTEXT).then((users) => {
			if (enablePeopleInvite === true && query !== undefined && query !== '' && users.length < 3) {
				fireInviteItemRendererEvent(createAnalyticsEvent, fieldId, users.length, isAdmin);
				return [...users, this.getInvitePeopleOption()];
			}

			return users;
		});

		const options: Array<UserForEditing | Promise<Array<UserForEditing>>> = [];

		const assignToMeUser = this.loadAssignToMeUser();
		if (assignToMeUser) {
			options.push(assignToMeUser);
		}

		const unassignedUser = this.loadUnassignedUser();
		if (unassignedUser) {
			options.push(unassignedUser);
		}

		const automaticUser = this.loadAutomaticUser();
		if (automaticUser) {
			options.push(automaticUser);
		}

		options.push(searchPromise);
		return options;
	};

	render() {
		const {
			width,
			value,
			AsyncUserPicker,
			onComponentLoadFailure,
			rowListRef,
			fieldId,
			isSaving,
			onSetEditingRef,
		} = this.props;
		const isOpen = this.state.isOpen && !isSaving;

		// onSetEditingRef is used to set ref of current container such that
		// UserPicker options list can render with the correct X/Y offset.
		// This is required in react-redux v8 upgrade because ref is not set properly.
		// https://hello.atlassian.net/wiki/spaces/JFP/pages/3593342635/Rollout+Strategy+For+React-Redux+V8#JSM-assignee-bug
		return (
			<Container
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="issue-table.view.fields.common.single-user-select.user-select"
				ref={onSetEditingRef}
			>
				<AsyncUserPicker
					open={isOpen}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
					loadOptions={this.loadUsers}
					width={width}
					value={toUserForEditing(value)}
					onChange={this.onChange}
					onError={onComponentLoadFailure}
					isClearable={false}
					menuMinWidth={MENU_MIN_WIDTH}
					menuPortalTarget={rowListRef}
					onSelection={this.onSelection}
					fieldId={fieldId}
				/>
			</Container>
		);
	}
}

export default withAnalyticsEvents()(injectIntl(EditAssignee));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
	left: token('space.negative.050', '-4px'),
});
