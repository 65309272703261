import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { getEnhancedCapabilityHeader } from '@atlassian/jira-ufo-capability-header/src/index.tsx';
import type IssueMutation from './issue-mutation/index.tsx';
import { MutationSource } from './storage/constants.tsx';
import IssueCache from './storage/index.tsx';
import { mergeResponseData } from './storage/utils.tsx';
import type { IEcClient, TenantContext } from './types.tsx';

export class EcClient implements IEcClient {
	#issueCache: IssueCache;

	#config: Object;

	constructor() {
		this.#issueCache = new IssueCache('issue-cache-v1');
		this.#config = {};
	}

	/**
	 * A method to set tenant level details.
	 * These details include activation id, cloud id and user id
	 */

	static setTenantDetails(
		activationID: string | undefined,
		cloudID: string | undefined,
		atlassianAccountId: string | null | undefined,
	) {
		IssueCache.setTenantDetails(activationID, cloudID, atlassianAccountId);
	}

	/**
	 * A method to fire analytics events for both success and failure
	 */
	static fireAnalyticsEvents(
		issueMutations: IssueMutation | IssueMutation[],
		key:
			| { analyticsEventObj: Object; analyticsMetadata: { scenario: string } }
			| { analyticsMetadata: { scenario: string } } = {
			analyticsMetadata: {
				scenario: 'unknown',
			},
		},
		isSaved: boolean,
		analyticsFailureEventObj: Object = {},
	) {
		try {
			// let mutationAnalyticsObj: any = [];
			let trackingId = '-1';
			if (Array.isArray(issueMutations)) {
				//   mutationAnalyticsObj = issueMutations.map((item: IssueMutation) => item.issueId);
				trackingId =
					String(new Date(issueMutations[0]?.lastModified).valueOf()) + IssueCache.userID;
			} else {
				trackingId = String(new Date(issueMutations.lastModified).valueOf()) + IssueCache.userID;
				//  mutationAnalyticsObj = [issueMutations.issueId];
			}

			const payload: {
				action: string;
				actionSubject: string;
				attributes: Object;
				source: string;
			} = {
				action: 'mutated',
				actionSubject: 'issue',
				source: key?.analyticsMetadata?.scenario || 'unknown',
				attributes: {
					...key.analyticsMetadata,
					// issueMutationDetails: mutationAnalyticsObj,
					trackingId,
				},
			};
			// Error condition
			if (!isSaved) {
				if (!analyticsFailureEventObj)
					// eslint-disable-next-line no-param-reassign
					analyticsFailureEventObj = { error: true, errorMsg: payload.source };
				payload.attributes = { ...payload.attributes, ...analyticsFailureEventObj };
			}

			if (Object.prototype.hasOwnProperty.call(key, 'analyticsEventObj')) {
				fireTrackAnalytics(
					// @ts-expect-error - Argument of type 'Object' is not assignable to parameter of type 'UIAnalyticsEvent'.
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(key as { analyticsEventObj: Object }).analyticsEventObj,
					`${payload.actionSubject} ${payload.action}`,
					payload.attributes,
				);
			} else {
				// if there is no UI component that triggers the event then we manually fire the event
				getAnalyticsWebClientPromise().then((client) => {
					client.getInstance().sendTrackEvent(payload);
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			logger.safeErrorWithoutCustomerData('jsis-ec-client', e);
		}
	}

	/**
	 * A method to save a single issue mutations in cache
	 */
	async saveIssueMutationToCache(
		issueMutation: IssueMutation,
		key:
			| { analyticsEventObj: Object; analyticsMetadata: { scenario: string } }
			| { analyticsMetadata: { scenario: string } } = {
			analyticsMetadata: {
				scenario: 'unknown',
			},
		},
		analyticsFailureEventObj: Object = {},
	): Promise<boolean> {
		if (ff('odin.enable.backend.cache.ec.client')) {
			const isSaved = IssueCache.setBackendCache(issueMutation);
			EcClient.fireAnalyticsEvents(issueMutation, key, isSaved, analyticsFailureEventObj);
			return isSaved;
		}

		return this.#issueCache.isPresent(issueMutation.issueId).then(async (result) => {
			let isSaved = false;
			if (issueMutation.mutationType === MutationSource.DELETE) {
				isSaved = await this.#issueCache.delete(issueMutation.issueId);
			} else if (result || issueMutation.mutationType === MutationSource.UPDATE) {
				isSaved = await this.#issueCache.update(issueMutation.issueId);
			} else {
				isSaved = await this.#issueCache.create(issueMutation.issueId);
			}

			EcClient.fireAnalyticsEvents(issueMutation, key, isSaved, analyticsFailureEventObj);
			return new Promise((resolve) => resolve(isSaved));
		});
	}

	/**
	 * A method to save multiple issue mutations in cache
	 */
	async saveIssueMutationsToCache(
		issueMutations: IssueMutation[],
		key:
			| { analyticsEventObj: Object; analyticsMetadata: { scenario: string } }
			| { analyticsMetadata: { scenario: string } } = {
			analyticsMetadata: {
				scenario: 'unknown',
			},
		},
		analyticsFailureEventObj: Object = {},
	): Promise<boolean[]> {
		const data: boolean[] = [];

		if (ff('odin.enable.backend.cache.ec.client')) {
			const isSaved = IssueCache.setBackendCache(issueMutations);
			EcClient.fireAnalyticsEvents(issueMutations, key, isSaved, analyticsFailureEventObj);
			return [isSaved];
		}

		issueMutations.forEach(async (issueMutation) => {
			this.#issueCache.isPresent(issueMutation.issueId).then(async (result) => {
				let isSaved = false;
				if (issueMutation.mutationType === MutationSource.DELETE) {
					isSaved = await this.#issueCache.delete(issueMutation.issueId);
				} else if (result || issueMutation.mutationType === MutationSource.UPDATE) {
					isSaved = await this.#issueCache.update(issueMutation.issueId);
				} else {
					isSaved = await this.#issueCache.create(issueMutation.issueId);
				}

				data.push(isSaved);
				EcClient.fireAnalyticsEvents(issueMutations, key, isSaved, analyticsFailureEventObj);
			});
		});

		return new Promise((resolve) => resolve(data));
	}

	/**
	 * A method to get issue mutations in saved in cache
	 */
	getIssueMutationFromCache(key: null | string | string[]): Promise<{
		[key: string]: IssueMutation;
	}> {
		return this.#issueCache.get(key);
	}

	/**
	 * A method to remove the deleted issues from the search API
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async filterData(data: any[]): Promise<any[]> {
		const localStorageData = await this.getIssueMutationFromCache(null);

		const deletedData = new Set();

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.values(localStorageData).forEach((element: any) => {
			if (element.mutationType === MutationSource.DELETE) {
				deletedData.add(element.issueId);
			}
		});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const filteredData: Array<any> = [];
		let success = true;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.values(data).forEach((element: any) => {
			if (!element.id || typeof element.id !== 'number') {
				success = false;
			} else if (!deletedData.has(element.id.toString())) {
				filteredData.push(element);
			}
		});

		return new Promise(
			(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				resolve: (result: Promise<Array<any>> | Array<any>) => void,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				reject: (error?: any) => void,
			) => {
				if (success) resolve(filteredData);
				else reject(new Error());
			},
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async constructMatchRequest(): Promise<any> {
		const issueData: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			issues: any[];
			hydrationMode: number;
		} = {
			issues: [],
			hydrationMode: 0,
		};

		const data: { [key: string]: IssueMutation } = await this.getIssueMutationFromCache(null);
		Object.values(data).forEach((issueMutation: IssueMutation) => {
			const element = { issueId: issueMutation.issueId, version: issueMutation.version };
			issueData.issues.push(element);
		});

		const options = {
			method: 'POST',
			headers: {
				content_type: 'application/json',
				// @ts-expect-error - TS2339 - Property 'capability' does not exist on type 'object'.
				'x-atlassian-capability': getEnhancedCapabilityHeader(this.#config.capability),
			},
			body: JSON.stringify(issueData),
		};

		return new Promise((resolve) => resolve(options));
	}

	/**
	 * A method to get the relevant search data from the parsing function that the user has provided
	 */

	static getIssueDataFromSearch(
		data: Object,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		parseIssueData: (issueData: { [key: string]: any }) => any[],
	) {
		const result: Object[] = parseIssueData(data);
		if (result == null) return [];
		return result;
	}

	/**
	 * A method to get the search data and reconcile it with the data present in cache
	 */
	async searchAndReconcile(
		matchApiUrl: string,
		jqlMatchingKey: string,
		search: Promise<{
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
		}>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		parseIssueData: (issueData: { [key: string]: any }) => any[],
		capability: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> {
		// @ts-expect-error - TS2339 - Property 'capability' does not exist on type 'object'.
		this.#config.capability = capability;
		const matchRequest = await this.constructMatchRequest();
		if (
			matchRequest &&
			matchRequest.body &&
			matchRequest.body.issues &&
			matchRequest.body.issues.length === 0
		) {
			return search.then(
				(data) =>
					new Promise((resolve) =>
						resolve({
							reconciledIssues: EcClient.getIssueDataFromSearch(data, parseIssueData),
							matchAPIResponse: {
								response: {
									resultsByIssueId: {},
								},
								issue: {
									issuesResponse: {},
								},
							},
						}),
					),
			);
		}
		const matchPromise = fetchJson(matchApiUrl, matchRequest);
		return Promise.all([search, matchPromise]).then(([data, response]) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const structuredData: any[] = EcClient.getIssueDataFromSearch(data, parseIssueData);
			return this.filterData(structuredData) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.then((filteredSearchData: any[]) => {
					if (
						response == null ||
						response.response == null ||
						response.response.resultsByIssueId == null
					) {
						throw new Error('Match response is invalid!');
					}

					const matchedIssueIds = response.response.resultsByIssueId;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const filteredMatchedIssueIdsData: any[] = [];

					Object.entries(matchedIssueIds).forEach(([key, value]) => {
						if (value === 'RESULT_MATCH') {
							filteredMatchedIssueIdsData.push({ id: parseInt(key, 10) });
						}
					});

					return new Promise((resolve) => {
						resolve({
							reconciledIssues: mergeResponseData(filteredSearchData, filteredMatchedIssueIdsData),
							matchAPIResponse: response,
						});
					});
				})
				.catch(
					() =>
						new Promise((resolve) =>
							resolve({
								reconciledIssues: structuredData,
								matchAPIResponse: response,
							}),
						),
				);
		});
	}
}

const ecClient = new EcClient();
export function getEcClient(tenantContext: TenantContext = {}) {
	EcClient.setTenantDetails(
		tenantContext?.activationId,
		tenantContext?.cloudId,
		tenantContext?.atlassianAccountId,
	);
	return ecClient;
}
export default ecClient;
