import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	serviceRequestsLearnMore: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.service-requests-learn-more',
		defaultMessage: 'Learn more about service requests',
		description: 'Service request queues empty state - Learn more link text',
	},
	incidentsLearnMore: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.incidents-learn-more',
		defaultMessage: 'Learn more about incident management',
		description: 'Incident queues empty state - Learn more link text',
	},
	problemsLearnMore: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.problems-learn-more',
		defaultMessage: 'Learn more about problem management',
		description: 'Problem queues empty state - Learn more link text',
	},
	changesLearnMore: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.changes-learn-more',
		defaultMessage: 'Learn more about change management',
		description: 'Change queues empty state - Learn more link text',
	},
	postIncidentReviewsLearnMore: {
		id: 'servicedesk-queues-empty-queue.common.empty-practice-queue.empty-unconfigured-practice-queue.post-incident-reviews-learn-more',
		defaultMessage: 'Learn more about post-incident reviews',
		description: 'Post-incident reviews queues empty state - Learn more link text',
	},
});
