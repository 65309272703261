import React, { type ReactNode } from 'react';
import { BreadcrumbManager } from '@atlassian/jira-breadcrumbs/src/breadcrumb-manager/index.tsx';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import { usePracticeList } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import {
	INCIDENTS,
	CHANGES,
	SERVICE_REQUEST,
	PROBLEMS,
	POST_INCIDENT_REVIEWS,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import practiceMessages from '@atlassian/jira-servicedesk-work-category/src/common/messages.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactNode;
	projectKey: string;
	projectName: string;
	baseUrl: string;
	href: string;
	text?: string;
	type?: string;
	category?: QueueCategory;
};

const getBreadcrumbText = (category: QueueCategory) => {
	if (!category) {
		return messages.queues;
	}
	switch (category) {
		case INCIDENTS:
			return practiceMessages.incidents;
		case CHANGES:
			return practiceMessages.changes;
		case SERVICE_REQUEST:
			return practiceMessages.serviceRequests;
		case PROBLEMS:
			return practiceMessages.problems;
		case POST_INCIDENT_REVIEWS:
			return practiceMessages.postIncidentReviews;
		default:
			return practiceMessages.queues;
	}
};

const QueuesBreadcrumbManager = ({
	children,
	projectKey,
	projectName,
	baseUrl,
	href,
	text = '',
	type = 'queues',
	category = undefined,
}: Props) => {
	const [practiceList] = usePracticeList();
	const hasPractices = Boolean(practiceList && practiceList.length > 1);
	const { formatMessage } = useIntl();

	return (
		<BreadcrumbManager>
			<ProjectBreadcrumb baseUrl={baseUrl} projectKey={projectKey} projectName={projectName}>
				<Breadcrumb
					breadcrumb={{
						href,
						text: text || formatMessage(getBreadcrumbText(hasPractices ? category : undefined)),
						type,
					}}
				>
					{children}
				</Breadcrumb>
			</ProjectBreadcrumb>
		</BreadcrumbManager>
	);
};

export default QueuesBreadcrumbManager;
