import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type COLOR_GQL_FIELD,
	ISSUE_FIELD_STRING_CF_TYPE,
	EPIC_COLOR_TYPE,
	COLOR_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggColorToLegacyField: AggTransformerFunction<typeof COLOR_GQL_FIELD> = (
	node,
) => {
	const value = node.color?.colorKey ?? null;

	switch (node.type) {
		case EPIC_COLOR_TYPE:
		case COLOR_CF_TYPE:
			return {
				...transformAggNodeToLegacyField(node),
				value,
				schema: {
					configuration: null,
					type: ISSUE_FIELD_STRING_CF_TYPE,
					renderer: PLAIN_TEXT,
					custom: node.type,
					system: null,
					items: null,
				},
			};
		default:
			return undefined;
	}
};
