import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import { Observable } from 'rxjs/Observable';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { SortOrder } from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import triggerAnalyticsEvent from '@atlassian/jira-servicedesk-queues-common/src/analytics/index.tsx';
import { getFieldTypesEligibleForJsonData } from '@atlassian/jira-servicedesk-queues-common/src/json-fields/index.tsx';
import {
	type QueueId,
	type Column,
	fromQueueId,
	LOCAL_STORAGE_SOURCE,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import { transformColumns } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/index.tsx';
import type { LoadedIssueList } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/transform/types.tsx';
import {
	getFromLocalStorage,
	setInLocalStorage,
} from '@atlassian/jira-servicedesk-queues-common/src/services/local-storage/local-storage.tsx';
import getTransformedIssues from '../transform/index.tsx';
import type { QueueLocalStorage } from './types.tsx';

const ISSUES_KEY = 'issues';
const LOCAL_STORAGE_ANALYTICS_KEY = 'agent-view.issuelist.local-storage.status';

const getLocalStorageData = (queueId: QueueId): MaybeType<QueueLocalStorage> =>
	Maybe.fromNull(getFromLocalStorage(`${ISSUES_KEY}_${fromQueueId(queueId)}`));

const localStorageMatchesIssueListResponse = (
	localStorage: QueueLocalStorage,
	issueListResponse: IssueListResponse,
	jql: string,
	sortedBy?: string,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	sortOrder: SortOrder,
): boolean =>
	isEqual(localStorage.jql, jql) &&
	isEqual(localStorage.issueListResponse.issueHash, issueListResponse.issueHash) &&
	isEqual(localStorage.issueListResponse.columns, issueListResponse.columns) &&
	isEqual(localStorage.sortedBy, sortedBy) &&
	(!sortedBy || isEqual(localStorage.sortOrder, sortOrder));

export const storeIssuesInLocalStorage = (
	issueListResponse: IssueListResponse,
	jql: string,
	queueId: QueueId,
	sortedBy?: string,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	sortOrder: SortOrder,
	isUpdate: boolean,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	if (!isUpdate) {
		const localStorageData = getLocalStorageData(queueId);
		const hasData = localStorageData.isSome();
		const hasLatestData = localStorageData.cata(
			() => false,
			// @ts-expect-error - TS7006 - Parameter 'localStorage' implicitly has an 'any' type.
			(localStorage) =>
				localStorageMatchesIssueListResponse(
					localStorage,
					issueListResponse,
					jql,
					sortedBy,
					sortOrder,
				),
		);
		// remove outdated analytics event with FF cleanup `view_queues_local_storage_data_updated`
		triggerAnalyticsEvent(LOCAL_STORAGE_ANALYTICS_KEY, {
			hasData,
			hasLatestData,
		});
		if (createAnalyticsEvent && fg('view_queues_local_storage_data_updated')) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'viewQueuesLocalStorageData updated', {
				hasData,
				hasLatestData,
			});
		}
	}
	setInLocalStorage(`${ISSUES_KEY}_${fromQueueId(queueId)}`, {
		jql,
		issueListResponse,
		jsonColumns: getFieldTypesEligibleForJsonData(),
		sortedBy,
		sortOrder,
	});
};

const localStorageMatchesStore = (
	localStorage: QueueLocalStorage,
	jql: string,
	columns: Column[],
	columnTypesAsJson: string[],
	sortedBy?: string,
	sortOrder?: SortOrder,
): boolean =>
	isEqual(localStorage.jql, jql) &&
	isEqual(transformColumns(localStorage.issueListResponse.columns), columns) &&
	difference(localStorage.jsonColumns, columnTypesAsJson).length === 0 &&
	isEqual(localStorage.sortedBy, sortedBy) &&
	(!sortedBy || isEqual(localStorage.sortOrder, sortOrder));

const maybeIssuesInLocalStorageInternal = (
	queueId: QueueId,
	jql: string,
	columns: Column[],
	columnTypesAsJson: string[],
	filterQuery: string | null,
	sortedBy?: string,
	sortOrder?: SortOrder,
): MaybeType<LoadedIssueList> => {
	try {
		return (
			getLocalStorageData(queueId)
				// @ts-expect-error - TS7006 - Parameter 'localStorage' implicitly has an 'any' type.
				.map((localStorage) => {
					if (
						localStorageMatchesStore(
							localStorage,
							jql,
							columns,
							columnTypesAsJson,
							sortedBy,
							sortOrder,
						)
					) {
						setMark('jsd.performance.profile.queues.localstorage.received');
						return Maybe.Some(
							getTransformedIssues(
								localStorage.issueListResponse,
								LOCAL_STORAGE_SOURCE,
								filterQuery !== null,
								false,
							),
						);
					}
					return Maybe.None();
				})
				.orSome(Maybe.None())
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		return Maybe.None();
	}
};

export const maybeIssuesInLocalStorage = (
	queueId: QueueId,
	jql: string,
	columns: Column[],
	columnTypesAsJson: string[],
	filterQuery: string | null,
	sortedBy?: string,
	sortOrder?: SortOrder,
): MaybeType<LoadedIssueList> => {
	const maybeIssueList = maybeIssuesInLocalStorageInternal(
		queueId,
		jql,
		columns,
		columnTypesAsJson,
		filterQuery,
		sortedBy,
		sortOrder,
	);

	return maybeIssueList;
};

export const getIssuesInLocalStorage = (
	queueId: QueueId,
	jql: string,
	columns: Column[],
	columnTypesAsJson: string[],
	filterQuery: string | null,
	sortedBy?: string,
	sortOrder?: SortOrder,
): Observable<LoadedIssueList> =>
	maybeIssuesInLocalStorage(
		queueId,
		jql,
		columns,
		columnTypesAsJson,
		filterQuery,
		sortedBy,
		sortOrder,
	).cata(Observable.empty, Observable.of);
