export const ISSUE_FRAGMENT_READY_MARK = 'ISSUE_FRAGMENT_READY_MARK';
export const ISSUE_START_RENDERING_MARK = 'ISSUE_START_RENDERING_MARK';
export const ISSUE_PREVIEW_RENDERED = 'ISSUE_PREVIEW_RENDERED';
export const ISSUE_FETCHED_MARK = 'ISSUE_FETCHED_MARK';
export const ISSUE_START_DATA_FETCH = 'ISSUE_START_FETCHING_DATA';
export const ISSUE_READY_FOR_DATA = 'ISSUE_READY_FOR_DATA';
export const ISSUE_DATA_AVAILABLE = 'ISSUE_DATA_AVAILABLE';
export const ISSUE_CACHED_CONTENT_LOADED = 'ISSUE_CACHED_CONTENT_LOADED';
export const ISSUE_SSR_RENDERED_MARK = 'ssr.issue.rendered';
export const ISSUE_START_GIRA_FETCH = 'ISSUE_START_GIRA_FETCH';
export const ISSUE_FINISH_GIRA_FETCH = 'ISSUE_FINISH_GIRA_FETCH';
export const ISSUE_MOUNTED = 'ISSUE_MOUNTED';
export const ISSUE_RENDERED = 'ISSUE_RENDERED';
export const ISSUE_LEGACY_INTERACTIVE = 'ISSUE_LEGACY_INTERACTIVE';
export const ISSUE_SPA_MARKED_READY_END_OF_CRITICAL_PATH =
	'ISSUE_SPA_MARKED_READY_END_OF_CRITICAL_PATH';
export const LCP_CANDIDATE = 'LCP_CANDIDATE';
export const ISSUE_CRITICAL_DATA_AGG_SUSPENSE = 'issueViewCriticalData-agg-suspense';
export const ISSUE_CRITICAL_DATA_GIRA_SUSPENSE = 'issueViewCriticalData-gira-suspense';
export const ISSUE_CRITICAL_DATA_REDUX = 'issueViewCriticalData-redux';
