import type {
	ColumnConfiguration,
	ColumnId,
	ColumnTree,
} from '../../../../model/columns/index.tsx';
import type { Optional } from '../../../../model/optional/index.tsx';
import getColumnDepth from '../../../common/get-tree-depth.tsx';
import getSortedLevelIds from './get-sorted-level-ids.tsx';

const traverseColumns = (
	columnTree: ColumnTree,
	columnIds: ColumnId[],
	hiddenColumnIdsHash: Record<ColumnId, boolean>,
	sortedColumnIds: Optional<ColumnId[]>,
	result: ColumnConfiguration[],
) => {
	getSortedLevelIds(sortedColumnIds, columnIds).forEach((columnId) => {
		const column = columnTree.columns[columnId];
		const { parentId } = column;
		const isParentHidden = parentId ? !!hiddenColumnIdsHash[parentId] : false;

		const columnConfiguration: ColumnConfiguration = {
			id: column.id,
			title: column.title,
			// FSN-4987 Remove resize strategy related logic during cleanup
			resizeStrategy: column.resizeStrategy,
			isHidden: !!hiddenColumnIdsHash[columnId],
			isDisabled: column.isHideable === false,
			parentId,
			isParentHidden,
			depth: getColumnDepth(columnTree.columns, columnId),
		};

		if (!column.isMarkup) {
			result.push(columnConfiguration);
		}

		const childrenIds = column.childrenIds || [];
		traverseColumns(columnTree, childrenIds, hiddenColumnIdsHash, sortedColumnIds, result);
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	columnTree: ColumnTree,
	rootColumnIds: ColumnId[],
	hiddenColumnIdsHash: Record<ColumnId, boolean>,
	sortedColumnIds: Optional<ColumnId[]>,
): ColumnConfiguration[] => {
	const result: Array<ColumnConfiguration> = [];
	traverseColumns(columnTree, rootColumnIds, hiddenColumnIdsHash, sortedColumnIds, result);
	return result;
};
