import React from 'react';
import { styled } from '@compiled/react';

export const AttachmentDropzoneContainer = () => (
	<Container id="attachment-dropzone-mask-container" />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'none',
});
