import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlassianAnalytics: {
		defaultMessage: 'Atlassian Analytics',
		id: 'servicedesk-spa-commons.common.utils.document-title.atlassian-analytics',
		description: 'Section label to follow the project name in the window title',
	},
	serviceProject: {
		id: 'servicedesk-spa-commons.common.utils.document-title.service-project',
		defaultMessage: 'Service project',
		description: 'Project type label to follow the name and section the user is in',
	},
	knowledge: {
		defaultMessage: 'Knowledge base',
		id: 'servicedesk-spa-commons.common.utils.document-title.knowledge',
		description: 'Section label to follow the project name in the window title',
	},
	itsmFeatures: {
		defaultMessage: 'Features',
		id: 'servicedesk-spa-commons.common.utils.document-title.itsm-features',
		description: 'Section label to follow the project name in the window title',
	},
	queues: {
		defaultMessage: 'Queues',
		id: 'servicedesk-spa-commons.common.utils.document-title.queues',
		description: 'Section label to follow the project name in the window title',
	},
	customers: {
		defaultMessage: 'Customers',
		id: 'servicedesk-spa-commons.common.utils.document-title.customers',
		description: 'Section label to follow the project name in the window title',
	},
	organizations: {
		defaultMessage: 'Organizations',
		id: 'servicedesk-spa-commons.common.utils.document-title.organizations',
		description: 'Section label to follow the project name in the window title',
	},
	customerServiceEscalations: {
		defaultMessage: 'Developer escalations',
		id: 'servicedesk-spa-commons.common.utils.document-title.customer-service-escalations',
		description: 'Section label to follow the project name in the window title',
	},
	reports: {
		defaultMessage: 'Reports',
		id: 'servicedesk-spa-commons.common.utils.document-title.reports',
		description: 'Section label to follow the project name in the window title',
	},
	services: {
		defaultMessage: 'Services',
		id: 'servicedesk-spa-commons.common.utils.document-title.services',
		description: 'Section label to follow the project name in the window title',
	},
	oncall: {
		defaultMessage: 'On-call',
		id: 'servicedesk-spa-commons.common.utils.document-title.oncall',
		description: 'Section label to follow the project name in the window title',
	},
	email: {
		defaultMessage: 'Email request',
		id: 'servicedesk-spa-commons.common.utils.document-title.email',
		description: 'Section label to follow the project name in the window title',
	},
	sla: {
		defaultMessage: 'Service level agreements',
		id: 'servicedesk-spa-commons.common.utils.document-title.sla',
		description: 'Section label to follow the project name in the window title',
	},
	csat: {
		defaultMessage: 'Customer satisfaction',
		id: 'servicedesk-spa-commons.common.utils.document-title.csat',
		description: 'Section label to follow the project name in the window title',
	},
	details: {
		defaultMessage: 'Details',
		id: 'servicedesk-spa-commons.common.utils.document-title.details',
		description: 'Section label to follow the project name in the window title',
	},
	portal: {
		defaultMessage: 'Customer portal',
		id: 'servicedesk-spa-commons.common.utils.document-title.portal',
		description: 'Section label to follow the project name in the window title',
	},
	externalLinks: {
		defaultMessage: 'External resources',
		id: 'servicedesk-spa-commons.common.utils.document-title.external-links',
		description: 'Section label to follow the project name in the window title',
	},
	portalSettings: {
		defaultMessage: 'Portal settings',
		id: 'servicedesk-spa-commons.common.utils.document-title.portal-settings',
		description: 'Section label to follow the project name in the window title',
	},
	automation: {
		defaultMessage: 'Automation',
		id: 'servicedesk-spa-commons.common.utils.document-title.automation',
		description: 'Section label to follow the project name in the window title',
	},
	advanced: {
		defaultMessage: 'Advanced',
		id: 'servicedesk-spa-commons.common.utils.document-title.advanced',
		description: 'Section label to follow the project name in the window title',
	},
	languageSupport: {
		defaultMessage: 'Language support',
		id: 'servicedesk-spa-commons.common.utils.document-title.language-support',
		description: 'Section label to follow the project name in the window title',
	},
	languageTranslations: {
		defaultMessage: 'Language translations',
		id: 'servicedesk-spa-commons.common.utils.document-title.language-translations',
		description: 'Section label to follow the project name in the window title',
	},
	notificationEmail: {
		defaultMessage: 'Notification email',
		id: 'servicedesk-spa-commons.common.utils.document-title.notification-email',
		description: 'Section label to follow the project name in the window title',
	},
	notifications: {
		defaultMessage: 'Notifications',
		id: 'servicedesk-spa-commons.common.utils.document-title.notifications',
		description: 'Section label to follow the project name in the window title',
	},
	internalNotifications: {
		defaultMessage: 'Internal notifications',
		id: 'servicedesk-spa-commons.common.utils.document-title.internal-notifications',
		description: 'Section label to follow the project name in the window title',
	},
	customerNotifications: {
		defaultMessage: 'Customer notifications',
		id: 'servicedesk-spa-commons.common.utils.document-title.customer-notifications',
		description: 'Section label to follow the project name in the window title',
	},
	widget: {
		defaultMessage: 'Widget',
		id: 'servicedesk-spa-commons.common.utils.document-title.widget',
		description: 'Section label to follow the project name in the window title',
	},
	customerPermissions: {
		defaultMessage: 'Customer permissions',
		id: 'servicedesk-spa-commons.common.utils.document-title.customer-permissions',
		description: 'Section label to follow the project name in the window title',
	},
	people: {
		defaultMessage: 'Internal access',
		id: 'servicedesk-spa-commons.common.utils.document-title.people',
		description: 'Section label to follow the project name in the window title',
	},
	issueType: {
		defaultMessage: 'Request types',
		id: 'servicedesk-spa-commons.common.utils.document-title.issue-type',
		description: 'Section label to follow the project name in the window title',
	},
	connectAppControls: {
		defaultMessage: 'Apps',
		id: 'servicedesk-spa-commons.common.utils.document-title.connect-app-controls',
		description: 'Section label to follow the project name in the window title',
	},
	features: {
		defaultMessage: 'Features',
		id: 'servicedesk-spa-commons.common.utils.document-title.features',
		description: 'Section label to follow the project name in the window title',
	},
	calendar: {
		defaultMessage: 'Calendar',
		id: 'servicedesk-spa-commons.common.utils.document-title.calendar',
		description: 'Section label to follow the project name in the window title',
	},
	projectSummary: {
		defaultMessage: 'Summary',
		id: 'servicedesk-spa-commons.common.utils.document-title.project-summary',
		description: 'Section label to follow the project name in the window title',
	},
	changeCalendar: {
		defaultMessage: 'Change calendar',
		id: 'servicedesk-spa-commons.common.utils.document-title.change-calendar',
		description:
			'Section label to follow the project name in the window title. Please note this is "Change" in the ITSM sense; this calendar shows all pending/completed changes for a given time period.',
	},
	changeManagementSettings: {
		defaultMessage: 'Change management',
		id: 'servicedesk-spa-commons.common.utils.document-title.change-management-settings',
		description:
			'Section label to follow the project name in the window title. Please note this is "Change" in the ITSM sense; this page is for managing settings for the Change management feature of Jira Service Management.',
	},
	incidentManagementSettings: {
		defaultMessage: 'Incident management',
		id: 'servicedesk-spa-commons.common.utils.document-title.incident-management-settings',
		description:
			'Section label to follow the project name in the window title. Please note this is "Incident" in the ITSM sense; this page is for managing settings for the Incident management feature of Jira Service Management.',
	},
	virtualAgentConversations: {
		defaultMessage: 'Conversations',
		id: 'servicedesk-spa-commons.common.utils.document-title.virtual-agent-conversations',
		description: 'Section label to follow the project name in the window title. ',
	},
	virtualAgentIntents: {
		defaultMessage: 'Intents',
		id: 'servicedesk-spa-commons.common.utils.document-title.virtual-agent-intents',
		description: 'Section label to follow the project name in the window title. ',
	},
	virtualAgentStandardFlows: {
		defaultMessage: 'Standard flows',
		id: 'servicedesk-spa-commons.common.utils.document-title.virtual-agent-standard-flows',
		description: 'Section label to follow the project name in the window title. ',
	},
	virtualAgentSettings: {
		defaultMessage: 'Settings',
		id: 'servicedesk-spa-commons.common.utils.document-title.virtual-agent-settings',
		description: 'Section label to follow the project name in the window title. ',
	},
	virtualAgentLanding: {
		defaultMessage: 'Virtual agent',
		id: 'servicedesk-spa-commons.common.utils.document-title.virtual-agent-landing',
		description: 'Section label to follow the project name in the window title. ',
	},
	chat: {
		defaultMessage: 'Chat',
		id: 'servicedesk-spa-commons.common.utils.document-title.chat',
		description: 'Section label to follow the project name in the window title',
	},
	proformaForms: {
		defaultMessage: 'Forms',
		id: 'servicedesk-spa-commons.common.utils.document-title.proforma-forms',
		description: 'Section label to follow the project name in the window title',
	},
	playbooks: {
		defaultMessage: 'Playbooks',
		id: 'servicedesk-spa-commons.common.utils.document-title.playbooks-non-final',
		description: 'Section label to follow the project name in the window title',
	},
	dataClassifications: {
		defaultMessage: 'Data classification',
		id: 'servicedesk-spa-commons.common.utils.document-title.data-classifications',
		description: 'Section label to follow the project name in the window title',
	},
	// EAX-256 - Finalise this string
	fieldsNonFinal: {
		defaultMessage: 'Fields',
		id: 'servicedesk-spa-commons.common.utils.document-title.fields-non-final',
		description:
			'Section label to follow the project name in the window title - used for the "Fields" settings page TMP project settings',
	},
	workflowScheme: {
		defaultMessage: 'Workflows',
		id: 'servicedesk-spa-commons.common.utils.document-title.workflow-scheme',
		description: 'Section label to follow the project name in the window title',
	},
	journeysNonFinal: {
		defaultMessage: 'Journeys',
		id: 'servicedesk-spa-commons.common.utils.document-title.journeys-non-final',
		description:
			'Section label to follow the project name in the window title - used for the "Journeys" settings page in JSM',
	},
	journeyConfigNonFinal: {
		defaultMessage: 'Journey configuration',
		id: 'servicedesk-spa-commons.common.utils.document-title.journey-config-non-final',
		description:
			'Section label to follow the project name in the window title - used for a particular "Journey" settings page in JSM',
	},
	helpCenterForms: {
		defaultMessage: 'Forms',
		id: 'servicedesk-spa-commons.common.utils.document-title.helpcenter-forms',
		description: 'Section label to follow the project name in the window title',
	},
	virtualAgentAiAnswers: {
		defaultMessage: 'Atlassian Intelligence answers',
		id: 'servicedesk-spa-commons.common.utils.document-title.ai-answers',
		description: 'Section label to follow the project name in the window title',
	},
});
