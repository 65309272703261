import { connect } from '../../../../../../../../common/table-redux.tsx';
import type { RowId } from '../../../../../../../../model/rows/index.tsx';
import { setActiveRow } from '../../../../../../../../ops/items/active/set/action.tsx';
import {
	isInRowNavigationMode,
	getLoadingRowComponent,
	shouldDelayRowRenderingOnScroll,
} from '../../../../../../../../state/consumer/selectors/index.tsx';
import {
	isRowBeingAdded,
	isRowLoading,
} from '../../../../../../../../state/consumer/selectors/rows/index.tsx';
import { getVirtualBoundaries } from '../../../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../../../state/types.tsx';
import DefaultRow from '../../../../common/default-row/index.tsx';
import RowItem from './index-dumb.tsx';
import NewRow from './new/index.tsx';

type Props = {
	id: RowId;
	rowIndex: number;
};

const mapStateToProps = (state: State, { id }: Props) => {
	const { displayStart } = getVirtualBoundaries(state);
	return {
		delayRowRenderOnScroll: shouldDelayRowRenderingOnScroll(state) && displayStart > 0,
		isInCreation: isRowBeingAdded(state, id),
		isLoading: isRowLoading(state, id),
		LoadingRow: getLoadingRowComponent(state),
		isInRowNavigationMode: isInRowNavigationMode(state),
		NewRow,
		DefaultRow,
	};
};

export default connect(mapStateToProps, {
	// @ts-expect-error - TS7006 - Parameter 'newActiveRow' implicitly has an 'any' type.
	onSetActiveRow: (newActiveRow) => setActiveRow(newActiveRow),
})(RowItem);
