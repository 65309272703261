import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'servicedesk.queues.agent-view.layout.header.async-issue-search.issue-search.placeholder',
		defaultMessage: 'Search for issues',
		description: 'Placeholder text for search input',
	},
	clearSearch: {
		id: 'servicedesk.queues.agent-view.layout.header.async-issue-search.issue-search.clear-search',
		defaultMessage: 'Clear search',
		description: 'Clear search input',
	},
});
