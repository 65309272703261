import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue-table.flags.field.assignee.error.title',
		defaultMessage: 'Looks like there was a problem',
		description: 'Title of error flag shown when assignee field on queue could not be edited.',
	},

	description: {
		id: 'issue-table.flags.field.assignee.error.description',
		defaultMessage: 'The assignee field cannot be edited. Try refreshing the page.',
		description: 'Description of error flag shown when assignee field on queue could not be edited',
	},

	refresh: {
		id: 'issue-table.flags.field.assignee.error.refresh',
		defaultMessage: 'Refresh',
		description: 'Link allowing user to refresh the page when assignee field could not be edited.',
	},
});
