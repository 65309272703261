import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { AggIssueField as AggField } from '../../types/issue-type.tsx';

export const LOGGER_LOCATION = 'issue.agg-field-transformers.missing-renderer';

// If the renderer is missing, log an error and return true.
// This was added as part of https://jplat.atlassian.net/browse/JIV-14881 to help identify why
// fields are sometimes missing the renderer.
const isFieldMissingRenderer = (field: AggField) => {
	try {
		// Narrow the type of field to fields with a renderer
		if (!('renderer' in field)) {
			return false;
		}

		if (field?.renderer) {
			return false;
		}

		logger.safeErrorWithoutCustomerData(
			LOGGER_LOCATION,
			`Field ${field.fieldId} of type ${field.__typename} has missing renderer: ${field.renderer}`,
		);

		return true;
	} catch {
		return false;
	}
};

export default isFieldMissingRenderer;
