import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import AkDropdownMenu from '@atlaskit/dropdown-menu';
import ConfigureIcon from '@atlaskit/icon/glyph/settings';
import { colors } from '@atlaskit/theme';
import { type IntlShape, defineMessages, injectIntl } from '@atlassian/jira-intl';
import type { UpdateHiddenColumnsCallback } from '../../../../model/callbacks/index.tsx';
import type { ColumnConfiguration as ConfiguredColumn } from '../../../../model/columns/index.tsx';
import ColumnsList from './list/index.tsx';

const messages = defineMessages({
	configureIcon: {
		id: 'virtual-table.table.header.column-configuration.configure-icon',
		defaultMessage: 'Click to expand column configuration',
		description: '',
	},
});

type Props = {
	columnConfiguration: ConfiguredColumn[];
	headerHeight: number;
	onChangeHiddenColumnsRequested: UpdateHiddenColumnsCallback | undefined;
	intl: IntlShape;
};

export function ColumnConfigurationComponent(props: Props) {
	const {
		intl: { formatMessage },
		columnConfiguration,
		headerHeight,
		onChangeHiddenColumnsRequested,
	} = props;

	return (
		<ConfigWrapper headerHeight={headerHeight}>
			<AkDropdownMenu
				trigger={({ triggerRef, ...propsTrigger }) => (
					<Button
						{...propsTrigger}
						iconBefore={<ConfigureIcon label={formatMessage(messages.configureIcon)} />}
						appearance="subtle"
						ref={triggerRef}
					/>
				)}
				placement="bottom-end"
			>
				<ColumnsList
					columnConfiguration={columnConfiguration}
					onChangeHiddenColumnsRequested={onChangeHiddenColumnsRequested}
				/>
			</AkDropdownMenu>
		</ConfigWrapper>
	);
}

export default injectIntl(ColumnConfigurationComponent);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigWrapper = styled.div<{ headerHeight: string | number }>({
	position: 'absolute',
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ headerHeight }) => `${headerHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: colors.N0,
});
