import type { ComponentType } from 'react';
import flow from 'lodash/flow';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import isShallowEqual from '@atlassian/jira-common-util-is-shallow-equal/src/index.tsx';
import type { FieldType } from '../../../model/fields/types.tsx';
import type { FieldDataSelectorProps } from '../../../model/index.tsx';
import { issueEditStartedAction } from '../../../state/actions/issues/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { isCellValid } from '../../../state/selectors/cells/index.tsx';
import { getIssueKeyPositionIndex } from '../../../state/selectors/issues/index.tsx';
import { getRowListRef } from '../../../state/selectors/row-list-ref/index.tsx';
import { isUserPickerDisabled } from '../../../state/selectors/ui/index.tsx';
import { connect } from '../../../state/store/index.tsx';
import { ReduxAnalyticsData } from '../../analytics/index.tsx';
import JsonCell from './json-cell/index.tsx';
import TableStateConnector from './table-state-connector/index.tsx';
import Cell, { type JsonComponentArgs, type MergedColumnProps } from './view.tsx';

export type Props<T extends FieldType> = {
	isActive: boolean;
	shouldHydrateFully?: boolean;
	canBeMultiLine?: boolean;
	jsonComponentArgs: JsonComponentArgs<T>;
	mergedColumnProps: MergedColumnProps<T>;
	FieldDataSelector: ComponentType<FieldDataSelectorProps>;
	rowId: string;
	columnId?: string;
	width?: number;
	onMount?: () => void;
	onUpdate?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any, ownProps: any) => ({
	TableStateConnector,
	isCellValid: isCellValid(state, ownProps.columnId, ownProps.rowId),
	rowListRef: getRowListRef(state),
	JsonCell,
	isUserPickerDisabled: isUserPickerDisabled(state),
});

const mapDispatchToProps = {
	onEditStart: issueEditStartedAction,
} as const;

export default flow(
	withFireUiAnalytics({
		onClick: (fieldType: string) => ({
			name: fieldType,
		}),
	}),
	connect(mapStateToProps, mapDispatchToProps, null, {
		// @ts-expect-error - TS7006 - Parameter 'nextProps' implicitly has an 'any' type. | TS7006 - Parameter 'prevProps' implicitly has an 'any' type.
		areStatePropsEqual: (nextProps, prevProps) => {
			// Stop rendering immediately if the cell isn't valid. This
			// should always be due to the virtual-table rendering the old
			// locations in the first reconciliation, before a second
			// reconciliation kicks in with the new row and column IDs.
			if (!nextProps.isCellValid) {
				return true;
			}

			return isShallowEqual(nextProps, prevProps);
		},
	}),
	ReduxAnalyticsData((state: State, ownProps: Props<FieldType>) => ({
		attributes: {
			fieldType: ownProps.mergedColumnProps.fieldType,
			tableRowNumber: getIssueKeyPositionIndex(state, ownProps.rowId) + 1,
		},
	})),
)(Cell);
