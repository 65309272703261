import React, { useState, useMemo, useCallback, type FormEvent } from 'react';
import Heading from '@atlaskit/heading';
import SearchIcon from '@atlaskit/icon/core/search';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context/index.tsx';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category/index.tsx';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { MenuSection } from '@atlassian/navigation-system';
import { QueueList } from '../../queue-list/main.tsx';
import { messages } from '../messages.tsx';

interface AllQueuesProps {
	projectKey: string;
	practice: ItsmPractice;
	isFlyOut?: boolean;
}

export const AllQueues = ({ projectKey, practice, isFlyOut = false }: AllQueuesProps) => {
	const { formatMessage } = useIntl();
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	const [filter, setFilter] = useState('');
	const searchResultQueues = useMemo(
		() =>
			allQueues?.filter((queue) =>
				queue.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
			) ?? [],
		[allQueues, filter],
	);
	const SearchIconMemo = useMemo(
		() => <SearchIcon label="" spacing="spacious" color="currentColor" />,
		[],
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleChange = useCallback((event: FormEvent<HTMLInputElement>) => {
		setFilter(event.currentTarget.value);
	}, []);

	const onStopQueueSearch = useCallback(() => {
		const getViewAllQueueSearchEvent = createAnalyticsEvent({
			action: 'blurred',
			actionSubject: 'jsmViewAllQueueFlyoutSearch',
		});
		fireUIAnalytics(getViewAllQueueSearchEvent, 'stopViewAllQueueSearch', {
			projectKey,
			practice,
		});
	}, [createAnalyticsEvent, projectKey, practice]);

	if (!allQueues || allQueues.length === 0) {
		return null;
	}

	return (
		<MenuSection>
			<Stack space="space.050">
				{isFlyOut && (
					<Box paddingBlock="space.100">
						<Heading size="xsmall">{formatMessage(messages.allQueues)}</Heading>
					</Box>
				)}

				{isFlyOut && (
					<Box xcss={filterContainerStyles}>
						<Textfield
							isCompact
							value={filter}
							onChange={handleChange}
							aria-label={formatMessage(messages.searchYourQueue)}
							placeholder={formatMessage(messages.searchYourQueue)}
							elemBeforeInput={SearchIconMemo}
							onBlur={onStopQueueSearch}
						/>
					</Box>
				)}
				<Stack space="space.050" xcss={scrollBoxStyles}>
					{searchResultQueues.length > 0 ? (
						<QueueList
							queues={searchResultQueues}
							section={QueueListSection.ALL_QUEUES}
							isQueueListInFlyOut={isFlyOut}
						/>
					) : (
						<Box paddingBlock="space.100" xcss={noSearchResultsFoundStyles}>
							{formatMessage(messages.noSearchResultsFound)}
						</Box>
					)}
				</Stack>
			</Stack>
		</MenuSection>
	);
};

const noSearchResultsFoundStyles = xcss({
	fontStyle: 'italic',
});

const scrollBoxStyles = xcss({
	maxHeight: '500px',
	overflow: 'auto',
	scrollbarGutter: 'stable',
	padding: 'space.050',
});

const filterContainerStyles = xcss({
	minHeight: 'space.400',
	paddingRight: 'space.100',
});
