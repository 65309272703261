import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type SINGLE_SELECT_GQL_FIELD,
	CATEGORY_TYPE,
	SELECT_CF_TYPE,
	EPIC_STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggIssueField as AggField } from '../types/issue-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

const getField = (node: AggField<typeof SINGLE_SELECT_GQL_FIELD>, customType?: string) => {
	const value =
		node.fieldOption != null
			? {
					ari: node.fieldOption.id,
					id: node.fieldOption.optionId,
					value: node.fieldOption.value,
				}
			: null;

	return {
		...transformAggNodeToLegacyField(node),
		value,
		schema: {
			type: 'option',
			renderer: PLAIN_TEXT,
			custom: customType || node.type,
			system: null,
			items: null,
			configuration: null,
		},
	};
};

export const transformAggSingleSelectToLegacyField: AggTransformerFunction<
	typeof SINGLE_SELECT_GQL_FIELD
> = (node) => {
	switch (node.type) {
		case EPIC_STATUS_TYPE:
		case SELECT_CF_TYPE: {
			return getField(node);
		}
		case CATEGORY_TYPE: {
			return getField(node, SELECT_CF_TYPE);
		}
		default:
			return undefined;
	}
};
