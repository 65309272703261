import { AgileColors } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import {
	EPIC_TYPE,
	type EPIC_LINK_GQL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { EpicLink } from '@atlassian/jira-shared-types/src/rest/jira/epic.tsx';
import type { AggJiraEpicLink } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

const transformJiraEpicToLegacyEpicLink = (epic: AggJiraEpicLink | null): EpicLink | null => {
	if (epic == null) return null;

	return {
		id: Number(epic.issueId),
		key: epic.key || '',
		done: !!epic.done,
		name: epic.name || '',
		summary: epic.summary || '',
		color: {
			key: epic.color ?? AgileColors.AGILE_COLOR_1,
		},
		// This can only be set from cache handlers
		fromCache: false,
	};
};

export const transformAggEpicLinkToLegacyField: AggTransformerFunction<
	typeof EPIC_LINK_GQL_FIELD
> = (node) => {
	if (node.type !== EPIC_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		autoCompleteUrl: node.searchUrl,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		value: transformJiraEpicToLegacyEpicLink(node.epic as AggJiraEpicLink),
		schema: {
			configuration: null,
			items: null,
			renderer: 'jira-text-renderer',
			type: 'any',
			custom: EPIC_TYPE,
			system: null,
		},
	};
};
