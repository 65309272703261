import memoize from 'lodash/memoize';
import { getMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import { isExpandedObservationData, type ObservationData } from './types.tsx';

let observer: PerformanceObserver | null = null;

const stopObserver = () => {
	if (observer) {
		observer.disconnect();
		observer = null;
	}
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CallbackExtras = any;
type LongTaskMetrics = {
	start: (
		appName: string,
		startPerformanceMark?: string | null | undefined,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extra?: Record<any, any>,
	) => void;
	stop: (
		callback: (
			param: ObservationData,
			extra: CallbackExtras,
			stopTimestamp: DOMHighResTimeStamp,
		) => void,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extra?: Record<any, any>,
	) => void;
	stopIfNoMark: (performanceMark?: string | null | undefined) => void;
};

// Use our own type because 'durationThreshold' does not exist in type 'PerformanceObserverInit'
type ObserverOption = {
	buffered?: boolean;
	entryTypes?: string[];
	type?: string;
	durationThreshold: number;
};

export const getLongTasksMetrics = memoize((type: string): LongTaskMetrics => {
	const clearData: ObservationData = { type };
	let isStarted = false;
	let observationData: ObservationData = { ...clearData };

	const isDnd = /dnd$/.test(type.toLowerCase());

	return {
		start: (
			appName: string,
			startPerformanceMark: string | null | undefined,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			extra: Record<any, any> = {},
		) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (window.PerformanceObserver && !isStarted) {
				try {
					const startTimestamp = performance.now();
					observationData = {
						type: observationData.type,
						startTimestamp,
						dragStartEndTimestamp: startTimestamp,
						eventStartPerformanceMark: startPerformanceMark,
						events: [],
						appName,
						extra,
					};
					isStarted = true;
					stopObserver();

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					observer = new window.PerformanceObserver((list) => {
						list.getEntries().forEach((entry) => {
							if (entry.entryType === 'longtask') {
								if (
									!isExpandedObservationData(observationData) ||
									observationData.events.find(
										(item) =>
											item.timestamp === entry.startTime && item.duration === entry.duration,
									)
								) {
									return;
								}

								observationData.events.push({
									timestamp: entry.startTime,
									duration: entry.duration,
								});
							} else if (entry.entryType === 'event' && entry.name === 'dragstart') {
								/**
								 * Use the observed event to compute the "end" timestamp of the dragstart event,
								 * which will be used for splitting the long tasks for dragstart and dragmove.
								 */
								observationData.dragStartEndTimestamp = entry.startTime + entry.duration;
							}
						});
					});

					observer.observe({ type: 'longtask' });

					if (isDnd) {
						/**
						 * Observe events with >48ms duration.
						 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceObserver/observe
						 */
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						observer.observe({
							type: 'event',
							durationThreshold: 48,
						} as ObserverOption);
					}

					// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty
				} catch (e: any) {}
			}
		},
		stop: (
			callback: (
				param: ObservationData,
				extra: CallbackExtras,
				stopTimestamp: DOMHighResTimeStamp,
			) => void,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			extra: Record<any, any> = {},
		) => {
			if (performance.now && isStarted) {
				const stopTimestamp = performance.now();
				setTimeout(() => {
					stopObserver();
					callback({ ...observationData }, extra, stopTimestamp);
					observationData = { ...clearData };
					isStarted = false;
				});
			}
		},
		stopIfNoMark: (performanceMark?: string | null) => {
			if (observer && performanceMark) {
				const perfMark = getMark(performanceMark);
				if (!perfMark) {
					stopObserver();
					observationData = { ...clearData };
					isStarted = false;
				}
			}
		},
	};
});
