import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';

export const SET_ENABLED_PRACTICES = 'state.actions.practices.SET_ENABLED_PRACTICES';

export type SetEnabledPracticesAction = {
	payload: {
		practices: ItsmPractice[];
	};
	type: typeof SET_ENABLED_PRACTICES;
};

export const setEnabledPracticesAction = (
	practices: ItsmPractice[],
): SetEnabledPracticesAction => ({
	type: SET_ENABLED_PRACTICES,
	payload: {
		practices,
	},
});
