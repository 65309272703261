/** @jsx jsx */
// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType, type ReactNode } from 'react';
import { css, cssMap, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { ColumnId } from '../../../../../../model/columns/index.tsx';
import { getWidth } from '../../../../common/styled.tsx';

type ColumnComponentType = ComponentType<{
	id: ColumnId;
	width: number;
	isFirstColumn: boolean;
}>;

type Props = {
	id: ColumnId;
	width: number;
	isFirstColumn: boolean;
	DefaultColumn: ColumnComponentType;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnWrapper extends PureComponent<Props> {
	static defaultProps: Partial<Props> = {};

	render() {
		const { id, width, isFirstColumn, DefaultColumn } = this.props;

		return (
			<Wrapper width={width} isFirstColumn={isFirstColumn}>
				<DefaultColumn id={id} width={width} isFirstColumn={isFirstColumn} />
			</Wrapper>
		);
	}
}

const wrapperStyles = css({
	flex: '0 0 auto',
	display: 'flex',
	position: 'relative',
});

const wrapperMarginLeftStylesMap = cssMap({
	firstColumn: {
		marginLeft: token('space.300', '24px'),
	},
	otherColumn: {
		marginLeft: token('space.0', '0px'),
	},
});

const Wrapper = ({
	isFirstColumn,
	width,
	children,
}: {
	isFirstColumn: boolean;
	width: number;
	children: ReactNode;
}) => (
	<div
		css={[wrapperStyles, wrapperMarginLeftStylesMap[isFirstColumn ? 'firstColumn' : 'otherColumn']]}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		style={{ width: getWidth({ width, isFirstColumn }) }}
	>
		{children}
	</div>
);
