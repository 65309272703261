import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type RICH_TEXT_GQL_FIELD,
	DESCRIPTION_TYPE,
	ENVIRONMENT_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
	TEXT_AREA_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { removeCollectionFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { AggJiraRichTextField } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';
import isFieldMissingRenderer from './helpers/is-field-missing-renderer.tsx';

const emptyAdfObject = getEmptyADF();

const getSchema = (aggType: string, renderer?: string | null) => {
	switch (aggType) {
		case TEXT_AREA_CF_TYPE:
			return {
				custom: aggType,
				renderer,
				type: ISSUE_FIELD_STRING_CF_TYPE,
				system: null,
				configuration: null,
				items: null,
			};
		case DESCRIPTION_TYPE:
		case ENVIRONMENT_TYPE:
		default:
			return {
				system: aggType,
				renderer,
				type: ISSUE_FIELD_STRING_CF_TYPE,
				custom: null,
				configuration: null,
				items: null,
			};
	}
};

// Exactly match the legacy /graphql value, handling the case where the field is empty and has never been edited
// There's no functional difference between `null` or empty string when rendering the field in the issue view.
const getPlainTextValue = (node: AggJiraRichTextField) => {
	// When the field is empty and a value has never been set
	if (!node.richText) {
		return null;
	}
	// When the field has been set to a value, and then set back to empty
	if (node.richText?.plainText == null) {
		return '';
	}
	return node.richText?.plainText;
};

export const transformAggRichTextToLegacyField: AggTransformerFunction<
	typeof RICH_TEXT_GQL_FIELD
> = (node) => {
	// Description field always renders with ADF
	const { renderer } = node;

	if (isFieldMissingRenderer(node)) {
		return undefined;
	}

	const isPlainTextRenderer = node.type !== DESCRIPTION_TYPE && renderer === PLAIN_TEXT;

	if (isPlainTextRenderer) {
		return {
			...transformAggNodeToLegacyField(node),
			value: getPlainTextValue(node),
			schema: getSchema(node.type, renderer),
		};
	}

	const adfValueJson = node.richText?.adfValue?.json ?? null;

	return {
		...transformAggNodeToLegacyField(node),
		// The usages of the collection is deprecated.
		adfValue: adfValueJson ? removeCollectionFromAdf(adfValueJson) : emptyAdfObject,
		value: null,
		schema: getSchema(node.type, renderer),
	};
};
