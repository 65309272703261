import { asyncWrmRequire } from '@atlassian/jira-wrm/src/async-wrm.tsx';

export type LegacyIssueView = {
	setElement: (arg1: Element) => LegacyIssueView;
	render: (arg1: { key: string; queueId: string }) => Promise<void>;
	updateQueueId: (arg1: string) => void;
	dismiss: () => void;
};

export type LegacyIssueViewClass = () => LegacyIssueView;

export const getLegacyIssueView = (): Promise<LegacyIssueViewClass> =>
	asyncWrmRequire([
		'wrc!jira.view.issue',
		'wrc!sd.project.legacy.issue.view',
		'wrc!sd.project.queues.legacy.issue.view.bridge',
	]).then(() => __jsdQueues.LegacyIssueView);
