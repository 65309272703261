import { createSelector } from 'reselect';
import type { HierarchyLevel } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import {
	ISSUE_TYPE,
	SUBTASK_PARENT,
	PARENT,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { issueKeySelector, baseUrlSelector } from '../common/state/selectors/context-selector.tsx';
import { fieldSelector } from '../common/state/selectors/field-selector.tsx';
import { parentIssueEntitySelector } from '../common/state/selectors/issue-selector.tsx';

// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'. | TS7006 - Parameter 'issueKey' implicitly has an 'any' type.
const createRelativeIssueUrl = (baseUrl: string | BaseUrl, issueKey): string =>
	`${baseUrl ? baseUrl.replace(/\/?$/, '/') : '/'}browse/${issueKey}`;

export const createFullIssueUrl = (baseUrl: string, issueKey: string): string =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	`${window.location.protocol}//${window.location.host}${createRelativeIssueUrl(
		baseUrl,
		issueKey,
	)}`;

const issueTypeFieldSelector = fieldSelector(ISSUE_TYPE);

const subtaskIssueFieldSelector = fieldSelector(SUBTASK_PARENT);

const portfolioParentFieldSelector = fieldSelector(PARENT);

export type ParentIssue = {
	key: string;
	summary: string;
	url: string;
	issueTypeName: string;
	issueTypeIconUrl: string;
};

type Issue = Omit<ParentIssue, 'url'>;

export type IssueType = {
	avatarId: number;
	description: string;
	hierarchyLevel: HierarchyLevel;
	iconUrl: string;
	id: IssueTypeId;
	name: string;
	self: string;
	subtask: boolean;
};

export const issueTypeSelector = createSelector(
	issueTypeFieldSelector,
	(issueType) => issueType && issueType.value,
);

export const issueUrlSelector = createSelector(
	baseUrlSelector,
	issueKeySelector,
	createRelativeIssueUrl,
);

export const fullIssueUrlSelector = createSelector(
	baseUrlSelector,
	issueKeySelector,
	createFullIssueUrl,
);

export const issueTypeNameSelector = createSelector(
	issueTypeSelector,
	(issueType) => issueType && issueType.name,
);

export const issueTypeHierarchyLevelSelector = createSelector(issueTypeSelector, (issueType) => {
	if (!issueType) {
		return null;
	}
	return issueType && issueType.hierarchyLevel;
});

export const issueTypeIdSelector = createSelector(
	issueTypeSelector,
	(issueType) => issueType && issueType.id,
);

export const issueTypeIconUrlSelector = createSelector(
	issueTypeSelector,
	(issueType) => issueType && issueType.iconUrl,
);

export const isIssueTypeFieldEditableSelector = createSelector(
	issueTypeFieldSelector,
	(issueType) => issueType && Boolean(issueType.editable),
);

const transformParentIssue = (issue: Issue | null, baseUrl: string): ParentIssue | null =>
	issue
		? {
				summary: issue.summary,
				key: issue.key,
				url: createRelativeIssueUrl(baseUrl, issue.key),
				issueTypeName: issue.issueTypeName || '',
				issueTypeIconUrl: issue.issueTypeIconUrl || '',
			}
		: null;

// @ts-expect-error - TS7006 - Parameter 'issue' implicitly has an 'any' type.
const transformSubtaskParentIssue = (issue, baseUrl: string): ParentIssue | null =>
	issue
		? {
				summary: issue.fields.summary,
				key: issue.key,
				url: createRelativeIssueUrl(baseUrl, issue.key),
				issueTypeName: issue.issueTypeName || '',
				issueTypeIconUrl: issue.issueTypeIconUrl || '',
			}
		: null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformPortfolioParentIssue = (value: any, baseUrl: string): ParentIssue | null =>
	value && value.data
		? {
				summary: value.data.summary,
				key: value.data.key,
				url: createRelativeIssueUrl(baseUrl, value.data.key),
				issueTypeName: value.data.issueTypeName || '',
				issueTypeIconUrl: value.data.issueTypeIconUrl || '',
			}
		: null;

export const portfolioParentSelector = createSelector(
	portfolioParentFieldSelector,
	baseUrlSelector,
	(portfolioParentIssue, baseUrl) =>
		transformPortfolioParentIssue(portfolioParentIssue && portfolioParentIssue.value, baseUrl),
);

const subtaskIssueParentSelector = createSelector(
	subtaskIssueFieldSelector,
	baseUrlSelector,
	(subtaskIssue, baseUrl) =>
		transformSubtaskParentIssue(subtaskIssue && subtaskIssue.value, baseUrl),
);

const parentIssueSelector = createSelector(
	parentIssueEntitySelector,
	baseUrlSelector,
	(parentIssue, baseUrl) => transformParentIssue(parentIssue, baseUrl),
);

export const parentSelector = createSelector(
	parentIssueSelector,
	subtaskIssueParentSelector,
	portfolioParentSelector,
	(parentIssue, subtaskIssue, portfolioParentIssue) =>
		parentIssue || subtaskIssue || portfolioParentIssue,
);

export const getParentIssuesSelector = createSelector(parentIssueSelector, (parentIssue) =>
	[parentIssue].filter(Boolean),
);
