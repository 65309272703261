import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import type { Observable } from 'rxjs/Observable';
import {
	getEditQueueFragment,
	navigate,
} from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import { fromQueueId } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { getProjectKey } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import { EDIT_QUEUE } from '../../../state/actions/queue/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getQueueId, getCategory } from '../../../state/selectors/queue/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>): Observable<Action> =>
		action$.ofType(EDIT_QUEUE).switchMap(() => {
			const state = store.getState();
			const projectKey = getProjectKey(state);
			const queueId = getQueueId(state);
			const category = getCategory(state);

			if (push) {
				return push(getEditQueueFragment(fromQueueId(queueId), category));
			}

			// fallback to push state nav in case anything goes wrong.
			// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
			return navigate(projectKey, getEditQueueFragment(fromQueueId(queueId), category));
		});
