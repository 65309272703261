import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { CASCADING_SELECT_GQL_FIELD } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggCascadingSelectToLegacyField: AggTransformerFunction<
	typeof CASCADING_SELECT_GQL_FIELD
> = (node) => {
	let value = null;

	if (node.cascadingOption) {
		const { childOptionValue, parentOptionValue } = node.cascadingOption;

		const child = childOptionValue
			? {
					child: {
						id: childOptionValue.optionId,
						value: childOptionValue.value,
					},
				}
			: {};

		value = {
			// assigned in the statement above
			id: parentOptionValue?.optionId,
			value: parentOptionValue?.value,
			...child,
		};
	}

	return {
		...transformAggNodeToLegacyField(node),
		value,
		allowedValues: [],
		schema: {
			type: 'option-with-child',
			renderer: PLAIN_TEXT,
			custom: node.type,
			system: null,
			items: null,
			configuration: null,
		},
	};
};
