import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import AsyncPagination from './async-pagination/index.tsx';
import Pagination from './view.tsx';

export default connect(
	() => ({
		Pagination: AsyncPagination,
	}),
	{},
)(Pagination);
