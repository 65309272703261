import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { LabelsField } from '@atlassian/jira-issue-shared-types/src/common/types/fields/labels-field-type.tsx';
import {
	type LABEL_GQL_FIELD,
	LABELS_TYPE,
	LABELS_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';

const defaultSchema = {
	configuration: null,
	items: 'string',
	type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	renderer: PLAIN_TEXT,
};

export const transformAggLabelsNodeToLegacyField: AggTransformerFunction<typeof LABEL_GQL_FIELD> = (
	node,
): LabelsField | undefined => {
	const selectedLabels = transformAggConnection(node.selectedLabelsConnection, ({ name }) => name);

	switch (node.type) {
		case LABELS_TYPE:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return {
				...transformAggNodeToLegacyField(node),
				value: selectedLabels,
				schema: {
					...defaultSchema,
					custom: null,
					system: node.type,
				},
			} as LabelsField<typeof LABELS_TYPE>;
		case LABELS_CF_TYPE:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return {
				...transformAggNodeToLegacyField(node),
				// empty value for custom labels field is null while system labels field is `[]`
				value: selectedLabels.length === 0 ? null : selectedLabels,
				schema: {
					...defaultSchema,
					custom: node.type,
					system: null,
				},
			} as LabelsField<typeof LABELS_CF_TYPE>;
		default:
			return undefined;
	}
};
