import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagText: {
		id: 'virtual-table.table.content.rows.common.conditional-error-boundary.error-inline-message.error-flag-text',
		defaultMessage:
			"We're having trouble loading this data. Try again later or contact Atlassian support.",
		description: 'Error flag body text',
	},
	errorFlagLinkText: {
		id: 'virtual-table.table.content.rows.common.conditional-error-boundary.error-inline-message.error-flag-link-text',
		defaultMessage: 'Reload',
		description: 'Error flag link text',
	},
});
