import { connect } from '../../../../../../../../../common/table-redux.tsx';
import {
	type ActiveCell,
	type ActiveRow,
	ACTIVE_CELL_TYPE,
	ACTIVE_ROW_TYPE,
	CELL_NAVIGATION,
	ROW_NAVIGATION,
} from '../../../../../../../../../model/navigation/index.tsx';
import type { RowId } from '../../../../../../../../../model/rows/index.tsx';
import { clear as clearActiveItem } from '../../../../../../../../../ops/items/active/clear/action.tsx';
import { setActiveCell } from '../../../../../../../../../ops/items/active/set/action.tsx';
import {
	type SetMultiAddAnchorAction,
	set as setMultiAddAnchor,
} from '../../../../../../../../../ops/rows/add-anchor/set/action.tsx';
import { getVisibleCoreColumnIds } from '../../../../../../../../../state/consumer/selectors/columns/index.tsx';
import {
	getNavigationMode,
	getVisibleAdditionalColumnIds,
	getDefaultAddColumnId,
	getPersistAddedRowCallback,
	getCancelAddedRowCallback,
} from '../../../../../../../../../state/consumer/selectors/index.tsx';
import {
	getMaxDepth,
	getRowAddingStatus,
} from '../../../../../../../../../state/consumer/selectors/rows/index.tsx';
import { getRowDepth, isActiveRowId } from '../../../../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../../../../state/types.tsx';
import AdditionalColumnsContainer from '../../../../../../../common/additional-columns-container/index.tsx';
import CellWrapper from '../../../../../common/cell-wrapper/index.tsx';
import NewRow from './index-dumb.tsx';

type Props = {
	id: RowId;
	rowIndex: number;
};

type DispatchProps = {
	setMultiAddAnchor: SetMultiAddAnchorAction;
	clearActiveItem: () => void;
	setActiveCell: (arg1: ActiveCell) => void;
	setActiveRow: (arg1: ActiveRow) => void;
};

export default connect(
	(state: State, { id }: Props) => ({
		depth: getRowDepth(state, id),
		maxDepth: getMaxDepth(state),
		status: getRowAddingStatus(state, id),
		coreColumnIds: getVisibleCoreColumnIds(state),
		additionalColumnIds: getVisibleAdditionalColumnIds(state),
		defaultAddColumnId: getDefaultAddColumnId(state),
		isActive: isActiveRowId(state, id),
		onPersistAddedRowRequested: getPersistAddedRowCallback(state),
		onCancelAddedRowRequested: getCancelAddedRowCallback(state),
		navigationMode: getNavigationMode(state),

		AdditionalColumnsContainer,
		CellWrapper,
	}),
	{ setActiveCell, clearActiveItem, setMultiAddAnchor },
	// @ts-expect-error - TS7031 - Binding element 'defaultAddColumnId' implicitly has an 'any' type.
	({ defaultAddColumnId, ...stateProps }, dispatchProps: DispatchProps, ownProps: Props) => ({
		...ownProps,
		...stateProps,
		setMultiAddAnchor: dispatchProps.setMultiAddAnchor,
		setActiveItem: () => {
			if (CELL_NAVIGATION) {
				dispatchProps.setActiveCell({
					type: ACTIVE_CELL_TYPE,
					rowId: ownProps.id,
					columnId: defaultAddColumnId,
				});
			} else if (ROW_NAVIGATION) {
				dispatchProps.setActiveRow({
					type: ACTIVE_ROW_TYPE,
					rowIndex: ownProps.rowIndex,
				});
			}
		},
		clearActiveItem: dispatchProps.clearActiveItem,
	}),
)(NewRow);
