import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Team } from '@atlassian/jira-issue-shared-types/src/common/types/team-type.tsx';
import {
	type TEAM_VIEW_GQL_FIELD,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggJiraTeamView } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

const transformAggTeamViewToLegacyTeam = (team: AggJiraTeamView | null): Team | null => {
	if (team == null) return null;

	return {
		id: team.jiraSuppliedTeamId,
		name: team.jiraSuppliedName || '',
		isVisible: team.jiraSuppliedVisibility || false,
		avatarUrl: team.jiraSuppliedAvatar?.xsmall || '',
	};
};

export const transformAggTeamViewToLegacyField: AggTransformerFunction<
	typeof TEAM_VIEW_GQL_FIELD
> = (node) => {
	if (node.type !== TEAMS_PLATFORM_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		// @ts-expect-error - Argument of type '{ readonly jiraSuppliedAvatar: { readonly large: string | null | undefined; readonly medium: string | null | undefined; readonly small: string | null | undefined; readonly xsmall: string | null | undefined; } | null | undefined; readonly jiraSuppliedId: string; readonly jiraSuppliedName: string | ... 1 more ... | un...' is not assignable to parameter of type '{ readonly jiraSuppliedAvatar: { readonly large: string | null | undefined; readonly medium: string | null | undefined; readonly small: string | null | undefined; readonly xsmall: string | null | undefined; } | null | undefined; readonly jiraSuppliedId: string; readonly jiraSuppliedName: string | ... 1 more ... | un...'.
		value: transformAggTeamViewToLegacyTeam(node.selectedTeam),
		schema: {
			configuration: null,
			custom: TEAMS_PLATFORM_CF_TYPE,
			items: null,
			renderer: PLAIN_TEXT,
			system: null,
			type: 'team',
		},
	};
};
