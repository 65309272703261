import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotlightHeading: {
		id: 'servicedesk-queues-popout-panel-nav4.spotlight-heading',
		defaultMessage: 'Work on queues your way',
		description: 'Queues popout spotlight heading',
	},
	spotlightMessage: {
		id: 'servicedesk-queues-popout-panel-nav4.spotlight-message',
		defaultMessage:
			'Choose to work on queues from your main navigation, or pop out queues to keep focused.',
		description: 'Description of queues popout spotlight',
	},
	spotlightDismissButtonLabel: {
		id: 'servicedesk-queues-popout-panel-nav4.spotlight-dismiss-button-label',
		defaultMessage: 'OK',
		description: 'Dismiss queues popout spotlight button label',
	},
});
