import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	negativeSentimentIconLabel: {
		id: 'issue-table.common.fields.sentiment.negative-sentiment-icon-label',
		defaultMessage: 'Negative sentiment icon',
		description: 'Label for negative sentiment icon.',
	},
	positiveSentimentIconLabel: {
		id: 'issue-table.common.fields.sentiment.positive-sentiment-icon-label',
		defaultMessage: 'Positive sentiment icon',
		description: 'Label for positive sentiment icon.',
	},
	neutralSentimentIconLabel: {
		id: 'issue-table.common.fields.sentiment.neutral-sentiment-icon-label',
		defaultMessage: 'Neutral sentiment icon',
		description: 'Label for neutral sentiment icon.',
	},
});
