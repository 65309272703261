import { type Action, createStore, createHook } from '@atlassian/react-sweet-state';
import type { QueuesMenuIssueCountEnabledState } from '../../types/index.tsx';

export const actions = {
	updateIssueCountEnabledQueues:
		(queues: number[]): Action<QueuesMenuIssueCountEnabledState> =>
		({ setState }) => {
			setState({ queues });
		},
};

type Actions = typeof actions;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialState: QueuesMenuIssueCountEnabledState = {
	queues: [],
} as QueuesMenuIssueCountEnabledState;

const Store = createStore<QueuesMenuIssueCountEnabledState, Actions>({
	name: 'jsm-queue-groups-issue-count-enabled-queues-store',
	initialState,
	actions,
});

export const useIssueCountEnabledQueues = createHook(Store, {
	selector: (state: QueuesMenuIssueCountEnabledState) => state?.queues,
});
