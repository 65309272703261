export default class IssueMutation {
	issueId: string;

	mutationType: number;

	version: string;

	matchStatus: string[];

	lastModified: string;

	constructor(
		issueId: string,
		mutationType: number,
		version = '1',
		matchStatus: string[] = [],
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		lastModified?: string,
	) {
		this.issueId = issueId;
		this.mutationType = mutationType;
		this.version = version;
		this.matchStatus = matchStatus;
		// create a new timeStamp whenever an object is initialized
		this.lastModified = new Date().toISOString();
	}
}
