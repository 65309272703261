/** @jsx jsx */
// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType } from 'react';
import { css, styled, jsx } from '@compiled/react';
import debounce from 'lodash/debounce';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { withTheme } from '../../app/context/theme-context/index.tsx';
import type { RowId } from '../../model/rows/index.tsx';
import type { CompiledTheme } from '../../model/themes/index.tsx';

const ANALYTICS_DEBOUNCE_MILLIS = 1000;

type Props = {
	rowIds: RowId[];
	width: number;
	containerWidth: number;
	hasHorizontalScrollbar: boolean;
	hasVerticalScrollbar: boolean;
	hasLoadingRows: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Header: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Content: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ResizeColumnOverlay: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HorizontalScrollOverlay: ComponentType<Record<any, any>>;
	onTableChange: () => void;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class TableMain extends PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		this.analyticsDebouncer = debounce(props.onTableChange, ANALYTICS_DEBOUNCE_MILLIS);
	}

	componentDidMount() {
		const { containerWidth, hasLoadingRows, onTableChange } = this.props;
		if (containerWidth && !hasLoadingRows) {
			onTableChange();
		}
	}

	// @ts-expect-error - TS7006 - Parameter 'prevProps' implicitly has an 'any' type.
	componentDidUpdate(prevProps) {
		const { rowIds, containerWidth, hasLoadingRows, hasHorizontalScrollbar, hasVerticalScrollbar } =
			this.props;

		if (
			!hasLoadingRows &&
			(rowIds !== prevProps.rowIds ||
				containerWidth !== prevProps.containerWidth ||
				hasHorizontalScrollbar !== prevProps.hasHorizontalScrollbar ||
				hasVerticalScrollbar !== prevProps.hasVerticalScrollbar)
		) {
			/*
			 * send analytics event if -
			 * - the window was resized
			 * - the scrollbar state changed (new rows added, rows expanded, columns added)
			 * - table content updated (new table/queue, pagination, sorting, asyn updates)
			 *    - but not 'loading' rows
			 *
			 * Do this over a 1 sec timer to avoid multiple events getting fired during window resize
			 * since AutoSizer keeps updating this component during every resize
			 */
			this.analyticsDebouncer();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsDebouncer: any;

	render() {
		const { width, Header, Content, ResizeColumnOverlay, HorizontalScrollOverlay } = this.props;

		return (
			<div css={wrapperStyles} style={{ width: `${width}px` }}>
				<HeaderWrapper>
					<Header />
				</HeaderWrapper>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<ContentWrapper className="virtual-table-content-wrapper">
					<Content />
				</ContentWrapper>
				<ResizeColumnOverlay />
				<HorizontalScrollOverlay />
			</div>
		);
	}
}

export default ComponentWithAnalytics('virtualTable', {
	onTableChange: 'updated',
})(withTheme(TableMain));

const wrapperStyles = css({
	flex: '0 0 auto',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	flex: '0 0 auto',
	paddingRight: token('space.400', '32px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	flex: '1 1 auto',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
});
