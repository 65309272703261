// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { ColumnExpandCallback } from '../../../../../../../../../model/callbacks/index.tsx';
import type { Optional } from '../../../../../../../../../model/optional/index.tsx';
import minusMaskImage from './minus-mask.svg';
import neutralMaskImage from './neutral-mask.svg';
import plusMaskImage from './plus-mask.svg';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpansionContainer = styled.div({
	flex: '0 0 auto',
	width: token('space.150', '12px'),
	marginRight: token('space.075', '6px'),
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpansionIndicatorContainer = styled.div<{ columnHasChildren?: any }>({
	width: token('space.150', '12px'),
	height: token('space.150', '12px'),
	position: 'relative',
	opacity: 0.75,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props: any) => (props.columnHasChildren ? 'pointer' : 'default'),
	'&:hover': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpansionIndicatorBackground = styled.div<{ color: string }>({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: any) => props.color,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BaseExpansionIndicatorMask = styled.div({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NeutralExpansionIndicatorMask = styled(BaseExpansionIndicatorMask)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundImage: `url(${neutralMaskImage})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PlusExpansionIndicatorMask = styled(BaseExpansionIndicatorMask)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundImage: `url(${plusMaskImage})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MinusExpansionIndicatorMask = styled(BaseExpansionIndicatorMask)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundImage: `url(${minusMaskImage})`,
});

type Props = {
	columnId: string;
	isParentColumn: boolean;
	columnHasChildren: Optional<boolean>;
	isColumnExpanded: boolean;
	color: string;
	onColumnExpansionChangeRequested: ColumnExpandCallback;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnChildrenOperationIndicator extends PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		columnHasChildren: undefined,
	};

	onColumnExpanded = () => {
		const { columnId, isColumnExpanded, columnHasChildren, onColumnExpansionChangeRequested } =
			this.props;

		// neutral indicators should not be actionable
		if (columnHasChildren === true) {
			onColumnExpansionChangeRequested({
				columnId,
				shouldExpand: !isColumnExpanded,
			});
		}
	};

	renderExpansionIndicator() {
		const { columnHasChildren, isColumnExpanded } = this.props;

		if (columnHasChildren === true) {
			if (isColumnExpanded) {
				return <MinusExpansionIndicatorMask />;
			}
			return <PlusExpansionIndicatorMask />;
		}
		return <NeutralExpansionIndicatorMask />;
	}

	renderExpansionIndicatorContainer() {
		const { color, columnHasChildren } = this.props;
		return (
			<ExpansionIndicatorContainer
				onClick={this.onColumnExpanded}
				columnHasChildren={columnHasChildren}
				role="button"
				tabIndex={0}
				onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && this.onColumnExpanded}
			>
				<ExpansionIndicatorBackground color={color} />
				{this.renderExpansionIndicator()}
			</ExpansionIndicatorContainer>
		);
	}

	render() {
		return (
			this.props.isParentColumn && (
				<ExpansionContainer>{this.renderExpansionIndicatorContainer()}</ExpansionContainer>
			)
		);
	}
}
