import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import has from 'lodash/has';
import { Observable } from 'rxjs/Observable';
import commonFetchJson$, {
	type JiraFetchOptions,
} from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import commonfetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import commonFetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

type ResponseInnerError = {
	errorMessage: string;
};

export type ErrorResponse = {
	response: {
		errors: ResponseInnerError[];
		reasonKey: string;
		reasonCode: string;
	};
	statusCode: number;
	traceId?: string;
};

const buildErrorResponse = (
	message: string,
	statusCode: number,
	traceId?: string,
): ErrorResponse => ({
	response: {
		errors: [{ errorMessage: message }],
		reasonKey: 'generic.error',
		reasonCode: `${statusCode}`,
	},
	statusCode,
	traceId,
});

const toAnError = (error: FetchError): ErrorResponse => {
	const traceId = error.traceId;

	try {
		// ServiceDesk REST errors be usually be in the form of a JSON
		// object (e.g., AnError).  We'll try to parse it.
		const response = JSON.parse(error.message);

		// Normalize the shape of "simple" JSON error responses containing
		// message and status-code, as used in e.g., standard Jira
		// unauthorized response.
		if (has(response, 'message') && has(response, 'status-code')) {
			return buildErrorResponse(response.message, parseInt(response['status-code'], 10), traceId);
		}

		return {
			response,
			statusCode: error.statusCode,
			traceId,
		};
	} catch (e) {
		// Message didn't parse as JSON; assume it's a plain ol' string, and
		// we'll produce an AnError-like object with it.
		return buildErrorResponse(error.message, error.statusCode, traceId);
	}
};

// fetchJson, wrapped with additional JSD-oriented handling for error responses.
export const fetchJson = (url: string, opts?: RequestInit) =>
	commonfetchJson(url, opts).catch((error: FetchError) => Promise.reject(toAnError(error)));

// fetchJson$, wrapped with additional JSD-oriented handling for error responses.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchJson$ = (url: string, opts?: JiraFetchOptions): Observable<any> =>
	commonFetchJson$(url, opts).catch((error: FetchError) => Observable.throw(toAnError(error)));

// fetchText$, wrapped with additional JSD-oriented handling for error responses.
export const fetchText$ = (url: string, opts?: JiraFetchOptions) =>
	commonFetchText$(url, opts).catch((error: FetchError) => Observable.throw(toAnError(error)));
