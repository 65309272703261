import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';

type Props = {
	coreColumnsWidth: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AddLink: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ScrollArea: ComponentType<Record<any, any>>;
};

export default function UtilityRow(props: Props) {
	const { coreColumnsWidth, AddLink, ScrollArea } = props;

	return (
		<Wrapper>
			<CoreWidthPlaceholder width={coreColumnsWidth}>
				<AddLink />
			</CoreWidthPlaceholder>
			<ScrollArea />
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CoreWidthPlaceholder = styled.div<{ width: string | number }>({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
});
