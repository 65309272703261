import { connect } from '../../../../../../common/table-redux.tsx';
import type { ColumnId } from '../../../../../../model/columns/index.tsx';
import { isFirstColumn } from '../../../../../../state/consumer/selectors/columns/index.tsx';
import { getColumnWidth } from '../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../state/types.tsx';
import DefaultColumn from './default-column/index.tsx';
import ColumnWrapper from './index-dumb.tsx';

export default connect(
	(state: State, { id }: { id: ColumnId }) => ({
		width: getColumnWidth(state, id),
		isFirstColumn: isFirstColumn(state, id),
		DefaultColumn,
	}),
	{},
)(ColumnWrapper);
