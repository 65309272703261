import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { CompiledTheme } from '../../../../../../../model/themes/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div<{
	addPadding: boolean;
	addBarOverflow: number;
	theme: CompiledTheme;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ addPadding, addBarOverflow }) =>
		addPadding ? `${addBarOverflow}px` : token('space.0', '0px'),
	paddingTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Spacer = styled.div<{ height: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InnerWrapper = styled.div({
	position: 'relative',
});
