import React, { type ReactNode, useEffect } from 'react';

import useControlled from '@atlaskit/ds-lib/use-controlled';
import { Popup } from '@atlaskit/popup/experimental';

import { IsOpenContext, SetIsOpenContext } from './flyout-menu-item-context';

export type FlyoutMenuItemProps = {
	children: ReactNode;
	/**
	 * ID that is assigned to the popup container element and used to associate the trigger with the content.
	 */
	id?: string;
	/**
	 * Allows to control the open state of the flyout externally.
	 */
	isOpen?: boolean;
	/**
	 * Whether the flyout menu is open by default.
	 */
	isDefaultOpen?: boolean;
	/**
	 * Callback that is called when the flyout menu is opened or closed.
	 */
	onOpenChange?: (isOpen: boolean) => void;
};

/**
 * __FlyoutMenuItem__
 *
 * Displays content in a flyout menu, triggered by a button.
 *
 * The top-level component that contains the trigger and content of a flyout menu.
 *
 * Usage example:
 * ```jsx
 * <FlyoutMenuItem>
 *   <FlyoutMenuItemTrigger>Trigger</FlyoutMenuItemTrigger>
 *   <FlyoutMenuItemContent>
 *     <MenuButtonItem>Item 1</MenuButtonItem>
 *     <MenuButtonItem>Item 2</MenuButtonItem>
 *   </FlyoutMenuItemContent>
 * </FlyoutMenuItem>
 * ```
 */
export const FlyoutMenuItem = ({
	children,
	id,
	isOpen: isOpenControlled,
	isDefaultOpen = false,
	onOpenChange,
}: FlyoutMenuItemProps) => {
	const [isOpen, setIsOpen] = useControlled(isOpenControlled, () => isDefaultOpen);

	useEffect(() => {
		onOpenChange?.(isOpen);
	}, [isOpen, onOpenChange]);

	return (
		<IsOpenContext.Provider value={isOpen}>
			<SetIsOpenContext.Provider value={setIsOpen}>
				<Popup id={id} isOpen={isOpen}>
					{children}
				</Popup>
			</SetIsOpenContext.Provider>
		</IsOpenContext.Provider>
	);
};
