import React, { Component } from 'react';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import { SelectableChildGutterPaddingContainer } from '../common/styled/index.tsx';
import EditableStatus from './editable-status/view.tsx';
import ReadOnlyStatus from './read-only-status/view.tsx';

type State = {
	canRenderEditExperience: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class StatusFieldWrapper extends Component<JsonComponentProps<'status'>, State> {
	static getDerivedStateFromProps(
		props: JsonComponentProps<'status'>,
		{ canRenderEditExperience }: State,
	) {
		const {
			dataSelectorProps: { isEditable },
			tableInjectedProps: { shouldHydrateFully },
		} = props;

		if (shouldHydrateFully && isEditable === true && !canRenderEditExperience) {
			return {
				canRenderEditExperience: true,
			};
		}

		return null;
	}

	state = {
		canRenderEditExperience: false,
	};

	onMouseOver = () => {
		this.enableEditExperienceIfAvailable();
	};

	onFocus = () => {
		this.enableEditExperienceIfAvailable();
	};

	enableEditExperienceIfAvailable() {
		if (this.props.dataSelectorProps.isEditable === true && !this.state.canRenderEditExperience) {
			this.setState({ canRenderEditExperience: true });
		}
	}

	render() {
		const {
			dataSelectorProps: {
				value: {
					id,
					name,
					description,
					statusCategory: { id: statusCategoryId },
				},
				isEditable,
				onChange,
			},
			tableInjectedProps: { isLastColumn, issueKey, issueFieldLockControls },
		} = this.props;
		const { canRenderEditExperience } = this.state;

		const transformedProps = {
			value: {
				id: id.toString(),
				name,
				description,
				statusCategory: { id: statusCategoryId },
			},
			isLastColumn,
			appearance: 'lozenge',
			issueKey: toIssueKey(issueKey),
			onChange,
			issueFieldLockControls,
		};

		if (isEditable === true && canRenderEditExperience) {
			return (
				<SelectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
					{/* @ts-expect-error - TS2322 - Type '{ value: { id: string; name: string; description: string; statusCategory: { id: number; }; }; isLastColumn: boolean; appearance: string; issueKey: string; onChange: OnChangeHandler<StatusProps>; issueFieldLockControls: IssueFieldLockControls; }' is not assignable to type 'Readonly<Props>'. */}
					<EditableStatus {...transformedProps} />
				</SelectableChildGutterPaddingContainer>
			);
		}

		// SelectableChildGutterPaddingContainer, because it always renders within (possibly disabled) button.
		return (
			<SelectableChildGutterPaddingContainer
				isLastColumn={isLastColumn}
				onMouseOver={this.onMouseOver}
				onFocus={this.onFocus}
			>
				{/* @ts-expect-error - TS2322 - Type '{ value: { id: string; name: string; description: string; statusCategory: { id: number; }; }; isLastColumn: boolean; appearance: string; issueKey: string; onChange: OnChangeHandler<StatusProps>; issueFieldLockControls: IssueFieldLockControls; }' is not assignable to type 'Props'. */}
				<ReadOnlyStatus {...transformedProps} />
			</SelectableChildGutterPaddingContainer>
		);
	}
}
