import React, { type Dispatch } from 'react';
import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import { UIControllerSubscriber as NavUIControllerSubscriber } from '@atlassian/jira-navigation-next-bridge/src/controllers/navigation-ui-controller/index.tsx';
import { NavigationUISubscriber } from '@atlassian/jira-navigation-ui-controller/src/components.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { UpFlowPersistentUpgradeBanner } from '@atlassian/jira-up-flow-persistent-upgrade-banner/src/async.tsx';
import { LOAD_ISSUES_ACTION_SOURCE_SIDEBAR, type SortedQueue } from '../../model/index.tsx';
import {
	filterQueryChangedAction,
	filterQuerySubmittedAction,
	isNewFilterQueryPendingAction,
} from '../../state/actions/filter/index.tsx';
import { loadIssuesAction } from '../../state/actions/issue/index.tsx';
import { shutdownAction } from '../../state/actions/lifecycle/index.tsx';
import {
	changeVisibilityAction,
	displayQueuesErrorHeaderAction,
} from '../../state/actions/page/index.tsx';
import { queueChangedAction } from '../../state/actions/queue/index.tsx';
import {
	onboardingResolvedAction,
	setNavCollapsedAction,
	setSidebarIssueKey,
} from '../../state/actions/ui/index.tsx';
import { restartPollAction, stopPollAction } from '../../state/actions/update-metadata/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { getCreateAnalyticsEvent } from '../../state/selectors/analytic/index.tsx';
import { getAppProps } from '../../state/selectors/common/index.tsx';
import { getIsCurrentDataFiltered } from '../../state/selectors/filter/index.tsx';
import { getCollection, getIssueCount } from '../../state/selectors/issues/index.tsx';
import { getEnabledPractices, isItsmProject } from '../../state/selectors/practices/index.tsx';
import { getCategory, getColumns, getJql } from '../../state/selectors/queue/index.tsx';
import {
	getOnboardingResolved,
	isInitialized,
	isLoadingIssues,
	isQueueEmpty,
	showIssueFailureError,
	shouldShowBadFilterQueryError,
	getSidebarIssueKey,
	getSidebarReactKey,
	isQueuesErrorHeaderDisplayed,
} from '../../state/selectors/ui/index.tsx';
import EmptyFilteredQueue from './empty-filtered-queue/view.tsx';
import EmptyQueue from './empty-queue/index.tsx';
import ErrorState from './error-state/index.tsx';
import Header from './header/index.tsx';
import IssueContextListener from './issue-context-listener/index.tsx';
import ItsmContextListener from './itsm-context-listener/index.tsx';
import NavUpdater from './nav-updater/index.tsx';
import QueuesBreadcrumb from './queues-breadcrumb/index.tsx';
import Table from './table/index.tsx';
import UsageSampler from './usage-sampler/view.tsx';
import Layout, { type DispatchProps, type OwnProps, type Props, type StateProps } from './view.tsx';

const getColumnsMemoized = () => createSelector(getColumns, (res) => res);
const EMPTY_LIST: ReturnType<typeof getEnabledPractices> = [];
const mapStateToPropsFactory = () => {
	const getColumnsInstance = getColumnsMemoized();
	return (state: State): StateProps => ({
		Header,
		UsageSampler,
		EmptyQueue,
		ErrorState,
		isQueueEmpty: isQueueEmpty(state),
		showIssueFailureError: showIssueFailureError(state),
		isLoadingIssues: isLoadingIssues(state),
		issueCount: getIssueCount(state),
		onboardingResolved: getOnboardingResolved(state),
		isCurrentDataFiltered: getIsCurrentDataFiltered(state),
		Table,
		EmptyFilteredQueue,
		NavUpdater,
		ViewTracker,
		QueuesBreadcrumb,
		IssueContextListener,
		ItsmContextListener,
		UpFlowPersistentUpgradeBanner,
		baseUrl: '',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		projectKey: getAppProps(state).projectKey!,
		isInitialized: isInitialized(state),
		category: getCategory(state),
		itsmPractices: isItsmProject(state) ? getEnabledPractices(state) : EMPTY_LIST,
		issueCollection: getCollection(state),
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		projectId: getAppProps(state).projectId!,
		columns: getColumnsInstance(state),
		jql: getJql(state),
		shouldShowBadFilterQueryError: shouldShowBadFilterQueryError(state),
		createAnalyticsEvent: getCreateAnalyticsEvent(state),
		sidebarIssueKey: getSidebarIssueKey(state),
		sidebarReactKey: getSidebarReactKey(state),
		isQueuesErrorHeaderDisplayed: isQueuesErrorHeaderDisplayed(state),
	});
};

const mapDispatchToProps = (
	dispatch: Dispatch<{
		type: unknown;
	}>,
) => ({
	onPageVisibilityChanged: (isQueueVisible: boolean) => {
		dispatch(changeVisibilityAction(isQueueVisible));

		if (!isQueueVisible) {
			dispatch(stopPollAction());
		}
	},
	onUnmount: () => {
		dispatch(shutdownAction());
	},
	onQueueChanged: (queue: SortedQueue) => {
		dispatch(queueChangedAction(queue));
	},
	onOnboardingResolved: (resolved: boolean) => dispatch(onboardingResolvedAction(resolved)),
	onNavCollapseChange: (isNavCollapsed: boolean) => {
		dispatch(setNavCollapsedAction(isNavCollapsed));
	},
	onWindowFocus: () => {
		dispatch(restartPollAction());
	},
	onFilterClear: () => {
		dispatch(filterQueryChangedAction(null));
		dispatch(filterQuerySubmittedAction(null));
	},
	onIssueChange: () => {
		dispatch(isNewFilterQueryPendingAction(true));
		dispatch(loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_SIDEBAR));
	},
	onSetIssueKey: (key: string | null) => {
		dispatch(setSidebarIssueKey(key));
	},
	onNonPremiumSLAColumnError: () => displayQueuesErrorHeaderAction,
});

const getSidebarApiProps = memoizeOne((sidebarApiProps) => sidebarApiProps);

const LayoutWithSidebarApi = (props: Props) => (
	<NavUIControllerSubscriber>
		{({ state: sidebarState }) => (
			<NavigationUISubscriber>
				{(_, navActions) => (
					<Layout
						{...props}
						sidebarApi={getSidebarApiProps({
							navActions,
							isCollapsed: sidebarState.isCollapsed,
						})}
					/>
				)}
			</NavigationUISubscriber>
		)}
	</NavUIControllerSubscriber>
);

const ConnectedLayout = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToPropsFactory,
	mapDispatchToProps,
)(LayoutWithSidebarApi);

export default ConnectedLayout;
