import type { MouseEvent } from 'react';

export const overrideTransitionsInCurrentTab = (
	push: () => void,
	event: MouseEvent<HTMLElement>,
) => {
	const { ctrlKey, metaKey } = event;
	// Let default browser behaviour occur when opening link in new tab/window
	// metaKey for macOS cmd. ctrKey for other OSes.
	if (ctrlKey || metaKey) {
		return;
	}

	event.preventDefault();
	push();
};

export const getIssueTransitionOverride =
	(push: (arg1: string) => void) => (issueKey: string, event: MouseEvent<HTMLElement>) =>
		overrideTransitionsInCurrentTab(() => push(issueKey), event);
