import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type CMDB_GQL_FIELD,
	CMDB_OBJECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { JiraCmdbAttributeType } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import type {
	AggJiraCMDBField,
	AggJiraCMDBAttribute,
	AggJiraCMDBObject,
	AggJiraCMDBObjectAttributeValue,
} from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import type { Path } from '../types/utils.tsx';
import {
	transformAggNodeToLegacyField,
	transformAggConnection,
} from './default-node-transformer.tsx';

const transformObjectTypeToLegacy = (
	attribute: Path<AggJiraCMDBObject, ['objectType']>,
	workspaceId: string,
) => ({
	workspaceId,
	id: `${workspaceId}:${attribute.objectTypeId}`,
	name: attribute.name,
	objectSchemaId: attribute.objectSchemaId,
	objectTypeId: attribute.objectTypeId,
	// `icon` is unused
	icon: {
		id: attribute.icon.id,
		name: attribute.icon.name,
		url16: attribute.icon.url16,
		url48: attribute.icon.url48,
	},
});

const transformCMDBConfigurationToLegacyConfiguration = (node: AggJiraCMDBField) => {
	if (!node.cmdbFieldConfig) {
		return node.isInsightAvailable === false ? { isInsightAvailable: node.isInsightAvailable } : {};
	}

	return {
		cmdbFieldConfig: {
			multiple: node.cmdbFieldConfig.multiple,
			objectSchemaId: node.cmdbFieldConfig.objectSchemaId.split('/')[2],
			attributesDisplayedOnIssue: transformAggConnection(
				node.cmdbFieldConfig?.attributesDisplayedOnIssue,
			),
			attributesIncludedInAutoCompleteSearch: transformAggConnection(
				node.cmdbFieldConfig?.attributesIncludedInAutoCompleteSearch,
			),
		},
		isInsightAvailable: node.isInsightAvailable,
		wasInsightRequestSuccessful: node.wasInsightRequestSuccessful,
	};
};

const transformObjectTypeAttributeToLegacy = (
	type: string | null | undefined | JiraCmdbAttributeType,
) => {
	switch (type) {
		case 'REFERENCED_OBJECT':
			return 1;
		case 'USER':
			return 2;
		case 'GROUP':
			return 4;
		case 'PROJECT':
			return 6;
		case 'STATUS':
			return 7;
		case 'BITBUCKET_REPO':
			return 8;
		case 'OPSGENIE_TEAM':
			return 9;
		case 'DEFAULT':
			return 0;
		default:
			// eslint-disable-next-line no-console
			console.error(
				new Error(
					`Unknown attribute type found in transformObjectTypeAttributeToLegacy: ${type}. Mapping type to '-1'`,
				),
			);
			return -1;
	}
};

const transformReferencedObject = (
	node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['referencedObject']>>,
) => ({
	id: node.objectGlobalId,
	workspaceId: node.workspaceId,
	objectKey: node.objectKey,
	label: node.label,
	avatar: node.avatar,
	objectId: node.objectId,
});

const transformCmdbUser = (node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['user']>>) => ({
	avatarUrl: node.picture,
	displayName: node.name,
	name: node.name,
	key: node.accountId,
});

const transformCmdbGroup = (
	node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['group']>>,
) => ({
	name: node.name,
});

const transformCmdbProject = (
	node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['project']>>,
) => ({
	avatarUrl: node.avatar?.large,
	id: Number(node.id),
	key: node.key,
	name: node.name,
});

const transformCmdbBitbucketRepo = (
	node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['bitbucketRepo']>>,
) => ({
	uuid: node.uuid,
	name: node.name,
	url: node.url,
	avatarUrl: node.avatarUrl,
});

const transformCmdbOpsgenieTeam = (
	node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['opsgenieTeam']>>,
) => ({
	id: node.id,
	name: node.name,
	url: node.url,
});

const transformCmdbStatus = (
	node: NonNullable<Path<AggJiraCMDBObjectAttributeValue, ['status']>>,
) => ({
	id: node.id,
	name: node.name,
	category: node.category,
});

const transformObjectAttributeValuesToLegacy = (node: AggJiraCMDBObjectAttributeValue) => {
	if (!node) {
		return null;
	}

	const defaultObjectValue = {
		displayValue: node.displayValue,
		searchValue: node.searchValue,
		...(node.value != null ? { value: node.value } : {}),
	};

	if (node.referencedObject) {
		return {
			...defaultObjectValue,
			referencedObject: transformReferencedObject(node.referencedObject),
		};
	}

	if (node.user) {
		return {
			...defaultObjectValue,
			user: transformCmdbUser(node.user),
		};
	}

	if (node.group) {
		return {
			...defaultObjectValue,
			group: transformCmdbGroup(node.group),
		};
	}

	if (node.project) {
		return {
			...defaultObjectValue,
			project: transformCmdbProject(node.project),
		};
	}

	if (node.bitbucketRepo) {
		return {
			...defaultObjectValue,
			bitbucketRepo: transformCmdbBitbucketRepo(node.bitbucketRepo),
		};
	}

	if (node.opsgenieTeam) {
		return {
			...defaultObjectValue,
			opsgenieTeam: transformCmdbOpsgenieTeam(node.opsgenieTeam),
		};
	}

	if (node.status) {
		return {
			...defaultObjectValue,
			status: transformCmdbStatus(node.status),
		};
	}

	return defaultObjectValue;
};

const transformObjectAttributesToLegacyObjectAttributes = (
	node: AggJiraCMDBAttribute,
	workspaceId: string,
) => {
	if (!node) {
		return null;
	}

	const { objectTypeAttributeId, objectTypeAttribute } = node;

	return {
		objectTypeAttributeId,
		workspaceId,
		objectAttributeValues: transformAggConnection(
			node.objectAttributeValues,
			transformObjectAttributeValuesToLegacy,
		),
		objectTypeAttribute: {
			id: objectTypeAttributeId,
			label: objectTypeAttribute.label,
			name: objectTypeAttribute.name,
			workspaceId,
			type: transformObjectTypeAttributeToLegacy(objectTypeAttribute.type),
			...(objectTypeAttribute.defaultType
				? {
						defaultType: {
							id: objectTypeAttribute.defaultType.id,
							name: objectTypeAttribute.defaultType.name,
						},
					}
				: {}),
		},
	};
};

const transformJiraCMDBToLegacyCMDB = (node: AggJiraCMDBObject) => {
	if (!node) {
		return null;
	}

	const { label, objectGlobalId, objectId, objectKey, workspaceId, webUrl, avatar } = node;

	return {
		attributes: transformAggConnection(node.attributes, (nodeAttribute) =>
			transformObjectAttributesToLegacyObjectAttributes(nodeAttribute, workspaceId),
		),
		avatar,
		label,
		id: objectGlobalId,
		objectId,
		objectKey,
		workspaceId,
		links: {
			self: webUrl,
		},
		objectType: transformObjectTypeToLegacy(node.objectType, workspaceId),
	};
};

export const transformAggCMDBToLegacyField: AggTransformerFunction<typeof CMDB_GQL_FIELD> = (
	node,
) => {
	if (node.type !== CMDB_OBJECT_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		autoCompleteUrl: node.searchUrl != null ? `${node.searchUrl}?` : null,
		configuration: transformCMDBConfigurationToLegacyConfiguration(node),
		value: transformAggConnection(
			node.selectedCmdbObjectsConnection,
			transformJiraCMDBToLegacyCMDB,
		),
		schema: {
			configuration: null,
			custom: CMDB_OBJECT_CF_TYPE,
			items: 'cmdb-object-field',
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};
};
