// set table size
import type { TableSize } from '../../model/index.tsx';

export const SET_TABLE_SIZE = 'ops.table-size.SET_TABLE_SIZE' as const;

export type SetTableSizeAction = {
	type: typeof SET_TABLE_SIZE;
	payload: TableSize;
};

export const set = (tableSize: TableSize): SetTableSizeAction => ({
	type: SET_TABLE_SIZE,
	payload: tableSize,
});
