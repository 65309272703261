import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

// UPDATE_PROVIDED_ISSUE_KEYS

export const UPDATE_PROVIDED_ISSUE_KEYS =
	'state.actions.issues.UPDATE_PROVIDED_ISSUE_KEYS' as const;

export type UpdateProvidedIssueKeysAction = {
	type: typeof UPDATE_PROVIDED_ISSUE_KEYS;
	payload: {
		issueKeys: string[];
	};
};

export const updateProvidedIssueKeysAction = (
	issueKeys: string[],
): UpdateProvidedIssueKeysAction => ({
	type: UPDATE_PROVIDED_ISSUE_KEYS,
	payload: {
		issueKeys,
	},
});

// ISSUE_EDIT_STARTED

export const ISSUE_EDIT_STARTED = 'state.actions.issues.ISSUE_EDIT_STARTED' as const;

export type IssueEditStartedAction = {
	type: typeof ISSUE_EDIT_STARTED;
	payload: {
		issueKey: string;
		fieldType: string;
	};
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const issueEditStartedAction = (
	fieldType: string,
	issueKey: string,
	analyticsEvent: UIAnalyticsEvent,
): IssueEditStartedAction => ({
	type: ISSUE_EDIT_STARTED,
	payload: {
		issueKey,
		fieldType,
	},
	meta: {
		analyticsEvent,
	},
});
