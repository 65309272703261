import type { SortOrder } from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { ResponseField } from '../rest/issue/fields/types.tsx';

export type QueueId = number;
export const toQueueId = (queueId: number): QueueId => queueId;
export const fromQueueId = (queueId: QueueId): number => queueId;

export const toQueueIdString = (queueId: string): QueueId => toQueueId(Number(queueId));
export const fromQueueIdString = (queueId: QueueId): string => fromQueueId(queueId).toString();

export type QueueCategory = ItsmPractice | undefined;

export type Column = {
	id: string;
	title: string;
	fieldType: string;
	sortable: boolean;
	currentSorting?: SortOrder;
};

export type Queue = {
	id: QueueId;
	name: string;
	jql: string;
	completeJql: string;
	order: number;
	columns: Column[];
	isFavorite: boolean;
	/**
	 * A transient queue is one which is unavailable in persistent
	 * storage. This is used, for example, to provide a queue-like experience
	 * when no queues have been defined in the project.
	 */
	isTransient?: boolean;
	category?: QueueCategory;
	canBeHidden: boolean;
};

export type Queues = {
	[key: string]: Queue;
};

export type QueueChangedNotifier = {
	onIssueCountUpdated: (queueId: QueueId, itemCount: number) => void;
	onNavUpdated: (arg1: boolean) => void;
};

export type IndexItems = {
	[key: string]: number;
};

export type FieldsById = {
	[key: string]: ResponseField;
};

export type Issue = {
	id: number;
	key: string;
	fields: FieldsById;
};

export const LOCAL_STORAGE_SOURCE = 'LOCAL_STORAGE' as const;
export const PREFETCHER_SOURCE = 'PREFETCHER' as const;
export const REST_SOURCE = 'REST' as const;
export const SSR_SOURCE = 'SSR' as const;

export type IssueListSource =
	| typeof LOCAL_STORAGE_SOURCE
	| typeof PREFETCHER_SOURCE
	| typeof SSR_SOURCE
	| typeof REST_SOURCE;
