import React from 'react';
import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const WRMFallbackContainer = styled.span({
	/* We don't want this to display under any circumstances */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	display: 'none !important',
	height: 0,
	opacity: 0,
});

type Props = {
	wrmData: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ wrmData }: Props) => (
	<WRMFallbackContainer id="jsd-spa-page-wrm-data-container">
		{JSON.stringify(wrmData)}
	</WRMFallbackContainer>
);
