// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { sortIndicatorWidth } from '../../../../../../../../../constants/index.tsx';
import type { SortedColumnChangeCallback } from '../../../../../../../../../model/callbacks/index.tsx';
import type {
	SortOrder,
	SortOrderConfiguration,
} from '../../../../../../../../../model/columns/index.tsx';
import type { Optional } from '../../../../../../../../../model/optional/index.tsx';
import { INDICATOR_METADATA, DEFAULT_SORT_INDICATOR_METADATA } from './constants.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortIndicator = styled.div<{ image: any }>({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${sortIndicatorWidth}px`,
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: `center center / ${sortIndicatorWidth}px ${sortIndicatorWidth / 2}px no-repeat`,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundImage: `url(${(props: any) => props.image})`,
	marginLeft: token('space.075', '6px'),
	cursor: 'pointer',
});

type VoidFunction = () => void;

type Props = {
	columnId: string;
	sortOrder: Optional<SortOrder>;
	isColumnSortable: boolean;
	isSortEnabled: boolean;
	isDefaultSortColumn: boolean;
	setColumnSortOrder: (arg1: SortOrderConfiguration) => void;
	clearColumnSortOrder: VoidFunction;
	onClearAddedRowsRequested: VoidFunction;
	onSortedColumnChanged: SortedColumnChangeCallback;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnSortOperationIndicator extends PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		sortOrder: undefined,
	};

	setSortOrder(nextSortOrder: SortOrder) {
		return () => {
			const { columnId, setColumnSortOrder, onSortedColumnChanged } = this.props;
			const payload = { columnId, sortOrder: nextSortOrder };

			setColumnSortOrder(payload);
			onSortedColumnChanged(payload);
		};
	}

	clearSortOrder() {
		return () => {
			const { clearColumnSortOrder, onSortedColumnChanged } = this.props;

			clearColumnSortOrder();
			onSortedColumnChanged(undefined);
		};
	}

	/* Along with sorting, any rows that were added since the last sort
	 * and thus positioned "absolutely" should be discarded to allow for
	 * explicit sorting once again.
	 */
	createClickAction(sortSpecificCallbacks: VoidFunction) {
		return () => {
			const { onClearAddedRowsRequested } = this.props;

			sortSpecificCallbacks();
			onClearAddedRowsRequested();
		};
	}

	renderSortOperationIndicator() {
		const { sortOrder, isDefaultSortColumn } = this.props;

		const { nextSortOrder, image } = isDefaultSortColumn
			? DEFAULT_SORT_INDICATOR_METADATA[sortOrder || 'DEFAULT']
			: INDICATOR_METADATA[sortOrder || 'DEFAULT'];

		const clickAction =
			nextSortOrder === 'DEFAULT'
				? this.createClickAction(this.clearSortOrder())
				: this.createClickAction(this.setSortOrder(nextSortOrder));

		return (
			<SortIndicator
				onClick={clickAction}
				image={image}
				role="button"
				tabIndex={0}
				onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && clickAction}
			/>
		);
	}

	render() {
		const { isColumnSortable, isSortEnabled } = this.props;

		return isColumnSortable && isSortEnabled ? this.renderSortOperationIndicator() : null;
	}
}
