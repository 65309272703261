import {
	QUEUE_ROUTES,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-common/src/navigation/queues/index.tsx';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context/index.tsx';
import {
	useCategorizedNavItems,
	useFavouriteNavItems,
} from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getCategoryStateKey,
	useLastVisitedQueueId,
} from '../../common/controllers/queues-category-state/index.tsx';
import { useFilteredCategorizedQueueGroups } from '../../common/ui/practice-queues/common/index.tsx';

const getStrippedPathname = (pathname: string) => {
	// strip url params and trailing slashes if they exist
	const pathnameWithoutParams = pathname.split('?')[0];
	const pathnameWithoutTrailingSlash = pathnameWithoutParams.endsWith('/')
		? pathnameWithoutParams.slice(0, -1)
		: pathnameWithoutParams;

	return pathnameWithoutTrailingSlash;
};

// Queue URL Validators
export const isValidViewQueueUrl = (
	projectKey: ProjectKey,
	queueId: string | undefined,
	pathname: string,
) => {
	if (!queueId) {
		return false;
	}

	const currentPath = getStrippedPathname(pathname);
	const constructedPath = `${getQueuesUrl(projectKey)}/custom/${queueId}`;

	return currentPath.endsWith(constructedPath);
};
export const isValidQueuesUrl = (projectKey: ProjectKey, pathname: string) =>
	pathname.includes(getQueuesUrl(projectKey));

// Categorised queue URL Validators
export const isValidCategorisedViewQueueUrl = (
	projectKey: ProjectKey,
	category: QueueCategory,
	queueId: string | undefined,
	pathname: string,
) => {
	if (!queueId) {
		return false;
	}
	if (!category) {
		return isValidViewQueueUrl(projectKey, queueId, pathname);
	}

	const currentPath = getStrippedPathname(pathname);
	const constructedPath = `${getCategorisedQueuesUrl(projectKey, category)}/custom/${queueId}`;

	return currentPath.endsWith(constructedPath);
};
export const isValidCategorisedQueuesUrl = (
	projectKey: ProjectKey,
	category: QueueCategory,
	pathname: string,
) => {
	if (!category) {
		return isValidQueuesUrl(projectKey, pathname);
	}

	return pathname.includes(getCategorisedQueuesUrl(projectKey, category));
};

// URL Constructors
export const getCategorisedQueuesUrl = (projectKey: ProjectKey, category: QueueCategory) => {
	if (!category) {
		return getQueuesUrl(projectKey);
	}

	return `${getJsmProjectUrl(projectKey)}/${QUEUE_ROUTES[category]}`;
};
export const getQueuesUrl = (projectKey: ProjectKey) => `${getJsmProjectUrl(projectKey)}/queues`;
export const getJsmProjectUrl = (projectKey: ProjectKey) =>
	`/jira/servicedesk/projects/${projectKey}`;

export const useHighestPriorityQueueId = (projectKey: ProjectKey, category: ItsmPractice) => {
	// Need to get all data before returning to avoid hook errors
	const categoryStateKey = getCategoryStateKey(projectKey, category);
	const [lastVisitedQueueId] = useLastVisitedQueueId(categoryStateKey);

	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [starredQueues] = useFavouriteNavItems('', projectKey, category, queueCustomContext);
	const [allQueues] = useCategorizedNavItems('', projectKey, category, queueCustomContext);
	const [filteredGroups] = useFilteredCategorizedQueueGroups(projectKey, toItsmPractice(category));

	// Always use the last visited queue if it exists
	if (lastVisitedQueueId) {
		return lastVisitedQueueId;
	}

	// Next best option is to return a starred queue
	if (starredQueues?.length) {
		return starredQueues[0].id;
	}

	// Next best option is to return the first queue in the first group
	if (filteredGroups?.length && filteredGroups[0].queues?.length) {
		return filteredGroups[0].queues[0].id;
	}

	// Final option is to return the first queue in the all queues list
	if (allQueues?.length) {
		return allQueues[0].id;
	}

	return undefined;
};

export const useHighestPriorityQueueUrl = (projectKey: ProjectKey, category: ItsmPractice) => {
	const queueId = useHighestPriorityQueueId(projectKey, category);

	if (!queueId) {
		return '';
	}

	return `${getCategorisedQueuesUrl(projectKey, category)}/custom/${queueId}`;
};
