import {
	type Column,
	type Queues,
	toQueueId,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type {
	Page,
	PageResponseColumn,
} from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types.tsx';

const transformColumns = (responseColumns: PageResponseColumn[]): Column[] =>
	responseColumns.map((responseColumn) => {
		const column = {
			id: responseColumn.fieldId,
			title: responseColumn.title,
			fieldType: responseColumn.fieldType,
			sortable: responseColumn.sortable,
		};

		if (responseColumn.sortOrder) {
			return {
				...column,
				currentSorting: responseColumn.sortOrder,
			};
		}

		return column;
	});

export const pageToQueues = (page: Page[]): Queues =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	page.reduce<Record<string, any>>((acc, queue) => {
		acc[queue.id] = {
			id: toQueueId(queue.id),
			name: queue.name,
			jql: queue.jql,
			completeJql: queue.completeJql,
			order: queue.order,
			columns: transformColumns(queue.columnsDetails),
			// British spelling from legacy Backend code
			isFavorite: queue.favourite,
			category: queue.category,
			canBeHidden: queue.canBeHidden,
		};
		return acc;
	}, {});
