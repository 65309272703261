// LOCK_FIELD

export const LOCK_FIELD = 'state.actions.field.lock.LOCK_FIELD' as const;

export type LockFieldAction = {
	type: typeof LOCK_FIELD;
	payload: {
		issueKey: string;
		fieldId: string;
		positionIndex: number;
	};
};

export const lockFieldAction = (
	issueKey: string,
	fieldId: string,
	positionIndex: number,
): LockFieldAction => ({
	type: LOCK_FIELD,
	payload: {
		issueKey,
		positionIndex,
		fieldId,
	},
});

// UNLOCK_FIELD

export const UNLOCK_FIELD = 'state.actions.field.lock.UNLOCK_FIELD' as const;

export type UnlockFieldAction = {
	type: typeof UNLOCK_FIELD;
	payload: {
		issueKey: string;
		fieldId: string;
	};
};

export const unlockFieldAction = (issueKey: string, fieldId: string): UnlockFieldAction => ({
	type: UNLOCK_FIELD,
	payload: {
		issueKey,
		fieldId,
	},
});

// UNLOCK_ALL_FIELDS

export const UNLOCK_ALL_FIELDS = 'state.actions.field.lock.UNLOCK_ALL_FIELDS' as const;

export type UnlockAllFieldsAction = {
	type: typeof UNLOCK_ALL_FIELDS;
};

export const unlockAllFieldsAction = (): UnlockAllFieldsAction => ({
	type: UNLOCK_ALL_FIELDS,
});
