import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type JSM_REQUEST_TYPE_GQL_FIELD,
	REQUEST_TYPE_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { StateValue } from '@atlassian/jira-shared-types/src/rest/jira/customer-request-type.tsx';
import type { AggJiraRequestType } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

const transformRequestTypeToLegacy = (
	requestType: AggJiraRequestType | null,
): StateValue | null => {
	if (!requestType) return null;

	return {
		requestType: {
			id: parseInt(requestType.requestTypeId, 10),
			issueTypeId: requestType.issueType?.issueTypeId || '',
			name: requestType.name || '',
			icon: {
				_links: {
					iconUrls: {
						'48x48': requestType.avatar?.large || '',
					},
				},
			},
		},
	};
};

export const transformAggJSMRequestTypeToLegacyField: AggTransformerFunction<
	typeof JSM_REQUEST_TYPE_GQL_FIELD
> = (node) => {
	if (node.type !== REQUEST_TYPE_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		// @ts-expect-error - Argument of type '{ readonly avatar: { readonly large: string | null | undefined; readonly medium: string | null | undefined; readonly small: string | null | undefined; readonly xsmall: string | null | undefined; } | null | undefined; readonly id: string; readonly issueType: { ...; } | ... 1 more ... | undefined; readonly name: strin...' is not assignable to parameter of type '{ readonly avatar: { readonly large: string | null | undefined; readonly medium: string | null | undefined; readonly small: string | null | undefined; readonly xsmall: string | null | undefined; } | null | undefined; readonly id: string; readonly issueType: { ...; } | ... 1 more ... | undefined; readonly name: strin...'.
		value: transformRequestTypeToLegacy(node.requestType),
		schema: {
			configuration: null,
			custom: REQUEST_TYPE_CF_TYPE,
			items: null,
			renderer: PLAIN_TEXT,
			system: null,
			type: 'sd-customerrequesttype',
		},
	};
};
