import type { Offsets } from '../../reducers/persisted/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getPersisted } from '../common/index.tsx';

export const getOffsets = (state: State): Offsets => getPersisted(state).offsets;

export const getHorizontalScrollOffset = (state: State): number =>
	getOffsets(state).horizontalScrollOffset;

export const getVerticalScrollOffset = (state: State): number =>
	getOffsets(state).verticalScrollOffset;
