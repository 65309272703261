import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import {
	PERSISTENT_UPGRADE_TOUCHPOINT_BANNER,
	PERSISTENT_UPGRADE_ANALYTICS_SOURCE,
} from './constants.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyUpFlowPersistentUpgradeBanner = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-up-flow-persistent-upgrade-banner" */ './main'),
	{
		ssr: false,
	},
);

export const UpFlowPersistentUpgradeBanner = (props: Props) => (
	<ErrorBoundary
		id={PERSISTENT_UPGRADE_ANALYTICS_SOURCE}
		packageName={PERSISTENT_UPGRADE_TOUCHPOINT_BANNER}
		render={() => <></>}
	>
		<Placeholder name="up-flow-persistent-upgrade-banner" fallback={<></>}>
			<LazyUpFlowPersistentUpgradeBanner {...props} />
		</Placeholder>
	</ErrorBoundary>
);
