/* eslint-disable jira/js/no-reduce-accumulator-spread */

import { createSelector } from 'reselect';
import merge from 'lodash/merge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createStore,
	createHook,
	createActionsHook,
	createStateHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type {
	State,
	IssueConfiguration,
	FieldConfigurationWithOptionalKeys,
	FieldConfiguration,
} from './types.tsx';

const setIssueConfig =
	(issueKey: IssueKey, issueConfiguration: IssueConfiguration | null) =>
	({ setState }: StoreActionApi<State>) => {
		// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: IssueConfiguration | null; }' is not assignable to parameter of type 'Partial<Partial<Record<string, IssueConfiguration>>>'.
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: issueConfiguration,
		});
	};

const mergeIssueConfig =
	(issueKey: IssueKey, issueConfiguration: IssueConfiguration | null) =>
	({ setState, getState }: StoreActionApi<State>) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: merge({}, getState()[issueKey], issueConfiguration),
		});
	};

const setIssuesConfig =
	(issuesWithConfigurations: Record<IssueKey, IssueConfiguration>[]) =>
	({ setState }: StoreActionApi<State>) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newIssues = issuesWithConfigurations.reduce<Record<string, any>>(
			(acc: State, issue: Record<IssueKey, IssueConfiguration>) => ({
				...acc,
				...issue,
			}),
			{},
		);
		setState(newIssues);
	};

const mergeIssuesConfig =
	(issuesWithConfigurations: Record<IssueKey, IssueConfiguration>[]) =>
	({ setState, getState }: StoreActionApi<State>) => {
		if (issuesWithConfigurations.length === 0) {
			return;
		}

		const newIssues: Record<IssueKey, IssueConfiguration> = {};
		issuesWithConfigurations.forEach((issue) => {
			Object.assign(newIssues, issue);
		});

		setState(merge({}, getState(), newIssues));
	};

const setFieldConfigValue =
	(
		issueKey: IssueKey,
		fieldKey: string,
		fieldConfiguration: FieldConfigurationWithOptionalKeys<unknown>,
	) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const issueKeyFieldConfiguration = getState()[issueKey];
		if (issueKeyFieldConfiguration && issueKeyFieldConfiguration[fieldKey]) {
			setState({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...getState()[issueKey],
					[fieldKey]: {
						...getState()[issueKey][fieldKey],
						...fieldConfiguration,
					},
				},
			});
		}
	};

const actions = {
	setIssueConfig,
	setIssuesConfig,
	mergeIssueConfig,
	mergeIssuesConfig,
	setFieldConfigValue,
} as const;

export type Actions = typeof actions;

// this is a global state
const store = createStore<State, Actions>({
	name: 'issue-field-config',
	initialState: {},
	actions,
});

const getIssueFieldConfig = createSelector<
	State,
	{ issueKey: IssueKey },
	IssueConfiguration,
	IssueConfiguration
>(
	(state, props) => state[props.issueKey],
	(issue) => issue,
);

export const useIssueFieldConfigStore = createHook(store, {
	selector: getIssueFieldConfig,
});

const getFieldConfig = createSelector<
	State,
	{ issueKey: IssueKey; fieldKey: string },
	IssueConfiguration,
	string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldConfiguration<any> | null
>(
	(state, props) => state[props.issueKey],
	(state, props) => props.fieldKey,
	(issue, fieldKey) => {
		// issue being undefined means we haven't fetched it yet
		// issue being null means it doesn't exist.
		// either way we just return that value
		if (issue == null) {
			return issue;
		}
		// if we have the field key, return it. Otherwise return null to show
		// we have done a data fetch of the issue but the field doesn't exist
		return issue[fieldKey] ? issue[fieldKey] : null;
	},
);

const getFieldConfigWithLabel = createSelector<
	State,
	{ issueKey: IssueKey; fieldKey: string },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldConfiguration<any> | null,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(FieldConfiguration<any> & { label?: string }) | null
>(getFieldConfig, (fieldConfig) => {
	if (fieldConfig?.title === undefined) {
		return fieldConfig;
	}
	return {
		...fieldConfig,
		label: fieldConfig.title,
	};
});

const getFieldOptionsOverrides = createSelector(
	(state: State, props: { issueKey: IssueKey; fieldKey: string }) => state[props.issueKey],
	(state, props) => props.fieldKey,
	(issue, fieldKey) => {
		// issue being undefined means we haven't fetched it yet
		// issue being null means it doesn't exist.
		// either way we just return that value
		if (issue == null) {
			return issue;
		}
		// if we have the field key, return it. Otherwise return null to show
		// we have done a data fetch of the issue but the field doesn't exist
		return issue[fieldKey] ? issue[fieldKey].allowedValuesOverrides : null;
	},
);

export const useFieldConfigStore = createHook(store, {
	selector: getFieldConfig,
});

export const useFieldConfigStoreActions = createHook(store, {
	selector: null,
});

const setOverrides =
	(
		issueKey: IssueKey,
		fieldKey: string,
		fieldConfigOverrides: FieldConfigurationWithOptionalKeys<unknown>,
	) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const issueKeyFieldConfigurationWithOverrides = getState()[issueKey] ?? {};
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: {
				...issueKeyFieldConfigurationWithOverrides,
				[fieldKey]: {
					...issueKeyFieldConfigurationWithOverrides[fieldKey],
					...fieldConfigOverrides,
				},
			},
		});
	};

const resetOverridesStore =
	(issueKey: IssueKey) =>
	({ setState }: StoreActionApi<State>) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		setState({ [issueKey as string]: undefined });
	};

const overridesActions = {
	setFieldConfigOverridesValue: setOverrides,
	resetOverridesStore,
} as const;

export type OverridesActions = typeof overridesActions;

// Store contains overrides to the field config. It is used by UI modifications to override field config properties by apps.
// UI modifications is a Forge extension point providing apps JS API to override field's properties: name, description, isEditable
// and limit visibility of select fields options.
const overridesStore = createStore<State, OverridesActions>({
	name: 'issue-field-overrides',
	initialState: {},
	actions: overridesActions,
});

export const useIssueOverridesStore = createHook(overridesStore, {
	selector: getIssueFieldConfig,
});

export const useFieldOverridesStore = createHook(overridesStore, {
	selector: getFieldConfig,
});

export const useFieldOverridesStoreWithLabel = createStateHook(overridesStore, {
	selector: getFieldConfigWithLabel,
});

export const useOverridesStoreActions = createActionsHook(overridesStore);

export const useFieldOptionsOverrides = createHook(overridesStore, {
	selector: getFieldOptionsOverrides,
});

const getIsFieldBusySelector = (state: State, args: { fieldId?: string; issueKey: IssueKey }) => {
	const { fieldId, issueKey } = args;
	return fieldId !== undefined && !!state[issueKey]?.[fieldId]?.isBusy;
};
export const useIsFieldBusy = createStateHook(overridesStore, { selector: getIsFieldBusySelector });
