import type { ComponentType } from 'react';
import { chain } from 'icepick';
import get from 'lodash/get';
import type { TableColumn } from '@atlassian/jira-virtual-table/src/app/table/types.tsx';
import type { HeaderComponentProps } from '@atlassian/jira-virtual-table/src/model/columns/index.tsx';
import type { CreateCellType } from '../../../../common/create-cell/view.tsx';
import {
	MIN_WIDTH,
	BUILTIN_COLUMN_CONFIGURATIONS,
	BUILTIN_HTML_COLUMN_CONFIGURATIONS,
	FALLBACK_COLUMN_CONFIGURATION,
} from '../../../../common/field-configuration/index.tsx';
import CheckboxHeader from '../../../../common/fields/checkbox/header/index.tsx';
import Checkbox from '../../../../common/fields/checkbox/index.tsx';
import { COLUMN_GUTTER } from '../../../../common/fields/common/styled/index.tsx';
import SortColumnHeader from '../../../../common/sort-column-header/index.tsx';
import type {
	BuiltinJsonColumnConfiguration,
	MergedHtmlColumnProps,
	MergedJsonColumnProps,
} from '../../../../model/columns/index.tsx';
import type { FieldType } from '../../../../model/fields/types.tsx';
import type { ColumnProps, FieldDataSelectorProps } from '../../../../model/index.tsx';

const getAdditionalColumnWidth = (index: number, totalColumns: number): number =>
	(index > 0 ? COLUMN_GUTTER.left : 0) + (index < totalColumns - 1 ? COLUMN_GUTTER.right : 0);

const finaliseColumnConfiguration = (
	config: TableColumn,
	index: number,
	totalColumns: number,
): TableColumn => {
	const additionalWidth = getAdditionalColumnWidth(index, totalColumns);
	return (
		chain(config)
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			.updateIn(['minWidth'], (minWidth) => Math.max(MIN_WIDTH, minWidth + additionalWidth))
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			.updateIn(['maxWidth'], (maxWidth) => Math.max(MIN_WIDTH, maxWidth + additionalWidth))
			.value()
	);
};

const getKnownFieldType = (fieldType: string): FieldType | null =>
	// @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'keyof DataSelectorPropsMap | null'.
	BUILTIN_COLUMN_CONFIGURATIONS[fieldType] ? fieldType : null;

export const getJsonColumnConfiguration = <T extends FieldType>(
	fieldType: T,
): BuiltinJsonColumnConfiguration<T> => get(BUILTIN_COLUMN_CONFIGURATIONS, fieldType);

const getJsonColumnProps = <T extends FieldType>(
	fieldType: T,
	columnProps: ColumnProps,
): MergedJsonColumnProps<T> => {
	const builtinJsonColumnConfiguration: BuiltinJsonColumnConfiguration<T> =
		getJsonColumnConfiguration(fieldType);
	return {
		...builtinJsonColumnConfiguration,
		fieldId: columnProps.id,
		fieldType,
	};
};

const getHtmlColumnProps = (columnProps: ColumnProps): MergedHtmlColumnProps => {
	const { fieldType } = columnProps;
	const columnConfiguration =
		BUILTIN_HTML_COLUMN_CONFIGURATIONS[fieldType] || FALLBACK_COLUMN_CONFIGURATION;
	return {
		...columnConfiguration,
		fieldId: columnProps.id,
		fieldType,
	};
};

const transformJsonColumnProps = <T extends FieldType>(
	fieldType: T,
	columnProps: ColumnProps,
	FieldDataSelector: ComponentType<FieldDataSelectorProps>,
	HeaderComponent: ComponentType<HeaderComponentProps>,
	createCell: CreateCellType,
) => {
	const { id, title } = columnProps;
	const mergedColumnProps = getJsonColumnProps(fieldType, columnProps);
	const { minWidth, maxWidth, canBeMultiLine, FieldComponent } = mergedColumnProps;
	const jsonComponentArgs = { fieldType, FieldComponent };
	return {
		id,
		title,
		minWidth: columnProps.minWidth || minWidth,
		maxWidth: columnProps.maxWidth || maxWidth,
		canBeMultiLine,
		// @ts-expect-error - TS2345 - Argument of type '{ fieldType: T; FieldComponent: ComponentType<JsonComponentProps<T>>; }' is not assignable to parameter of type 'JsonComponentArgs<keyof DataSelectorPropsMap>'.
		CellComponent: createCell(jsonComponentArgs, mergedColumnProps, FieldDataSelector),
		HeaderComponent,
	};
};

const transformHtmlColumnProps = (
	// @ts-expect-error - TS7006 - Parameter 'columnProps' implicitly has an 'any' type.
	columnProps,
	FieldDataSelector: ComponentType<FieldDataSelectorProps>,
	HeaderComponent: ComponentType<HeaderComponentProps>,
	createCell: CreateCellType,
) => {
	const { id, title } = columnProps;
	const mergedColumnProps = getHtmlColumnProps(columnProps);
	const { minWidth, maxWidth, canBeMultiLine } = mergedColumnProps;

	return {
		id,
		title,
		minWidth: columnProps.minWidth || minWidth,
		maxWidth: columnProps.maxWidth || maxWidth,
		canBeMultiLine,
		CellComponent: createCell(null, mergedColumnProps, FieldDataSelector),
		HeaderComponent,
	};
};

export const transformColumns = (
	FieldDataSelector: ComponentType<FieldDataSelectorProps>,
	columns: ColumnProps[],
	createCell: CreateCellType,
): TableColumn[] =>
	columns
		.map((column: ColumnProps) => {
			const knownFieldType = getKnownFieldType(column.fieldType);
			if (column.fieldType === 'checkbox') {
				return {
					id: 'checkbox',
					title: '',
					minWidth: 25, // real width will be +24px, see getAdditionalColumnWidth
					maxWidth: 25,
					CellComponent: Checkbox,
					HeaderComponent: CheckboxHeader,
				};
			}
			if (knownFieldType) {
				return transformJsonColumnProps(
					knownFieldType,
					column,
					FieldDataSelector,
					// @ts-expect-error - Argument of type 'ConnectedComponent<ComponentType<any>, { [x: string]: any; [x: number]: any; [x: symbol]: any; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: Store<...> | undefined; } | { ...; }>' is not assignable to parameter of type 'ComponentType<HeaderComponentProps>'.
					SortColumnHeader,
					createCell,
				);
			}
			return transformHtmlColumnProps(
				column,
				FieldDataSelector,
				// @ts-expect-error - Argument of type 'ConnectedComponent<ComponentType<any>, { [x: string]: any; [x: number]: any; [x: symbol]: any; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: Store<...> | undefined; } | { ...; }>' is not assignable to parameter of type 'ComponentType<HeaderComponentProps>'.
				SortColumnHeader,
				createCell,
			);
		})
		// @ts-expect-error - TS2345 - Argument of type '(column: TableColumn, index: number) => TableColumn' is not assignable to parameter of type '(value: { id: any; title: any; minWidth: any; maxWidth: any; canBeMultiLine: boolean | undefined; CellComponent: CellComponentType; HeaderComponent: ComponentType<HeaderComponentProps>; } | { ...; }, index: number, array: ({ ...; } | { ...; })[]) => TableColumn'.
		.map((column: TableColumn, index: number) =>
			finaliseColumnConfiguration(column, index, columns.length),
		);

export type ColumnsTransformer = typeof transformColumns;

export default transformColumns;
