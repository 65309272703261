import { createSelector } from 'reselect';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import {
	customFieldsToDisplay,
	ecosystemCustomFields,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { fieldsSelector } from '../../../common/state/selectors/field-selector.tsx';

/**
 */
export const customFieldItemsSelector = createSelector(fieldsSelector, (fields) =>
	Object.keys(fields).filter((id) => {
		const type = getFieldType(fields[id]);
		return type && customFieldsToDisplay().includes(type);
	}),
);

export const ecosystemCustomFieldIdsSelector = createSelector(fieldsSelector, (fields) =>
	Object.keys(fields).filter((id) => {
		const type = getFieldType(fields[id]);
		return type && ecosystemCustomFields.includes(type);
	}),
);
