import type { Observable } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-servicedesk-common/src/utils/fetch/index.tsx';
import type { PollResponse } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	projectKey: string,
	baseUrl: string,
	projectStateHash: string,
): Observable<PollResponse> => {
	const hashString = projectStateHash ? `?projectStateHash=${projectStateHash}` : '';
	return fetchJson$(
		`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues/poll${hashString}`,
		{
			method: 'GET',
		},
	);
};
