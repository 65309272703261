import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

// No delay on this spinner; we don't know if the monolith had previously
// provided one. Better to increase risk a very brief display of a spinner
// than have the spinner vanish and return.
const LoadingSpinner = () => (
	<SpinnerContainer>
		<Spinner size="large" testId="servicedesk-common.ui.components.page-spinner.spinner" />
	</SpinnerContainer>
);

export default LoadingSpinner;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	position: 'relative',
	top: token('space.500', '40px'),
	width: '100%',
	textAlign: 'center',
});
