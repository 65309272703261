// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	filterQuerySubmittedAction,
	isFilterFocusedAction,
	isNewFilterQueryPendingAction,
} from '../../../../state/actions/filter/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { getCurrentFilterQuery } from '../../../../state/selectors/filter/index.tsx';
import { getQueueId } from '../../../../state/selectors/queue/index.tsx';
import IssueSearch from './view.tsx';

export type Props = JSX.LibraryManagedAttributes<
	typeof IssueSearch,
	ComponentProps<typeof IssueSearch>
>;

const mapDispatchToProps = (
	dispatch: Dispatch<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		type: any;
	}>,
) => ({
	onChange: (text: string | null) => {
		dispatch(filterQuerySubmittedAction(text));
	},
	onChangedIsPendingSubmission: (loading: boolean) =>
		dispatch(isNewFilterQueryPendingAction(loading)),
	onFocusChange: (isFocused: boolean) => dispatch(isFilterFocusedAction(isFocused)),
});

const mapStateToProps = (state: State) => ({
	queueId: getQueueId(state),
	filterQuery: getCurrentFilterQuery(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(IssueSearch);
