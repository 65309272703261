import type {
	UserProps,
	StatusProps,
	IssueTypeProps,
	ReporterProps,
	LabelsProps,
	PriorityProps,
	MultiUserFieldItem,
} from '../common/types.tsx';

export type OnChangeHandler<T> = (issueKey: string, newValue: T) => void;

export type InlineEditableFieldProps<T> = {
	isEditable?: boolean;
	key: string | undefined;
	overrideDisabledAppearance?: boolean;
	onChange: OnChangeHandler<T>;
};

export type AssigneeInitialization = {
	avatarUrl?: string;
	displayName?: string;
	emailAddress?: string;
};

export type AssigneeDataSelectorProps = {
	fieldType: 'assignee' | 'reporterWithIcon';
	value: UserProps | undefined;
} & InlineEditableFieldProps<UserProps | undefined>;

export type ReporterDataSelectorProps = {
	fieldType: 'reporter';
	value: ReporterProps | null;
};

export type CreatorDataSelectorProps = {
	fieldType: 'creator';
	value: UserProps;
};

export type IssueKeyDataSelectorProps = {
	fieldType: 'issuekey';
	value: string;
};

export type IssueTypeDataSelectorProps = {
	fieldType: 'issuetype';
	value: IssueTypeProps;
};

export type StatusDataSelectorProps = {
	fieldType: 'status';
	value: StatusProps;
} & InlineEditableFieldProps<StatusProps>;

export type SummaryDataSelectorProps = {
	fieldType: 'summary';
	value: string;
};

export type LabelsDataSelectorProps = {
	fieldType: 'labels';
	value: LabelsProps;
};

export type PriorityDataSelectorProps = {
	fieldType: 'priority';
	value: PriorityProps | undefined;
};

export type DateDataSelectorProps = {
	fieldType: 'date';
	value: string;
};

export type MeatballMenuDataSelectorProps = {
	issueId: number;
	fieldType: 'meatball';
	useRefetch: () => {
		onRefetch: (issueKey: string) => void;
	};
};

export const REQUEST_PARTICIPANTS_FIELD_TYPE =
	'com.atlassian.servicedesk:sd-request-participants' as const;

export type RequestParticipantsDataSelectorProps = {
	fieldType: 'com.atlassian.servicedesk:sd-request-participants';
	value: MultiUserFieldItem[];
};
