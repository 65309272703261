import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { NewFieldValues } from './types.tsx';

const getUpdateFieldValueUrl = (issueKey: IssueKey) => `/rest/api/2/issue/${issueKey}`;

export const putFieldValue$ = (
	issueKey: IssueKey,
	newFieldValues: NewFieldValues,
): Observable<void> =>
	fetchJson$(getUpdateFieldValueUrl(issueKey), {
		method: 'PUT',
		body: JSON.stringify({ fields: newFieldValues }),
	});
