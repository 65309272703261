import { connect } from '../../../../../../../common/table-redux.tsx';
import type { ColumnId } from '../../../../../../../model/columns/index.tsx';
import { getColumnMinWidth } from '../../../../../../../state/consumer/selectors/columns/index.tsx';
import type { State } from '../../../../../../../state/types.tsx';
import { ErrorInlineMessage } from './view.tsx';

type Props = {
	columnId: ColumnId;
};

export default connect(
	(state: State, { columnId }: Props) => ({
		columnWidth: getColumnMinWidth(state, columnId),
	}),
	{},
)(ErrorInlineMessage);
