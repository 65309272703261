import React from 'react';
import { styled } from '@compiled/react';

type Props = {
	backgroundColor: string;
};

export default function ChildBar(props: Props) {
	const { backgroundColor } = props;
	return <BarBackground backgroundColor={backgroundColor} />;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarBackground = styled.div<{ backgroundColor: string }>({
	position: 'absolute',
	top: 0,
	right: 0,
	left: 0,
	height: '2px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	opacity: 0.75,
});
