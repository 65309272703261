const spaUrlRegex = '(/jira/servicedesk/projects/.*)';
const assetsPageRegex = '(/jira/servicedesk/assets/.*)';
const insightPageRegex = '(/jira/servicedesk/insight/.*)';

export const addLinkInterceptor = (
	element: HTMLElement | null,
	onNavigateClicked: (url: string) => void,
) => {
	if (!element) {
		return;
	}

	element.addEventListener('click', (e: Event) => {
		// @ts-expect-error - TS2339 - Property 'closest' does not exist on type 'EventTarget'.
		const anchor = e.target && e.target.closest('a');
		if (anchor) {
			const { href } = anchor;
			// We only want to target links, so that we can do the navigation ourselves.
			if (href != null) {
				// @ts-expect-error - TS2339 - Property 'getAttribute' does not exist on type 'EventTarget'.
				const isAuiTab = e.target.getAttribute('role') === 'tab';
				// We don't want to target links that open in a new tab
				// @ts-expect-error - TS2339 - Property 'target' does not exist on type 'EventTarget'.
				const opensInNewTab = e.target.target === '_blank';
				// We only want to target links that navigate somewhere else in this SPA
				const isPartOfSpa = href && href.match && href.match(spaUrlRegex) !== null;
				// Insight legacy pages are special because they are rendered outside of a servicedesk project context
				const isInsightPage =
					href &&
					href.match &&
					(href.match(assetsPageRegex) !== null || href.match(insightPageRegex) !== null);

				// Don't do it for '#' hrefs - these act like buttons
				const hrefWithoutPath =
					// @ts-expect-error - TS2339 - Property 'getAttribute' does not exist on type 'EventTarget'.
					e.target.getAttribute('href') || anchor.getAttribute('href');

				const actsAsButton =
					hrefWithoutPath && hrefWithoutPath.startsWith && hrefWithoutPath.startsWith('#');

				if (!isAuiTab && !opensInNewTab && isPartOfSpa && !actsAsButton) {
					e.preventDefault();
					onNavigateClicked(href.match(spaUrlRegex)[0]);
				} else if (!isAuiTab && !opensInNewTab && !actsAsButton && isInsightPage) {
					e.preventDefault();
					let matchUrl = href.match(insightPageRegex);
					if (matchUrl == null) {
						matchUrl = href.match(assetsPageRegex);
					}
					onNavigateClicked(matchUrl[0]);
				}
			}
		}
	});
};
