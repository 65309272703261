import { useContext, useRef } from 'react';
import find from 'lodash/find';
import values from 'lodash/values';
import { QUEUES_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import type { ItemMap } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { FavoriteChangeContext } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';

type Callback = (id: string, value: boolean) => void;
type WatchProps = {
	projectKey: string;
	category: ItsmPractice;
	changedItems: ItemMap | undefined;
};

export const useOnFavoritesChanged = (
	projectKey: string,
	category: ItsmPractice,
	queues: Page[] | null,
	callback: Callback,
) => {
	const { items } = useContext(FavoriteChangeContext);
	const queueItemChanges = items[QUEUES_ITEM_TYPE];
	const previousChangesRef = useRef<WatchProps>({
		projectKey,
		category,
		changedItems: undefined,
	});
	const { current: previousChanges } = previousChangesRef;
	const hasPotentialChanges =
		previousChanges.projectKey !== projectKey ||
		previousChanges.category !== category ||
		previousChanges.changedItems !== queueItemChanges;

	if (hasPotentialChanges) {
		previousChangesRef.current = {
			projectKey,
			category,
			changedItems: queueItemChanges,
		};

		if (queues) {
			values(queueItemChanges).forEach(({ id, value }) => {
				const queue = find(queues, { id: Number(id) });

				if (queue && queue.favourite !== value) {
					callback(id, value);
				}
			});
		}
	}
};
