import { connect } from '../../../common/table-redux.tsx';
import {
	isContentStale,
	shouldHideLoadingIndicator,
} from '../../../state/consumer/selectors/index.tsx';
import { hasHorizontalScrollbar } from '../../../state/selectors/index.tsx';
import type { State } from '../../../state/types.tsx';
import Content from './view.tsx';

export default connect(
	(state: State) => ({
		isHorizontalScrollVisible: hasHorizontalScrollbar(state),
		isContentStale: isContentStale(state),
		shouldHideLoadingIndicator: shouldHideLoadingIndicator(state),
	}),
	{},
)(Content);
