import * as icepick from 'icepick';

export const assocUnsafe = <T, V>(collection: T, key: number | string, value: V): T =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.assoc as any)(collection, key, value);
export const setUnsafe = <T, V>(collection: T, key: number | string, value: V): T =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.set as any)(collection, key, value);
export const dissocUnsafe = <T,>(collection: T, key: number | string): T =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.dissoc as any)(collection, key);
export const unsetUnsafe = <T,>(collection: T, key: number | string): T =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.unset as any)(collection, key);
export const assocInUnsafe = <T, V>(collection: T, path: (number | string)[], value: V): T =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.assocIn as any)(collection, path, value);
export const setInUnsafe = <T, V>(collection: T, path: (number | string)[], value: V): T =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.setIn as any)(collection, path, value);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInUnsafe = <T,>(collection: T, path: (number | string)[]): any =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	(icepick.getIn as any)(collection, path);
export const updateInUnsafe = <T, V>(
	collection: T,
	path: (number | string)[],
	callback: (value: V) => V, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
) => (icepick.updateIn as any)(collection, path, callback);

class IcepickUnsafeWrapper<T> {
	constructor(collection: T) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		this.wrapper = (icepick.chain as any)(collection);
	}

	// @ts-expect-error - TS2694 - Namespace '"/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/node_modules/@types/icepick/index"' has no exported member 'IcepickWrapper'.
	wrapper: icepick.IcepickWrapper<T>;

	value(): T {
		return this.wrapper.value();
	}

	freeze(): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.freeze().value());
	}

	assoc<V>(key: string | number, value: V): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.assoc as any)(key, value).value());
	}

	set<V>(key: string | number, value: V): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.set as any)(key, value).value());
	}

	dissoc(key: string | number): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.dissoc as any)(key).value());
	}

	unset(key: string | number): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.unset as any)(key).value());
	}

	assocIn<V>(path: (string | number)[], value: V): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.assocIn as any)(path, value).value());
	}

	setIn<V>(path: (string | number)[], value: V): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.setIn as any)(path, value).value());
	}

	getIn<V>(path: (string | number)[], value: V): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.getIn as any)(path, value).value());
	}

	updateIn<V>(path: (string | number)[], callback: (value: V) => V): IcepickUnsafeWrapper<T> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return new IcepickUnsafeWrapper((this.wrapper.updateIn as any)(path, callback).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	assign<S1>(source: S1): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.assign(source).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	assign<S1, S2>(s1: S1, s2: S2): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.assign(s1, s2).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	assign<S1, S2, S3>(s1: S1, s2: S2, s3: S3): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.assign(s1, s2, s3).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	assign<S1, S2, S3, S4>(s1: S1, s2: S2, s3: S3, s4: S4): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.assign(s1, s2, s3, s4).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	extend<S1>(source: S1): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.extend(source).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	extend<S1, S2>(s1: S1, s2: S2): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.extend(s1, s2).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	extend<S1, S2, S3>(s1: S1, s2: S2, s3: S3): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.extend(s1, s2, s3).value());
	}

	// @ts-expect-error - TS2393 - Duplicate function implementation.
	extend<S1, S2, S3, S4>(s1: S1, s2: S2, s3: S3, s4: S4): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.extend(s1, s2, s3, s4).value());
	}

	merge<S1>(source: S1): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.merge(source).value());
	}

	thru(val: (arg1: T) => T): IcepickUnsafeWrapper<T> {
		return new IcepickUnsafeWrapper(this.wrapper.thru(val).value());
	}
}

export const chainUnsafe = <T,>(collection: T): IcepickUnsafeWrapper<T> =>
	new IcepickUnsafeWrapper(collection);
