import { combineEpics } from 'redux-observable';
import cells from './cells/navigate/epic.tsx';
import columns from './columns/index.tsx';
import header from './header/size/epic.tsx';
import hydrate from './hydrate/index.tsx';
import items from './items/index.tsx';
import rows from './rows/index.tsx';
import scrolling from './scrolling/index.tsx';
import size from './table-size/epic.tsx';

export default combineEpics(cells, hydrate, size, columns, items, rows, scrolling, header);
