import {
	type ORIGINAL_ESTIMATE_GQL_FIELD,
	TIME_ESTIMATE_TYPE,
	ISSUE_FIELD_NUMBER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggOriginalEstimateToLegacyField: AggTransformerFunction<
	typeof ORIGINAL_ESTIMATE_GQL_FIELD
> = (node) => {
	if (node.type !== TIME_ESTIMATE_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: node.originalEstimate?.timeInSeconds ?? null,
		schema: {
			configuration: null,
			custom: null,
			system: node.type,
			items: null,
			type: ISSUE_FIELD_NUMBER_CF_TYPE,
			renderer: null,
		},
	};
};
