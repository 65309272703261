import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	overflow: 'auto',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Content = styled.div({
	padding: `${token('space.100', '8px')} ${token('space.300', '24px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.100', '8px')}`,
	overflowWrap: 'break-word',
});
