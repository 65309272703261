// REJECT_NAVIGATION
export const REJECT_NAVIGATION = 'navigation.bridge.epic.REJECT_NAVIGATION' as const;

export type RejectNavigationAction = {
	type: typeof REJECT_NAVIGATION;
};

export const rejectNavigationAction = (): RejectNavigationAction => ({
	type: REJECT_NAVIGATION,
});

// CONFIRM_NAVIGATION
export const CONFIRM_NAVIGATION = 'navigation.bridge.epic.CONFIRM_NAVIGATION' as const;

export type ConfirmNavigationAction = {
	type: typeof CONFIRM_NAVIGATION;
};

export const confirmNavigationAction = (): ConfirmNavigationAction => ({
	type: CONFIRM_NAVIGATION,
});
