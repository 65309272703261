import queryString from 'query-string';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useNewIssueViewLockInStatus } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';

const OLD_ISSUE_VIEW_QUERY_PARAM = 'oldIssueView';

const hasOldIssueViewQueryParam = (search: string) => {
	if (__SERVER__) {
		const query = queryString.parse(search);
		return Object.keys(query).includes(OLD_ISSUE_VIEW_QUERY_PARAM);
	}
	const query = new URLSearchParams(search);
	return query.get('oldIssueView') === 'true';
};

const useNewIssueViewLockedIn = (): boolean => {
	const { error, newIssueViewLockedIn } = useNewIssueViewLockInStatus();

	if (newIssueViewLockedIn !== null) {
		return newIssueViewLockedIn;
	}
	if (error) {
		log.safeErrorWithoutCustomerData(
			'spa-commons.utils.should-show-legacy-issue-view-provider',
			'Error getting new issue view lock in status, default to false',
		);
	}

	return false;
};

export const useShouldShowLegacyIssueView = (
	isProjectSimplified: boolean,
	canSeeNewIssueView: boolean,
	search: string,
) => {
	const isNewIssueViewLockedIn = useNewIssueViewLockedIn();
	if (isNewIssueViewLockedIn) {
		return { showLegacyIssueView: false };
	}
	if (canSeeNewIssueView) {
		return { showLegacyIssueView: hasOldIssueViewQueryParam(search) };
	}
	return { showLegacyIssueView: true };
};
