import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { ServerParentIssue } from '@atlassian/jira-issue-shared-types/src/common/types/parent-issue-type.tsx';

type AggJiraParent = {
	id: string;
	key: string;
	issueId: string;
	webUrl: string;
	issueColorField: {
		color: {
			colorKey: Color | null;
		};
	};
	issueTypeField: {
		issueType: {
			avatar: {
				xsmall: string;
			};
			name: string;
		};
	};
	summaryField: {
		text: string;
	};
};

export const transformAggParentFieldValueToLegacyFieldValue = (
	parentIssue: AggJiraParent | null,
): ServerParentIssue | null => {
	if (!parentIssue) {
		return null;
	}
	return {
		id: parentIssue.issueId,
		ari: parentIssue.id,
		key: parentIssue.key,
		self: parentIssue.webUrl,
		color: parentIssue.issueColorField?.color?.colorKey,
		fields: {
			summary: parentIssue.summaryField?.text,
			issuetype: {
				name: parentIssue.issueTypeField?.issueType?.name,
				iconUrl: parentIssue.issueTypeField?.issueType?.avatar?.xsmall,
				// We do not have this data
				id: '',
				self: '',
				description: '',
				subtask: false,
				avatarId: 0,
				hierarchyLevel: 0,
			},
		},
	};
};
