import {
	NONE_PANEL_INSTANCE_ID,
	FORGE_FIELD_TYPE_PREFIX,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';

export const toForgeKey = (appKey: string, moduleKey: string): string => `${appKey}/${moduleKey}`;

export const toForgeKeyWithDate = (
	appKey: string,
	moduleKey: string,
	panelInstanceId: string,
	dateAdded: string,
): string => `${appKey}/${moduleKey}-${panelInstanceId}-${dateAdded}`;

export const toForgeKeyFromExtensionId = (
	extensionId: string,
	panelInstanceId: string | null | undefined,
	dateAdded: number,
): string =>
	panelInstanceId != null
		? `${extensionId}-${panelInstanceId}-${dateAdded}`
		: `${extensionId}-${NONE_PANEL_INSTANCE_ID}-${dateAdded}`;

export const toConnectKey = (appKey: string, moduleKey: string): string => `${appKey}_${moduleKey}`;

export const toEcosystemKeys = (
	extensionId: string,
): {
	appKey: string;
	moduleKey: string;
} => {
	const [prefix, appId, envId, ...rest] = extensionId.split('/');
	const appKey = [prefix, appId, envId].join('/');
	const moduleKey = rest.join('/');
	return {
		appKey,
		moduleKey,
	};
};

export const isForgeExtension = (appKey: string): boolean =>
	appKey.startsWith(FORGE_FIELD_TYPE_PREFIX);

export const isKeyMatchPrefix = (key: string, prefix: string): boolean =>
	key.startsWith(prefix) && /^\w+-\d+$/.test(key.slice(prefix.length));
