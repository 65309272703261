export const EDIT_ISSUE = 'issueaction-edit-issue';

export const ASSIGN = 'issueaction-assign-issue';
export const ASSIGN_TO_ME = 'issueaction-assign-to-me hide-from-opsbar';

export const COMMENT_ISSUE = 'issueaction-comment-issue add-issue-comment';
export const LOG_WORK = 'issueaction-log-work';
export const AGILE_BOARD =
	'issueaction-greenhopper-rapidboard-operation js-rapidboard-operation-issue';

export const RANK_TO_TOP = 'issueaction-greenhopper-rank-top-operation';
export const RANK_TO_BOTTOM = 'issueaction-greenhopper-rank-bottom-operation';

export const ATTACH_FILE = 'unified-attach-file';
export const ATTACH_SCREENSHOT = 'issueaction-attach-screenshot-html5';

export const ADD_VOTE = 'issueaction-vote-issue';
export const REMOVE_VOTE = 'issueaction-unvote-issue';
export const VIEW_VOTERS = 'issueaction-view-voters';

export const WATCH_ISSUE = 'issueaction-watch-issue';
export const UNWATCH_ISSUE = 'issueaction-unwatch-issue';
export const WATCHERS = 'issueaction-manage-watchers';

export const CREATE_SUBTASK = 'issueaction-create-subtask';
export const CONVERT_TO_ISSUE = 'issueaction-subtask-to-issue';
export const CONVERT_TO_SUBTASK = 'issueaction-issue-to-subtask';

export const CREATE_BRANCH = 'devstatus-cta-link';
export const CREATE_LINKED_ISSUE = 'issueaction-create-linked-issue';

export const MOVE = 'issueaction-move-issue';
export const LINK = 'issueaction-link-issue';
export const CLONE = 'issueaction-clone-issue';
export const LABELS = 'issueaction-edit-labels';
export const DELETE = 'issueaction-delete-issue';

export const ADD_FIELD = 'issueaction-fields-add';
export const PERMISSION_HELPER = 'permissionhelper-trigger';
export const NOTIFICATION_HELPER = 'notificationhelper-trigger';

export const LINK_ISSUE = 'add-jira-issue-link-link';
export const LINK_CONFLUENCE_PAGE = 'add-confluence-page-link-link';
export const ADD_WEB_LINK = 'add-web-link-link';

export const OPERATION_IDS = {
	EDIT_ISSUE,
	ASSIGN,
	ASSIGN_TO_ME,
	COMMENT_ISSUE,
	LOG_WORK,
	AGILE_BOARD,
	RANK_TO_TOP,
	RANK_TO_BOTTOM,
	ATTACH_FILE,
	ATTACH_SCREENSHOT,
	ADD_VOTE,
	REMOVE_VOTE,
	VIEW_VOTERS,
	WATCH_ISSUE,
	UNWATCH_ISSUE,
	WATCHERS,
	CREATE_SUBTASK,
	CONVERT_TO_ISSUE,
	CONVERT_TO_SUBTASK,
	CREATE_BRANCH,
	CREATE_LINKED_ISSUE,
	MOVE,
	LINK,
	CLONE,
	LABELS,
	DELETE,
	ADD_FIELD,
	PERMISSION_HELPER,
	NOTIFICATION_HELPER,
} as const;

export const LINK_IDS = {
	LINK_ISSUE,
	LINK_CONFLUENCE_PAGE,
	ADD_WEB_LINK,
} as const;

export const DIALOG_FIELDS = [
	EDIT_ISSUE,
	COMMENT_ISSUE,
	LOG_WORK,
	RANK_TO_TOP,
	RANK_TO_BOTTOM,
	ATTACH_FILE,
	ADD_VOTE,
	REMOVE_VOTE,
	WATCH_ISSUE,
	UNWATCH_ISSUE,
	LINK,
	CLONE,
	LABELS,
	DELETE,
	ADD_FIELD,
	PERMISSION_HELPER,
	NOTIFICATION_HELPER,
	ASSIGN,
	ASSIGN_TO_ME,
	ATTACH_SCREENSHOT,
	CREATE_LINKED_ISSUE,
	CREATE_SUBTASK,
] as const;

export const SKIP_FIELDS = [
	EDIT_ISSUE,
	ASSIGN,
	ASSIGN_TO_ME,
	ATTACH_SCREENSHOT,
	CREATE_LINKED_ISSUE,
	CREATE_BRANCH,
	LINK,
] as const;

export const URL_OVERRIDE_FIELDS = {
	[PERMISSION_HELPER]: '/secure/admin/PermissionHelperAdmin.jspa',
	[NOTIFICATION_HELPER]: '/secure/admin/NotificationHelperAdmin.jspa',
} as const;

export const DIALOG_DATA = {
	[ASSIGN]: {
		id: 'assign-dialog',
		issueMsg: 'thanksIssueAssigned',
	},
	[ASSIGN_TO_ME]: {
		id: 'assign-dialog',
		issueMsg: 'thanksIssueAssigned',
	},
	[COMMENT_ISSUE]: {
		id: 'comment-add-dialog',
		issueMsg: 'thanksIssueCommented',
	},
	[ATTACH_FILE]: {
		id: 'attach-file-dialog',
		issueMsg: 'thanksIssueAttached',
	},
	[DELETE]: {
		id: 'delete-issue-dialog',
		issueMsg: 'thanksIssueDeleted',
	},
	[CLONE]: {
		id: 'clone-issue-dialog',
		issueMsg: 'thanksIssueCloned',
	},
	[LOG_WORK]: {
		id: 'edit-log-work-dialog',
		issueMsg: 'thanksIssueWorklogged',
	},
	[LABELS]: {
		id: 'edit-labels-dialog',
		issueMsg: 'thanksIssueLabelled',
	},
} as const;
