import React from 'react';
import fireErrorAnalytics, {
	type AnalyticsPayload,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { SLA_VIEW_MODE_QUEUE } from '@atlassian/jira-issue-sla/src/common/constants.tsx';
import type { SlaExtraErrorAttributes } from '@atlassian/jira-issue-sla/src/common/types.tsx';
import { AsyncLazySla } from '@atlassian/jira-issue-sla/src/ui/sla/async.tsx';
import { SLA_CUSTOM_FIELD_TYPE } from '../../../model/fields/json-fields/custom-fields/types.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import { isClientFetchError } from './utils.tsx';

export const METRIC_NOT_FOUND = 'metric not found';

type Props = JsonComponentProps<typeof SLA_CUSTOM_FIELD_TYPE>;

const Sla = (props: Props) => {
	const {
		dataSelectorProps: { value },
		tableInjectedProps: { isLastColumn },
	} = props;

	const onError = (location: string, error: Error, attributes: SlaExtraErrorAttributes) => {
		// This check is added to supress the false burn events the client errors are creating in SLA Queue View Reliability SLOs
		// These errors are safe to be ignored
		// Platform team added a check to filter them from SFX & Splunk but still JSM Bumblebee team is seeing SLO Burn events
		// Ref: https://product-fabric.atlassian.net/browse/P2X-1109
		if (isClientFetchError(error)) return;
		const errorPayload: AnalyticsPayload = {
			error,
			meta: {
				id: 'slaQueueView',
				packageName: 'jiraIssueTable',
			},
			attributes: {
				...attributes,
				location,
				fieldType: SLA_CUSTOM_FIELD_TYPE,
			},
			sendToPrivacyUnsafeSplunk: true,
		};
		return fireErrorAnalytics(errorPayload);
	};

	return (
		<AsyncLazySla
			isLastColumn={isLastColumn}
			value={value}
			onError={onError}
			viewMode={SLA_VIEW_MODE_QUEUE}
		/>
	);
};

export default Sla;
