/* global JIRA */

import React, { forwardRef } from 'react';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExternalProps = Record<any, any>;

/**
 * This replicates the shortcuts that are defined in the `issueaction` context
 * of system-keyboard-shortcuts-plugin.xml in the monolith.
 *
 * It was preferred to copy the functionality than to muck around the various
 * WRM layers to get it to work.
 */
export const LegacyIssueShortcuts = forwardRef<HTMLElement, ExternalProps>((_, ref) => {
	// Provides a callback to click on the element found via
	// the given query selector.
	const clickElementCallback = (querySelector: string) => () => {
		const element = ref && 'current' in ref ? ref.current : null;
		if (element) {
			const elementToClick = element.querySelector(querySelector);
			if (elementToClick) {
				// @ts-expect-error - Property 'click' does not exist on type 'Element'
				elementToClick.click();
			}
		}
	};

	return (
		<Shortcuts
			keyMap={{
				e: {
					callback: clickElementCallback('.issueaction-edit-issue'),
				},
				a: {
					callback: clickElementCallback('.issueaction-assign-issue'),
				},
				i: {
					callback: clickElementCallback('.issueaction-assign-to-me'),
				},
				l: {
					callback: clickElementCallback('#edit-labels'),
				},
				s: {
					callback: clickElementCallback('.viewissue-share'),
				},
				m: {
					callback: () => {
						// :bow: PLEASE DON'T JUDGE :bow:
						// Shortcuts doesn't pass through the event so we can't
						// preventDefault(). This prevents 'm' being typed into
						// the comment box after you activate the shortcut.
						setTimeout(() => JIRA.Issue.invokeCommentTrigger(), 0);
					},
				},
			}}
		/>
	);
});
