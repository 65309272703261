import type { AppPropsWithProjectKey } from '@atlassian/jira-servicedesk-common/src/model/types.tsx';

export const RESET_PROJECT_CONTEXT = 'state.actions.page.RESET_PROJECT_CONTEXT' as const;

export type ResetProjectContextAction = {
	type: typeof RESET_PROJECT_CONTEXT;
	payload: {
		projectContext: AppPropsWithProjectKey;
	};
};

export const resetProjectContextAction = (
	projectContext: AppPropsWithProjectKey,
): ResetProjectContextAction => ({
	type: RESET_PROJECT_CONTEXT,
	payload: {
		projectContext,
	},
});

export type Action = ResetProjectContextAction;
