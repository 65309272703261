import { type BatchAction, BATCH } from 'redux-batched-actions';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { Action } from '../../../state/actions/types.tsx';

export const withUnpackedBatchActions = (action$: ActionsObservable<Action | BatchAction>) =>
	action$.mergeMap((action: Action | BatchAction) => {
		if (action.type === BATCH) {
			return Observable.of(...action.payload);
		}
		return Observable.of(action);
	});
