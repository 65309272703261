import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type PostIncidentReviewBenefitModal from './ui/benefit-modal/index.tsx';

type AsyncPostIncidentReviewBenefitModalProps = {
	queueCategory: QueueCategory;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPostIncidentReviewBenefitModal = lazyAfterPaint<typeof PostIncidentReviewBenefitModal>(
	() =>
		import(/* webpackChunkName: "async-post-incident-review-benefit-modal" */ './ui/benefit-modal'),
	{
		ssr: false,
	},
);

export const AsyncPostIncidentReviewBenefitModal = (
	props: AsyncPostIncidentReviewBenefitModalProps,
) => (
	<JSErrorBoundary
		id="postIncidentReviewBenefitModalComponent"
		packageName="jiraServiceDeskPostIncidentReviewTour"
		teamName="itsm-agni"
		fallback="unmount"
	>
		<Placeholder name="post-incident-review-benefit-modal" fallback={null}>
			<LazyPostIncidentReviewBenefitModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
