import { styled } from '@compiled/react';
import { DEFAULT_ROW_HEIGHT } from '../table/constants.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CellWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${DEFAULT_ROW_HEIGHT}px`,
	boxSizing: 'border-box',
	width: '100%',
});
