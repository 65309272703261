import React from 'react';
import { FlagsBoundary } from '@atlassian/jira-flag-boundary/src/index.tsx';
import { type AsyncFlagProps, CommonFlag } from '../index.tsx';

const AkFlag = ({ packageName = 'flags', ...props }: AsyncFlagProps) => (
	<FlagsBoundary packageName={packageName}>
		<CommonFlag flagType="info" {...props} />
	</FlagsBoundary>
);

export default AkFlag;
