import { connect } from '../../../../common/table-redux.tsx';
import DynamicColumnComponent from './dynamic/index-dumb.tsx';
import Column from './index-dumb.tsx';

export default connect(
	() => ({
		DynamicColumnComponent,
	}),
	{},
)(Column);
