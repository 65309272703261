import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/bufferTime';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireOperationalAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-operational-event.tsx';
import { isRowLoading, getRow } from '../../../state/consumer/selectors/rows/index.tsx';
import { getVirtualBoundaries } from '../../../state/selectors/index.tsx';
import type { State } from '../../../state/types.tsx';
import { FIRE_LOADING_ROW_ANALYTICS } from './action.tsx';

// Buffer all loading row analytics events for 15s and fire as one event
const BUFFER_TIME = 15000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIRE_LOADING_ROW_ANALYTICS)
		.bufferTime(BUFFER_TIME)
		.do((actions) => {
			const eventAttributes: Record<string, string | boolean | number>[] = [];
			let lastAnalyticsEvent;

			actions.forEach((action) => {
				const { rowId, timeVisibleFor, numberOfLoadingRows, reasonType, analyticsEvent } =
					action.payload;
				const state = store.getState();
				const { displayStart, displayEnd } = getVirtualBoundaries(state);

				// check if the rowId was removed from the tree due to row data update
				const rowStillExists = !!getRow(state, rowId);

				// check if the row, if exists, is still in 'loading' state
				const isStillLoading = rowStillExists && isRowLoading(state, rowId);

				// check if the row is in the display boundary
				const intRowId = parseInt(rowId, 10);
				const isInDisplayBoundary =
					intRowId >= 0 && intRowId >= displayStart && intRowId <= displayEnd;

				// gather all event attributes into one for all the events fired within BUFFER_TIME
				eventAttributes.push({
					displayStart,
					displayEnd,
					numberOfLoadingRows,
					reasonType,
					timeVisibleFor,
					rowIdStillExists: !!rowStillExists,
					isInDisplayBoundary,
					gotFulfilled: !isStillLoading,
				});
				lastAnalyticsEvent = analyticsEvent;
			});

			// fire the event
			lastAnalyticsEvent &&
				fireOperationalAnalytics(lastAnalyticsEvent, {
					containerType: 'loadingRow',
					attributes: {
						eventAttributes,
					},
				});
		})
		.switchMap(() => Observable.empty<never>());
