import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';

const isEcClientEnabled = () => {
	const experiences: string[] = getFeatureFlagValue<string>(
		'hela.ec.client.integration.jsm',
		'',
	).split(',');
	return Array.isArray(experiences) && experiences.includes('jsm-issue-view');
};

export const saveIssueMutationAnalyticsToCache = (
	event: ChangeEvent,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	/* saveIssueMutationToCache from useEcClient() */
	saveIssueMutationToCache: Function,
) => {
	if (isEcClientEnabled() && event?.type === ChangeEventTypes.FIELD_CHANGED) {
		const mutationFailedAnalyticsPayload = {
			errorMsg: 'FAILED_JSM_ISSUEVIEW',
			error: true,
		};
		const analyticsEventObj = createAnalyticsEvent({});
		const analyticsDataKey = {
			analyticsEventObj,
			analyticsMetadata: {
				scenario: 'jsm-issue-view',
				type: event.type,
			},
		};

		saveIssueMutationToCache(
			new IssueMutation(event?.issueId, MutationSource.UPDATE),
			analyticsDataKey,
			mutationFailedAnalyticsPayload,
		);
	}
};
