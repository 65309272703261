export type UrlState = {
	module: string;
	item: string;
	secondaryItem: string;
	tertiaryItem: string;
	quaternaryItem: string;
	quinternaryItem: string;
};

export const generatePageState = ({
	newRoute,
	isSettings,
}: {
	newRoute: string;
	isSettings: boolean;
}): UrlState => {
	const processedSubRoute = newRoute.match(`/projects/[^/]+${isSettings ? '/settings' : ''}/(.*)`);

	const subRoute = processedSubRoute && processedSubRoute.length >= 2 ? processedSubRoute[1] : '';
	const splitUrl = subRoute.split('/');
	return {
		module: splitUrl[0],
		item: splitUrl[1],
		secondaryItem: splitUrl[2],
		tertiaryItem: splitUrl[3],
		quaternaryItem: splitUrl[4],
		quinternaryItem: splitUrl[5],
	};
};
