import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import {
	SERVICE_DESK_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { Permissions } from '@atlassian/jira-issue-view-common-constants/src/permissions.tsx';
import type { ApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	appPermissionsSelector,
	tenantHasActiveServiceDeskLicenseSelector,
} from './context-selector.tsx';
import { entitiesSelector, projectTypeSelector } from './issue-selector.tsx';

const internalPermissionsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.permissions,
);

const jsdPermissionsSetThatNeedApplicationRoleAndAgentPerm = new Set([
	// In the BE this is not technically a veto'd permission, however it simplifies the FE code to pretend that it is.
	'canUseServiceDeskAgentFeatures',
	// Normal veto'd permissions as per ServiceDeskCollaboratorPermissionOverrideStrategy in BE
	'canEditIssues',
	'canTransitionIssues',
	'canScheduleIssues',
	'canMoveIssue',
	'canAssignIssues',
	'canBeAssignedToIssues',
	'canDeleteIssue',
	'canManageWatchers',
	'canEditAllComments',
	'canDeleteAllComments',
	'canDeleteAllAttachments',
	'canLogWork',
	'canEditOwnWorklogs',
	'canEditAllWorklogs',
	'canDeleteOwnWorklogs',
	'canDeleteAllWorklogs',
	// These should be vetoed, but aren't requested by JFE yet
	'canResolveIssues',
	'canViewReadonlyWorkflow',
	'canCloseIssues',
	'canModifyReporter',
	'canSetIssueSecurity',
	'canArchiveIssue',
	'canUnarchiveIssue',
]);

export const permissionsSelector = createSelector(
	appPermissionsSelector,
	projectTypeSelector,
	internalPermissionsSelector,
	tenantHasActiveServiceDeskLicenseSelector,
	(
		applicationPermissions: ApplicationPermissions,
		projectType: ProjectType | null,
		permissions: Permissions,
		tenantHasActiveServiceDeskLicense: boolean,
	): Permissions => {
		if (projectType === SERVICE_DESK_PROJECT && tenantHasActiveServiceDeskLicense) {
			const { canUseServiceDeskAgentFeatures } = permissions;
			const hasJsdAppRole = applicationPermissions.hasServiceDeskAccess;

			return mapValues(permissions, (hasPermission, key) =>
				jsdPermissionsSetThatNeedApplicationRoleAndAgentPerm.has(key)
					? hasPermission && hasJsdAppRole && canUseServiceDeskAgentFeatures
					: hasPermission,
			);
		}

		return permissions;
	},
);

export const canBeAssignedToIssuesPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => !!permissions.canBeAssignedToIssues,
);

export const canAdministerProjectPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => !!permissions.canAdministerProject,
);

export const canAdministerJiraPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => !!permissions.canAdministerJira,
);

const canViewWatchersPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canViewWatchers,
);

const canManageWatchersPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canManageWatchers,
);

export const canModifyReporterPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canModifyReporter,
);

export const canAssignIssuesPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canAssignIssues,
);

export const canExportIssueSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canExportIssue,
);

export const watchersPermissionsSelector = createSelector(
	canViewWatchersPermissionsSelector,
	canManageWatchersPermissionsSelector,
	(canViewWatchers, canManageWatchers) => ({
		canViewWatchers,
		canManageWatchers,
	}),
);

export const canBrowseUsersSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canBrowseUsers,
);

export const canViewDevToolsPermissionsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canViewDevTools,
);

export const canUseServiceDeskAgentFeaturesSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canUseServiceDeskAgentFeatures,
);
