import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type RADIO_GQL_FIELD,
	RADIO_BUTTONS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';

export const transformAggRadioSelectToLegacyField: AggTransformerFunction<
	typeof RADIO_GQL_FIELD
> = (node) => {
	if (node.type !== RADIO_BUTTONS_CF_TYPE) return undefined;

	const value = node.selectedOption
		? {
				id: node.selectedOption?.optionId,
				value: node.selectedOption?.value,
				isDisabled: node.selectedOption?.isDisabled,
			}
		: null;

	const allowedValues = transformAggConnection(node.radioSelectFieldOptions, (option) => ({
		id: option.optionId,
		value: option.value ?? null,
		ari: option.id,
	}));

	return {
		...transformAggNodeToLegacyField(node),
		value,
		allowedValues,
		schema: {
			type: 'option',
			renderer: PLAIN_TEXT,
			custom: node.type,
			system: null,
			items: null,
			configuration: null,
		},
	};
};
