import type { ColumnId } from '../../../../model/columns/index.tsx';

export const SET_RESIZING_COLUMN = 'ops.columns.resizing.set.SET_RESIZING_COLUMN' as const;

export type SetResizingColumnAction = {
	type: typeof SET_RESIZING_COLUMN;
	payload: ColumnId;
};

export const set = (payload: ColumnId): SetResizingColumnAction => ({
	type: SET_RESIZING_COLUMN,
	payload,
});
