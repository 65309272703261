import find from 'lodash/find';
import { Observable } from 'rxjs/Observable';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getFieldTypesEligibleForJsonData } from '@atlassian/jira-servicedesk-queues-common/src/json-fields/index.tsx';
import {
	PREFETCHER_SOURCE,
	REST_SOURCE,
	type IssueListSource,
	type QueueId,
	SSR_SOURCE,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type {
	IssueListResponse,
	PrefetcherIssueResponse,
} from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import {
	getPrefetchedIssuePromise,
	unsetPrefetchedIssuePromise,
} from '@atlassian/jira-servicedesk-queues-common/src/services/issue/index.tsx';
import type { LoadedIssueList } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/transform/types.tsx';
import {
	getBaseUrl,
	getProjectKey,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props/index.tsx';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/retryWhen';
import get, { type IssueListFetchArgs } from '../../../rest/issue/index.tsx';
import updated from '../../../rest/updated/index.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getCreateAnalyticsEvent } from '../../../state/selectors/analytic/index.tsx';
import { getCurrentFilterQuery } from '../../../state/selectors/filter/index.tsx';
import {
	getCategory,
	getColumnIds,
	getColumns,
	getJql,
	getQueueId,
} from '../../../state/selectors/queue/index.tsx';
import {
	getIsAscendingOrder,
	getSortOrder,
	getSortedByColumn,
} from '../../../state/selectors/sorting/index.tsx';
import {
	getIsInitialLoad,
	getSsrIssueList,
	getSsrIssuesError,
} from '../../../state/selectors/ssr/index.tsx';
import { getIssueHash } from '../../../state/selectors/update-metadata/index.tsx';
import { removeSortOrderInLocalStorage, storeSortOrderInLocalStorage } from '../../sort/index.tsx';
import {
	getIssuesInLocalStorage,
	storeIssuesInLocalStorage,
	maybeIssuesInLocalStorage,
} from '../local-storage/index.tsx';
import getTransformedIssues from '../transform/index.tsx';
import {
	sendIssuelistStalenessAnalytics,
	fireInitialLoadSsrAnalyticEventWithState,
} from './analytics/index.tsx';
import {
	INITIAL_LOAD_ALL,
	INITIAL_LOAD_SSR_HIT,
	INITIAL_LOAD_SSR_ERROR,
	INITIAL_LOAD_SSR_TIMEOUT_ERROR,
	INITIAL_LOAD_CACHE_HIT,
	INITIAL_BROWSER_ISSUE_LIST_FETCH,
} from './analytics/types.tsx';

// Number of retries. Excludes the first call.
const NUMBER_OF_RETRIES = 2;

const ISSUES_FETCH_STARTED_MARK = 'jsd.performance.profile.queues.issues.fetch.from.server.started';
const ISSUES_FETCH_COMPLETED_MARK =
	'jsd.performance.profile.queues.issues.fetch.from.server.completed';

/**
 * Due to various reason (sorting field id is not longer valid, custom field has been removed, sorting field is no longer
 * visible in the queue, etc), the sorting returned from the /issuelist endpoint maybe different from what we
 * stored in the local storage. We should keep them in sync after every ajax call.
 */
const updateManualSortingInLocalStorage = (queueId: QueueId, result: IssueListResponse): void => {
	const { columns, isUsingDefaultSorting } = result;
	const manualSortingColumn = isUsingDefaultSorting
		? undefined
		: find(columns, (column) => column.sortOrder != null);

	if (manualSortingColumn != null) {
		storeSortOrderInLocalStorage(
			queueId,
			manualSortingColumn.fieldId,
			// @ts-expect-error - TS2345 - Argument of type 'SortOrder | undefined' is not assignable to parameter of type 'SortOrder'.
			manualSortingColumn.sortOrder,
		);
	} else {
		removeSortOrderInLocalStorage(queueId);
	}
};

const isRetryableStatusCode = (statusCode: number) => statusCode === 429 || statusCode >= 500;

const MAX_RETRY_DELAY_MS = 15000;
const BASE_DELAY_MS = 5000;
/**
 * Exponential backoff with max delay and jitter
 * https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
 */
const maxExpBackoffWithJitter = (retries: number, baseDelay: number, maxDelay: number) => {
	const exponential = Math.pow(2, retries) * baseDelay;
	const delay = Math.min(exponential, maxDelay);
	const jitter = Math.random();
	const delayWithJitter = Math.floor(jitter * delay);
	return delayWithJitter;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	startIndex: number,
	endIndex: number,
	isUpdate: boolean,
	isSourceFromPage: boolean,
	bypassCache: boolean,
	state: State,
	isQueueFiltering = false,
	unsetIsInitialLoad: () => void,
	isSourceFromSort: boolean,
): Observable<LoadedIssueList> => {
	const queueId = getQueueId(state);
	const columnIds = getColumnIds(state);
	const columns = getColumns(state);
	const jql = getJql(state);
	const columnTypesAsJson = getFieldTypesEligibleForJsonData();
	const issuesPerPage = endIndex - startIndex + 1;
	const baseUrl = getBaseUrl(state);
	const projectKey = getProjectKey(state);
	const sortedBy = getSortedByColumn(state);
	const isAscendingOrder = getIsAscendingOrder(state);
	const sortOrder = getSortOrder(state);
	const category = getCategory(state);
	const filterQuery = getCurrentFilterQuery(state);
	const isFilterRequested = filterQuery !== null;
	let retries = 0;

	const storeAndTransform = (
		result: IssueListResponse,
		source: IssueListSource,
	): LoadedIssueList => {
		updateManualSortingInLocalStorage(queueId, result);
		if (startIndex === 0 && !isFilterRequested) {
			let createAnalyticsEvent;
			if (fg('view_queues_local_storage_data_updated')) {
				createAnalyticsEvent = getCreateAnalyticsEvent(state);
			}
			storeIssuesInLocalStorage(
				result,
				jql,
				queueId,
				sortedBy,
				sortOrder,
				isUpdate,
				createAnalyticsEvent,
			);
		}
		return getTransformedIssues(result, source, isFilterRequested, isFilterRequested);
	};

	const fireQueueFilteringAnalytics = (result: IssueListResponse) => {
		const createAnalyticsEvent = getCreateAnalyticsEvent(state);
		if (createAnalyticsEvent) {
			// extract the query text from the filter query
			const queryText = filterQuery?.match(/"([^"]*)\*"/);
			fireTrackAnalytics(createAnalyticsEvent({}), 'jsmQueueFiltering searched', {
				filteredQueueLength: result.issues.length,
				filterLength: queryText ? queryText[1].length : 0,
			});
		}
	};

	const fireIssueListRetryAnalyticsSuccess = (numberOfRetries: Number) => {
		const createAnalyticsEvent = getCreateAnalyticsEvent(state);
		if (createAnalyticsEvent) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'jsmIssueList retrySucceeded', {
				numberOfRetries,
			});
		}
	};

	const fireIssueListRetryAnalyticsFailed = (statusCode: Number) => {
		const createAnalyticsEvent = getCreateAnalyticsEvent(state);
		if (createAnalyticsEvent) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'jsmIssueList retryFailed', {
				statusCode,
			});
		}
	};

	// analytic event to help study issuelist ssr
	const fireInitialLoadSsrAnalyticEvent = fireInitialLoadSsrAnalyticEventWithState(state);

	const shouldDeleteSorting = isSourceFromSort && sortedBy === undefined;

	const requestData: IssueListFetchArgs = {
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string' for projectKey.
		projectKey,
		baseUrl,
		jql,
		columnNames: columnIds,
		columnTypesAsJson,
		startIndex,
		issuesPerPage,
		orderBy: sortedBy,
		isAscendingOrder,
		category,
		shouldDeleteSorting: fg('migrate_sorting_to_user_property') ? shouldDeleteSorting : undefined,
		queueId: fg('migrate_sorting_to_user_property') ? queueId : undefined,
		...{ filterClause: filterQuery },
	};

	const getIssuesFromLocalStorage = () =>
		bypassCache || startIndex !== 0 || filterQuery !== null
			? Observable.empty<never>()
			: getIssuesInLocalStorage(
					queueId,
					jql,
					columns,
					columnTypesAsJson,
					filterQuery,
					sortedBy,
					sortOrder,
				);

	const ssrIssuesData = getSsrIssueList(state);
	const ssrIssuesDataError = getSsrIssuesError(state);

	const fetchRequestHandler = (isInitialLoad: boolean) => {
		setMark(ISSUES_FETCH_STARTED_MARK);
		const fetchStartTime = performance.now();
		return get(requestData)
			.flatMap((result) => {
				addSpanToAll(
					'fetch',
					'rest/servicedesk/1/servicedesk/projectKey/issuelist',
					[{ name: 'network' }],
					fetchStartTime,
					performance.now(),
				);

				if (isInitialLoad) {
					fireInitialLoadSsrAnalyticEvent(INITIAL_BROWSER_ISSUE_LIST_FETCH);
				}

				setMark(ISSUES_FETCH_COMPLETED_MARK);
				sendIssuelistStalenessAnalytics(result, false);
				if (isQueueFiltering) {
					fireQueueFilteringAnalytics(result);
				}
				if (retries > 0) {
					fireIssueListRetryAnalyticsSuccess(retries);
				}

				return Observable.of(storeAndTransform(result, REST_SOURCE));
			})
			.retryWhen((errors) =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				errors.mergeMap((error: any) => {
					retries += 1;
					if (!isRetryableStatusCode(error?.statusCode) || retries > NUMBER_OF_RETRIES) {
						fireIssueListRetryAnalyticsFailed(error.statusCode);
						return Observable.throw(error);
					}
					return Observable.of(error.statusCode).delay(
						maxExpBackoffWithJitter(retries, BASE_DELAY_MS, MAX_RETRY_DELAY_MS),
					);
				}),
			);
	};

	const getIssuesFromPrefetcher = (
		prefetchedIssuePromise: Promise<PrefetcherIssueResponse>,
		isInitialLoad: boolean,
	): Observable<LoadedIssueList> =>
		Observable.fromPromise(prefetchedIssuePromise)
			.map((response) => {
				setMark('jsd.performance.profile.queues.prefetch.resolved');
				unsetPrefetchedIssuePromise(queueId);
				return storeAndTransform(response.data, PREFETCHER_SOURCE);
			})
			.catch(() => fetchRequestHandler(isInitialLoad));

	if (sortedBy) {
		storeSortOrderInLocalStorage(queueId, sortedBy, sortOrder);
	} else {
		removeSortOrderInLocalStorage(queueId);
	}

	if (isUpdate) {
		return updated({ ...requestData, issueHash: getIssueHash(state) }).flatMap((result) => {
			sendIssuelistStalenessAnalytics(result, true);
			if (isQueueFiltering) {
				fireQueueFilteringAnalytics(result);
			}
			return Observable.of(storeAndTransform(result, REST_SOURCE));
		});
	}

	const prefetchedIssuePromise = getPrefetchedIssuePromise(queueId);

	const isInitialLoad = fg('issuelist_skip_fetch_cache_hit')
		? startIndex === 0 &&
			isSourceFromPage &&
			!isFilterRequested &&
			!isUpdate &&
			!!getIsInitialLoad(state)
		: false;

	if (isInitialLoad) {
		// initial load total traffic
		fireInitialLoadSsrAnalyticEvent(INITIAL_LOAD_ALL);
		unsetIsInitialLoad();

		if (sortedBy === undefined && fg('ssr_view_queues_issuelist')) {
			if (ssrIssuesData && !ssrIssuesDataError) {
				// initial load SSR hit traffic
				fireInitialLoadSsrAnalyticEvent(INITIAL_LOAD_SSR_HIT);
				const loadedIssueListData = storeAndTransform(ssrIssuesData, SSR_SOURCE);
				return Observable.of(loadedIssueListData);
			}

			// initial load SSR error out traffic
			if (ssrIssuesDataError) {
				if (ssrIssuesDataError.name === 'TimeoutError') {
					fireInitialLoadSsrAnalyticEvent(INITIAL_LOAD_SSR_TIMEOUT_ERROR);
				}
				fireInitialLoadSsrAnalyticEvent(INITIAL_LOAD_SSR_ERROR);
			}
		}

		const localStorageData = maybeIssuesInLocalStorage(
			queueId,
			jql,
			columns,
			columnTypesAsJson,
			filterQuery,
			sortedBy,
			sortOrder,
		);

		if (localStorageData.isSome()) {
			// initial load cache hit traffic
			fireInitialLoadSsrAnalyticEvent(INITIAL_LOAD_CACHE_HIT);
			return localStorageData.cata(Observable.empty, Observable.of);
		}
	}
	const issueListObservable =
		prefetchedIssuePromise.isSome() && !filterQuery
			? prefetchedIssuePromise
					// @ts-expect-error - TS7006 - Parameter 'promise' implicitly has an 'any' type.
					.map((promise) => getIssuesFromPrefetcher(promise, isInitialLoad))
					.orSome(Observable.empty<never>())
			: fetchRequestHandler(isInitialLoad);

	// The concat operator is best used when you need to combine multiple observables,
	// but you want their emissions to be in a specific order, one after the other.
	return Observable.concat(getIssuesFromLocalStorage(), issueListObservable);
};
