import type { BatchAction } from 'redux-batched-actions';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { LOAD_ISSUES, loadIssuesInternalAction } from '../../../state/actions/issue/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import { stopPollAction } from '../../../state/actions/update-metadata/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action | BatchAction>) =>
	action$.ofType(LOAD_ISSUES).mergeMap((action) =>
		Observable.of(
			stopPollAction(),
			// @ts-expect-error - TS2769 - No overload matches this call.
			loadIssuesInternalAction(
				action.payload.startIndex,
				action.payload.endIndex,
				// @ts-expect-error - TS2339 - Property 'payload' does not exist on type 'Action'.
				action.payload.source,
				action.payload.bypassCache,
			),
		),
	);
