const KNOWN_NETWORK_ERRORS = [
	'Failed to fetch dynamically imported module',
	'error loading dynamically imported module',
	'Importing a module script failed',
];

const isClientFetchErrorMessage = (message: string): boolean =>
	Boolean(message) &&
	!!KNOWN_NETWORK_ERRORS.find((networkErrorMessage) =>
		// Using `includes` here because the underlying fetch error messages
		// are sometimes wrapped to provide additional context, e.g.
		// "Something went wrong: ${error.message}"
		message.includes(networkErrorMessage),
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isClientFetchError = (error: any) =>
	Boolean(error) &&
	typeof error === 'object' &&
	(isClientFetchErrorMessage(error.message) ||
		(typeof error.cause === 'object' && isClientFetchErrorMessage(error.cause.message)));
