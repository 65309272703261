import { connect } from '../../../../../../common/table-redux.tsx';
import { getFlattenedWithAddedRowIds as getVisibleRowIds } from '../../../../../../state/selectors/visible-row-ids/index.tsx';
import type { State } from '../../../../../../state/types.tsx';
import List from '../common/base-list/index.tsx';
import AutoHeightList from './index-dumb.tsx';

export default connect(
	(state: State) => ({
		rowIds: getVisibleRowIds(state),
		List,
	}),
	{},
)(AutoHeightList);
