import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type JSM_REQUEST_LANGUAGE_GQL_FIELD,
	REQUEST_LANGUAGE_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';

export const transformAggJSMRequestLanguageToLegacyField: AggTransformerFunction<
	typeof JSM_REQUEST_LANGUAGE_GQL_FIELD
> = (node) => {
	if (node.type !== REQUEST_LANGUAGE_CF_TYPE) return undefined;

	return {
		...transformAggNodeToLegacyField(node),
		value: node.language,
		allowedValues: [...(node.languages ?? [])],
		schema: {
			type: 'sd-request-lang',
			renderer: PLAIN_TEXT,
			custom: REQUEST_LANGUAGE_CF_TYPE,
			system: null,
			items: null,
			configuration: null,
		},
	};
};
