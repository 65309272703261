import React from 'react';
import { ExperienceResultFromPropsTracker as ViewExperienceResultFromPropsTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import {
	type ExperienceResult,
	EXPERIENCE_SUCCEEDED,
	EXPERIENCE_FAILED,
} from '../../../../common/constants.tsx';

export type Props = {
	initialFetchStatus: ExperienceResult;
	initialFetchFailureEventAttributes?: {
		[key: string]: string | boolean | number;
	};
};
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ViewExperienceResultFromPropsTracker
		location="failed-fetching-initial-data"
		hasSucceeded={props.initialFetchStatus === EXPERIENCE_SUCCEEDED}
		hasFailed={props.initialFetchStatus === EXPERIENCE_FAILED}
		failureEventAttributes={props.initialFetchFailureEventAttributes}
	/>
);
