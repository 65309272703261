import type { IssueListSource } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types.tsx';
import {
	transformIssues,
	transformColumns,
} from '@atlassian/jira-servicedesk-queues-common/src/services/issue/index.tsx';
import type { LoadedIssueList } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/transform/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	issueListResponse: IssueListResponse,
	source: IssueListSource,
	isFilterRequested: boolean,
	isFiltered: boolean,
): LoadedIssueList => ({
	issues: transformIssues(issueListResponse.issues),
	issueHash: issueListResponse.issueHash,
	totalCount: issueListResponse.total,
	columns: transformColumns(issueListResponse.columns),
	isUsingDefaultSorting: issueListResponse.isUsingDefaultSorting,
	source,
	isFilterRequested,
	isFiltered,
});
