import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SetQueues } from '../../types.tsx';
import { transformQueues } from '../transformer/index.tsx';

const getQueuesUrl = (baseUrl: string, projectKey: string): string =>
	`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues`;

// TODO KITKAT-1091 handle error
export const setQueues: SetQueues = (baseUrl, projectKey, queues, category) =>
	performPutRequest(getQueuesUrl(baseUrl, projectKey), {
		body: JSON.stringify(transformQueues(queues, category)),
	});
