import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sortedOrder: {
		id: 'issue-table.common.sort-column-header.sorted-order',
		defaultMessage: `Sorted {sortOrder} {isDefault, select, 
                true {(Default)}
                other {}
            }`,
		description:
			'Used in a sortable issue table, to indicate in what order a column is currently sorted. e.g. Sorted A → Z, Sorted lowest → highest, etc.',
	},
	sortOrder: {
		id: 'issue-table.common.sort-column-header.sort-order',
		defaultMessage: 'Sort {sortOrder}',
		description:
			'Used in a sortable issue table, to indicate in what order a column will be sorted if the user clicks on the column header. e.g. Sort A → Z, Sort lowest → highest, etc.',
	},
	defaultSortedOrder: {
		id: 'issue-table.common.sort-column-header.default-sorted-order',
		defaultMessage: `Sorted in {isAscending, select, 
                true {ascending} 
                other {descending}
            } order {isDefault, select, 
                true {(Default)}
                other {}
            }`,
		description:
			'Used in a sortable issue table, to indicate that a field (could be any type of field) is currently sorted in ascending or descending order.',
	},
	defaultSortOrder: {
		id: 'issue-table.common.sort-column-header.default-sort-order',
		defaultMessage: `Sort in {isAscending, select, 
                true {ascending} 
                other {descending}
            } order`,
		description:
			'Used in a sortable issue table, to indicate that a field (could be any type of field) will be sorted in ascending or descending order if the user click on the column header.',
	},
});
