import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warningTitle: {
		id: 'servicedesk.queues.agent-view.flags.message.warning-title',
		defaultMessage: 'Queue data may be outdated',
		description: 'warning message for the stale data in queue due to update failure',
	},
	warningDescription: {
		id: 'servicedesk.queues.agent-view.flags.message.warning-description',
		defaultMessage: 'We are unable to refresh the queue data. Check your internet connection.',
		description: 'warning message for the stale data in queue due to update failure',
	},
	errorTitle: {
		id: 'servicedesk.queues.agent-view.flags.message.error-title',
		defaultMessage: 'Problem loading queue',
		description: 'error message for failure during data fetch while infinite scrolling',
	},
	errorDescription: {
		id: 'servicedesk.queues.agent-view.flags.message.error-description',
		defaultMessage: "We couldn't load the queue. Give it a few seconds, then try again.",
		description: 'error message for failure during data fetch while loading a queue',
	},
	errorOnScrollDescription: {
		id: 'servicedesk.queues.agent-view.flags.message.error-on-scroll-description',
		defaultMessage: "We couldn't load some requests. Check your internet connection.",
		description: 'error message for failure during data fetch while infinite scrolling',
	},
	tryAgainButton: {
		id: 'servicedesk.queues.agent-view.flags.message.try-again-button',
		defaultMessage: 'Try again',
		description: 'title of the button that lets the user reload a page',
	},
	queueDeleteFlagErrorTitle: {
		id: 'servicedesk.queues.agent-view.flags.message.queue-delete-flag-error-title',
		defaultMessage: 'Problem deleting queue',
		description: 'error message for failure during queue deletion',
	},
	queueDeleteFlagErrorDescription: {
		id: 'servicedesk.queues.agent-view.flags.message.queue-delete-flag-error-description',
		defaultMessage: "'{queueName}' couldn't be deleted. Please try again in a few seconds.",
		description:
			'error message for failure when the user has attempted to delete a queue unsuccessfully. "queueName" is the name of the queue which the user attempted to delete.',
	},
});
