import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './actions-menu/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponent = lazy(
	() => import(/* webpackChunkName: "async-queues-agent-view-actions-menu" */ './actions-menu'),
);

const Component = (props: Props) => (
	<Placeholder
		name="queues-agent-view-actions-menu"
		fallback={
			<LoadingComponent>
				<Spinner size="small" />
			</LoadingComponent>
		}
	>
		<LazyComponent {...props} />
	</Placeholder>
);

export default Component;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingComponent = styled.div({
	width: '36px',
	height: '24px',
	textAlign: 'center',
});
