import { createSelector } from 'reselect';
import type { RowId } from '../../../model/rows/index.tsx';
import {
	getRowTree,
	getTemporaryAddedRow,
	getExpandedRowIdsHash,
} from '../../consumer/selectors/rows/index.tsx';
import { getDraggingRowId, getSortedRowIdsHash } from '../../internal/selectors.tsx';
import type { State } from '../../types.tsx';
import flattenVisibleRowIds from './flatten-visible-row-ids.tsx';
import insertAnchoredRowIds from './utils/insert-anchored-row-ids.tsx';

export const getFlattenedRowIds = createSelector(
	getSortedRowIdsHash,
	// FSN-4037 Remove draggingRowId related logic during FF cleanup
	getDraggingRowId,
	getExpandedRowIdsHash,
	(sortedRowIdsHash, draggingRowId, expandedRowIdsHash) =>
		flattenVisibleRowIds(sortedRowIdsHash, draggingRowId, expandedRowIdsHash),
);

// memoize and transform to expected type for insertAnchoredRowIds utility
const getTemporaryAddedRowHash = createSelector(getTemporaryAddedRow, (temporaryAddedRow) =>
	temporaryAddedRow !== undefined
		? { [temporaryAddedRow.temporaryId]: temporaryAddedRow }
		: undefined,
);

export const getFlattenedWithAddedRowIds = createSelector(
	getFlattenedRowIds,
	getRowTree,
	getExpandedRowIdsHash,
	getTemporaryAddedRowHash,
	(flattenedRowIds, rowTree, expandedRowIdsHash, temporaryAddedRows) =>
		insertAnchoredRowIds(flattenedRowIds, rowTree, expandedRowIdsHash, temporaryAddedRows),
);

/* ============
 * === MISC ===
 * ============
 */

export const getVisibleRowsCount = (state: State): number =>
	getFlattenedWithAddedRowIds(state).length;

export const getVisibleRowPositionMapping = createSelector(
	getFlattenedWithAddedRowIds,
	(flattenedRowIds) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		flattenedRowIds.reduce<Record<string, any>>((acc, rowId, idx) => {
			acc[rowId] = idx;
			return acc;
		}, {}),
);

export const getVisibleIndexForRowId = (state: State, rowId: RowId): number | undefined =>
	getVisibleRowPositionMapping(state)[rowId];
