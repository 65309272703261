import { connect } from '../../../../common/table-redux.tsx';
import { getColumnConfiguration } from '../../../../state/consumer/selectors/columns/index.tsx';
import { getChangeHiddenColumnsCallback } from '../../../../state/consumer/selectors/index.tsx';
import { getHeaderHeight } from '../../../../state/internal/selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import ColumnConfiguration from './index-dumb.tsx';

export default connect(
	(state: State) => ({
		columnConfiguration: getColumnConfiguration(state),
		headerHeight: getHeaderHeight(state),
		onChangeHiddenColumnsRequested: getChangeHiddenColumnsCallback(state),
	}),
	{},
)(ColumnConfiguration);
