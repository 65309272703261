import { createSelector } from 'reselect';
import { ff } from '@atlassian/jira-feature-flagging';
import type {
	Callbacks,
	EmptyCallback,
	RowExpandCallback,
	RowAddCallback,
	RowPersistAddCallback,
	RowCancelAddCallback,
	ColumnExpandCallback,
	ColumnMoveCallback,
	UpdateHiddenColumnsCallback,
	ActiveItemChangeCallback,
	ActiveRowIdChangeCallback,
	SortedColumnChangeCallback,
	SortedRowIdsChangeCallback,
	DisplayedRowsChangeCallback,
	HorizontalScrollOffsetChangeCallback,
	VerticalScrollOffsetChangeCallback,
	RowListFocusFunctionChangedCallback,
	ReactPortalContainerRefChangedCallback,
} from '../../../model/callbacks/index.tsx';
import type { CellWrapperComponentType } from '../../../model/cell-component/index.tsx';
import type { Column } from '../../../model/columns/index.tsx';
import type { DetailsComponentType } from '../../../model/details/index.tsx';
import {
	type NavigationMode,
	ROW_NAVIGATION,
	CELL_NAVIGATION,
} from '../../../model/navigation/index.tsx';
import type { Optional } from '../../../model/optional/index.tsx';
import type { LoadingRowComponent } from '../../../model/rows/index.tsx';
import { TREE_TABLE, TABLE } from '../../../model/table/index.tsx';
import type { State } from '../../types.tsx';
import type { ConsumerState } from '../types.tsx';
import flattenVisibleColumnIds from './columns/flatten-visible-column-ids.tsx';
import {
	getHiddenColumnsLookupHash,
	getColumnTree,
	getExpandedColumnIdsHash,
	getVisibleCoreColumnIds,
	getSortedColumnIds,
} from './columns/index.tsx';

// GENERAL

export const getConsumerState = (state: State): ConsumerState => state.consumer;

export const isAutoHeight = (state: State): boolean => state.consumer.autoHeight;

export const isContentStale = (state: State): boolean | undefined => state.consumer.isContentStale;

export const shouldHideLoadingIndicator = (state: State): boolean | undefined =>
	state.consumer.shouldHideLoadingIndicator;

export const shouldRerenderRowOnHover = (state: State): boolean | undefined =>
	state.consumer.shouldRerenderRowOnHover;

export const showBottomDecal = (state: State): boolean => state.consumer.showDecalAtBottom;

export const getNavigationMode = (state: State): NavigationMode => state.consumer.navigationMode;

export const isInCellNavigationMode = (state: State): boolean =>
	getNavigationMode(state) === CELL_NAVIGATION;

export const isInRowNavigationMode = (state: State): boolean =>
	getNavigationMode(state) === ROW_NAVIGATION;

export const getShouldSkipVirtualization = (state: State): boolean | undefined =>
	state.consumer.shouldSkipVirtualization;

export const disableRowHighlight = (state: State): boolean => state.consumer.disableRowHighlight;

// CALLBACKS

const getConsumerCallbacks = (state: State): Callbacks => state.consumer.callbacks;

export const getRowExpansionCallback = (state: State): RowExpandCallback =>
	getConsumerCallbacks(state).onRowExpansionChangeRequested;

export const getRowAddCallback = (state: State): Optional<RowAddCallback> =>
	getConsumerCallbacks(state).onRowAddRequested;

export const getClearAddedRowsCallback = (state: State): Optional<EmptyCallback> =>
	getConsumerCallbacks(state).onClearAddedRowsRequested;

export const getPersistAddedRowCallback = (state: State): Optional<RowPersistAddCallback> =>
	getConsumerCallbacks(state).onPersistAddedRowRequested;

export const getCancelAddedRowCallback = (state: State): Optional<RowCancelAddCallback> =>
	getConsumerCallbacks(state).onCancelAddedRowRequested;

export const getColumnExpandCallback = (state: State): ColumnExpandCallback =>
	getConsumerCallbacks(state).onColumnExpansionChangeRequested;

export const getColumnMoveCallback = (state: State): Optional<ColumnMoveCallback> =>
	getConsumerCallbacks(state).onColumnMoveRequested;

export const getChangeHiddenColumnsCallback = (
	state: State,
): Optional<UpdateHiddenColumnsCallback> =>
	getConsumerCallbacks(state).onChangeHiddenColumnsRequested;

export const getActiveItemChangedCallback = (state: State): ActiveItemChangeCallback =>
	getConsumerCallbacks(state).onActiveItemChanged;

export const getActiveRowIdChangedCallback = (state: State): Optional<ActiveRowIdChangeCallback> =>
	getConsumerCallbacks(state).onActiveRowIdChanged;

export const getSortedColumnChangedCallback = (state: State): SortedColumnChangeCallback =>
	getConsumerCallbacks(state).onSortedColumnChanged;

export const getSortedRowIdsChangedCallback = (
	state: State,
): Optional<SortedRowIdsChangeCallback> => getConsumerCallbacks(state).onSortedRowIdsChanged;

export const getDisplayedRowsChangedCallback = (
	state: State,
): Optional<DisplayedRowsChangeCallback> => getConsumerCallbacks(state).onDisplayedRowsChanged;

export const getVerticalScrollOffsetChangedCallback = (
	state: State,
): Optional<VerticalScrollOffsetChangeCallback> =>
	getConsumerCallbacks(state).onVerticalScrollOffsetChanged;

export const getHorizontalScrollOffsetChangedCallback = (
	state: State,
): Optional<HorizontalScrollOffsetChangeCallback> =>
	getConsumerCallbacks(state).onHorizontalScrollOffsetChanged;

export const getOnRowListFocusFunctionChangedCallback = (
	state: State,
): Optional<RowListFocusFunctionChangedCallback> =>
	state.consumer.callbacks.onRowListFocusFunctionChanged;

export const getOnReactPortalContainerRefChangedCallback = (
	state: State,
): Optional<ReactPortalContainerRefChangedCallback> =>
	state.consumer.callbacks.onReactPortalContainerRefChanged;

// DETAILS

export const getDetailsPanelComponent = (state: State): Optional<DetailsComponentType> =>
	state.consumer.DetailsPanelComponent;

export const getNonTemporaryCellWrapper = (state: State): Optional<CellWrapperComponentType> =>
	state.consumer.NonTemporaryCellWrapper;

export const isDetailsPanelOpen = (state: State): boolean => !!getDetailsPanelComponent(state);

// COLUMNS
export const isColumnConfigurationVisible = createSelector(
	getChangeHiddenColumnsCallback,
	isDetailsPanelOpen,
	(hiddenColumnsCallback, detailsPanelOpen) =>
		hiddenColumnsCallback !== undefined && !detailsPanelOpen,
);

export const getVisibleAdditionalColumnIds = createSelector(
	getColumnTree,
	getExpandedColumnIdsHash,
	getHiddenColumnsLookupHash,
	getSortedColumnIds,
	isDetailsPanelOpen,
	(columnTree, expandedColumnIdsHash, hiddenColumnIdsHash, sortedColumnIds, isDetailsOpen) =>
		isDetailsOpen
			? []
			: flattenVisibleColumnIds(
					columnTree,
					columnTree.rootAdditionalIds,
					expandedColumnIdsHash,
					hiddenColumnIdsHash,
					sortedColumnIds,
				),
);

export const getVisibleColumnIds = createSelector(
	getVisibleCoreColumnIds,
	getVisibleAdditionalColumnIds,
	(coreColumnIds, additionalColumnIds) => coreColumnIds.concat(additionalColumnIds),
);

export const getVisibleColumnCount = createSelector(
	getVisibleColumnIds,
	(visibleColumnIds) => visibleColumnIds.length,
);

export const getDefaultAddColumnId = createSelector(
	getColumnTree,
	getVisibleColumnIds,
	(columnTree, visibleColumnIds) =>
		visibleColumnIds.find((columnId) => columnTree.columns[columnId].isDefaultAdd) ||
		visibleColumnIds[0],
);

const makeSummedDynamicColumnProperty = (
	accessor: (arg1: Column) => number,
): ((arg1: State) => number) =>
	createSelector(getVisibleColumnIds, getColumnTree, (visibleColumnIds, columnTree) =>
		visibleColumnIds
			.map((columnId) => accessor(columnTree.columns[columnId]))
			.reduce((a, b) => a + b, 0),
	);

export const getTotalDynamicColumnMaxWidth: (arg1: State) => number =
	makeSummedDynamicColumnProperty((column: Column) => column.maxWidth);

export const getTotalDynamicColumnMinWidth: (arg1: State) => number =
	makeSummedDynamicColumnProperty((column: Column) => column.minWidth);

export const getLoadingRowComponent = (state: State): Optional<LoadingRowComponent> =>
	getConsumerState(state).LoadingRowComponent;

export const getFacade = (state: State): string => getConsumerState(state).facade;

export const isTableFacade = (state: State): boolean => getFacade(state) === TABLE;

export const isTreeTableFacade = (state: State): boolean => getFacade(state) === TREE_TABLE;

export const shouldDelayRowRenderingOnScroll = (state: State): boolean => isTableFacade(state);

export const getPremiumSLAColumns = (state: State): string[] | undefined =>
	ff('platform.queue-page-error-boundaries_ro9zq')
		? getConsumerState(state).premiumSLAColumnIds
		: undefined;
