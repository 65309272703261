import memoize from 'lodash/memoize';
import type { State } from '../../../state/reducers/types.tsx';
import { getQueueId } from '../../../state/selectors/queue/index.tsx';
import messages from './messages.tsx';
import type { EmptyStateMessage } from './types.tsx';

export const getEmptyStateMessageSelector: () => EmptyStateMessage = memoize(() => {
	const randomNumber: number = Math.floor(Math.random() * Math.floor(4));
	switch (randomNumber) {
		case 0:
			return {
				header: messages.emptyQueueHeaderOne,
				description: messages.emptyQueueDescriptionOne,
			};
		case 1:
			return {
				header: messages.emptyQueueHeaderTwo,
				description: messages.emptyQueueDescriptionTwo,
			};
		case 2:
			return {
				header: messages.emptyQueueHeaderThree,
				description: messages.emptyQueueDescriptionThree,
			};
		default:
			return {
				header: messages.emptyQueueHeaderFour,
				description: messages.emptyQueueDescriptionFour,
			};
	}
});

export const getEmptyStateMessage = (state: State): EmptyStateMessage =>
	// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
	getEmptyStateMessageSelector(getQueueId(state));
