import { createContext } from 'react';
import { enableBatching } from 'redux-batched-actions';
import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import {
	type ReactReduxContextValue,
	connect as connectWrapper,
	createProvider,
} from '@atlassian/jira-react-redux/src/index.tsx';
import { STORE_KEY } from '../../common/store/index.tsx';
import rootReducer from '../reducers/index.tsx';
import type { State } from '../reducers/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createStore = (rootEpic: Epic<any, State>, initialState: Partial<State>) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initiateStore<State, any, any>({
		appName: 'IssueTable',
		// @ts-expect-error - Type 'Partial<State>' is not assignable to type '{ readonly initialAppProps: { cloudId?: string | undefined; atlassianAccountId?: string | undefined; fabricPfDirUrl?: string | undefined; }; readonly persisted: { lockedIssues: { [x: string]: { issueKey: string; positionIndex: number; fields: { ...; }; } | undefined; }; columns: { ...; }[]; providedIssueKeys: string...'.
		initialState,
		rootReducer: enableBatching(rootReducer),
		rootEpic,
	});

// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'ReactReduxContextValue<Partial<State>, AnyAction>'.
export const IssueTableContext = createContext<ReactReduxContextValue<Partial<State>>>(null);

export const Provider = createProvider(IssueTableContext);

export const connect = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapStateToProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapDispatchToProps: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mergeProps: any = undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: any = {},
) =>
	connectWrapper(mapStateToProps, mapDispatchToProps, mergeProps, {
		...options,
		context: IssueTableContext,
		storeKey: STORE_KEY,
	});
