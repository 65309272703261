import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue-table.flags.field.update.error.title',
		defaultMessage: 'Issue could not be updated',
		description: 'Title of the error flag when there was a problem updating the issue',
	},

	description: {
		id: 'issue-table.flags.field.update.error.description',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			"The ''{fieldName}'' field of {issueKey} could not be updated. {reasonMessage}",
		description: 'Description of the error flag when a field on an issue could not be updated.',
	},

	simpleDescription: {
		id: 'issue-table.flags.field.update.error.simple-description',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			"The ''{fieldName}'' field of {issueKey} could not be updated. Please try again later.",
		description:
			'Description of the error flag when a field on an issue could not be updated; additional information is not known.',
	},
});
