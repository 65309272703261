export const CLEAR_COLUMN_SORT_ORDER = 'ops.columns.sorting.clear.CLEAR_COLUMN_SORT_ORDER' as const;

export type ClearColumnSortOrderAction = {
	type: typeof CLEAR_COLUMN_SORT_ORDER;
	payload: undefined;
};

export const clear = (): ClearColumnSortOrderAction => ({
	type: CLEAR_COLUMN_SORT_ORDER,
	payload: undefined,
});
