import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import {
	type SPRINT_GQL_FIELD,
	SPRINT_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { AggSprintNode, Sprint } from '../types/fields-type.tsx';
import type { AggTransformerFunction } from '../types/transformer-type.tsx';
import {
	transformAggConnection,
	transformAggNodeToLegacyField,
} from './default-node-transformer.tsx';

const SprintOptionalKeyMap = {
	goal: 'goal',
	completeDate: 'completionDate',
	startDate: 'startDate',
	endDate: 'endDate',
} as const;

type SprintOptionalKeyMap = (typeof SprintOptionalKeyMap)[keyof typeof SprintOptionalKeyMap];

type SprintOptionsKeyType = keyof typeof SprintOptionalKeyMap;

const transformAggSprintToLegacySprint = (sprint: NonNullable<AggSprintNode>): Sprint => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const optionalValues: Record<string, any> = {};

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(Object.keys(SprintOptionalKeyMap) as Array<SprintOptionsKeyType>).forEach((key) => {
		const aggKey = SprintOptionalKeyMap[key];
		const aggValue = sprint[aggKey];

		if (aggValue !== undefined && aggValue !== null) {
			optionalValues[key] = aggValue;
		}
	});

	return {
		id: Number(sprint.sprintId),
		name: sprint.name,
		state: sprint.state?.toLowerCase(),
		boardId: Number(sprint.boardName) || 0,
		...optionalValues,
	};
};

export const transformAggSprintToLegacyField: AggTransformerFunction<typeof SPRINT_GQL_FIELD> = (
	node,
) => {
	if (node.type !== SPRINT_TYPE) return undefined;

	const sprints = transformAggConnection(
		node.selectedSprintsConnection,
		transformAggSprintToLegacySprint,
	);

	return {
		...transformAggNodeToLegacyField(node),
		value: sprints,
		schema: {
			configuration: null,
			custom: SPRINT_TYPE,
			items: 'json',
			renderer: PLAIN_TEXT,
			system: null,
			type: 'array',
		},
	};
};
