import React, { forwardRef, type Ref } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	ExpandableMenuItemTrigger,
	type ExpandableMenuItemTriggerProps,
} from '@atlassian/navigation-system';

type ExpandableMenuItemTriggerWithAnalyticsProps = ExpandableMenuItemTriggerProps & {
	onClick?: (
		event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	analytics?: {
		actionSubjectId: string;
		itemId?: string;
		[attribute: string]: boolean | number | string | undefined;
	};
};

export const EXPANDABLE_MENU_ACTION = {
	EXPAND: 'expand',
	COLLAPSE: 'collapse',
};

const ExpandableMenuItemTriggerWithAnalytics = (
	{ analytics, onClick, ...rest }: ExpandableMenuItemTriggerWithAnalyticsProps,
	ref: Ref<HTMLButtonElement>,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick: ExpandableMenuItemTriggerProps['onClick'] = (
		event,
		analyticsEvent,
		{ isExpanded },
	) => {
		onClick?.(event, analyticsEvent);

		if (!analytics) {
			return;
		}

		const { actionSubjectId, ...analyticsAttributes } = analytics;

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'navigationItem',
			}),
			actionSubjectId,
			{
				...analyticsAttributes,
				expandAction: isExpanded ? EXPANDABLE_MENU_ACTION.EXPAND : EXPANDABLE_MENU_ACTION.COLLAPSE,
			},
		);
	};

	return <ExpandableMenuItemTrigger ref={ref} onClick={handleClick} {...rest} />;
};

export default forwardRef<HTMLButtonElement, ExpandableMenuItemTriggerWithAnalyticsProps>(
	ExpandableMenuItemTriggerWithAnalytics,
);
