import { combineEpics } from 'redux-observable';
import addAnchor from './add-anchor/index.tsx';
import analytics from './analytics/epic.tsx';
import navigateToRow from './navigate/epic.tsx';
import setRowBorderWidth from './row-border/epic.tsx';
import setCalculatedRowHeight from './row-height/epic.tsx';

export default combineEpics(
	addAnchor,
	navigateToRow,
	setCalculatedRowHeight,
	setRowBorderWidth,
	analytics,
);
