import { routerMiddleware, replace } from 'react-router-redux';
import type { Middleware, Store, Reducer, PreloadedState } from 'redux';
import type { Epic } from 'redux-observable';
import type { History } from 'history';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import { RouterManager } from '@atlassian/servicedesk-frontend-routing';
import type { Action, BaseState } from '../../../../model/types.tsx';

const combineMiddleWare = <_S, _A, _D>(
	extraMiddleware: Middleware[],
	history?: History,
): Middleware[] => {
	const middlewares = extraMiddleware.slice(0);
	if (history) {
		middlewares.push(routerMiddleware(history));
	}
	return middlewares;
};

const initStore = <S extends BaseState, Dependencies>(
	storeName: string,
	initialState: PreloadedState<S>,
	rootReducer: Reducer<S>,
	extraMiddleware: Middleware[],
	rootEpic?: Epic<Action, S>,
	history?: History,
): Store<S> => {
	const middlewares = combineMiddleWare(extraMiddleware, history);
	return initiateStore<S, Action, Dependencies>({
		appName: storeName,
		initialState,
		rootReducer,
		rootEpic,
		middlewares,
	});
};

/**
 * "Create Redux store" setup code
 */
export const createStore = <S extends BaseState, _Dependencies>(
	storeName: string,
	initialState: PreloadedState<S>,
	rootReducer: Reducer<S>,
	extraMiddleware: Middleware[] = [],
	rootEpic?: Epic<Action, S>,
	history?: History,
	basename?: string,
): Store<S> => {
	const store = initStore(storeName, initialState, rootReducer, extraMiddleware, rootEpic, history);

	// Expose a route function to the JSD monolith, allowing its page-enabler
	// to manage routing.  We 'replace' the history here as JSD already
	// provides us with a history mechanism; avoids double-up.
	if (history) {
		RouterManager.setFeRouteFunction((url: string) =>
			store.dispatch(replace(url.replace(basename || '', ''))),
		);
	}
	return store;
};
