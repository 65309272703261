import { createSelector, type Selector } from 'reselect';
import type { EcosystemState } from '@atlassian/jira-issue-view-common-types/src/ecosystem-types.tsx';
import type { ForgeState } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';
import { initialState as forgeInitialState } from '../reducers/entities/forge-reducer.tsx';

export const ecosystemEntitiesSelector: Selector<State, EcosystemState> = createSelector(
	entitiesSelector,
	(entities) => entities.ecosystem || {},
);

export const forgeEntitiesSelector: Selector<State, ForgeState> = createSelector(
	entitiesSelector,
	(entities) => entities.forge || forgeInitialState,
);
