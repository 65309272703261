import React, { forwardRef, type Ref } from 'react';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MenuLinkItem, type MenuLinkItemProps } from '@atlassian/navigation-system';

export type MenuLinkItemWithAnalyticsProps<
	RouterLinkConfig extends Record<string, unknown> = never,
> = MenuLinkItemProps<RouterLinkConfig> & {
	// TODO BLU-5544 make `analytics` non-optional, after all Cosmos team usages of Nav4MenuLinkItem are updated (to pass analytics= prop)
	analytics?: {
		actionSubjectId: string;
		itemId: string;
		level: number;
		[attribute: string]: boolean | number | string | undefined;
	};
};

function MenuLinkItemWithAnalytics<RouterLinkConfig extends Record<string, unknown> = never>(
	{ analytics, onClick, ...rest }: MenuLinkItemWithAnalyticsProps<RouterLinkConfig>,
	ref: Ref<HTMLAnchorElement>,
) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick: MenuLinkItemWithAnalyticsProps['onClick'] = (event, analyticsEvent) => {
		onClick?.(event, analyticsEvent);

		if (!analytics) {
			return;
		}

		const { actionSubjectId, ...analyticsAttributes } = analytics;

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			}),
			actionSubjectId,
			analyticsAttributes,
		);
	};

	return <MenuLinkItem<RouterLinkConfig> ref={ref} onClick={handleClick} {...rest} />;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default forwardRef(MenuLinkItemWithAnalytics) as <
	RouterLinkConfig extends Record<string, unknown> = never,
>(
	props: MenuLinkItemWithAnalyticsProps<RouterLinkConfig> & {
		ref?: Ref<HTMLAnchorElement>;
	},
) => ReturnType<typeof MenuLinkItem>;
