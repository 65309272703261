import { connect } from '../../../../../common/table-redux.tsx';
import { setHorizontal as setHorizontalScrollOffset } from '../../../../../ops/scrolling/offset/action.tsx';
import { getVisibleCoreColumnsWidth } from '../../../../../state/consumer/selectors/columns/index.tsx';
import {
	getTableRowMargin,
	getHorizontalScrollOffset,
} from '../../../../../state/internal/selectors.tsx';
import {
	getVisibleAdditionalColumnsWidth,
	getHorizontalScrollWidth,
} from '../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import HorizontalScrollbar from './index-dumb.tsx';

export default connect(
	(state: State) => ({
		left: getVisibleCoreColumnsWidth(state),
		viewportWidth: getHorizontalScrollWidth(state),
		contentWidth: getVisibleAdditionalColumnsWidth(state) + getTableRowMargin(state),
		scrollLeft: getHorizontalScrollOffset(state),
	}),
	{ setHorizontalScrollOffset },
)(HorizontalScrollbar);
