import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	incidentManagementFlagTitle: {
		id: 'servicedesk.queues.agent-view.layout.jsm-assets-onboarding.incident-management-flag-title',
		defaultMessage: 'Add Assets objects to incidents',
		description: 'Onboarding flag title',
	},
	incidentManagementFlagDescription: {
		id: 'servicedesk.queues.agent-view.layout.jsm-assets-onboarding.incident-management-flag-description',
		defaultMessage:
			'Link Assets objects with incidents and gain better insight on your IT infrastructure.',
		description: 'Onboarding flag description',
	},
	incidentManagementFlagActionText: {
		id: 'servicedesk.queues.agent-view.layout.jsm-assets-onboarding.incident-management-flag-action-text',
		defaultMessage: 'View incident management settings ',
		description:
			'Onboarding flag action that navigates users to the Incident Management Settings page',
	},
	changeManagementFlagTitle: {
		id: 'servicedesk.queues.agent-view.layout.jsm-assets-onboarding.change-management-flag-title',
		defaultMessage: 'Add Assets objects to changes',
		description: 'Onboarding flag title',
	},
	changeManagementFlagDescription: {
		id: 'servicedesk.queues.agent-view.layout.jsm-assets-onboarding.change-management-flag-description',
		defaultMessage:
			'Link Assets objects with changes and gain better insight on your IT infrastructure.',
		description: 'Onboarding flag description',
	},
	changeManagementFlagActionText: {
		id: 'servicedesk.queues.agent-view.layout.jsm-assets-onboarding.change-management-flag-action-text',
		defaultMessage: 'View change management settings ',
		description:
			'Onboarding flag action that navigates users to the Change Management Settings page',
	},
});
