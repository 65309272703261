import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { openIssueAction } from '../../../../state/actions/issue/index.tsx';
import { getIssueKeyContext } from '../../../../state/selectors/ui/index.tsx';
import KeyboardShortcutListener from './view.tsx';

const mapDispatchToProps = {
	onOpenIssue: openIssueAction,
} as const;

export default connect(
	(state) => ({
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		activeIssueKey: getIssueKeyContext(state),
	}),
	mapDispatchToProps,
)(KeyboardShortcutListener);
