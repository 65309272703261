import type { Cell } from '../cells/index.tsx';
import type { ColumnId } from '../columns/index.tsx';
import type {
	CellNavigationDirection,
	RowNavigationDirection,
} from '../navigation-directions/index.tsx';
import type { RowId } from '../rows/index.tsx';

export const CELL_NAVIGATION = 'cellNavigation' as const;
export const ROW_NAVIGATION = 'rowNavigation' as const;
export const NO_NAVIGATION = 'noNavigation' as const;

export type CellNavigation = typeof CELL_NAVIGATION;
export type RowNavigation = typeof ROW_NAVIGATION;
export type NoNavigation = typeof NO_NAVIGATION;

export type NavigationMode = CellNavigation | RowNavigation | NoNavigation;

export type NavigateCell = {
	rowId: RowId;
	columnId: ColumnId;
	direction: CellNavigationDirection;
};

export type NavigateRow = {
	direction: RowNavigationDirection;
};

export const ACTIVE_ROW_TYPE = 'activeRow' as const;
export const ACTIVE_CELL_TYPE = 'activeCell' as const;

export type ActiveRow = {
	type: typeof ACTIVE_ROW_TYPE;
	rowIndex: number;
	setFocusOnRow?: boolean;
};

export type ActiveCell = {
	type: typeof ACTIVE_CELL_TYPE;
} & Cell & {
		activatedFrom?: CellNavigationDirection;
	};

export type ActiveItem = ActiveCell | ActiveRow;

export type SetActiveRowCallback = (
	arg1: Flow.Diff<
		ActiveRow,
		{
			type: typeof ACTIVE_ROW_TYPE;
		}
	>,
) => void;
