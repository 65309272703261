export const SET_HEADER_HEIGHT = 'ops.header.size.SET_HEADER_HEIGHT' as const;

export type SetHeaderHeightAction = {
	type: typeof SET_HEADER_HEIGHT;
	payload: number;
};

export const set = (height: number): SetHeaderHeightAction => ({
	type: SET_HEADER_HEIGHT,
	payload: height,
});
