import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	maximumReached: {
		id: 'issue-table.common.fields.checkbox.maximum-reached',
		defaultMessage: 'Limit reached ({limit})',
		description:
			'Tooltip to appear when the user has selected more issues for a bulk action than the system supports.',
	},
	selectThisCheckboxLabel: {
		id: 'issue-table.common.fields.checkbox.select-this-checkbox-label',
		defaultMessage: 'Select issue ({issueKey})',
		description: 'Aria label for checkbox that selects issue in the table.',
	},
});
