import { connect } from '../../../../../../../../../common/table-redux.tsx';
import type { ColumnId } from '../../../../../../../../../model/columns/index.tsx';
import { clear as clearSortingColumn } from '../../../../../../../../../ops/columns/sorting/clear/action.tsx';
import { set as setSortingColumn } from '../../../../../../../../../ops/columns/sorting/set/action.tsx';
import {
	isColumnSortable,
	getDefaultComparator,
} from '../../../../../../../../../state/consumer/selectors/columns/index.tsx';
import {
	getSortedColumnChangedCallback,
	getClearAddedRowsCallback,
} from '../../../../../../../../../state/consumer/selectors/index.tsx';

import { isAnyRowBeingAdded } from '../../../../../../../../../state/consumer/selectors/rows/index.tsx';

import { getColumnSortOrder } from '../../../../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../../../../state/types.tsx';
import ColumnSortOperationIndicator from './index-dumb.tsx';

type Props = {
	columnId: ColumnId;
};

export default connect(
	(state: State, { columnId }: Props) => ({
		sortOrder: getColumnSortOrder(state, columnId),
		isColumnSortable: isColumnSortable(state, columnId),
		isSortEnabled: !isAnyRowBeingAdded(state),
		isDefaultSortColumn: getDefaultComparator(state) === columnId,
		onClearAddedRowsRequested: getClearAddedRowsCallback(state),
		onSortedColumnChanged: getSortedColumnChangedCallback(state),
	}),
	{
		setColumnSortOrder: setSortingColumn,
		clearColumnSortOrder: clearSortingColumn,
	},
)(ColumnSortOperationIndicator);
