import React, { type SyntheticEvent, useCallback, useMemo, useEffect } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { LinkButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import ArrowUpRightIcon from '@atlaskit/icon/core/migration/arrow-up-right--open';
import { useThemeObserver } from '@atlaskit/tokens';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { JSM_EMPTY_QUEUE_CREATE_REQUEST } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import {
	fireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { usePracticeList } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/index.tsx';
import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import GenericEmptyQueueImage from './common/assets/generic.svg';
import GenericEmptyQueueImageInDarkMode from './common/assets/genericDark.svg';
import { EmptyPracticeQueue } from './common/ui/empty-practice-queue/index.tsx';
import { getPortalUrlWithQueuesReferrer } from './common/utils.tsx';
import { learnMoreUrl, MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from './constants.tsx';
import messages from './messages.tsx';

export type EmptyStateProps = {
	header: string;
	description: string;
	category: QueueCategory;
	projectId: number;
	showCTA: boolean;
};

export const EmptyQueue = (props: EmptyStateProps) => {
	const { category, projectId, showCTA = true } = props;
	const [practices] = usePracticeList();

	const { data: projectContext } = useProjectContext();
	const [{ data: servicedeskContext }] = useServiceDeskContext();
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const openCreateIssueDialogModern = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'emptyQueueCreateIssueButton', {
				isAnyWorkCategoryEnabled: false,
			});
			openIssueCreateModal({
				triggerPointKey: JSM_EMPTY_QUEUE_CREATE_REQUEST,
			});
		},
		[openIssueCreateModal],
	);

	useEffect(() => {
		fireScreenAnalytics(createAnalyticsEvent({}));
	}, [createAnalyticsEvent]);

	const redirectToPortal = useCallback(
		(_event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'emptyQueueGoToPortalButton');
		},
		[],
	);

	const { formatMessage } = useIntl();

	const isAdminAnd1DFoCAQEnabled =
		category === NO_CATEGORY &&
		projectContext?.isProjectAdmin &&
		expValEquals('jsm_1d_fo_core_actions', 'cohort', 'variation');

	const portalURL = useMemo(
		() => getPortalUrlWithQueuesReferrer(servicedeskContext),
		[servicedeskContext],
	);

	const primaryAction = useMemo(() => {
		if (showCTA) {
			return isAdminAnd1DFoCAQEnabled ? (
				<LinkButton
					appearance="primary"
					onClick={redirectToPortal}
					data-pid={projectId}
					href={portalURL}
					target="_blank"
					isDisabled={!portalURL}
					testId="servicedesk-queues-empty-queue.create-issue-button"
					iconAfter={ArrowUpRightIcon}
				>
					{formatMessage(messages.emptyQueueRaiseRequestInPortalButtonLabel)}
				</LinkButton>
			) : (
				<Button
					appearance="primary"
					onClick={openCreateIssueDialogModern}
					data-pid={projectId}
					testId="servicedesk-queues-empty-queue.create-issue-button"
				>
					{formatMessage(messages.emptyQueueButtonLabel)}
				</Button>
			);
		}
	}, [
		formatMessage,
		isAdminAnd1DFoCAQEnabled,
		openCreateIssueDialogModern,
		portalURL,
		projectId,
		redirectToPortal,
		showCTA,
	]);

	// If ITSM Project, render the ITSM empty states
	if (category !== undefined && practices && practices.length > 1) {
		return <EmptyPracticeQueue projectId={projectId} />;
	}

	const IPHButton = (
		<HelpPanelButton
			articleId="5b7BuVaVJqmbyCZ2mbMYHD"
			appearance="link"
			spacing="none"
			fallbackComponent={
				<a target="_blank" href={learnMoreUrl}>
					{formatMessage(messages.emptyQueueLearnMoreText)}
				</a>
			}
		>
			{formatMessage(messages.emptyQueueLearnMoreText)}
		</HelpPanelButton>
	);

	return (
		<EmptyState
			header={formatMessage(messages.emptyQueueHeader)}
			description={
				<FormattedMessage
					{...messages.emptyQueueDescription}
					values={{
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						p: (chunks: string) => <p>{chunks}</p>,
						IPHArticle: IPHButton,
						break: <br />,
					}}
				/>
			}
			imageUrl={isDarkMode ? GenericEmptyQueueImageInDarkMode : GenericEmptyQueueImage}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			primaryAction={primaryAction}
		/>
	);
};

export default EmptyQueue;
