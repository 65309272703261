// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';

const NAVIGATION_API_MODULE = 'servicedesk/jira/api';

// Try to require navigation api over 500ms, otherwise we'll fallback.
const REQUIRE_TIMEOUT = 100;
const REQUIRE_RETRIES = 5;

type ItemManager = {
	deleteItem: (arg1: string) => Promise<void>;
};

export type NavigationApi = {
	onExpandStart: (arg1: () => void) => () => void;
	onCollapseStart: (arg1: () => void) => () => void;
	isCollapsed(): boolean;
	getItemManager: (arg1: string) => Promise<ItemManager>;
	viewIds: {
		SERVICE_DESK_QUEUES: string;
	};
};

export const createNavigationApi = (): Promise<NavigationApi> =>
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge<{ getNavigationApi: () => NavigationApi }>({
		name: NAVIGATION_API_MODULE,
		timeout: REQUIRE_TIMEOUT,
		tentatives: REQUIRE_RETRIES,
		shouldLogError: false,
	}).then((jiraApi) => jiraApi.getNavigationApi());
